/*!
=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client"; // New import for React 18
import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-google";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import TermsAndConditionsPage from "views/Pages/TermsAndConditionsPage";


import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

import Amplify, { Auth } from "aws-amplify";
import LoginPage from "views/Pages/LoginPage";
Amplify.configure({
  Auth: {
    userPoolId: "us-west-2_C6xJ1UnEm",
    userPoolWebClientId: "f4b59vhc8l6jg4n2mlveeqsdc",
  },
});

loadReCaptcha();

const hist = createBrowserHistory();
const rootElement = document.getElementById("root");

// Auth.currentAuthenticatedUser()
//   .catch((err) => {

//     if (err === "not authenticated") {
//       window.location.href = "/auth/login-page";
//     }
//   });

if (rootElement) {
  const root = createRoot(rootElement); // Use createRoot instead of ReactDOM.render

  root.render(
    <Router history={hist}>
      <Switch>
        <Route path="/terms" component={TermsAndConditionsPage} />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="*" to="/admin/project-design" />
      </Switch>
    </Router>
  );
}
