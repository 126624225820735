import React, { useState, useEffect } from 'react';

import { Grid, Typography, Card, TextField, Box, CircularProgress, Divider, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { fetchLabAPI, generateTechnicalSpecificationsAPI, updateSelectedIdea, updateLabIdeas } from "views/APIs/APIUtility";

// Styles and Assets
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
// Material-UI Styles and Connectors
import { makeStyles, withStyles } from "@material-ui/core/styles";

import MDEditor from "@uiw/react-md-editor";

// Material-UI Icons
import { Adjust, Assistant, BubbleChart, Category, CloudUpload } from "@material-ui/icons";

// Contexts
import NotificationContext from "views/Components/Context.js";

import Button from "components/CustomButtons/Button";
import Alert from "views/Components/Alert";
import { set } from 'date-fns';


const useStyles = makeStyles((theme) => ({
    gridItem: {
        // Full width on extra-small devices (mobile)
        flexBasis: '100%',
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
            // On medium and larger screens, each item takes 20% of the width
            flexBasis: '20%',
            maxWidth: '20%',
        },
    },
    dropzone: {
        minHeight: "100px",
        backgroundColor: "#f0f0f0",
        borderColor: quCreateColor,
        border: "2px dashed",
        borderRadius: "5px",
        padding: "20px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    previewText: {
        color: "#007BFF", // Change the text color
    },
    dropzoneText: {
        color: "#888888", // Grey color for instructions
    },
    resourceTile: {
        height: "100%", // Ensure all tiles have the same height
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // To ensure the content inside each tile is spaced well
        alignItems: "center",
        textAlign: "center",
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            boxShadow: theme.shadows[5],
            cursor: "pointer",
        },
    },
    dialogTitle: {
        fontWeight: "600",
        fontSize: "1.25rem",
        textAlign: "left",
        marginBottom: theme.spacing(3),
    },
}));

const LabIdea = ({ labID, setActiveStep }) => {
    const classes = useStyles();
    const { showNotification } = React.useContext(NotificationContext);
    const [lab, setLab] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [ideaTemplates, setIdeaTemplates] = useState([]);
    const [loading, setLoading] = useState({ isLoading: false, message: "" });
    const [changesMade, setChangesMade] = useState(false);
    const [activeTile, setActiveTile] = useState("Custom");
    const [showAlert, setShowAlert] = useState(false);
    const [reviewDialog, setReviewDialog] = useState(false);
    const icons = [Adjust, Assistant, BubbleChart, Category, BubbleChart];

    useEffect(() => {
        async function fetchLabDetails() {
            try {
                setLoading({ isLoading: true, message: "Fetching lab details..." });
                const data = await fetchLabAPI(labID);
                console.log("Lab data is: ", data);
                setLab(data);
                setIdeaTemplates(data.lab_ideas);
                setActiveTile(data.lab_ideas[0].name);
                setSelectedTemplate(data.lab_ideas[0]);
                // handleSetFileResources(data.raw_resources);
                setLoading({ isLoading: false, message: "" }); // Ensure loading state is reset
            } catch (error) {
                console.error("Error in fetchLabDetails:", error);
                showNotification("Error fetching lab details", "danger");
            } finally { }
        }

        fetchLabDetails();
    }, [labID]);

    const _handleTemplateSelect = (template) => {
        setActiveTile(template.name);
        setSelectedTemplate(template);
    };

    const _capitalize = (text) => {
        const lowerText = text.toLowerCase();
        return lowerText.charAt(0).toUpperCase() + lowerText.slice(1);
    };

    // Function to update the template content in newWritingDetails
    const _handleTemplateContentChange = (value) => {

        // Also update the selectedTemplate state to reflect changes immediately
        setSelectedTemplate({ ...selectedTemplate, description: value });
        const updatedTemplates = ideaTemplates.map((t) =>
            t.name === selectedTemplate.name
                ? { ...t, description: value }
                : t
        );
        console.log("Updated templates", updatedTemplates);
        setIdeaTemplates(updatedTemplates);
    };

    const handleUpdateSelectedIdea = async () => {
        try {
            const formData = new FormData();
            formData.append("lab_id", labID);
            const selectedIndex = ideaTemplates.findIndex(
                (template) => template.name === selectedTemplate.name
            );
            console.log("Selected Index: ", selectedIndex);
            formData.append("index", parseInt(selectedIndex));

            for (let pair of formData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }
            await updateSelectedIdea(formData);
        }
        catch (error) {
            throw error;
        }

    };

    const handleNext = async () => {
        if (lab.status != "Idea Selection") {
            setShowAlert(true);
        }
        else {
            setReviewDialog(true);
        }
    };

    const submitHandler = async () => {
        try {
            handleReviewDialogClose();
            setLoading({ isLoading: true, message: "Generating Technical Specifications..." });
            await handleUpdateSelectedIdea();
            const formData = new FormData();
            formData.append("lab_id", labID);
            formData.append("prompt", "");
            formData.append("use_metaprompt", true);
            await generateTechnicalSpecificationsAPI(formData);
            window.location.reload();
        }
        catch (error) {
            console.error("Error in handleNext:", error);
            showNotification("Error generating technical specifications", "danger");
        }
        finally { }
    };

    const handleConfirm = () => {
        setShowAlert(false); // Close the alert
        setReviewDialog(true);
    };

    const handleCancel = () => {
        setShowAlert(false); // Close the alert
    };

    const handleBack = () => {
        setActiveStep(1);
    };

    const handleSave = async () => {
        if (!selectedTemplate) {
            showNotification("No template selected to save", "warning");
            return;
        }

        try {
            setLoading({ isLoading: true, message: "Saving Template..." });

            // Update the selected template content inside ideaTemplates
            const updatedTemplates = ideaTemplates.map((t) =>
                t.name === selectedTemplate.name
                    ? { ...t, description: selectedTemplate.description } // Use selectedTemplate.value
                    : t
            );

            setIdeaTemplates(updatedTemplates); // Update state
            console.log("Lab ID: ", labID);

            console.log("Updated Templates: ", JSON.stringify(updatedTemplates));

            // await saveLabIdeasAPI(formData);
            const formData = new FormData();
            formData.append("lab_id", labID);
            formData.append("lab_ideas", JSON.stringify(updatedTemplates));
            await updateLabIdeas(formData);
            showNotification("Lab Ideas saved successfully!", "success");
        } catch (error) {
            console.error("Error saving template:", error);
            showNotification("Error saving template", "danger");
        } finally {
            setLoading({ isLoading: false, message: "" });
        }
    };

    const handleReviewDialogClose = () => {
        setReviewDialog(false);
    };

    return (
        <div>
            <Dialog open={reviewDialog} onClose={handleReviewDialogClose} maxWidth="lg" fullWidth>
                <DialogTitle id="alert-dialog-title">Review Lab Idea</DialogTitle>
                <DialogContent dividers>
                    <Box data-color-mode="light" >
                        <Typography variant="body1" gutterBottom>
                            The following idea will be used as the basis for generating technical specifications.
                        </Typography>
                        {selectedTemplate && (
                            <Box mt={2}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {selectedTemplate.name}
                                </Typography>
                                <Box mt={1}>
                                    <MDEditor preview="preview" disabled value={selectedTemplate.description} hideToolbar={true} height={400} />
                                </Box>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" justifyContent="space-between" p={2}>
                        <Button onClick={handleReviewDialogClose} color="secondary">
                            Close
                        </Button>
                        <Button onClick={submitHandler} color="quCreateColor" variant="contained">
                            Confirm
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>



            {showAlert && (
                <Alert
                    title="Are you sure?"
                    warning={true}
                    showCancel={true}
                    cancelBtnText="No"
                    confirmBtnText="Yes"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                >
                    Proceeding to the next step will reset your current progress in further steps. Your existing work will be overwritten, and new content will be created. Are you sure you want to continue?
                </Alert>
            )}
            {loading.isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginLeft: 16 }}>
                        {loading.message}
                    </Typography>
                </Box>
            ) : (
                <>
                    <Typography variant="h5" style={{}}>
                        Idea Selection
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "10px" }}>
                        {
                            _capitalize(
                                `Choose one of the following ideas to start with or create your own.`
                            )
                        }
                    </Typography>
                    <Divider style={{ marginBottom: "20px" }} />

                    {/* Grid for Template Tiles */}
                    <Grid container spacing={2} justifyContent="center">
                        {ideaTemplates.map((template, index) => (
                            <Grid item className={classes.gridItem} key={index}>
                                <Box
                                    className={classes.resourceTile}
                                    onClick={() => _handleTemplateSelect(template)}
                                    style={{
                                        backgroundColor: template.name === activeTile ? quCreateColor : 'transparent',
                                        color: template.name === activeTile ? "#fff" : quCreateColor,
                                    }}
                                >
                                    {/* Icon and Heading */}
                                    <Box mb={2}>
                                        {icons[index] && React.createElement(icons[index], { fontSize: "large" })}
                                    </Box>
                                    <Typography variant="body1">
                                        {template.name}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Markdown Editor for Template Editing */}
                    <Grid container spacing={3} alignItems="flex-start" style={{ marginTop: "20px" }}>
                        <Grid item xs={12} style={{ flexGrow: 1 }}>
                            <div data-color-mode="light">
                                <MDEditor
                                    value={selectedTemplate?.description || ""}
                                    onChange={_handleTemplateContentChange}
                                    height={350}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: "10px" }}>
                        <Grid item xs={12}>
                            <Button color="quCreateColor" style={{ float: 'right', fontWeight: 'bold' }}
                                endIcon={<NavigateNextIcon />} onClick={handleNext}
                            >
                                Generate Technical Specifications
                            </Button>
                            <Button color="quCreateColor" style={{ float: "left", fontWeight: "bold" }}
                                startIcon={<ArrowBackIcon />} onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Button color="quCreateColor" style={{ float: 'right', fontWeight: 'bold' }}
                                startIcon={<SaveIcon />} onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )
            }
        </div >
    );
};

export default LabIdea;