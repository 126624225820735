import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Tooltip, IconButton, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Lock } from "@material-ui/icons";


const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: "15px",
        minHeight: "120px",
        maxHeight: "120px",
        position: "relative", // Allow absolute positioning for the lock icon
    },
    lockIconContainer: {
        position: "absolute", // Position the lock icon absolutely
        top: "50%", // Center it vertically
        left: "50%", // Center it horizontally
        transform: "translate(-50%, -50%)", // Offset the positioning to make it truly centered
        zIndex: 10, // Make sure it overlays above other content
    },
    content: {
        textAlign: "left",
        width: "100%", // Ensure content takes up full width for left alignment
    },
    apiKeyContainer: {
        display: "flex", // Align items horizontally
        alignItems: "center", // Vertically center items in the container
    },
    apiKeyText: {
        // marginRight: "8px",
    },
    avatarContainer: {
        position: "absolute",
        top: "10px", // Adjust spacing from the top
        right: "10px", // Adjust spacing from the right
        padding: "5px",
        textAlign: "center",
    },
    avatar: {
        // width: 80,  // Set desired width
        height: 45, // Set desired height
    },
    lockStyle: {
        color: "#4D8FA1",
    },
});

const VaultCardBody = ({ className, children, locked, handleLock, image, apiKeyObject }) => {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            {/* Lock/Unlock Icon - Centered */}
            <div className={classes.lockIconContainer}>
                <IconButton onClick={handleLock}>
                    {locked && <Lock className={classes.lockStyle} fontSize="large" />}
                </IconButton>
            </div>

            {/* Show API Key & Model ID when unlocked */}
            {!locked && (
                <div className={classes.content}>
                    <div className={classes.apiKeyContainer}>
                        <Typography variant="body2" className={classes.apiKeyText}>
                            <strong>API Key:</strong> ****{apiKeyObject.key.slice(-4)}
                        </Typography>

                    </div>

                    <Typography variant="body2" className={classes.apiKeyText}>
                        <strong>Model:</strong> {apiKeyObject.model}
                    </Typography>
                    <Typography variant="body2" className={classes.apiKeyText}>
                        {apiKeyObject.description}
                    </Typography>
                </div>
            )}

            {/* Avatar fixed at the top-right with an image */}
            <div className={classes.avatarContainer}>
                <img src={image} alt="avatar" className={classes.avatar} />
            </div>


        </div>
    );
};

VaultCardBody.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    locked: PropTypes.bool,
    image: PropTypes.string,
    apiKeyObject: PropTypes.shape({
        api_key: PropTypes.string.isRequired,
        model_id: PropTypes.string.isRequired,
    }).isRequired,
    handleLock: PropTypes.func.isRequired,
};

export default VaultCardBody;
