import React, { useEffect, useRef, useState } from "react";
import { Grid, Box, CircularProgress, Typography } from '@material-ui/core';
import { RotateLeft } from '@material-ui/icons';
// Third Party Imports
import MDEditor from "@uiw/react-md-editor";
import { Document, Page } from 'react-pdf';
// Custom Components
import Button from "components/CustomButtons/Button.js";
import { fetchPDFAPI } from "views/APIs/APIUtility";

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};


export default function EditorViewer({ version, latestVersion, outline, setOutline, pdfUrl, pdfLoading, setPdfLoading, handleRefreshContent }) {

    function getDefaultWidth() {
        const screenWidth = window.innerWidth;
        if (screenWidth < 600) return 320; // Mobile
        if (screenWidth < 960) return 500; // Tablet
        if (screenWidth < 1280) return 700; // Laptop
        return 950; // Desktop
    }

    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(getDefaultWidth()); // Default width
    const [numPages, setNumPages] = useState(null);
    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    const [pdfFile, setPdfFile] = useState(null);

    useEffect(() => {
        async function fetchPDF(url) {
            try {
                if (url === undefined || url === null) { return; }
                const formData = new FormData();
                formData.append("url", url);
                const response = await fetchPDFAPI(formData); // Convert the response to a Blob
                const pdfBlob = await response.blob(); // Create a URL for the fetched PDF Blob            
                const pdfBlobUrl = URL.createObjectURL(pdfBlob); // Set the Blob URL to your state to display in the viewer            
                setPdfFile(pdfBlobUrl);
            }
            catch (error) {

            }
            finally {
            }
        }
        fetchPDF(pdfUrl);
    }, [pdfUrl]);


    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.clientWidth);
            }
        };

        setTimeout(() => {
            updateWidth(); // Set width after timeout
            setPdfLoading({ isLoading: false, message: "" });
        }, 2000); // Wait for 2 seconds before setting loading to false

        window.addEventListener("resize", updateWidth);

        return () => window.removeEventListener("resize", updateWidth);
    }, []);


    return (
        <Grid container spacing={2} style={{ position: "relative", marginTop: "10px", marginBottom: "10px" }}>

            {/* Markdown Editor (Left Side) */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <div data-color-mode="light" style={{ marginTop: "20px" }}>
                    {version === latestVersion ? (
                        <MDEditor preview="edit" value={outline} height={600} onChange={(value) => setOutline(value)} />
                    ) : (
                        <MDEditor preview="preview" value={outline} height={600} readOnly={true} />
                    )}
                </div>
            </Grid>

            {/* Floating Button (Center) */}
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                }}
            >
                <Button
                    onClick={() => handleRefreshContent(outline, false)}
                    color="quSandBoxColor"
                    variant="contained"
                    disabled={version !== latestVersion}
                    style={{
                        borderRadius: "50%",
                        padding: "12px",
                    }}
                >
                    <RotateLeft />
                </Button>
            </div>

            {/* PDF Viewer (Right Side) */}
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ marginTop: "20px" }}>
                {pdfLoading.isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                        <CircularProgress color="quCreateColor" />
                        <Typography variant="h6" style={{ marginLeft: 16 }}>
                            {pdfLoading.message}
                        </Typography>
                    </Box>
                ) : (
                    pdfUrl && (
                        <div
                            ref={containerRef}
                            style={{
                                overflowY: "auto",
                                overflowX: "hidden",
                                height: "600px", // Set a fixed height
                                width: "100%", // Ensure the div uses the full available width
                            }}
                        >
                            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} width={containerWidth} style={{ margin: "10px 0" }} />
                                ))}
                            </Document>
                        </div>
                    )
                )}
            </Grid>

        </Grid>
    );
}