import React from "react";
import { Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { CloudUpload } from "@material-ui/icons";

export default function CreateLabModal({
    newLabDetails,
    setNewLabDetails,
    iconImage,
    createLabModal,
    handleCreateLabModalClose,
    handleCreateLab,
    handleIconUpload,
}) {

    // setCurrentDialog("Create Lab");
    const [currentDialog, setCurrentDialog] = React.useState("Create Lab");
    const [loading, setLoading] = React.useState({ loading: false, message: "" })

    const renderDialogContent = () => {
        switch (currentDialog) {
            case "Create Lab":
                return (
                    <div>
                        <Typography
                            variant="h5"
                            style={{
                                marginBottom: "10px",
                            }}
                        >
                            Enter the Lab details:
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Lab Name"
                            type="text"
                            fullWidth
                            required
                            value={newLabDetails.name}
                            onChange={(event) => setNewLabDetails({ ...newLabDetails, name: event.target.value })}
                        />
                        <TextField
                            margin="dense"
                            id="brief"
                            label="Brief Description"
                            type="text"
                            fullWidth
                            multiline
                            required
                            rows={3}
                            value={newLabDetails.description}
                            onChange={(event) => setNewLabDetails({ ...newLabDetails, description: event.target.value })}
                        />
                        {/* Icon Image Upload */}
                        <Button
                            variant="contained"
                            component="label"
                            color="info"
                            style={{ marginTop: "20px" }}
                        >
                            <CloudUpload /> &nbsp; Upload Image
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                required
                                onChange={handleIconUpload}
                            />
                        </Button>
                        {iconImage && (
                            <div style={{ marginTop: "10px" }}>
                                <strong>Uploaded Image:</strong> {iconImage.name}
                            </div>
                        )}
                        <Typography
                            variant="caption"
                            style={{ display: "block", marginTop: "5px" }}
                        >
                            A 16:9 aspect ratio image is recommended
                        </Typography>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div>
            <Dialog
                open={createLabModal}
                onClose={handleCreateLabModalClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    <div style={{ fontSize: "30px" }}>Create New Lab</div>
                </DialogTitle>
                <DialogContent>{renderDialogContent()}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCreateLabModalClose}
                        color="quCreateColor"
                        disabled={loading.loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCreateLab}
                        disabled={loading.loading}
                        color="quCreateColor"
                    >
                        {currentDialog === "Create Lab" ? "Submit" : "Next"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
