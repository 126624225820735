import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Add } from "@material-ui/icons";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    fontFamily: "Roboto, sans-serif",
  },
  activeCard: {
    backgroundColor: "rgba(63, 81, 181, 0.1)",
    transition: "background-color 0.3s, border 0.3s",
  },
  card: {
    borderRadius: theme.shape.borderRadius,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: theme.shadows[3],
  },
  cardTitle: {
    fontSize: "1.1rem",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    wordWrap: "break-word",
  },
  cardDescription: {
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
    wordWrap: "break-word",
  },
  iconContainer: {
    width: 50,
    height: 50,
    backgroundColor: theme.palette.grey[200],
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  gridContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(3),
    justifyContent: "space-evenly",
  },
  resourceTile: {
    height: "100%", // Ensure all tiles have the same height
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // To ensure the content inside each tile is spaced well
    alignItems: "center",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      boxShadow: theme.shadows[5],
      cursor: "pointer",
    },
  },
  dialogTitle: {
    fontWeight: "600",
    fontSize: "1.25rem",
    textAlign: "left",
    marginBottom: theme.spacing(3),
  },
}));

export default function LabDesignTiles(props) {
  const search = useLocation().search;
  const stage = new URLSearchParams(search).get("stage");
  const [active, setActive] = React.useState(props.active);

  useEffect(() => {
    if (stage) {
      setActive(parseInt(stage));
    }
  }, [stage]);

  const handleChange = (active) => {
    setActive(active);
  };

  const classes = useStyles();
  const { tabs } = props;

  const tabWidth = 3;
  const tabSpacing = 2;

  const tabButtons = (
    <Grid container spacing={tabSpacing} justifyContent="center">
      {tabs.map((prop, key) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={tabWidth}
          key={key}
        >
          <Box
            className={classes.resourceTile}
            onClick={() => {
              handleChange(key);
              if (prop.onClick) {
                prop.onClick(); // Call the onClick handler passed in props
              }
            }}
            style={{
              backgroundColor: key === active ? quCreateColor : "transparent",
              color: key === active ? "#fff" : quCreateColor,
            }}
          >
            {/* Icon and Heading */}
            <Box mb={2}>
              <prop.icon className={classes.tabIcon} />
            </Box>
            <Typography variant="body1">{prop.heading}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  return <div>{tabButtons}</div>;
}

LabDesignTiles.defaultProps = {
  active: 0,
};

LabDesignTiles.propTypes = {
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      description: PropTypes.string.isRequired,
      onClick: PropTypes.func, // onClick prop
    })
  ).isRequired,
};
