import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Tooltip
} from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import MDEditor from "@uiw/react-md-editor";
import NotificationContext from "views/Components/Context.js";
import { replaceResourceAPI } from 'views/APIs/APIUtility';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '20px',
        boxSizing: 'border-box',
    },
    title: { marginBottom: '20px' },
    button: {
        marginBottom: '20px',
        padding: '15px 30px',
        fontSize: '1.2rem',
    },
    tableContainer: {
        width: '100%',
        maxHeight: '70vh',
        margin: '0 auto',
    },
    dialogContent: {
        width: '100%',
        maxWidth: '90vw',
    },
    dialogButtonSpacing: { margin: '10px 0' },
    centeredCell: {
        textAlign: 'center',
        padding: '16px',
    },
    rightAlignedCell: {
        textAlign: 'right',
        padding: '16px',
    },
    leftAlignedCell: {
        textAlign: 'left',
        padding: '16px',
    },
    headerCell: {
        display: 'flex',
        padding: '26px',
    },
    ellipsisText: {
        maxWidth: '200px',  // Adjust max-width as needed
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tableCell: {
        maxWidth: "200px", // Add maxWidth to ensure text truncation
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
});

export default function Notes({ courseID, moduleID, notes, setNotes, handleDeleteResource, handleUpdateResource }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentNote, setCurrentNote] = useState({ name: "", description: "", content: "", url: "" });
    const [nameError, setNameError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { showNotification } = React.useContext(NotificationContext);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleOpenModal = (note, index, mode) => {
        setCurrentIndex(index);
        setCurrentNote(note);
        setViewMode(mode === 'view');
        setIsEditMode(mode === 'edit');
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setViewMode(false);
        setIsEditMode(false);
        setNameError(false);
    };

    const handleSaveNote = () => {
        if (!currentNote.name.trim()) {
            setNameError(true);
            return;
        }
        if (isEditMode) {
            // setNotes(notes.map(note =>
            //     note.id === currentNote.id ? { ...note, content: currentNote.content } : note
            // ));
            handleUpdateResource(currentNote, currentIndex);
        } else {
            setNotes([...notes, currentNote]);
        }
        setOpen(false);
    };

    return (
        <div className={classes.container}>
            {notes.length === 0 && (
                <Typography variant="body1">
                    No notes available. You can upload notes using the + button above.
                </Typography>
            )}
            {notes.length > 0 && (
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Name</strong></TableCell>
                                <TableCell className={classes.leftAlignedCell}><strong>Description</strong></TableCell>
                                <TableCell className={classes.headerCell}><strong>Actions</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notes.map((note, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCell}>
                                        <Tooltip title={note.name}>
                                            <Typography className={classes.ellipsisText}>{note.name}</Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Tooltip title={note.description}>
                                            <Typography className={classes.ellipsisText}>{note.description}</Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={classes.leftAlignedCell}>
                                        <IconButton onClick={() => handleOpenModal(note, index, 'view')} color="info">
                                            <Visibility />
                                        </IconButton>
                                        <IconButton onClick={() => handleOpenModal(note, index, 'edit')}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteResource(note)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth PaperProps={{
                style: {
                    maxHeight: '50vh',
                    height: '50vh',
                },
            }}>
                <DialogTitle>{currentNote.name}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        {!viewMode && (
                            <Box display="flex" gap={2}>
                                <Box flex="1">
                                    <div data-color-mode="light">
                                        <MDEditor
                                            value={currentNote.content}
                                            onChange={(value) => setCurrentNote(prev => ({ ...prev, content: value }))}
                                            height={280}
                                        />
                                    </div>
                                </Box>
                            </Box>
                        )}
                        {viewMode && (
                            <Box>
                                {loading ? (
                                    <Typography>Loading content...</Typography>
                                ) : (
                                    <div data-color-mode="light">
                                        <MDEditor.Markdown source={currentNote.content} height={300} />
                                    </div>
                                )}
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        {viewMode ? 'Close' : 'Cancel'}
                    </Button>
                    {!viewMode && (
                        <Button onClick={handleSaveNote} color="primary">
                            {isEditMode ? 'Update' : 'Save'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
