import { Card, Box, Divider, Grid, Icon, Typography, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import ReviewPPT from "../ReviewPPT";
import NotificationContext from "views/Components/Context.js";
import { replaceResourceAPI } from "views/APIs/APIUtility";
import { fetchLectureAPI } from "views/APIs/APIUtility";
import VoiceSelectionModal from "views/Components/VoiceSelectionModal";

export default function LectureStructureReview(props) {

    
    const { showNotification } = React.useContext(NotificationContext);
    const [slideHeaders, setSlideHeaders] = useState([]);
    const [slideContents, setSlideContents] = useState([]);
    const [speakerNotes, setSpeakerNotes] = useState([]);
    const [notes, setNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [slide, setSlide] = useState([]);
    const [voiceModal, setVoiceModal] = useState(false);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const setActiveStep = props.setActiveStep;

    const [activeSlide, setActiveSlide] = useState(0);

    const voices_dir = require.context("assets/voices", true);


    //  const voice_links = {
    //         "make-US": "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/male-US.wav",
    //         "female-US": "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/female-US.wav",
    //         "male-UK": "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/male-UK.wav",
    //         "female-UK": "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/female-UK.wav",
    //     }


    const voices = [
        // { name: "Female UK", sampleUrl: voices_dir("./female-UK.mp3") }
        { name: "Female UK", sampleUrl: "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/female-UK.wav" },
        { name: "Male UK", sampleUrl: "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/male-UK.wav" },
        { name: "Female US", sampleUrl: "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/female-US.wav" },
        { name: "Male US", sampleUrl: "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/male-US.wav" },
    ];

    const fetchSlidesContent = async (fileUrl) => {
        const formData = new FormData();
        formData.append("url", fileUrl);

        try {
            const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/fetch_quizdata`, {
                method: "POST",
                body: formData,
            });
            const data = await response.json();
            
            return data;
        } catch (err) {
            console.error("Error fetching file:", err);
        }
    };

    const convertDataToJSON = (data) => {
        const jsonString = JSON.stringify(data);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const file = new File([blob], 'data.json', { type: 'application/json' });
        return file;
    };


    const writeFile = () => {
        const data = [];
        for (let i = 0; i < slideHeaders.length; i++) {
            data.push({
                slide_header: slideHeaders[i],
                slide_content: slideContents[i],
                speaker_notes: speakerNotes[i],
                notes: notes[i]
            });
        }
        // write data to a json file and return the file
        const file = convertDataToJSON(data);
        return file;
    }

    const handleSaveChanges = () => {
        
        const file = writeFile();
        const formData = new FormData();
        formData.append("lecture_id", props.projectID);
        // add resource_id, resource_name, resource_type, resource_link, resource_file, coruse_design_step
        formData.append("resource_type", "Slide_Content");
        formData.append("resource_description", slide.resource_description);
        formData.append("resource_link", slide.resource_link);
        formData.append("resource_id", slide.resource_id);
        formData.append("resource_name", slide.resource_name);
        formData.append("resource_file", file);
        // very important: if not specified it will replace the file in the raw_resources_directory
        formData.append("course_design_step", 2)

        try {
            replaceResourceAPI(formData);
            showNotification("Changes saved successfully", "success");
        }
        catch (error) {
            console.error("Error in handleUpdateNote:", error);
            showNotification("Error saving changes", "danger");
        }
    };

    useEffect(() => {
        const setSlidesandFetchData = async () => {
            try {
                const data = await fetchLectureAPI(props.projectID);
                const speaker_notes_review = data.speaker_notes_review;
                setSlide(speaker_notes_review[0]);
                const card = speaker_notes_review[0];
                
                if (card) {
                    const speaker_notes_link = card.speaker_notes_link;
                    const fetchedData = await fetchSlidesContent(speaker_notes_link);
                    
                    const localHeaders = [];
                    const localContent = [];
                    const localNotes = [];
                    
                    fetchedData.forEach(slide => {
                        localHeaders.push(slide.slide_header);
                        localContent.push(slide.slide_content);
                        localNotes.push(slide.speaker_notes);
                    });
                    
                    setSlideHeaders(localHeaders);
                    setSlideContents(localContent);
                    setSpeakerNotes(localNotes);
                    
                } else {
                    console.warn("No assessment data found.");
                }
            } catch (error) {
                console.error("Error fetching lecture data", error);
            } finally {
                setIsLoading(false);
            }
        };

        setSlidesandFetchData();
    }, [props.projectID]); // Add dependencies as needed

    const handleConfirmVoice = () => {
        
        setVoiceModal(false);
        setActiveStep(5);
        // setActiveTab("Slide Structure Review");
        // Handle voice selection logic here
    };

    const handleGenerateVideo = () => {
        setVoiceModal(true);
        // Handle voice selection logic here
    };

    const pptxUrl = 'https://qucoursify.s3.us-east-1.amazonaws.com/qu-course-design/673277707a469de58e0efa96/673277727a469de58e0efa9e/pre_processed_deliverables/2_Alternative_Data_and_AI_in_Investment_Research.pptx'; // Replace with your pptx file URL

    // Construct the Google Docs Viewer URL
    const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(pptxUrl)}&embedded=true`;
    const officeOnlineViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(pptxUrl)}`;


    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <div>
            {/* <Typography>Hello Outline</Typography> */}

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" style={{
                        marginTop: "20px"
                    }}>Slide Structure Review</Typography>
                    <Typography variant="body1">
                        Review the structure of the slide including the content and speaker notes.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <ReviewPPT
                        PDFURL={slide.resource_link}
                        activeSlide={activeSlide}
                        showButtons={false}
                        isNotesEditable={false}
                        speakerNotes={speakerNotes[activeSlide]}
                        notes={notes[activeSlide]}
                        setSlideHeader={(value) => {
                            setSlideHeaders(
                                slideHeaders.map((item, index) => {
                                    if (index === activeSlide) {
                                        return value;
                                    }
                                    return item;
                                })
                            )
                        }
                        }
                        setSlideContent={(value) => {
                            setSlideContents(
                                slideContents.map((item, index) => {
                                    if (index === activeSlide) {
                                        return value;
                                    }
                                    return item;
                                })
                            )
                        }}
                        setSpeakerNotes={(value) => {
                            setSpeakerNotes(
                                speakerNotes.map((item, index) => {
                                    if (index === activeSlide) {
                                        return value;
                                    }
                                    return item;
                                })
                            )
                        }}
                        setNotes={(value) => {
                            setNotes(
                                notes.map((item, index) => {
                                    if (index === activeSlide) {
                                        return value;
                                    }
                                    return item;
                                })
                            )
                        }
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={12} justify="center">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Button style={{
                                float: "right"
                            }} color="quSandBoxColor"
                                disabled={activeSlide === 0}
                                onClick={() => setActiveSlide(activeSlide - 1)}

                            >
                                <Icon>arrow_back</Icon>
                                &nbsp;
                                Previous Slide</Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body1" style={{
                                textAlign: "center",
                                marginTop: "10px"
                            }}>Slide {activeSlide + 1} of {slideHeaders.length}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button style={{
                                float: "left"
                            }} color="quSandBoxColor"
                                disabled={activeSlide === slideHeaders.length - 1}
                                onClick={() => setActiveSlide(activeSlide + 1)}

                            >
                                Next Slide&nbsp;
                                <Icon>arrow_forward</Icon>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    {/* 3 buttons aligned to the right */}
                    <Grid item xs={12} sm={12} >
                        <Button color="info" onClick={handleGenerateVideo}
                            style={{
                                float: "right"
                            }}
                        // onClick={() => showNotification("Marked as reviewed. You will be notified when the content is ready for the structure review.", "quSandBoxColor")}
                        >
                            <Icon>check</Icon> &nbsp; Generate Video
                        </Button>
                    </Grid>
                </Grid>

            </Grid>

            {/* Voice Selection Modal */}
            <VoiceSelectionModal
                open={voiceModal}
                onClose={() => setVoiceModal(false)}
                voices={voices}
                selectedVoice={selectedVoice}
                setSelectedVoice={setSelectedVoice}
                onConfirm={handleConfirmVoice}
            />
        </div>
    );
}