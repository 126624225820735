import React, { useEffect } from "react";
import PropTypes from "prop-types";
// Amplify components
import { Auth } from "aws-amplify";
import Alert from "views/Components/Alert.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";

import {
  Slide,
  Tooltip,
  Radio
} from "@material-ui/core";

import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";


import AssessmentIcon from "@material-ui/icons/Assessment";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import SaveIcon from '@material-ui/icons/Save';
import { Check, Close } from "@material-ui/icons";

import "survey-react/survey.css";
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@material-ui/core";

import Table from "components/Table/Table.js";
import { useRouteMatch } from "react-router-dom";

import DeleteIcon from "@material-ui/icons/Delete";
import NotificationContext from "views/Components/Context.js";
import CustomInput from "components/CustomInput/CustomInput";
import { createNull } from "typescript";
import GridContainer from "components/Grid/GridContainer";

import ImageViewer from "views/Components/ImageViewer.js";
import GridItem from "components/Grid/GridItem";
import CardText from "components/Card/CardText";
import CardIcon from "components/Card/CardIcon";
import { Survey } from "survey-react";
import TagsInput from "react-tagsinput";
import ReactTable from "components/ReactTable/ReactTable.js";
import Checkbox from "@material-ui/core/Checkbox";


import styles from "assets/jss/material-dashboard-pro-react/components/reportStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);
const usedashboardStyle = makeStyles(dashboardStyle);
const useModalStyles = makeStyles(modalStyle);
const useFormStyles = makeStyles(regularFormsStyle);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

function NoteDropDown(props) {
  const classes = useStyles();
  const [select, setSelect] = React.useState(0);
  const [addedNotes, setAddedNotes] = React.useState([]);
  useEffect(() => {
    setAddedNotes(props.data)
  }, [props.data])

  const handleNoteAdd = () => {
    setAddedNotes([...addedNotes, props.notes[select - 1].PK.split("#")[1]]);
    // let newJson = { ...props.json };
    // newJson[props.key1][props.subKey].push(props.notes[select - 1].PK);
    props.setJson(props.notes[select - 1].PK.split("#")[1], "add");
    setSelect(0);
  };
  const handleTagRemoval = (regularTags) => {
    props.setJson(regularTags, "remove");
    setAddedNotes(regularTags);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={9} md={10}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Choose Note
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            onChange={(e) => {
              setSelect(e.target.value);
            }}
            value={select}
            inputProps={{
              name: "noteSelect",
              id: "note-select",
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem,
              }}
              value={0}
            >
              Choose Note
            </MenuItem>
            {props.notes.map((note, ind) => (
              <MenuItem
                key={ind}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={ind + 1}
              >
                {note.StageType} - {note.PK.split("#")[1]} - {note.Title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem xs={12} sm={3} md={2}>
        <Button color="info" onClick={handleNoteAdd} disabled={select < 1}>
          Add Note
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <TagsInput
          value={addedNotes}
          onChange={handleTagRemoval}
          tagProps={{ className: "react-tagsinput-tag info" }}
          renderInput={({ addTag, ...props }) => {
            let { onChange, value, ...other } = props;
            return <></>;
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

export default function ReportTemplate(props) {
  const classes = useStyles();
  const dashboardClasses = usedashboardStyle();
  const modalClasses = useModalStyles();
  const formClasses = useFormStyles();
  const sweetAlertClasses = usesweetAlertStyle();

  const { params } = useRouteMatch();
  const [user, setUser] = React.useState({});

  const [alert, setAlert] = React.useState();
  const { showNotification } = React.useContext(NotificationContext);
  const [stringJson, setStringJson] = React.useState();
  const [surveyResult, setSurveyResult] = React.useState({});

  const [surveyJson, setSurveyJson] = React.useState({
    focusFirstQuestionAutomatic: false,
    focusOnFirstError: false,
    showNavigationButtons: false,
  });

  const [newStageId, setNewStageId] = React.useState(props.stageId);
  const [savedReports, setSavedReports] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  const [model, setModel] = React.useState(false);

  const [method, setMethod] = React.useState("create");

  const [name, setName] = React.useState("");
  const [version, setVersion] = React.useState("");

  const [reportHeight, setReportHeight] = React.useState("200");
  const [reportModel, setReportModel] = React.useState('close');
  const [HTML, setHTML] = React.useState();
  const [MD5, setMD5] = React.useState();

  const [QuTrackOption, setQuTrackOption] = React.useState("save");

  const [artifactId, setArtifactId] = React.useState("");
  const [editIndex, setEditIndex] = React.useState(0)
  useEffect(() => {

    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
        if (err === "not authenticated") {
          window.location.href = "/auth/login-page";
        }
      });
    setSurveyJson({
      ...surveyJson,
      ...require('./survey.json'),
    });
  }, []);

  const editJson = (value, operation) => {
    
    let newNotes = [...notes];
    
    if (operation === "add") {
      newNotes.push(value);
    } else {
      newNotes = value;
    }
    setNotes(newNotes);
  };

  const onValueChanged = (res) => {
    setSurveyResult(res.data);
  };

  const handleEdit = (data, index) => {
    
    setEditIndex(index);
    setArtifactId(data.SK);
    setNewStageId(data.PK);
    setName(data.Content.report_parameters.name);
    setVersion(data.Content.report_parameters.version);
    setSurveyResult(data.Content.report_details);
    setNotes(data.Content.notes)
    setMethod('edit');
  }

  const handleCreate = () => {
    setName("");
    setVersion("");
    setSurveyResult({});
    setNotes([])
    setMethod('create');
  }

  const handleSubmitModelOpen = () => {
    setQuTrackOption('save')
    setModel(true);
  };

  const handleSubmitModelClose = () => {
    setModel(false);
  };

  const handleSubmit = () => {
    if (method == 'create') {
      handleCreateReport();
    } else if (method == 'edit') {
      handleEditReport();
    }
  };

  const handleEditReport = () => {
    showNotification("Updating report", "info");
    let url =
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${params.exp_id}/stage/${newStageId.split("#")[1]
      }/artifact/${artifactId.split('#')[1]}`;
    if (QuTrackOption == 'save') {
      url = url + "?method=saveOnly";
    } else if (QuTrackOption == 'hash') {
      url = url + "?method=hash";
    } else if (QuTrackOption == 'publish') {
      url = url + "?method=publish";
    } else if (QuTrackOption == 'publishOnly') {
      url = url + "?method=publishOnly";
    }
    let body = {
      report_parameters: {
        name: name,
        version: version,
      },
      report_details: surveyResult,
      notes: notes,
    };
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(url, {
      method: "PUT",
      mode: "cors",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }).then(
      function (res) {
        
        res.json().then(function (data) {
          
          let tmp = [...savedReports]
          tmp[editIndex] = helper(data['Items'][0], editIndex)
          setSavedReports([...tmp]);
          showNotification("Report updated successfully", "quSandBoxColor");
          setModel(false);
        });
      },
      function (err) {
        
        showNotification("Report updated failed.", "danger");
        console.log(err);
      }
    );
  }

  const handleCreateReport = () => {
    showNotification("Creating report", "info");
    let url = "";
    if (props.stageId != "") {
      url =
        process.env.REACT_APP_BACK_END_URL +
        `/experiment/${params.exp_id}/stage/${props.stageId.split("#")[1]
        }/artifact`;
    } else {
      url =
        process.env.REACT_APP_BACK_END_URL +
        `/experiment/${params.exp_id}/stages/${props.stageType}/artifact`;
    }
    if (QuTrackOption == 'save') {
      url = url + "?method=saveOnly";
    } else if (QuTrackOption == 'hash') {
      url = url + "?method=hash";
    } else if (QuTrackOption == 'publish') {
      url = url + "?method=publish";
    }
    
    
    let body = {
      report_parameters: {
        name: name,
        version: version,
      },
      report_details: surveyResult,
      notes: notes,
    };
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(url, {
      method: "POST",
      mode: "cors",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }).then(
      function (res) {
        
        res.json().then(function (data) {
          
          if (!newStageId) {
            setNewStageId(data['Items'][0]['PK'])
          }
          setSavedReports([...savedReports, helper(data['Items'][0], savedReports.length)]);
          showNotification("Report created successfully", "quSandBoxColor");
          setModel(false);
        });
      },
      function (err) {
        
        showNotification("Report created failed.", "danger");
        console.log(err);
      }
    );
  };

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (props.stageId != "" || props.stageType != "") {
        let url = "";
        if (props.stageId != "") {
          url =
            process.env.REACT_APP_BACK_END_URL +
            `/experiment/${params.exp_id}/stage/${props.stageId.split("#")[1]
            }/artifact`;
        }
        // if (newStageId != "") {
        //   url =
        //     process.env.REACT_APP_BACK_END_URL +
        //     `/experiment/${params.exp_id}/stage/${newStageId.split("#")[1]
        //     }/artifact`;
        // }
        else if (props.stageType != "") {
          url =
            process.env.REACT_APP_BACK_END_URL +
            `/experiment/${params.exp_id}/stages/${props.stageType}/artifact`;
        }
        let token = user.signInUserSession.accessToken.jwtToken;
        fetch(url, {
          method: "GET",
          mode: "cors",
          credentials: "omit",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }).then(
          function (res) {
            res.json().then(function (data) {
              
              addActionsButtons(data.Items);
            });
          },
          function (err) {
            
            showNotification("Report read failed.", "danger");
            console.log(err);
          }
        );
      }
    }
  }, [user]);

  const handleViewQuTrack = (item) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${item.ExperimentId.split('#')[1]}/stage/${item.PK.split("#")[1]
      }/artifact/${item.SK.split("#")[1]}?method=publish`,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        
        setMD5(data.md5)
        setHTML(data.Artifact)
        setReportModel('publish')
      });
    });
  }

  const handleViewReport = (item) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${item.ExperimentId.split('#')[1]}/stage/${item.PK.split("#")[1]
      }/artifact/${item.SK.split("#")[1]}`,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        setMD5()
        getHTML(data.s3URL, setHTML);
        setReportModel('view')
      });
    });
  }

  const getHTML = (url, setHTML) => {
    fetch(
      url,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        cache: "no-cache",
      }
    ).then((res) => {
      res.text().then((data) => {
        setHTML(data);
      });
    });
  };

  const resizeReportIframe = (e) => {
    const frame = e.target;
    setReportHeight(
      frame.contentWindow.document.documentElement.scrollHeight
    );
  };

  const helper = (item, i) => {
    return {
      ...item,
      PK: item.SK.split("#")[1],
      UpdatedAt: new Date(item.UpdatedAt * 1000).toLocaleString(),
      actions:
        (
          <div key={i}>

            {item.QLDBReference &&
              <Tooltip
                id="tooltip-top"
                title="View on QuTrack"
                placement="bottom"
                classes={{ tooltip: dashboardClasses.tooltip }}
              >
                <Button
                  color="transparent"
                  size="sm"
                  onClick={() => handleViewQuTrack(item, i)}
                >
                  <VerifiedUserIcon />
                </Button>
              </Tooltip>

            }
            {item.S3Key && <Tooltip
              id="tooltip-top"
              title="Publish to QuTrack"
              placement="bottom"
              classes={{ tooltip: dashboardClasses.tooltip }}
            >
              <Button
                color="transparent"
                size="sm"
                onClick={() => publishAlert(item, i)}
              >
                <SaveIcon />
              </Button>
            </Tooltip>}
            {item.S3Key &&
              <Tooltip
                id="tooltip-top"
                title="View the report"
                placement="bottom"
                classes={{ tooltip: dashboardClasses.tooltip }}
              >
                <Button
                  color="transparent"
                  size="sm"
                  onClick={() => handleViewReport(item)}
                >
                  <VisibilityIcon />
                </Button>
              </Tooltip>}
            <Tooltip
              id="tooltip-top"
              title="Edit the record"
              placement="bottom"
              classes={{ tooltip: dashboardClasses.tooltip }}
            >
              <Button
                color="transparent"
                size="sm"
                onClick={() => handleEdit(item, i)}
              >
                <CreateIcon />
              </Button>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Delete the record"
              placement="bottom"
              classes={{ tooltip: dashboardClasses.tooltip }}
            >
              <Button
                color="transparent"
                size="sm"
                onClick={() => deleteAlert(item, i)}
              >
                <DeleteIcon />
              </Button></Tooltip>
          </div>
        ),
    };
  }

  const hideAlert = () => {
    setAlert();
  };

  const handlePublish = (item, index) => {
    showNotification("Publishing report", "info");
    let url =
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${params.exp_id}/stage/${item.PK.split("#")[1]
      }/artifact/${item.SK.split('#')[1]}?method=publishOnly`;
    let body = {
      report_parameters: {
        name: name,
        version: version,
      },
      report_details: surveyResult,
      notes: notes,
    };
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(url, {
      method: "PUT",
      mode: "cors",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }).then(
      function (res) {
        
        res.json().then(function (data) {
          
          let tmp = [...savedReports]
          tmp[index] = helper(data['Items'][0], index)
          setSavedReports([...tmp]);
          showNotification("Report published successfully", "quSandBoxColor");
          hideAlert();
        });
      },
      function (err) {
        
        showNotification("Report published failed.", "danger");
        console.log(err);
      }
    );
  }

  const publishAlert = (item, i) => {
    setAlert(
      <Alert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handlePublish(item, i)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button
        }
        cancelBtnCssClass={
          sweetAlertClasses.button
        }
        confirmBtnText="Yes, publish it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to delete this report from QuTrack!
      </Alert>
    );
  };

  const deleteAlert = (item, i) => {
    setAlert(
      <Alert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDelete(item, i)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button
        }
        cancelBtnCssClass={
          sweetAlertClasses.button
        }
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this report if not published on QuTrack!
      </Alert>
    );
  };

  const handleDelete = (item, index) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${item.ExperimentId.split("#")[1]}/stage/${item.PK.split("#")[1]}/artifact/${item.SK.split("#")[1]}`,
      {
        method: "DELETE",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function () {
        let tmp = [...savedReports]
        
        tmp.splice(index, 1);
        
        setSavedReports([...tmp]);
        showNotification("Report deleted successfully.", "quSandBoxColor");
      })
      .catch((error) => {
        showNotification("Delete report failed.", "danger");
        console.log(error);
      });
    hideAlert();
  };

  const addActionsButtons = (data) => {
    setSavedReports(
      data.map((item, i) => {
        return helper(item, i)
      }
      )
    )
  };

  const handleChangeQuTrackOption = event => {
    setQuTrackOption(event.target.value);
  };

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (props.templateId != "") {
        let token = user.signInUserSession.accessToken.jwtToken;
        fetch(
          process.env.REACT_APP_BACK_END_URL +
          `/template/${props.templateId.split("#")[1]}`,
          {
            method: "GET",
            mode: "cors",
            credentials: "omit",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        ).then(
          function (res) {
            res.json().then(function (data) {
              // let tempJson = JSON.parse(data.Items[0].Template);
              // tempJson["focusFirstQuestionAutomatic"] = false;
              // tempJson["showNavigationButtons"] = false;
              setSurveyJson({
                ...surveyJson,
                ...JSON.parse(data.Items[0].Template),
              });
            });
          },
          function (err) {

            // showNotification("Note read failed.", "danger");
            console.log(err);
          }
        );
      }
    }
  }, [user, props.templateId]);

  return (
    <>
      {alert}
      <Dialog
        open={reportModel != 'close'}
        transition={Transition}
        maxWidth="lg"
        scroll='body'
        keepMounted
        onClose={() => setReportModel('close')}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <CardHeader color="quSandBox" icon>
          <Button
            justIcon
            className={modalClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setReportModel('close')}
            style={{ marginTop: "0px" }}
          >
            <Close className={modalClasses.modalClose} />
          </Button>
          {reportModel == 'view' && <h4 className={classes.cardIconTitle}>View Report</h4>}
          {reportModel == 'publish' && <h4 className={classes.cardIconTitle}>Browse QuTrack</h4>}
        </CardHeader>
        <DialogContent
          id="modal-slide-description"
          className={modalClasses.modalBody}
        >
          {MD5 && <div>MD5:{MD5}</div>}
          {HTML && <iframe
            id="data"
            srcDoc={HTML}
            width="800px"
            frameBorder="0"
            height={`${reportHeight}px`}
            scrolling="no"
            onLoad={resizeReportIframe}
          ></iframe>}
        </DialogContent>
      </Dialog>

      <Dialog
        open={model}
        onClose={handleSubmitModelClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Report</DialogTitle>
        <DialogContent>
          {/* {method == "edit" && <div
            className={
              formClasses.checkboxAndRadio +
              " " +
              formClasses.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={QuTrackOption === "publishOnly"}
                  onChange={handleChangeQuTrackOption}
                  value="publishOnly"
                  name="radio button create"
                  aria-label="Publish prev generated report to QuTrack only"
                  icon={
                    <FiberManualRecord
                      className={formClasses.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={formClasses.radioChecked}
                    />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Publish prev generated report to QuTrack only"
            />
          </div>
          } */}
          <div
            className={
              formClasses.checkboxAndRadio +
              " " +
              formClasses.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={QuTrackOption === "save"}
                  onChange={handleChangeQuTrackOption}
                  value="save"
                  name="radio button create"
                  aria-label="Save only"
                  icon={
                    <FiberManualRecord
                      className={formClasses.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={formClasses.radioChecked}
                    />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Save only"
            />
          </div>
          <div
            className={
              formClasses.checkboxAndRadio +
              " " +
              formClasses.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={QuTrackOption === "report"}
                  onChange={handleChangeQuTrackOption}
                  value="report"
                  name="radio button create"
                  aria-label="Generate report"
                  icon={
                    <FiberManualRecord
                      className={formClasses.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={formClasses.radioChecked}
                    />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Generate report"
            />
          </div>
          <div
            className={
              formClasses.checkboxAndRadio +
              " " +
              formClasses.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={QuTrackOption === "hash"}
                  onChange={handleChangeQuTrackOption}
                  value="hash"
                  name="radio button create"
                  aria-label="Publish record on QuTrack"
                  icon={
                    <FiberManualRecord
                      className={formClasses.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={formClasses.radioChecked}
                    />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Publish record on QuTrack"
            />
          </div>
          <div
            className={
              formClasses.checkboxAndRadio +
              " " +
              formClasses.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={QuTrackOption === "publish"}
                  onChange={handleChangeQuTrackOption}
                  value="publish"
                  name="radio button create"
                  aria-label="Publish report on QuTrack"
                  icon={
                    <FiberManualRecord
                      className={formClasses.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={formClasses.radioChecked}
                    />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Publish record and report on QuTrack"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitModelClose} color="quSandBoxColor">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="quSandBoxColor">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Card>
        <CardHeader icon>
          <CardIcon color="quSandBox">
            <AssessmentIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Reports</h4>
        </CardHeader>
        <CardBody>
          {method == 'edit' && <TextField
            margin="dense"
            id="ID"
            label="ID"
            type="text"
            value={artifactId}
            fullWidth
          />}

          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            onChange={(event) => {
              setName(event.target.value);
            }}
            value={name}
            fullWidth
            required
          />

          <TextField
            margin="dense"
            id="version"
            label="Version"
            type="text"
            onChange={(event) => {
              setVersion(event.target.value);
            }}
            value={version}
            fullWidth
            required
          />

          <Survey
            data={surveyResult}
            json={surveyJson}
            showCompletedPage={false}
            onValueChanged={onValueChanged}
          />
          {Object.keys(surveyJson).length !== 0 && (
            <NoteDropDown notes={props.notes} setJson={editJson} data={notes} />
          )}
          <Button color="info" onClick={handleCreate}>
            Clear
          </Button>

          <Button color="info" onClick={handleSubmitModelOpen}>
            Submit
          </Button>
        </CardBody>
      </Card>

      {savedReports && savedReports.length > 0 && <Card>
        <CardHeader icon>
          <CardIcon color="quSandBox">
            <AssessmentIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Saved Reports</h4>
        </CardHeader>
        <CardBody>
          <Table
            tableHead={["#", "Title", "Id", "Last Updated", "Actions"]}
            tableData={savedReports.map((item, id) => [
              id + 1,
              item.Title,
              item.PK,
              item.UpdatedAt,
              item.actions,
            ])}
            customCellClasses={[classes.center, classes.right]}
            customClassesForCells={[0, 4]}
            customHeadCellClasses={[classes.center, classes.right]}
            customHeadClassesForCells={[0, 4]}
          />
          {method == 'edit' && <Button color="info" onClick={handleCreate}>
            Create new
          </Button>}
        </CardBody>
      </Card>}

    </>
  );
}
ReportTemplate.defaultProps = {
  notes: [],
  stageId: "",
  templateId: "",
  stageType: "",
};

ReportTemplate.propTypes = {
  notes: PropTypes.array,
  stageId: PropTypes.string,
  templateId: PropTypes.string,
  stageType: PropTypes.string,
};
