import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Tooltip } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import { CloudUpload } from '@material-ui/icons';

export default function FileUploadDialog({ open, onClose, newFile = {}, setNewFile, onSave, replace, file_type }) {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewFile({ ...newFile, file });
        }
    };

    const getFileFormats = (type) => {
        switch (type) {
            case "file":
                return {
                    // acceptableFormats: ".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .csv, .txt",
                    // fileAccept: ".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.csv,.txt",
                    acceptableFormats: ".pdf",
                    fileAccept: ".pdf",
                };
            case "image":
                return {
                    acceptableFormats: ".jpg, .jpeg, .png",
                    fileAccept: ".jpg,.jpeg,.png",
                };
            case "powerpoint_presentations":
                return {
                    acceptableFormats: ".ppt, .pptx",
                    fileAccept: ".ppt,.pptx",
                };
            case "video":
                return {
                    acceptableFormats: ".mp4, .avi, .mkv, .mov, .wmv",
                    fileAccept: ".mp4,.avi,.mkv,.mov,.wmv",
                };
            case "dataset":
                return {
                    acceptableFormats: ".csv, .xls, .xlsx",
                    fileAccept: ".csv,.xls,.xlsx",
                };
            default:
                return {
                    acceptableFormats: "",
                    fileAccept: "",
                };
        }
    };

    const { acceptableFormats, fileAccept } = getFileFormats(file_type);

    return (
        <Dialog open={open} onClose={onClose}>
            
            <DialogContent>
                <Typography variant="h6" >
                {replace ? 'Replace File' : 'Upload Resource File'}
                </Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    label="File Name"
                    type="text"
                    fullWidth
                    value={newFile?.name || ''}
                    onChange={(e) => setNewFile({ ...newFile, name: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="Description"
                    type="text"
                    fullWidth
                    value={newFile?.description || ''}
                    onChange={(e) => setNewFile({ ...newFile, description: e.target.value })}
                />
                <Button
                    variant="contained"
                    component="label"
                    color="quCreateColor"
                >
                    <CloudUpload />
                    {replace ? "Replace" : "Upload"} File
                    <input
                        type="file"
                        accept={fileAccept}
                        hidden
                        onChange={handleFileChange}
                    />
                </Button>
                {newFile?.file && (
                    <Tooltip title={newFile.file.name}>
                        <Typography
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100%', // Adjusts to the width of the modal
                            }}
                        >
                            {newFile.file.name}
                        </Typography>
                    </Tooltip>)}
                <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ marginTop: '8px', display: 'block' }}
                >
                    Acceptable formats: {acceptableFormats}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="quCreateColor">Cancel</Button>
                <Button
                    onClick={onSave}
                    color="quCreateColor"
                    disabled={!newFile.file} // Disable if no file is set
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
