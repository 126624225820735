// React & React Router
import React, { useEffect } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";

// Material-UI Core & Styles
import { CircularProgress, Typography, Box } from "@material-ui/core";

// Material-UI Icons & Components
import { CloudUpload, Create, DoneAll } from "@material-ui/icons";

// Sample Instructions Data
import {
  research_report_instructions,
  white_paper_instructions,
  blog_instructions,
  newsletter_instructions,
  key_insights_instructions,
  case_study_instructions,
  handout_instructions,
  project_plan_instructions,
} from "../Data/sampleInstructions.js";

// Custom & Local Components
import NotificationContext from "views/Components/Context.js";
import CreateCourseCard from "views/Components/CreateCourseCard";
import CreateWritingModal from "views/Components/CreateWritingModal.js";

// API Utilities
import {
  fetchAllWritingsAPI,
  createWritingAPI,
  deleteWritingAPI,
  fetchWritingTemplatesAPI,
  updateWritingTagsAPI,
  cloneEntryAPI,
} from "views/APIs/APIUtility";
import AssetDashboard from "views/Components/AssetDashboard.js";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import WritingModel from "../Models/WritingModel.js";
import { image_name, identifiers } from "../Constants.js";
import { fetchImageAsFile } from "views/Dashboard/Course/CourseConstants";

const useStyles = makeStyles({
  ...styles,
});

const instructions_mapping = {
  research_report: research_report_instructions,
  white_paper: white_paper_instructions,
  project_plan: project_plan_instructions,
  blog: blog_instructions,
  news_letter: newsletter_instructions,
  case_study: case_study_instructions,
  key_insights: key_insights_instructions,
  handout: handout_instructions,
};

export default function WritingsBoard() {
  // Access to global notifications
  const { showNotification } = React.useContext(NotificationContext);
  // Get the current route path and initialize current dialog text
  const path = useRouteMatch().path;
  // Utility & Resource Setup
  const images = require.context("assets/img/create_cards_16_9/", true);
  const location = useLocation();
  const classes = useStyles();

  // State Variables
  // Alert and Modal state management
  const [createWritingModal, setCreateWritingModal] = React.useState(false);

  const [identifier, setIdentifier] = React.useState("research_report");
  const [identifierPrint, setIdentifierPrint] = React.useState(
    identifiers["research_report"]
  );
  // New Writing Details with default outline template and empty fields
  const [newWritingDetails, setNewWritingDetails] = React.useState(new WritingModel());

  // Other UI and data states
  const [iconImage, setIconImage] = React.useState();
  const [allWritings, setAllWritings] = React.useState([]);
  const [initialWritings, setInitialWritings] = React.useState([]);
  const [loading, setLoading] = React.useState({ loading: false, message: "" });
  const [metapromptChecked, setMetaPromptChecked] = React.useState(false);

  // const [writingCreationLoader, setWritingCreationLoader] = React.useState(
  //   false
  // );
  const [writingCreationLoader, setWritingCreationLoader] = React.useState({
    isLoading: false,
    message: "",
  });
  // Writing ID state (note: TODO; remove writing_id later)
  const [writingId, setWritingId] = React.useState("");
  const [currentDialog, setCurrentDialog] = React.useState("Create Writing");
  const [writingAdded, setWritingAdded] = React.useState(false);

  // Event Handlers and Utility Functions

  // Handler: Upload icon image, saving the file to state
  const handleIconUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIconImage(file);
    }
  };

  // Handler: Delete a writing entry using API call
  const handleDeleteWriting = async (writingData) => {
    const formData = new FormData();
    formData.append("writing_id", writingData._id);

    try {
      await deleteWritingAPI(formData);
      // Remove deleted writing from state
      setAllWritings(
        allWritings.filter((writing) => writing._id !== writingData._id)
      );
    } catch (error) {
      showNotification(error, "danger");
    } finally { }
  };


  const handleCloneCourse = async (courseData) => {
    try {
      setLoading({ loading: true, message: "Cloning " + identifierPrint + "..." });
      const formData = new FormData();
      formData.append("collection", "writing_design");
      formData.append("id", courseData._id);

      const data = await cloneEntryAPI(formData);
      setAllWritings((prevCourses) => [...prevCourses, _mapResourceToAppType(data)]);
      setWritingAdded(true);
      showNotification(`${identifierPrint} cloned successfully`, "success");

    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally {
      setLoading({ loading: false, message: "" });
    }

  };



  // Fetch writings from the API and filter by identifier
  useEffect(() => {
    const fetchWritings = async () => {
      try {
        setLoading({ loading: true, message: "Loading " + identifierPrint + "..." });
        let identifier_local = "";
        let identifierPrint_local = "";
        if (location.pathname) {
          identifier_local = location.pathname.split("/")[2];
          identifierPrint_local = identifiers[identifier_local];
          setIdentifier(identifier_local);
          setIdentifierPrint(identifierPrint_local);
        }
        let data = await fetchAllWritingsAPI();
        // Filter writings based on the current identifier
        data = data.filter(
          (writing) => writing.identifier === identifier_local
        );
        const mappedData = data.map(_mapResourceToAppType);
        setAllWritings(mappedData);
        setInitialWritings(mappedData);
      } catch (error) {
        showNotification(error.message || "An error occurred", "danger");
      } finally {
        setLoading({ loading: false, message: "" });
      }
    };

    fetchWritings();
  }, [identifier]);

  // Handler: Create a new writing entry using API call
  const handleCreateWriting = async () => {
    // setWritingCreationLoader({
    //   isLoading: true,
    //   message: "Creating " + identifierPrint + " ...",
    // });
    // Use uploaded icon image or fetch default image if none is provided
    const imageToUse =
      iconImage || (await fetchImageAsFile(images(image_name[identifier])));

    const formData = new FormData();
    formData.append("writing_id", writingId);
    formData.append("writing_name", newWritingDetails.name);
    formData.append("writing_description", newWritingDetails.description);
    formData.append("writing_image", imageToUse);
    formData.append("writing_outline", newWritingDetails.outline);
    formData.append("identifier", identifier);
    // Append any additional files
    newWritingDetails.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const data = await createWritingAPI(formData);
      setAllWritings([...allWritings, _mapResourceToAppType(data)]);
      setWritingAdded(true);
      showNotification(identifierPrint + " created successfully", "success");
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      // setWritingCreationLoader({ isLoading: false, message: "" });
      handleCreateWritingModalClose();
    }
  };

  const handleSaveLabel = async (data) => {
    try {
      const formData = new FormData();
      formData.append("writing_id", data._id);
      if (data.tags.length === 0) {
        formData.append("tags", []);
      }
      // Append each tag separately
      else {
        data.tags.forEach(tag => formData.append("tags", tag));
      }
      await updateWritingTagsAPI(formData);
      setAllWritings((prevWriting) =>
        prevWriting.map((writing) =>
          writing._id === data._id ? { ...writing, tags: data.tags } : writing
        )
      );
    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally {
    }
  };

  const handleGenerateTemplate = async (prompt, use_metaprompt) => {
    try {
      setWritingCreationLoader({
        isLoading: true,
        message: `Let’s create some templates for the ${identifierPrint}...`,
      });
      const formData = new FormData();
      formData.append("identifier", identifier);
      // Append any additional files
      newWritingDetails.uploadedFiles.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("target_audience", newWritingDetails.target_audience);
      formData.append("expected_length", newWritingDetails.expected_length);
      formData.append("tone", newWritingDetails.tone);

      formData.append("prompt", prompt);
      formData.append("use_metaprompt", use_metaprompt);
      const data = await fetchWritingTemplatesAPI(formData);
      const formattedTemplates = (data.templates || []).map((item) => ({
        template_name: (item.template_name || "Unnamed")
          .replace(/\s*template\s*$/i, "")
          .trim(),
        template_content: item.template_content || "No content available",
      }));

      // Update state with the new template data
      setNewWritingDetails((prevState) => ({
        ...prevState,
        template: formattedTemplates,
      }));
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      setWritingCreationLoader({ isLoading: false, message: "" });
    }
  };

  // Handler: Close create writing modal and reset new writing details
  const handleCreateWritingModalClose = () => {
    setWritingCreationLoader({ isLoading: false, message: "" });
    setCreateWritingModal(false);
    setCurrentDialog("Create Writing");
    setNewWritingDetails(new WritingModel());
  };

  // Display Helper: Return status icon based on the writing's current status
  const getStatusIcon = (status) => {
    switch (status) {
      case "In Design Phase":
        return <Create style={{ color: "#435966" }} />;
      case "In Review Phase":
        return <CloudUpload style={{ color: "#435966" }} />;
      case "Published":
        return <DoneAll style={{ color: "#435966" }} />;
      default:
        return null;
    }
  };

  const _mapResourceToAppType = (resource) => {
    // Destructure the resource to exclude course_name and course_description
    const { writing_name, writing_description, writing_image, ...rest } = resource;

    return {
      ...rest,               // Spread all remaining properties
      name: writing_name,     // Add name property
      description: writing_description, // Add description property
      image: writing_image, // Add image property
    };
  };


  // Conditional Rendering: Show loading spinner if writings data is still loading
  if (loading.loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" className={classes.loaderClass} />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loading.message}
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <CreateWritingModal
        identifier={identifier}
        identifierPrint={identifierPrint}
        newWritingDetails={newWritingDetails}
        setNewWritingDetails={setNewWritingDetails}
        writingId={writingId}
        setWritingId={setWritingId}
        createWritingModal={createWritingModal}
        handleCreateWritingModalClose={handleCreateWritingModalClose}
        handleCreateWriting={handleCreateWriting}
        handleIconUpload={handleIconUpload}
        iconImage={iconImage}
        writingCreationLoader={writingCreationLoader}
        setWritingCreationLoader={setWritingCreationLoader}
        currentDialog={currentDialog}
        setCurrentDialog={setCurrentDialog}
        handleGenerateTemplate={handleGenerateTemplate}
      />

      <AssetDashboard
        path={path}
        welcomeTitle={`Welcome to the ${identifierPrint} Dashboard!`}
        welcomeSubtitle={`Start by creating a new ${identifierPrint.toLowerCase()} or reviewing your existing ${identifierPrint.toLowerCase()}.`}
        allAssets={allWritings}
        setAllAssets={setAllWritings}
        initialAssets={initialWritings}
        noAssetMessage={`Create or Review your ${identifierPrint.toLowerCase()} to get started.`}
        linkPath={`/`}
        state={{ identifier: identifier }}
        getStatusIcon={getStatusIcon}
        CreateAssetCard={CreateCourseCard}
        setCreateAssetModal={setCreateWritingModal}
        createAssetButtonText={`Create ${identifierPrint}`}
        handleSaveLabel={handleSaveLabel}
        asset_identifier={identifierPrint.toLowerCase()}
        handleDeleteAsset={handleDeleteWriting}
        handleCloneAsset={handleCloneCourse}
        assetAdded={writingAdded}
        setAssetAdded={setWritingAdded}
      />
    </div>
  );
}
