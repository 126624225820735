import React, { useEffect, useState, useContext } from "react";
import {
    Divider,
    Typography,
    Box,
    CircularProgress,
    Grid, IconButton, Tooltip
} from "@material-ui/core";

import SortIcon from '@material-ui/icons/Sort';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SearchBar from "material-ui-search-bar";
import {
    fetchQuAPIVaultAPI,
    createQUApiVaultKey,
    editQUApiVaultKey,
    deleteQUApiVaultKey,
} from "views/APIs/APIUtility";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import NotificationContext from "views/Components/Context";
import VaultCard from "views/Components/VaultCard";
import CreateCourseCard from "views/Components/CreateCourseCard";
import ModelSelectionModal from "views/Components/ModelSelectionModal";
import Pagination from "components/Pagination/Pagination.js"; // Import Pagination component

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    ...styles,
})

export default function NewQuApiVault() {
    const { showNotification } = useContext(NotificationContext);
    const classes = useStyles();

    const [searchValue, setSearchValue] = useState("");
    const [initialKeys, setInitialKeys] = useState([]);
    const [apiKeys, setApiKeys] = useState([]);
    const [loading, setLoading] = useState({ loading: false, message: "" });
    const [createAssetModal, setCreateAssetModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const assetsPerPage = 5; // Show 6 assets per page
    const [modelDetails, setModelDetails] = useState({ company: "", model: "", apiKey: "", name: "", description: "" });
    const [alphabeticallyInc, setAlphabeticallyInc] = useState(false);
    // Pagination Logic
    const indexOfLastAsset = currentPage * assetsPerPage;
    const indexOfFirstAsset = indexOfLastAsset - assetsPerPage;
    const currentAssets = apiKeys.slice(indexOfFirstAsset, indexOfLastAsset);

    const totalPages = Math.ceil(apiKeys.length / assetsPerPage);

    // Generate Pagination Buttons
    const pages = [];
    if (totalPages > 1) {
        pages.push({
            text: "PREV",
            disabled: currentPage === 1,
            onClick: () => setCurrentPage((prev) => Math.max(prev - 1, 1)),
        });

        for (let i = 1; i <= totalPages; i++) {
            pages.push({
                text: i,
                active: i === currentPage,
                onClick: () => setCurrentPage(i),
            });
        }

        pages.push({
            text: "NEXT",
            disabled: currentPage === totalPages,
            onClick: () => setCurrentPage((prev) => Math.min(prev + 1, totalPages)),
        });
    }

    useEffect(() => {
        const fetchKeys = async () => {
            setLoading({ loading: true, message: "Loading keys..." });
            try {
                const fetchedKeys = await fetchQuAPIVaultAPI();
                setApiKeys(fetchedKeys);
                setInitialKeys(fetchedKeys);

            } catch (error) {
                showNotification("Error fetching keys", "danger");
            } finally {
                setLoading({ loading: false, message: "" });
            }
        };
        fetchKeys();
    }, []);

    const handleDeleteAsset = async (asset) => {
        setLoading({ loading: true, message: "Deleting key..." });
        try {
            const formData = new FormData();
            formData.append("key_id", asset._id);
            await deleteQUApiVaultKey(formData);
            setApiKeys(apiKeys.filter((api) => api._id !== asset._id));
            showNotification("Key deleted successfully", "success");
        } catch (error) {
            console.error("Error deleting key:", error);
            showNotification("Error deleting key", "danger");
        } finally {
            setLoading({ loading: false, message: "" });
        }
    };

    const handleUpdateAsset = async (asset) => {
        setLoading({ loading: true, message: "Updating key..." });
        const formData = new FormData();
        formData.append("key_id", asset._id);
        formData.append("name", asset.name);
        formData.append("description", asset.description);
        formData.append("key", asset.new_api_key);
        formData.append("model", asset.model);
        try {
            await editQUApiVaultKey(formData);
            setApiKeys(apiKeys.map((api) => (api._id === asset._id ? { ...api, name: asset.name, description: asset.description, key: asset.new_api_key, model: asset.model } : api)));
            showNotification("Key updated successfully", "success");
        } catch (error) {
            console.error("Error updating key:", error);
            showNotification("Error updating key", "danger");
        } finally {
            setLoading({ loading: false, message: "" });
        }
    };

    // Handle search input value change
    const searchInputValue = (e) => {
        setSearchValue(e);
        if (e.length === 0) {
            setApiKeys(initialKeys);
            return;
        }
        const newList = initialKeys.filter((asset) => {
            const nameMatch = asset.name && asset.name.toLowerCase().includes(e.toLowerCase());
            const modelMatch = asset.model && asset.model.toLowerCase().includes(e.toLowerCase());

            return nameMatch || modelMatch;
        });
        setApiKeys(newList);
        setCurrentPage(1); // Reset to first page after search
    };

    const handleCreateAsset = async () => {
        try {
            const formData = new FormData();
            formData.append("model", modelDetails.model);
            formData.append("key", modelDetails.apiKey);

            formData.append("name", modelDetails.name);
            formData.append("description", modelDetails.description);
            formData.append("company", modelDetails.company);
            formData.append("type", "llms");


            const response = await createQUApiVaultKey(formData);
            const message = response.message;
            const newApiKey = response.response;
            setApiKeys((prevApiKeys) => [...prevApiKeys, newApiKey]);
            showNotification(message, "success");
        } catch (err) {
            throw err;
        }
    };

    const [ascendingOrder, setAscendingOrder] = useState(false);

    const sortCards = () => {
        const sortedAssets = [...apiKeys].sort((a, b) => {
            const dateA = new Date(parseInt(a._id.toString().slice(0, 8), 16) * 1000);
            const dateB = new Date(parseInt(b._id.toString().slice(0, 8), 16) * 1000);
            return ascendingOrder ? dateA - dateB : dateB - dateA;
        });

        setApiKeys(sortedAssets);
        setAscendingOrder(!ascendingOrder);
    };

    const sortCardsAlphabetically = () => {
        const sortedAssets = [...apiKeys].sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();

            return alphabeticallyInc ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
        });

        setApiKeys(sortedAssets);
        setAlphabeticallyInc(!alphabeticallyInc);
    };


    return (
        <>
            <ModelSelectionModal
                open={createAssetModal}
                handleModelSelectionModalClose={() => setCreateAssetModal(false)}
                handleCreateAsset={handleCreateAsset}
                modelDetails={modelDetails}
                setModelDetails={setModelDetails}
            />
            <Divider style={{ marginBottom: "20px" }} />
            <Typography variant="h5">Welcome to the QuAPI Vault!</Typography>
            <Typography variant="body1" style={{ margin: "10px 0 20px" }}>
                Here you can manage your QuAPI keys. You can create new keys, edit or delete existing keys.
            </Typography>
            <GridContainer>
                <GridItem xs={12} sm={8} md={8} lg={10}>
                    <SearchBar value={searchValue} onChange={searchInputValue} onRequestSearch={searchInputValue} placeholder={`Search by name or model`} />
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={2} style={{ textAlign: "center" }}>
                    <Button color="quCreateColor" fullWidth onClick={() => setCreateAssetModal(true)}>
                        Create API Key
                    </Button>
                </GridItem>
            </GridContainer>
            {loading.loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <CircularProgress color="quCreateColor" className={classes.loaderClass} />
                    <Typography variant="h6" style={{ marginLeft: 16 }}>{loading.message}</Typography>
                </Box>
            ) : (
                <GridContainer>
                    <GridItem item xs={12} style={{ marginTop: "20px", marginBottom: "20px", display: "flex", gap: "20px" }}>
                        <Tooltip title="Sort by creation date" placement="top">
                            <IconButton color="#20677b" style={{ backgroundColor: ascendingOrder ? quCreateColor : "#f5f5f5" }}>
                                <SortIcon onClick={sortCards} style={{ cursor: "pointer" }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Sort alphabetically" placement="top">
                            <IconButton style={{ backgroundColor: alphabeticallyInc ? quCreateColor : "#f5f5f5" }}>
                                <SortByAlphaIcon onClick={sortCardsAlphabetically} style={{ cursor: "pointer" }} />
                            </IconButton>
                        </Tooltip>
                    </GridItem>
                    {currentAssets.length === 0 && (
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "20px" }}>
                            <Box display="flex" justifyContent="center">
                                <Typography variant="h5" textAlign="center">
                                    Create or Review your API Keys to get started.
                                </Typography>
                            </Box>
                        </GridItem>
                    )}
                    {currentAssets.map((asset, index) => (
                        <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                            <VaultCard
                                apiKeyObject={asset}
                                handleDeleteAsset={handleDeleteAsset}
                                handleUpdateAsset={handleUpdateAsset}
                            />
                        </GridItem>
                    ))}
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                        <CreateCourseCard onClick={() => setCreateAssetModal(true)} />
                    </GridItem>
                </GridContainer>

            )}
            {/* Pagination Component */}
            {totalPages > 1 && (
                <GridContainer style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <Pagination pages={pages} color="quCreateColor" />
                </GridContainer>
            )}
        </>
    );
}