import React, { useState } from 'react';
import { Grid, Typography, Card, Box, CircularProgress, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from '@material-ui/icons/Save';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Button from "components/CustomButtons/Button";
import { saveLabInstructionsAPI, getLabIdeas } from "views/APIs/APIUtility";

import NotificationContext from "views/Components/Context.js";
import MDEditor from '@uiw/react-md-editor';
import Alert from "views/Components/Alert";
import { _mapResourceToAppType } from "../CommonFunctions";

const AdditionalDetails = ({ description, handleNavigation, lab, labID, setStage, files, instructions, setInstructions }) => {
    const { showNotification } = React.useContext(NotificationContext);
    const [loading, setLoading] = useState({ isLoading: false, message: "" });
    const [showAlert, setShowAlert] = useState(false);
    const [reviewDialog, setReviewDialog] = useState(false);



    const handleNext = async () => {
        // handleNavigation("Data Requirements");
        await handleSave(false);
        if (lab.status != "Lab Design") {
            setShowAlert(true);
        }
        else {
            setReviewDialog(true);
        }

    };

    const submitHandler = async () => {
        try {
            handleReviewDialogClose();
            setLoading({ isLoading: true, message: "Generating Ideas..." });
            const response = await getLabIdeas(labID);
            window.location.reload();
        }
        catch (error) {
            console.error("Error in handleNext:", error);
            showNotification("Error generating ideas", "danger");
        }
        finally {
            setLoading({ isLoading: false, message: "" });
        }
    };

    const handleBack = () => {
        setStage(2);
        handleNavigation("Visualization Requirements");
    };

    const handleAdditionalDetailsChange = (event) => {
        setInstructions({ ...instructions, additionalDetails: event });
    };

    const handleSave = async (showLoader = true) => {
        //TODO: Implement the save functionality
        try {
            if (showLoader) {
                setLoading({ isLoading: true, message: "Saving Additional Details..." });
            }
            const formData = new FormData();
            formData.append("lab_id", labID);
            formData.append("instructions", JSON.stringify(instructions)); // Convert instructions to JSON string

            // Display the key/value pairs
            for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            await saveLabInstructionsAPI(formData);
        }
        catch (error) {
            console.error("Error in handleSave:", error);
            showNotification("Error saving lab details", "danger");
        }
        finally {
            if (showLoader) {
                setLoading({ isLoading: false, message: "" });
            }
        }
    };

    const handleConfirm = async () => {
        setShowAlert(false); // Close the alert
        setReviewDialog(true);
    };

    const handleCancel = () => {
        setShowAlert(false); // Close the alert
    };

    const handleReviewDialogClose = () => {
        setReviewDialog(false);
    };

    return (
        <div>
            <Dialog open={reviewDialog} onClose={handleReviewDialogClose} maxWidth="lg" fullWidth>
                <DialogTitle id="alert-dialog-title">Review Lab Design</DialogTitle>
                <DialogContent dividers>
                    <Box data-color-mode="light" mt={0} p={0}>
                        <Typography variant="body1" gutterBottom>
                            The following requirements will be used as the basis for generating lab ideas.
                        </Typography>

                        {/* Learning Outcomes */}
                        <Box mt={2}>
                            <Typography variant="body1" gutterBottom>Learning Outcomes</Typography>
                            <MDEditor preview="preview" disabled value={instructions.learningOutcomes} hideToolbar={true} />
                        </Box>

                        {/* <Divider sx={{ my: 2 }} /> */}

                        {/* Dataset Details */}
                        <Box mt={2}>
                            <Typography variant="body1" gutterBottom>Dataset Details</Typography>
                            <MDEditor preview="preview" disabled value={instructions.datasetDetails} hideToolbar={true} />
                            {files.filter(file => file.type === "Dataset").length > 0 ? (
                                <Box mt={1}>
                                    <Typography variant="body1" color="textSecondary">
                                        The following datasets are being used:
                                    </Typography>
                                    <Box component="ul" pl={2} mt={1}>
                                        {files.filter(file => file.type === "Dataset").map((file, index) => (
                                            <Box component="li" key={index} variant="body2">
                                                {file.name}
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ) : (
                                <Box mt={1}>
                                    <Typography variant="body1" color="textSecondary">
                                        The following datasets are being used:
                                    </Typography>
                                    <Box component="ul" pl={2} mt={1}>
                                        <Typography variant="body2" color="textSecondary" mt={1}>
                                            No datasets have been uploaded.
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>

                        {/* <Divider sx={{ my: 2 }} /> */}

                        {/* Visualization Details */}
                        <Box mt={2}>
                            <Typography variant="body1" gutterBottom>Visualization Details</Typography>
                            <MDEditor preview="preview" disabled value={instructions.visualizationDetails} hideToolbar={true} />
                            {files.filter(file => file.type === "Image").length > 0 ? (
                                <Box mt={1}>
                                    <Typography variant="body1" color="textSecondary">
                                        The following images are being used:
                                    </Typography>
                                    <Box component="ul" pl={2} mt={1}>
                                        {files.filter(file => file.type === "Image").map((file, index) => (
                                            <Box component="li" key={index} variant="body2">
                                                {file.name}
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ) : (
                                <Box mt={1}>
                                    <Typography variant="body1" color="textSecondary">
                                        The following images are being used:
                                    </Typography>
                                    <Box component="ul" pl={2} mt={1}>
                                        <Typography variant="body2" color="textSecondary" mt={1}>
                                            No images have been uploaded.
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>

                        {/* <Divider sx={{ my: 2 }} /> */}

                        {/* Additional Details */}
                        <Box mt={2}>
                            <Typography variant="body1" gutterBottom>Additional Details</Typography>
                            <MDEditor preview="preview" disabled value={instructions.additionalDetails} hideToolbar={true} />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" justifyContent="space-between" p={2}>
                        <Button onClick={handleReviewDialogClose} color="secondary">
                            Close
                        </Button>
                        <Button onClick={submitHandler} color="quCreateColor" variant="contained">
                            Confirm
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>




            {showAlert && (
                <Alert
                    title="Are you sure?"
                    warning={true}
                    showCancel={true}
                    cancelBtnText="No"
                    confirmBtnText="Yes"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                >
                    Proceeding to the next step will reset your current progress in further steps. Your existing work will be overwritten, and new content will be created. Are you sure you want to continue?
                </Alert>
            )}
            {loading.isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginLeft: 16 }}>
                        {loading.message}
                    </Typography>
                </Box>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" >
                                Additional Details
                            </Typography>
                            <Typography variant="body1" style={{ marginBottom: "10px" }}>
                                {description}
                            </Typography>
                            <Divider style={{ marginBottom: "10px" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ marginBottom: "10px" }}>
                                Enter additional details for the application:
                            </Typography>
                            <Grid item xs={12} sm={12}>
                                <Card>
                                    <div data-color-mode="light">
                                        <MDEditor
                                            value={instructions.additionalDetails}
                                            onChange={(event) => handleAdditionalDetailsChange(event)}
                                        />
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: "10px" }}>
                        <Grid item xs={12}>
                            <Button color="quCreateColor" style={{ float: 'right', fontWeight: 'bold' }}
                                endIcon={<NavigateNextIcon />} onClick={handleNext}
                            >
                                Generate Idea
                            </Button>
                            <Button color="quCreateColor" style={{ float: "left", fontWeight: "bold" }}
                                startIcon={<ArrowBackIcon />} onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Button color="quCreateColor" style={{ float: 'right', fontWeight: 'bold' }}
                                startIcon={<SaveIcon />} onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </div >
    );
};

export default AdditionalDetails;