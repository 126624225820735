import BaseDetails from "./BaseDetails";

class PodcastDetails extends BaseDetails {
    constructor() {
        super(
            "",
            "",
            `# Instructions
 - Podcast Name: {{name}} 
 - Podcast Description: {{podcastDescription}} 
- Podcast requirements: The goal is to create an informative and entertaining podcast dialogue using the input text and identify the main topics, key points, and any interesting facts or anecdotes.`
        );
        this.podcastOutline = "";
    }
}

export default PodcastDetails;
