import React, { useState, useContext } from "react";
import {
    Grid, Dialog, TextField,
    DialogActions, DialogContent, DialogTitle,
    CircularProgress, Typography, Divider,
    Stepper, Step, StepLabel, Checkbox, Tooltip,
} from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import Button from "components/CustomButtons/Button.js";
import EditIcon from '@material-ui/icons/Edit';
import { CloudUpload } from "@material-ui/icons";
import NotificationContext from "views/Components/Context.js";
import { DropzoneArea } from "material-ui-dropzone";
import { generatePodcastOutlineAPI, fetchPodcastPromptAPI } from "views/APIs/APIUtility";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import QuStreamlineDialog from "./QuStreamlineDialog";
import useMetaPrompt from "hooks/useMetaPrompt";
import Alert from "views/Components/Alert";
import { stepperStyles, QontoConnector } from "views/Styles/stepperStyles";
import { dropzoneStyles } from "views/Styles/dropzoneStyles";

const useStyles = makeStyles((theme) => ({
    ...styles,
    ...stepperStyles(theme),
    ...dropzoneStyles,
}));

export default function CreatePodcastModal({
    newPodcastDetails,
    setNewPodcastDetails,
    iconImage,
    createPodcastModal,
    handleCreatePodcastModalClose,
    handleCreatePodcast,
    handleIconUpload,
    podcastCreationLoader,
}) {

    const {
        metapromptCheckbox,
        setMetapromptCheckbox,
        originalMetaPrompt,
        setOriginalMetaPrompt,
        metaPrompt,
        setMetaPrompt,
        metaPromptDialogOpen,
        setMetaPromptDialogOpen,
        requiredMetaPromptParameters,
        setRequiredMetaPromptParameters,
        displayMetaPromptParameters,
        setDisplayMetaPromptParameters,
        notRequiredParameters,
        isDisabled,
        setIsDisabled,
        handleMetaPromptChange,
        checkForDisabled,
        _extractParameters,
        _handleEnhancePrompt,
    } = useMetaPrompt();

    const category = localStorage.getItem("userCategory");

    const classes = useStyles();
    const { showNotification } = useContext(NotificationContext);
    const [loading, setLoading] = useState({ isLoading: false, message: "" });

    const dialogStages = [
        "Basic Details",
        "Instructions & Files",
        "Podcast Outline",
        "Submit",
    ];
    const stepperDisplayStages = [
        "Basic Details",
        "Instructions & Files",
        "Podcast Outline",
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [currentDialog, setCurrentDialog] = useState(dialogStages[0]);
    const [podcastOutlineLoader, setPodcastOutlineLoader] = useState(false);
    const [alert, setAlert] = useState(null);
    const [backAlert, setBackAlert] = useState(false);


    const generatePodcastOutline = async (prompt) => {
        setPodcastOutlineLoader(true);
        const formData = new FormData();
        newPodcastDetails.uploadedFiles.forEach((file) => {
            formData.append("files", file);
        });
        formData.append("instructions", newPodcastDetails.template);
        prompt = metapromptCheckbox ? metaPrompt : originalMetaPrompt;
        formData.append("prompt", prompt);
        formData.append("use_metaprompt", !metapromptCheckbox);

        try {
            const data = await generatePodcastOutlineAPI(formData);
            setNewPodcastDetails({ ...newPodcastDetails, podcastOutline: data });
            setPodcastOutlineLoader(false);
        } catch (error) {
            showNotification(error, "danger");
            setPodcastOutlineLoader(false);
        }
    };

    // Function to handle file upload
    const handleFileUpload = (files) => {
        setNewPodcastDetails({ ...newPodcastDetails, uploadedFiles: files });
    };

    const replacePlaceholders = (template, values) => {
        return Object.keys(values).reduce(
            (result, key) => result.replace(`{{${key}}}`, values[key]),
            template
        );
    };

    // Handle next actions on dialogs
    const handleStep1 = async () => {
        if (newPodcastDetails.name.trim() === "" || newPodcastDetails.description.trim() === "") {
            showNotification(
                "Please make sure all fields, including the podcast name and description, are properly filled out.",
                "danger"
            );
            return;
        }
        newPodcastDetails.template = replacePlaceholders(newPodcastDetails.template, {
            name: newPodcastDetails.name,
            podcastDescription: newPodcastDetails.description,
        });
        await getPrompt();
        setActiveStep(1);
        setCurrentDialog(dialogStages[1]);
    };

    const handleStep2 = async () => {
        if (newPodcastDetails.template.trim() === "") {
            showNotification(
                "Please fill in the instructions for generating the podcast outline.",
                "danger"
            );
            return;
        }
        if (newPodcastDetails.uploadedFiles.length === 0) {
            showNotification("Please upload at least one file to generate podcast outline.", "danger");
            return;
        }
        newPodcastDetails.template = newPodcastDetails.template
            .replace("{{description}}", newPodcastDetails.description)
            .replace("{{name}}", newPodcastDetails.name);
        setActiveStep(2);
        setCurrentDialog(dialogStages[2]);
        await generatePodcastOutline(prompt);
    };

    const handleStep3 = async () => {
        if (newPodcastDetails.podcastOutline.trim() === "") {
            showNotification("Please fill in the podcast outline.", "danger");
            return;
        }
        await handleCreation();

    };

    const handleCreation = async () => {
        setActiveStep(3);
        setCurrentDialog(dialogStages[3]);
        await handleCreatePodcast();
        handleModalClose();
    };

    const handleModalClose = () => {
        setActiveStep(0);
        setCurrentDialog(dialogStages[0]);
        setPodcastOutlineLoader(false);
        handleCreatePodcastModalClose();
    };

    const handleMetaPromptDialog = async (event) => {
        setMetaPromptDialogOpen(true);
    };

    const metaPromptDialogClose = () => {
        setMetaPromptDialogOpen(false);
        setMetapromptCheckbox(false);
    };

    const _handleSubmission = async () => {
        setMetapromptCheckbox(true);
        setMetaPromptDialogOpen(false);
    };

    const getPrompt = async () => {
        try {
            const data = await fetchPodcastPromptAPI();
            setMetaPrompt(data);
            setOriginalMetaPrompt(data);
            const parameters = _extractParameters(data);
            setRequiredMetaPromptParameters(parameters);
            setDisplayMetaPromptParameters(parameters);
        }
        catch (error) {
            console.log("error", error);
        }
    };

    const handleBack = async () => {
        if (activeStep === 1) {
            setNewPodcastDetails({
                ...newPodcastDetails,
                template: "# Instructions\n - Podcast Name: {{name}} \n - Podcast Description: {{podcastDescription}} \n- Podcast requirements: The goal is to create an informative and entertaining podcast dialogue using the input text and identify the main topics, key points, and any interesting facts or anecdotes.",
            });
        }
        if (activeStep === 2) {
            setNewPodcastDetails({ ...newPodcastDetails, podcastOutline: "" });
        }
        setActiveStep(activeStep - 1);
        setCurrentDialog(dialogStages[activeStep - 1]);
    };

    const handleConfirm = () => {
        setBackAlert(false); // Close the alert
        handleBack();
    };

    const handleCancel = () => {
        setBackAlert(false); // Close the alert
    };



    const renderDialogContent = () => {
        switch (currentDialog) {
            case "Basic Details":
                return (
                    <div>
                        <Typography variant="h5" style={{ marginBottom: "10px" }}>
                            Enter the podcast details:
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="podcastName"
                            label="Podcast Name"
                            placeholder="AI and Machine Learning in Finance"
                            type="text"
                            fullWidth
                            required
                            value={newPodcastDetails.name}
                            onChange={(event) =>
                                setNewPodcastDetails({
                                    ...newPodcastDetails,
                                    name: event.target.value,
                                })
                            }
                        />
                        <TextField
                            margin="dense"
                            id="brief"
                            label="Brief Description"
                            placeholder="This podcast provides a comprehensive introduction to the fundamentals of Artificial Intelligence (AI) and Machine Learning (ML) in finance."
                            type="text"
                            fullWidth
                            multiline
                            required
                            rows={4}
                            value={newPodcastDetails.description}
                            onChange={(event) =>
                                setNewPodcastDetails({
                                    ...newPodcastDetails,
                                    description: event.target.value,
                                })
                            }
                        />
                        {/* Icon Image Upload */}
                        <Button
                            variant="contained"
                            component="label"
                            color="info"
                            style={{ marginTop: "20px" }}
                        >
                            <CloudUpload /> &nbsp; Upload Image
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                required
                                onChange={handleIconUpload}
                            />
                        </Button>
                        {iconImage && (
                            <div style={{ marginTop: "10px" }}>
                                <strong>Uploaded Image:</strong> {iconImage.name}
                            </div>
                        )}
                        <Typography
                            variant="caption"
                            style={{ display: "block", marginTop: "5px" }}
                        >
                            A 16:9 aspect ratio image is recommended
                        </Typography>
                    </div>
                );
            case "Instructions & Files":
                return (
                    <Grid container spacing={1} alignItems="flex-start">
                        {/* Title for the Template Editing */}
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ marginBottom: "10px" }}>
                                Edit the instructions to customize it to your requirements:
                            </Typography>
                        </Grid>

                        {/* MDEditor for Template Editing */}
                        <Grid item xs={12} style={{ flexGrow: 1 }}>
                            <div data-color-mode="light">
                                <MDEditor
                                    value={newPodcastDetails.template
                                        .replace("{{name}}", newPodcastDetails.name)
                                        .replace("{{description}}", newPodcastDetails.description)}
                                    onChange={(event) =>
                                        setNewPodcastDetails({
                                            ...newPodcastDetails,
                                            template: event,
                                        })
                                    }
                                    height={250}
                                />
                            </div>
                        </Grid>
                        {(category === "super_admin" || category === "admin") && (
                            <Grid item xs={12} style={{ display: "flex", alignItems: "center", verticalAlign: "middle" }}>
                                <Checkbox color="quCreateColor" checked={metapromptCheckbox} disabled style={{ paddingLeft: "0px", marginLeft: "0px" }} />
                                <Typography variant="body2" color="textSecondary">
                                    Enable QuStreamline
                                </Typography>
                                <Tooltip title="QuStreamline streamlines the generation of the query with best practices and guardrails.">
                                    <EditIcon
                                        style={{ verticalAlign: "middle", marginLeft: "5px" }}
                                        fontSize="small"
                                        color="quCreateColor"
                                        onClick={handleMetaPromptDialog}
                                    />
                                </Tooltip>
                            </Grid>
                        )}
                        {/* Title for File Upload */}
                        <Grid item xs={12} style={{ flexGrow: 1 }}>
                            <Typography
                                variant="h5"
                                style={{ marginBottom: "10px", marginTop: "10px" }}
                            >
                                Upload supporting files to generate the podcast outline:
                            </Typography>
                        </Grid>

                        {/* DropzoneArea for File Upload */}
                        <Grid item xs={12}>
                            <DropzoneArea
                                initialFiles={newPodcastDetails.uploadedFiles}
                                onChange={handleFileUpload}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                useChipsForPreview
                                maxFileSize={50000000}
                                acceptedFiles={[
                                    "application/pdf"
                                ]}
                                previewText="Uploaded files"
                                dropzoneText={
                                    <div style={{ textAlign: "center" }}>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            Acceptable formats: .pdf
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Drag and drop or click here to upload files.
                                        </Typography>
                                    </div>
                                }
                                filesLimit={100}
                                dropzoneClass={classes.dropzone}
                            />
                        </Grid>
                    </Grid>
                );
            case "Podcast Outline":
                return (
                    <div>
                        <Typography variant="h5" style={{ marginBottom: "10px" }}>
                            Podcast outline:
                        </Typography>
                        {podcastOutlineLoader ? (
                            <div style={{ display: "flex" }}>
                                <CircularProgress size={20} /> &nbsp;
                                <Typography variant="body1">
                                    Generating podcast outline...
                                </Typography>
                            </div>
                        ) : (
                            <div data-color-mode="light">
                                <MDEditor
                                    value={newPodcastDetails.podcastOutline}
                                    onChange={(event) =>
                                        setNewPodcastDetails({
                                            ...newPodcastDetails,
                                            podcastOutline: event,
                                        })
                                    }
                                    height={400}
                                />
                            </div>
                        )}
                    </div>
                );
            case "Submit":
                return (
                    <div style={{ display: "flex" }}>
                        <CircularProgress size={20} /> &nbsp;
                        <Typography variant="body1">Creating podcast for you...</Typography>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {alert}
            {backAlert && (
                <Alert
                    title="Are you sure?"
                    warning={true}
                    showCancel={true}
                    cancelBtnText="No"
                    confirmBtnText="Yes"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                >
                    If you proceed, some changes made at this step will be lost. Are you
                    sure you want to continue?
                </Alert>
            )}
            <Dialog
                open={createPodcastModal}
                onClose={handleModalClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth
            >
                {/* Stepper Component */}
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<QontoConnector />}
                    className={classes.stepperRoot}
                >
                    {stepperDisplayStages.map((label, index) => (
                        <Step key={label}>
                            <StepLabel
                                StepIconProps={{
                                    classes: {
                                        root: classes.stepIcon, // Default icon color
                                        active: classes.activeStepIcon, // Active step circle
                                        completed: classes.completedStepIcon, // Completed step color
                                    }
                                }}
                            >{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Divider />
                <DialogTitle id="form-dialog-title">
                    <div style={{ fontSize: "30px" }}>
                        {newPodcastDetails.name
                            ? newPodcastDetails.name
                            : "Create a New Podcast"}
                    </div>
                </DialogTitle>
                <DialogContent>{renderDialogContent()}</DialogContent>
                <DialogActions>
                    {currentDialog !== "Basic Details" && (
                        <Button
                            onClick={() => setBackAlert(true)}
                            color="quCreateColor"
                            disabled={podcastOutlineLoader || podcastCreationLoader}
                        >
                            Back
                        </Button>
                    )}
                    <Button
                        onClick={handleCreatePodcastModalClose}
                        color="quCreateColor"
                        disabled={podcastOutlineLoader || podcastCreationLoader}
                    >
                        Cancel
                    </Button>

                    <Button
                        onClick={() => {
                            switch (currentDialog) {
                                case "Basic Details":
                                    return handleStep1();
                                case "Instructions & Files":
                                    return handleStep2();
                                case "Podcast Outline":
                                    return handleStep3();
                                default:
                                    return null;
                            }
                        }}
                        disabled={podcastOutlineLoader || podcastCreationLoader}
                        color="quCreateColor"
                    >
                        {currentDialog === "Podcast Outline" ? "Submit" : "Next"}
                    </Button>
                </DialogActions>
            </Dialog>
            <QuStreamlineDialog
                metaPromptDialogOpen={metaPromptDialogOpen}
                metaPromptDialogClose={metaPromptDialogClose}
                metaPrompt={metaPrompt}
                setMetaPrompt={setMetaPrompt}
                displayMetaPromptParameters={displayMetaPromptParameters}
                notRequiredParameters={notRequiredParameters}
                handleMetaPromptChange={handleMetaPromptChange}
                handleSubmission={_handleSubmission}
                checkForDisabled={checkForDisabled}
                _handleEnhancePrompt={_handleEnhancePrompt}
                _handleSubmission={_handleSubmission}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
            />
        </div>
    );
}
