import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Box, CircularProgress,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Snackbar,
    Grid, Checkbox, Tooltip,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { Delete, Edit, FileCopy, Add } from '@material-ui/icons';
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from '@material-ui/core/styles';
import { fetchCourseAPI, fetchAllLabsAPI, updateSelectedLabsInModule } from "views/APIs/APIUtility";
import { quCreateColor } from 'assets/jss/material-dashboard-pro-react';
import NotificationContext from "views/Components/Context.js";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '20px',
        boxSizing: 'border-box',
    },
    title: { marginBottom: '20px' },
    button: {
        marginBottom: '20px',
        padding: '15px 30px',
        fontSize: '1.2rem',
    },
    tableContainer: {
        width: '100%',
        maxHeight: '70vh',
        margin: '0 auto',
    },
    dialogContent: {
        width: '500px',
        maxWidth: '90vw',
    },
    dialogButtonSpacing: { margin: '10px 0' },
    urlCell: {
        wordBreak: 'break-word',
        maxWidth: '200px',
        textAlign: 'center',
    },
    centeredCell: {
        textAlign: 'center',
        padding: '16px', // Ensures equal padding inside each cell
    },
    rightAlignedTitleCell: {
        textAlign: 'right',
        padding: '16px', // Ensures equal padding inside each cell
    },
    rightAlignedCell: {
        textAlign: 'right',
        padding: '16px', // Ensures equal padding inside each cell
    },
});

export default function Labs(props) {
    const { showNotification } = React.useContext(NotificationContext);

    const location = useLocation(); // Get current location

    const courseID = props.courseID;
    const moduleID = props.moduleID;
    const classes = useStyles();
    const [links, setLinks] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentLink, setCurrentLink] = useState({ name: "", url: "" });
    const [successMessage, setSuccessMessage] = useState('');
    const [nameError, setNameError] = useState(false);
    const [selectedLabs, setSelectedLabs] = useState([]);
    const [labs, setLabs] = useState([]);
    const [loading, setLoading] = useState({ isLoading: false, message: "" });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading({ isLoading: true, message: "Fetching labs..." });
                const response = await fetchCourseAPI(courseID);
                const module = response.modules.find(mod => mod.module_id === moduleID);
                if (module?.selected_labs) {
                    setSelectedLabs(module.selected_labs);
                }


                let labsResponse = await fetchAllLabsAPI(); // Change const to let
                labsResponse = labsResponse.filter(lab => lab.status === "Published" || lab.status === "Review");
                setLabs(labsResponse);
            } catch (error) {
                console.error("Error fetching labs for card", error);
            }
            finally {
                setLoading({ isLoading: false, message: "" });
            }
        };

        fetchData(); // Call the async function

    }, [courseID, moduleID]); // Dependencies ensure it runs when IDs change


    const handleOpenModal = (link = { name: "", url: "" }, editMode = false) => {
        setCurrentLink(link);
        setIsEditMode(editMode);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setSuccessMessage('');
        setNameError(false);
    };

    const handleSaveLink = () => {
        if (!currentLink.name.trim()) {
            setNameError(true);
            return;
        }
        if (!isValidUrl(currentLink.url)) {
            alert("Please enter a valid URL.");
            return;
        }

        if (isEditMode) {
            setLinks(links.map(link => link.name === currentLink.name ? currentLink : link));
            setSuccessMessage('Link Updated Successfully');
        } else {
            setLinks([...links, currentLink]);
            setSuccessMessage('Link Added Successfully');
        }

        setOpen(false);
    };

    const handleDelete = (linkToDelete) => {
        setLinks(links.filter(link => link.name !== linkToDelete.name));
        setSuccessMessage('Link Deleted Successfully');
    };

    const isValidUrl = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' +
            '((([a-z0-9](?:[a-z0-9-]*[a-z0-9])?)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z0-9+&@#\\/%?=~_|!:,.;]*)*$', 'i');
        return !!pattern.test(url);
    };

    const handleCopy = (url) => {
        navigator.clipboard.writeText(url);
        alert("Link copied to clipboard!");
    };

    const handleSaveChanges = async () => {

        try {
            const formData = new FormData();
            formData.append("course_id", courseID);
            formData.append("module_id", moduleID);
            formData.append("selected_labs", selectedLabs);

            

            await updateSelectedLabsInModule(formData);
            showNotification("Saved changes successfully", "success");
        }
        catch (error) {
            console.error("Error updating selected labs in module", error);
        }
    };

    return (
        <div>
            {loading.isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginLeft: 16 }}>
                        {loading.message}
                    </Typography>
                </Box>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} style={{ position: "relative", marginTop: "20px" }}>
                        <Typography variant="h5" gutterBottom>
                            Concept Labs
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            The selected labs will be included in the module upon publishing.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        {labs && (
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: "5%" }}><strong>Select</strong></TableCell>
                                            <TableCell style={{ width: "40%" }}><strong>Name</strong></TableCell>
                                            <TableCell style={{ width: "40%" }}><strong>Description</strong></TableCell>
                                            <TableCell style={{ width: "40%" }}><strong>Link</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Display message when no labs are uploaded */}
                                        {labs.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={4} style={{ textAlign: "center", padding: "16px" }}>
                                                    <Typography variant="body1">
                                                        No published labs are visible. Please publish a lab to view it here.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {/* Render labs if they exist */}
                                        {labs.map((file, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.tableCell} style={{ width: "5%" }}>
                                                    <Checkbox
                                                        type="checkbox"
                                                        checked={selectedLabs.includes(file._id)} // Ensure the checkbox is checked based on the selectedLabs array
                                                        onChange={(e) => {
                                                            setSelectedLabs(prevSelectedLabs => {
                                                                if (e.target.checked) {
                                                                    return [...prevSelectedLabs, file._id]; // Add the lab ID if checked
                                                                } else {
                                                                    return prevSelectedLabs.filter(id => id !== file._id); // Remove the lab ID if unchecked
                                                                }
                                                            });
                                                        }}
                                                        color="quCreateColor"
                                                        aria-label={`Select ${file.lab_name}`} // Improve accessibility
                                                    />
                                                </TableCell>

                                                <TableCell className={classes.tableCell} style={{ width: "40%" }}>
                                                    <Tooltip title={file.lab_name} disableHoverListener={file.lab_name.length < 20}>
                                                        <div>
                                                            <span>{file.lab_name}</span>
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>

                                                <TableCell className={classes.tableCell} style={{ width: "40%" }}>
                                                    <Tooltip title={file.lab_description} disableHoverListener={file.lab_description.length < 20}>
                                                        <div>
                                                            <span>{file.lab_description}</span>
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>

                                                <TableCell className={classes.tableCell} style={{ width: "40%" }}>
                                                    <IconButton
                                                        onClick={() => window.open(`/admin/labdesign/lab/${file._id}`, '_blank')}
                                                        aria-label="Open Lab Design"
                                                    >
                                                        <CallMadeIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Button
                            color="success"
                            onClick={handleSaveChanges}
                            style={{ float: "right", marginLeft: "10px" }}
                            startIcon={<SaveIcon />}
                        >
                            Save Changes
                        </Button>
                    </Grid>
                </Grid>
            )}
        </div>
    );



}
