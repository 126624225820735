// templates.js

import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import GestureIcon from "@material-ui/icons/Gesture";
import SchoolIcon from "@material-ui/icons/School";
import TableChartIcon from "@material-ui/icons/TableChart";
import PieChartIcon from "@material-ui/icons/PieChart";

const templates = [
  {
    heading: "Financial Professionals",
    icon: AttachMoneyIcon,
    content: `Create a professional slide deck for financial professionals based on the resources provided. 
    
    Number of slides: 8
    Bullet points per slide: 3-5
    Tone: Professional, informative, and concise
    Speaker notes: Provide detailed explanations for each bullet point, expanding on the content with additional insights and examples.`,
  },
  {
    heading: "Board Executives",
    icon: BusinessCenterIcon,
    content: `Develop a professional slide deck for board executives using the resources provided. 
    
    Number of slides: 8
    Bullet points per slide: 3-5
    Tone: Professional, strategic, and forward-looking
    Speaker notes: Offer detailed explanations for each bullet point, providing insights that align with the strategic focus of board executives.`,
  },
  {
    heading: "AI Professional",
    icon: GestureIcon,
    content: `Create a professional module tailored for AI professionals based on the resources provided. 
    
    Number of slides: 8
    Bullet points per slide: 3-5
    Tone: Technical, informative, and industry-specific
    Speaker notes: Provide detailed explanations for each bullet point, offering insights and examples that enhance an AI professional's understanding of the material.`,
  },
  {
    heading: "Quant Student",
    icon: SchoolIcon,
    content: `Develop a professional module for quantitative finance students using the resources provided. 
    
    Number of slides: 8
    Bullet points per slide: 3-5
    Tone: Academic, informative, and technical
    Speaker notes: Offer detailed explanations for each bullet point, providing insights and examples that enhance a quantitative finance student's understanding of the material.`,
  },
  {
    heading: "Analyst",
    icon: TableChartIcon,
    content: `Create a professional module for financial analysts based on the provided resources. 
    
    Number of slides: 8
    Bullet points per slide: 3-5
    Tone: Analytical, informative, and data-driven
    Speaker notes: Provide detailed explanations for each bullet point, offering insights and examples that enhance a financial analyst's understanding of the material.`,
  },
  {
    heading: "Data Scientist",
    icon: PieChartIcon,
    content: `Develop a professional module for data scientists using the resources provided. 
    
    Number of slides: 8
    Bullet points per slide: 3-5
    Tone: Technical, informative, and data-driven
    Speaker notes: Offer detailed explanations for each bullet point, providing insights and examples that enhance a data scientist's understanding of the material.`,
  },
];

export default templates;
