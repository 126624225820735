import { Box, Grid, CircularProgress, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import React, { useState, useEffect } from "react";
import MDEditor from "@uiw/react-md-editor";
import NotificationContext from "views/Components/Context.js";
import { replaceResourceAPI, fetchContentAPI } from "views/APIs/APIUtility";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SaveIcon from '@material-ui/icons/Save';
import ExitReviewButton from "components/CustomButtons/ExitReviewButton";
import DoneIcon from '@material-ui/icons/Done';
import { submitModuleForContentGeneration } from "views/APIs/APIUtility";
import Alert from "views/Components/Alert";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    ...styles,
})

export default function ModuleInformationContentReview(props) {
    const classes = useStyles();
    // Extract the key and value of the top element in activeStatusData
    const [[activeStatusDataKey, activeStatusDataValue]] = Object.entries(props.activeStatusData);

    const { showNotification } = React.useContext(NotificationContext);
    const [moduleInformation, setModuleInformation] = useState();
    const [loadingState, setLoadingState] = useState({ isLoading: false, message: "" });
    const [showAlert, setShowAlert] = useState(false);
    if (props.moduleInformation.length > 0) {
        const moduleInformationLink = props.moduleInformation[0].resource_link || "";
    }
    else {
        const moduleInformationLink = "";
    }

    const fetchFile = async (fileUrl) => {
        if (!fileUrl) {
            showNotification("Invalid file URL", "danger");
            return;
        }

        const formData = new FormData();
        formData.append("url", fileUrl);
        try {
            const data = await fetchContentAPI(formData);
            setModuleInformation(data.content);
        }
        catch (error) {
            console.error("Error: ", error);
            showNotification("Error fetching file", "danger");
        }
    };

    const setMIForCard = async () => {
        const card = props.moduleInformation[0];
        if (card && card.resource_link) {
            // fetchFile("https://qucoursify.s3.amazonaws.com/qu-course-design/673277707a469de58e0efa96/673277717a469de58e0efa9a/raw_resources/67337197c061106b9ae5f07a.md");
            await fetchFile(card.resource_link);
        } else {
            console.warn("Card or resource link not found");
        }
    };

    useEffect(() => {
        const fetchModuleInformation = async () => {
            setLoadingState({ isLoading: true, message: "Loading Content Information..." });

            if (props.moduleInformation.length > 0) {
                await setMIForCard();
            }

            setLoadingState({ isLoading: false, message: "" });
        };

        fetchModuleInformation();
    }, [props.moduleInformation]);


    const handleModuleInformation = (content) => setModuleInformation(content);

    const handleSaveChanges = async () => {
        setLoadingState({ isLoading: true, message: "Saving changes..." });
        let resource_description = props.moduleInformation[0].resource_description;
        resource_description = resource_description + "###NOTE###" + moduleInformation;

        const formData = new FormData();
        formData.append("course_id", props.courseID);
        formData.append("module_id", props.moduleID);
        formData.append("resource_type", "Note");
        formData.append("resource_description", resource_description);
        formData.append("resource_link", props.moduleInformation[0].resource_link);
        formData.append("resource_id", props.moduleInformation[0].resource_id);
        formData.append("resource_name", props.moduleInformation[0].resource_name);
        formData.append("course_design_step", props.course_design_step);

        try {
            await replaceResourceAPI(formData);
            showNotification("Changes saved successfully", "success");
        }
        catch (error) {
            console.error("Error in handleUpdateNote:", error);
            showNotification("Error saving changes", "danger");
        }
        finally {
            setLoadingState({ isLoading: false, message: "" });
        }
    }

    const submitHandler = async () => {
        setLoadingState({ isLoading: true, message: "Submitting Module for Content Generation..." });
        const formData = new FormData();
        formData.append("course_id", props.courseID);
        formData.append("module_id", props.moduleID);

        try {
            const data = await submitModuleForContentGeneration(formData);
            setLoadingState({ isLoading: false, message: "" });
            showNotification("Your job is submitted & review will be unlocked as soon as your request is processed.", "quSandBoxColor");
            setTimeout(() => {
                window.location.reload();
            }, 2000); // Adjust the time as needed (2000ms = 2 seconds)
            window.location.reload();
        }
        catch (error) {
            showNotification("Error submitting module", "danger");
        }
        finally {
        }
    };

    const verifyOutline = async () => {
        if (props.moduleStatus != "Outline Review") {
            setShowAlert(true);
        }
        else {
            submitHandler();
        }
    };

    const handleConfirm = () => {
        setShowAlert(false); // Close the alert
        submitHandler();
    };

    const handleCancel = () => {
        setShowAlert(false); // Close the alert
    };

    const handleNext = async () => {
        // await handleSaveChanges();
        props.setActiveTab("Slides");
    };

    if (loadingState.isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
            >
                <CircularProgress color="quCreateColor" className={classes.loaderClass} />
                <Typography variant="h6" style={{ marginLeft: 16 }}>
                    {loadingState.message}
                </Typography>
            </Box>
        );
    }


    return (
        <div>
            {showAlert && (
                <Alert
                    title="Are you sure?"
                    warning={true}
                    showCancel={true}
                    cancelBtnText="No"
                    confirmBtnText="Yes"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                >
                    You already have a submission for this module. If you wish to make another submission, your module will be submitted for content review.
                </Alert>
            )}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    {activeStatusDataValue === 1 ? (
                        <>
                            <Typography variant="h5" style={{ marginTop: "20px" }}>Outline Information</Typography>
                            <Typography variant="body1">This is the brief description of the module outline.</Typography>
                        </>
                    ) : (
                        // This section is for deliverables
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginTop: '20px' }}>
                            <div>
                                <Typography variant="h5">Module Information</Typography>
                                <Typography variant="body1">This is the brief description of the module.</Typography>
                            </div>
                            {props.course_design_step === 11 && (
                                <Button
                                    color="success"
                                    onClick={handleSaveChanges}
                                    style={{ marginLeft: "10px" }}
                                    startIcon={<SaveIcon />}
                                >
                                    Save Changes
                                </Button>
                            )}
                        </div>
                    )}

                </Grid>
                <Grid item xs={12} sm={12}>
                    <div data-color-mode="light" style={{
                        marginTop: "10px",
                    }}>
                        <MDEditor value={moduleInformation} height={400} onChange={handleModuleInformation} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {props.course_design_step !== 11 && (
                        <Grid container spacing={3} style={{ marginTop: "10px" }}>
                            <Grid item xs={12} sm={4} style={{ textAlign: "left" }}>
                                <ExitReviewButton courseID={props.courseID} />
                            </Grid>
                            <Grid item xs={12} sm={4} style={{ textAlign: "center" }} />
                            <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
                                {activeStatusDataValue === 1 ? (
                                    <Button
                                        color="info"
                                        onClick={verifyOutline}
                                        style={{ float: "right" }}
                                        endIcon={<DoneIcon />}
                                    >
                                        Mark as Reviewed
                                    </Button>
                                ) : (
                                    <Button
                                        color="quCreateColor"
                                        onClick={handleNext}
                                        style={{ float: "right" }}
                                        endIcon={<NavigateNextIcon />}
                                    >
                                        Next
                                    </Button>
                                )}
                                <Button
                                    color="success"
                                    onClick={handleSaveChanges}
                                    style={{ float: "right", marginLeft: "10px" }}
                                    startIcon={<SaveIcon />}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
