// React and related hooks
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Custom components
import ModuleInformationContentReview from "./ModuleInformationContentReview";

// AWS Amplify
import { Auth } from "aws-amplify";

// Contexts
import NotificationContext from "views/Components/Context.js";

// Material-UI components
import { Divider, Grid, Typography, Box, CircularProgress } from "@material-ui/core";

// API utilities
import { fetchCourseAPI } from "views/APIs/APIUtility";

export default function OutlineReview({ courseID, moduleID, activeStatusData, moduleStatus }) {
    const { showNotification } = React.useContext(NotificationContext);
    const [user, setUser] = useState();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState("Outline Information");

    const [moduleInformation, setModuleInformation] = useState("");
    const [modulesLoading, setModulesLoading] = useState(true); // Track loading state

    const setContentReviewFromCourse = (data) => {
        const modules = data.modules;
        const module = modules.find(module => module.module_id === moduleID);
        const resources = module.pre_processed_outline;
        const notes = resources.filter(resource => resource.resource_type === "Note");

        setModuleInformation(notes);
    };

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const data = await fetchCourseAPI(courseID);
                setContentReviewFromCourse(data);
            } catch (error) {
                showNotification("Error fetching course data in outline", "danger");
            } finally {
                setModulesLoading(false);
            }
        };
        fetchCourseData();
    }, [courseID]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => setUser(user))
            .catch((err) => {
                if (err === "not authenticated") {
                    history.push("/auth/login-page");
                }
            });
    }, [history]);

    const renderContent = () => {
        if (modulesLoading) {
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="60vh"
                >
                    <CircularProgress color="quCreateColor" />
                    <Typography variant="h6" style={{ marginLeft: 16 }}>
                        Loading Content...
                    </Typography>
                </Box>
            );
        }

        switch (activeTab) {
            case "Outline Information":
                return <ModuleInformationContentReview
                    courseID={courseID} moduleID={moduleID} filetype="Note"
                    moduleInformation={moduleInformation} course_design_step={2} activeStatusData={activeStatusData} moduleStatus={moduleStatus}
                />;
            default:
                return null;
        }
    }

    return (
        <div>
            <div>
                <Grid container display="flex" justify="space-between" style={{ marginTop: "20px" }}>
                    <Grid item xs={4}>
                        <Typography variant="body1" style={{ marginTop: "10px", fontWeight: "bold", fontSize: "20px" }}>Outline Review</Typography>
                    </Grid>
                    {/* <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10px" }}>
                        <Button color="quSandBoxColor" style={activeTab === "Outline Information" ? { textDecoration: "underline" } : {}} onClick={() => setActiveTab("Outline Information")}>Outline Information</Button>
                    </Grid> */}
                </Grid>
                <Divider />
            </div>

            {renderContent()}
        </div>
    );
}
