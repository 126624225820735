import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    Divider, Grid, Box, CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Custom component imports
import Button from "components/CustomButtons/Button.js";
import { validateKeyAPI } from "views/APIs/APIUtility";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import { COMPANY_MODELS, getCompanyIcon } from "views/Components/Constants";

const useStyles = makeStyles({
    customCheckbox: {
        color: quCreateColor,
        "&.Mui-checked": {
            color: quCreateColor,
        },
    },
    loaderClass: {
        color: quCreateColor,
    },
})

const ModelSelectionModal = ({ open, handleModelSelectionModalClose, handleCreateAsset, modelDetails, setModelDetails }) => {
    const classes = useStyles();
    const [error, setError] = useState("");

    const companies = Object.keys(COMPANY_MODELS);
    const modelOptions = modelDetails.company ? COMPANY_MODELS[modelDetails.company] : [];
    const [loading, setLoading] = useState({ loading: false, message: "" });


    // async function validateKey() {
    //     if (!apiKey || apiKey.trim() === "") {
    //         setError("API Key is required.");
    //         return false;
    //     }
    //     const formData = new FormData();
    //     formData.append("model_id", model);
    //     formData.append("api_key", apiKey);
    //     const response = await validateKeyAPI(formData);
    //     console.log("Response", response);
    //     if (response) {
    //         setValidKey(true);
    //         return true;
    //     }
    //     else {
    //         setError("Invalid API Key. Please try again.");
    //         return false;
    //     }
    // }

    const validateKey = async () => {
        if (modelDetails.company.trim() === "") {
            setError("Company is required.");
            return false;
        }
        if (!modelDetails.model || modelDetails.model.trim() === "") {
            setError("Model is required.");
            return false;
        }
        if (!modelDetails.apiKey || modelDetails.apiKey.trim() === "") {
            setError("API Key is required.");
            return false;
        }
        if ((!modelDetails.name || modelDetails.name.trim() === "")) {
            setError("API Key Name is required.");
            return false;
        }
        const formData = new FormData();
        formData.append("model", modelDetails.model);
        formData.append("key", modelDetails.apiKey);
        const response = await validateKeyAPI(formData);
        console.log("Response", response);
        if (response) {
            // setValidKey(true);
            return true;
        }
        else {
            setError("The provided API key is invalid or does not support the selected model. Please verify and try again.");
            return false;
        }
    };

    const _handleSubmit = async () => {
        setLoading({ loading: true, message: "Creating key..." });
        try {
            setError("");
            const res = await validateKey();
            if (res) {
                await handleCreateAsset();
                onClose();
            }
            else {
                setError("Invalid API Key. Please try again.");
            }

        } catch (error) {
            setError(error.message);
        }
        finally {
            setLoading({ loading: false, message: "" });
        }
    };


    const onClose = () => {
        setModelDetails({ company: "", model: "", apiKey: "", name: "" });
        setError("");
        handleModelSelectionModalClose();
        // setValidKey(false);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle> Model Selection</DialogTitle>
            <Divider />
            <DialogContent>
                {loading.loading ? (  // Corrected ternary operator syntax
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="60vh"
                    >
                        <CircularProgress className={classes.loaderClass} />
                        <Typography variant="h6" style={{ marginLeft: 16 }}>
                            Creating Key...
                        </Typography>
                    </Box>
                ) : (  // Closing the ternary properly here
                    <>
                        <FormControl fullWidth margin="normal">
                            <InputLabel required>Select Company</InputLabel>
                            <Select
                                value={modelDetails.company}
                                onChange={(e) => {
                                    setModelDetails({ company: e.target.value, model: "", apiKey: "" });
                                    setError("");
                                }}
                            >
                                <MenuItem value="">-- Select Company --</MenuItem>
                                {companies.map((comp) => (
                                    <MenuItem key={comp} value={comp}>
                                        {getCompanyIcon(comp)}
                                        {comp.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {modelDetails.company && (
                            <FormControl fullWidth margin="normal">
                                <InputLabel required>Model</InputLabel>
                                <Select
                                    value={modelDetails.model}
                                    onChange={(e) => setModelDetails({ ...modelDetails, model: e.target.value })}
                                >
                                    <MenuItem value="">-- Select Model --</MenuItem>
                                    {modelOptions.map((m) => (
                                        <MenuItem key={m} value={m}>{m}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {modelDetails.model && (
                            <>
                                <TextField
                                    fullWidth
                                    required
                                    margin="normal"
                                    label="API Key"
                                    type="text"
                                    placeholder="Enter your API key"
                                    value={modelDetails.apiKey}
                                    onChange={(e) => setModelDetails({ ...modelDetails, apiKey: e.target.value })}
                                />
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        margin="normal"
                                        label="API Key Name"
                                        type="text"
                                        placeholder="Enter your API key name"
                                        value={modelDetails.name || ""}
                                        onChange={(e) => setModelDetails({ ...modelDetails, name: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label="API Key Description"
                                        type="text"
                                        placeholder="Enter your API key description"
                                        value={modelDetails.description || ""}
                                        onChange={(e) => setModelDetails({ ...modelDetails, description: e.target.value })}
                                    />
                                </Grid>
                            </>
                        )}

                        {error && (
                            <Typography color="error" variant="body2">
                                {error}
                            </Typography>
                        )}
                    </>
                )}
            </DialogContent>

            <DialogActions
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px 24px",
                    width: "100%",
                }}
            >
                {/* Buttons aligned to the right */}
                <div style={{ display: "flex", gap: "12px" }}>
                    <Button onClick={onClose} color="quSandBoxColor" disabled={loading.loading}>
                        Cancel
                    </Button>
                    <Button onClick={_handleSubmit} color="quCreateColor" disabled={!modelDetails.model || !modelDetails.apiKey || loading.loading}>
                        Submit
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default ModelSelectionModal;