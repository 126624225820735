import React, { useState, useCallback } from "react";
import {
    Modal, Box, Typography, Grid, IconButton, TextField, Card, useTheme
} from "@material-ui/core";
import { Add, ArrowBack, CloudUploadRounded, Close } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import ImageIcon from '@material-ui/icons/Image';
import NotesIcon from '@material-ui/icons/Notes';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import NotificationContext from "views/Components/Context.js";
import Button from "components/CustomButtons/Button.js";
import MDEditor from '@uiw/react-md-editor';
import { addResourceToModuleAPI, addResourceToLectureAPI, addResourceToLabAPI } from "../APIs/APIUtility";



const useStyles = makeStyles((theme) => ({
    ...styles,
    dropzone: {
        width: '100%',
        height: '60%',
        border: '2px dashed #3f51b5',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    modalBox: {
        backgroundColor: theme.palette.background.paper,
        width: '90%',
        maxWidth: '600px',
        height: 'auto',
        padding: theme.spacing(3),
        borderRadius: '12px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: theme.shadows[10],
        display: 'flex',
        flexDirection: 'column',
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    cardOption: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: theme.shadows[3],
        borderRadius: '8px',
        transition: 'transform 0.2s ease',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
}));

const getFileNameWithoutExtension = (fileName) => fileName.replace(/\.[^/.]+$/, '');

const ResourceModal = ({ open, onClose, projectID, moduleID, projectType }) => {
    const classes = useStyles();
    const theme = useTheme();
    const resourceTypes = ["File", "Link", "Image", "Note"];
    const { showNotification } = React.useContext(NotificationContext);

    const [activeScreen, setActiveScreen] = useState("main"); // "main", "upload", "describe"

    const [note, setNote] = useState("### Sample Note\nThis is a sample note. You can edit this note or create a new one to suit your needs.\n- Notes can be organized using concise bullet points.\n- Use bullet points to list tasks, ideas, or reminders.\nYou can also include links to relevant resources or websites, such as [Google](https://www.google.com) or any other useful reference.\nFeel free to format your note with headings, subheadings, or additional Markdown elements for clarity and structure.");
    const [link, setLink] = useState("");

    const [resource, setResource] = useState({
        "resource_name": "",
        "resource_description": "",
        "resource_type": "",
        "resource_file": null,
    })

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const filename = getFileNameWithoutExtension(file.name);
            setResource({
                ...resource,
                "resource_name": filename,
                "resource_file": file,
            });
            setActiveScreen("describe");
        }
    };

    const isValidURL = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' +
            '((([a-z0-9](?:[a-z0-9-]*[a-z0-9])?)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z0-9+&@#\\/%?=~_|!:,.;]*)*$', 'i');
        return !!pattern.test(url);
    };

    const validateResource = (resource, link, note) => {
        if (!resource.resource_name.trim()) {
            showNotification("Name is required", "danger");
            return false;
        }
        if (!resource.resource_file) {
            if (resource.resource_type === "File") {
                showNotification("File is required", "danger");
                return false;
            }
            if (resource.resource_type === "Image") {
                showNotification("Image is required", "danger");
                return false;
            }
        }
        if (resource.resource_type === "Link" && !link.trim()) {
            showNotification("Link is required", "danger");
            return false;
        }
        if (resource.resource_type === "Link" && !isValidURL(link)) {
            showNotification("Invalid link format", "danger");
            return false;
        }
        if (resource.resource_type === "Note" && !note.trim()) {
            showNotification("Note is required", "danger");
            return false;
        }
        if (!resource.resource_description.trim()) {
            showNotification("Description is required", "danger");
            return false;
        }
        return true; // All checks passed
    };


    const handleSave = async () => {

        // Perform validation
        if (!validateResource(resource, link, note)) return;

        const formData = new FormData();

        if (projectType === "course_designer") {
            formData.append("course_id", projectID);
            formData.append("module_id", moduleID);
        }
        else if (projectType === "lab_designer") {
            formData.append("lab_id", projectID);
        }
        else {
            formData.append("lecture_id", projectID);
        }

        formData.append("resource_name", resource.resource_name);

        if (resource.resource_type === "Link") {
            resource.resource_description += "###LINK###" + link;
        }
        if (resource.resource_type === "Note") {
            resource.resource_description += "###NOTE###" + note;
        }
        formData.append("resource_description", resource.resource_description);
        formData.append("resource_type", resource.resource_type);

        if (resource.resource_type === "File" || resource.resource_type === "Image") {
            formData.append("resource_file", resource.resource_file);
        }


        try {
            if (projectType === "course_designer") {
                await addResourceToModuleAPI(formData);
            }
            else if (projectType === "lab_designer") {
                await addResourceToLabAPI(formData);
            }
            else {
                await addResourceToLectureAPI(formData);
            }
            showNotification("Resource added successfully", "success");
            // Refresh the window
            window.location.reload();
            handleClose();
        } catch {
            showNotification("Failed to add resource", "danger");
        } finally {
            handleClose();
            onClose();
        }
    };


    const handleClose = () => {
        setResource({
            "resource_name": "",
            "resource_description": "",
            "resource_type": "",
            "resource_file": null,
        });
        setLink("");
        setNote("");
        setActiveScreen("main");
        onClose();
    };


    const handleDrop = useCallback((event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            setResource({
                ...resource,
                "resource_name": getFileNameWithoutExtension(file.name),
                "resource_file": file,
            });
            setActiveScreen("describe");
        }
    }, []);

    const handleDragOver = (event) => event.preventDefault();

    const getModalHeader = () => {
        switch (resource.resource_type) {
            case "File":
                return "Add File";
            case "Link":
                return "Add Link";
            case "Image":
                return "Add Image";
            case "Note":
                return "Add Note"
            default:
                return "Add Source";
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className={classes.modalBox}>
                <IconButton className={classes.closeButton} onClick={handleClose}>
                    <Close />
                </IconButton>
                <Typography variant="h5" align="center">
                    {getModalHeader()}
                </Typography>
                <Box style={{ marginTop: theme.spacing(2), height: '100%', flex: 1 }}>
                    {activeScreen === "main" && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card onClick={() => {
                                    setResource({ ...resource, resource_type: "File" });
                                    setActiveScreen("upload")
                                }} className={classes.cardOption} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: "10px",
                                    justifyContent: 'space-between',

                                }}>
                                    <FileCopyIcon style={{ fontSize: '36px', color: '#435966' }} />
                                    <Typography variant="body1" style={{
                                        fontSize: '1.2rem',
                                    }}>Add File</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card onClick={() => {
                                    setResource({ ...resource, resource_type: "Link" });
                                    setActiveScreen("describe")
                                }} className={classes.cardOption} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: "10px",
                                    justifyContent: 'space-between',

                                }}>
                                    <LinkIcon style={{ fontSize: '36px', color: '#435966' }} />
                                    <Typography variant="body1" style={{
                                        fontSize: '1.2rem',
                                    }}>Add Link</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card onClick={() => {
                                    setResource({ ...resource, resource_type: "Image" });
                                    setActiveScreen("upload")
                                }} className={classes.cardOption} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: "10px",
                                    justifyContent: 'space-between',

                                }}>
                                    <ImageIcon style={{ fontSize: '36px', color: '#435966' }} />
                                    <Typography variant="body1" style={{
                                        fontSize: '1.2rem',
                                    }}>Add Image</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card onClick={() => {
                                    setResource({ ...resource, resource_type: "Note" })
                                    setActiveScreen("describe")
                                }} className={classes.cardOption} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: "10px",
                                    justifyContent: 'space-between',

                                }}>
                                    <NotesIcon style={{ fontSize: '36px', color: '#435966' }} />
                                    <Typography
                                        variant="body1"
                                        style={{
                                            fontSize: '1.2rem',
                                        }}
                                    >
                                        {projectType === "lecture_designer" ? "Add Transcript" : "Add Note"}
                                    </Typography>

                                </Card>
                            </Grid>
                        </Grid>
                    )}

                    {activeScreen === "describe" && (
                        <Box width="100%">
                            <IconButton onClick={() => {
                                if (resource.resource_type === "File" || resource.resource_type === "Image") {
                                    setActiveScreen("upload");
                                }
                                else {
                                    setActiveScreen("main");
                                }
                            }} aria-label="Go back" style={{ marginBottom: theme.spacing(2) }}>
                                <ArrowBack />
                            </IconButton>
                            <TextField
                                autoFocus
                                label={resource.resource_type + " Name"}
                                fullWidth
                                value={resource.resource_name}
                                onChange={(e) => {
                                    setResource({ ...resource, resource_name: e.target.value });
                                }}
                                margin="dense"
                            />
                            <TextField
                                autoFocus
                                label={resource.resource_type + " Description"}
                                fullWidth
                                multiline
                                rows={4}
                                value={resource.resource_description}
                                onChange={(e) => {
                                    setResource({ ...resource, resource_description: e.target.value });
                                }}
                                margin="dense"
                            />
                            {
                                resource.resource_type === "Link" && (
                                    <TextField
                                        autoFocus
                                        label="Add Website Link"
                                        helperText={
                                            <>
                                                Enter the URL of the website (e.g., https://www.example.com). <br />
                                                Please note: Links are currently an experimental feature. In some cases, data extraction from certain websites may not be supported, which could result in incomplete information.
                                            </>
                                        }
                                        fullWidth
                                        value={link}
                                        onChange={(e) => {
                                            setLink(e.target.value);
                                        }}
                                        margin="dense"
                                    />
                                )
                            }
                            {
                                (resource.resource_type === "File" || resource.resource_type === "Image") && (
                                    <>
                                        <Button
                                            variant="contained"
                                            style={{ marginTop: theme.spacing(2) }}
                                            color="quSandBoxColor"
                                            onClick={() => document.getElementById(`file-input-${resource.id}`).click()} // Trigger input on button click
                                        >
                                            Add {resource.resource_type}
                                        </Button>
                                        <input
                                            id={`file-input-${resource.id}`} // Unique ID for each input
                                            type="file"
                                            hidden
                                            onChange={handleFileChange}
                                            accept={
                                                resource.resource_type === "File"
                                                    ? ".pdf"
                                                    : "image/*"
                                            }
                                        />

                                        {resource.resource_file && <Typography>{resource.resource_file.name}</Typography>}
                                    </>
                                )
                            }
                            {
                                resource.resource_type === "Note" && (
                                    <div>
                                        <Typography
                                            variant="body1"
                                            style={{
                                                fontSize: '1rem',
                                                marginTop: theme.spacing(2),
                                            }}
                                        >
                                            {projectType === "course_designer" ? "Note" : "Transcript"}
                                        </Typography>

                                        <div data-color-mode="light">

                                            <MDEditor
                                                value={note}
                                                onChange={(e) => setNote(e)}
                                                height={200}
                                            />
                                        </div>

                                    </div>
                                )
                            }

                            <Button variant="contained" color="quSandBoxColor" onClick={handleSave} style={{ marginTop: theme.spacing(2), float: "right" }}>
                                Save
                            </Button>
                        </Box>
                    )}

                    {activeScreen === "upload" && (
                        <Box>
                            <IconButton
                                onClick={() => setActiveScreen("main")}
                                aria-label="Go back"
                                style={{ marginBottom: theme.spacing(2) }}
                            >
                                <ArrowBack />
                            </IconButton>
                            <Box
                                className={classes.dropzone}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                onClick={() => document.getElementById("fileInput").click()}
                            >
                                <CloudUploadRounded style={{ fontSize: '48px', color: '#3f51b5', marginBottom: '8px' }} />
                                <Typography variant="body2" align="center">
                                    Drag & Drop files here or click to upload
                                </Typography>
                                <Typography variant="caption" align="center" color="textSecondary" style={{ marginTop: '8px' }}>
                                    {/* Acceptable formats: {resource.resource_type === "File"
                                        ? ".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .csv, .txt"
                                        : ".jpg, .jpeg, .png, .gif, .bmp"} */}
                                    Acceptable formats: {resource.resource_type === "File"
                                        ? ".pdf"
                                        : ".jpg, .jpeg, .png, .gif, .bmp"}
                                </Typography>
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept={resource.resource_type === "File" ? ".pdf" : "image/*"}
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal >
    );
};

export default ResourceModal;
