export const groups = ["Create", "Publish", "Share", "Research", "All"];
export const groupsAsset = ["Create", "Publish", "Share", "Research", "All"];

export const image_name = {
    research_report: "./report.png",
    white_paper: "./whitepaper.png",
    project_plan: "./project-plan.png",
    blog: "./blog.png",
    news_letter: "./newsletter.png",
    case_study: "./case-study.png",
    key_insights: "./insights.png",
    handout: "./handout.png",
}

export const identifiers = {
    research_report: "Research Report",
    white_paper: "White Paper",
    project_plan: "Project Plan",
    blog: "Blog",
    news_letter: "Newsletter",
    case_study: "Case Study",
    key_insights: "Key Insights",
    handout: "Handout",
    podcast: "Podcast",
    Lab: "Lab",
};

// Data for the cards
export const cardData = [
    {
        logo: require("assets/img/create_cards/course.png"),
        title: "Project",
        description:
            "Create and design engaging educational projects to share knowledge.",
        identifier: "courses",
        inProgress: false,
        icons: true,
        route: "/admin/project-design",
        group: "Create",
    },
    {
        logo: require("assets/img/create_cards/labs.png"),
        title: "Concept Labs",
        description:
            "Create interactive concept labs to provide hands-on experience to students.",
        identifier: "labs",
        inProgress: false,
        route: "/admin/labdesign",
        icons: true,
        group: "Create",
    },
    {
        logo: require("assets/img/create_cards/video.png"),
        title: "Video",
        description:
            "Create captivating videos to tell your story or share information.",
        identifier: "create_a_video",
        inProgress: true,
        icons: true,
        group: "Create",
    },
    {
        logo: require("assets/img/create_cards/podcast.png"),
        title: "Take 5 Podcast",
        description:
            "Create insightful podcasts to engage your audience through audio storytelling.",
        identifier: "create_a_podcast",
        inProgress: false,
        route: "/admin/podcastdesign",
        group: "Create",
    },
    {
        logo: require("assets/img/create_cards/project-plan.png"),
        title: "Project Plan",
        description:
            "Create comprehensive project plans to streamline workflows and objectives.",
        identifier: "project_plan",
        inProgress: false,
        route: "/admin/project_plan",
        group: "Create",
    },
    {
        logo: require("assets/img/create_cards/research.png"),
        title: "Research",
        description:
            "Create research to share findings and insights with the world.",
        identifier: "research",
        inProgress: true,
        icons: true,
        group: "Research",
    },
    {
        logo: require("assets/img/create_cards/report.png"),
        title: "Research Report",
        description:
            "Create in-depth research reports to present data-driven insights.",
        identifier: "research_report",
        inProgress: false,
        route: "/admin/research_report",
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/whitepaper.png"),
        title: "White Paper",
        description:
            "Create detailed white papers to showcase expertise on critical topics.",
        identifier: "white_paper",
        inProgress: false,
        icons: true,
        route: "/admin/white_paper",
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/blog.png"),
        title: "Blog",
        description:
            "Create impactful blogs to share thoughts and expertise with the world.",
        identifier: "blog",
        inProgress: false,
        route: "/admin/blog",
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/newsletter.png"),
        title: "Newsletter",
        description:
            "Create engaging newsletters to share updates and insights with your audience.",
        identifier: "news_letter",
        inProgress: false,
        route: "/admin/news_letter",
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/case-study.png"),
        title: "Case Study",
        description:
            "Create detailed case studies to showcase expertise and success stories.",
        identifier: "case_study",
        inProgress: false,
        route: "/admin/case_study",
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/e-book.png"),
        title: "E-Book",
        description:
            "Create compelling e-books to captivate readers and share ideas.",
        identifier: "e_book",
        inProgress: true,
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/insights.png"),
        title: "Key Insights",
        description:
            "Create key insights to share valuable information and knowledge.",
        identifier: "key_insights",
        inProgress: false,
        route: "/admin/keyinsights",
        group: "Share",
    },
    {
        logo: require("assets/img/create_cards/handout.png"),
        title: "Handout",
        description:
            "Create informative handouts to share knowledge and insights.",
        identifier: "handout",
        inProgress: false,
        route: "/admin/handout",
        group: "Share",
    },
];


export const cardDataAsset = [
    {
        logo: require("assets/img/create_cards/module.png"),
        title: "Module",
        description:
            "Create modules for your course to share knowledge and insights.",
        identifier: "module",
        inProgress: false,
        route: "/admin/labdesign",
        icons: true,
        group: "Create",
    },
    {
        logo: require("assets/img/create_cards/labs.png"),
        title: "Concept Labs",
        description:
            "Create interactive concept labs to provide hands-on experience to students.",
        identifier: "labs",
        inProgress: false,
        route: "/admin/labdesign",
        icons: true,
        group: "Create",
    },
    {
        logo: require("assets/img/create_cards/podcast.png"),
        title: "Take 5 Podcast",
        description:
            "Create insightful podcasts to engage your audience through audio storytelling.",
        identifier: "podcast",
        inProgress: false,
        route: "/admin/podcastdesign",
        group: "Create",
    },
    {
        logo: require("assets/img/create_cards/project-plan.png"),
        title: "Project Plan",
        description:
            "Create comprehensive project plans to streamline workflows and objectives.",
        identifier: "project_plan",
        inProgress: false,
        route: "/admin/project_plan",
        group: "Create",
    },
    {
        logo: require("assets/img/create_cards/report.png"),
        title: "Research Report",
        description:
            "Create in-depth research reports to present data-driven insights.",
        identifier: "research_report",
        inProgress: false,
        route: "/admin/research_report",
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/whitepaper.png"),
        title: "White Paper",
        description:
            "Create detailed white papers to showcase expertise on critical topics.",
        identifier: "white_paper",
        inProgress: false,
        icons: true,
        route: "/admin/white_paper",
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/blog.png"),
        title: "Blog",
        description:
            "Create impactful blogs to share thoughts and expertise with the world.",
        identifier: "blog",
        inProgress: false,
        route: "/admin/blog",
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/newsletter.png"),
        title: "Newsletter",
        description:
            "Create engaging newsletters to share updates and insights with your audience.",
        identifier: "news_letter",
        inProgress: false,
        route: "/admin/news_letter",
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/case-study.png"),
        title: "Case Study",
        description:
            "Create detailed case studies to showcase expertise and success stories.",
        identifier: "case_study",
        inProgress: false,
        route: "/admin/case_study",
        group: "Publish",
    },
    {
        logo: require("assets/img/create_cards/handout.png"),
        title: "Handout",
        description:
            "Create informative handouts to share knowledge and insights.",
        identifier: "handout",
        inProgress: false,
        route: "/admin/handout",
        group: "Share",
    },
];