import React, { useState, useEffect } from "react";

// Material UI Core Components
import {
  AppBar, Toolbar, Box, Typography, Grid, Tooltip, CircularProgress,
  Stepper, Step, StepLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';

// Custom Components
import Resources from "../Resources";
import OutlineReview from "./OutlineReview";
import ContentReview from "./ContentReview";
import StructureReview from "./StructureReview";
import Deliverables from "../Deliverables";
import { displayModuleStages } from "../Data/moduleStages";
import { module_status_enabled_tab_map, inBetweenStagesLoaderOptions, course_steps } from "./CourseConstants";
import { fetchCourseAPI, updateModuleDetails } from "views/APIs/APIUtility";
import { Auth } from "aws-amplify";

// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { stepperStyles, QontoConnector } from "views/Styles/stepperStyles";

import appbarStyles from "views/Styles/appbarStyles";

// Contexts
import NotificationContext from "views/Components/Context.js";

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...stepperStyles(theme),
  ...appbarStyles(theme),
}));

export default function ModulesBoard(props) {
  const classes = useStyles();
  const { showNotification } = React.useContext(NotificationContext);
  const displaySteps = getDisplaySteps();

  // Extract courseID and moduleID from URL params
  const { courseID, moduleID } = props.match.params;
  const [activeStep, setActiveStep] = React.useState(0);
  const [moduleCompletedTillStep, setModuleCompletedTillStep] = React.useState(0);

  const [courseStatus, setCourseStatus] = useState(""); // Store course status
  const [moduleStatus, setModuleStatus] = useState(""); // Store module status
  const [activeTab, setActiveTab] = useState("Resources");
  // const [loading, setLoading] = useState(true);
  const [loading, setLoading] = useState({ isLoading: false, message: "" });

  const [course, setCourse] = useState({});
  const [module, setModule] = useState({});

  const module_status_enabled_tab_map = {
    "Raw Resources": 0,
    "In Design Phase": 0,
    "In Outline Generation Queue": 0,
    "Outline Generation Failed": 0,
    "Outline Review": 1,
    "In Content Generation Queue": 1,
    "Content Generation Failed": 1,
    "Content Review": 2,
    "Pre Processed Content": 2,
    "Post Processed Content": 2,
    "In Structure Generation Queue": 2,
    "Structure Generation Failed": 2,
    "Structure Review": 3,
    "Pre Processed Structure": 3,
    "Post Processed Structure": 3,
    "In Deliverables Generation Queue": 3,
    "Deliverables Generation Failed": 3,
    "Deliverables Review": 4,
    "Pre Processed Deliverables": 4,
    "Post Processed Deliverables": 4,
    "In Publishing Queue": 4,
    "Publishing Failed": 5,
    Published: 5,
  };

  const [inBetweenStagesLoaderMessage, setInBetweenStagesLoaderMessage] = useState("");

  const inBetweenStagesLoaderOptions = {
    "In Outline Generation Queue": "We are generating the outline for you...",
    "In Content Generation Queue": "We are generating the content for you...",
    "In Structure Generation Queue": "We are generating the structure for you...",
    "In Deliverables Generation Queue": "We are generating the final deliverables for you...",
    "In Publishing Queue": "Module marked ready for publishing",
  };

  useEffect(() => {
    if (module_status_enabled_tab_map.hasOwnProperty(moduleStatus)) {
      setInBetweenStagesLoaderMessage(inBetweenStagesLoaderOptions[moduleStatus]);
    }
  }, [moduleStatus]);

  const [user, setUser] = useState("");

  // write a async useeffect to set the user to auth.currentAuthenticatedUser
  useEffect(() => {
    async function fetchUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch (error) {
        console.error("Error in fetchUser:", error);
        showNotification("Error fetching user details", "danger");
      }
    }
    fetchUser();
  }, []);


  useEffect(() => {
    if (user === "" || moduleID === undefined || inBetweenStagesLoaderOptions.hasOwnProperty(moduleStatus) === false) {
      return;
    }
    const wsUrl = `${process.env.REACT_APP_FASTAPI_URL.replace("http", "ws")}/ws/tasks/${user.username}/${moduleID}`;
    const ws = new WebSocket(wsUrl);
    console.log("WebSocket URL is: ", wsUrl);


    ws.onopen = () => console.log("Task WebSocket connected");
    ws.onmessage = (event) => {
      console.log("Task message:", event.data);
      if (event.data === "Done") {
        setInBetweenStagesLoaderMessage("Done!");
        window.location.reload();
      }
      else {
        setInBetweenStagesLoaderMessage("Failed!");
        setModuleStatus("Failed");
      }
    };
    ws.onclose = () => console.log("Task WebSocket disconnected");

    return () => ws.close();
  }, [user, moduleID, moduleStatus]);


  useEffect(() => {
    const fetchCourseDetails = async () => {
      console.log("fetchCourseDetails");
      try {
        // Fetch course details including modules
        const data = await fetchCourseAPI(courseID);

        // Update course status
        if (data.status) {
          setCourseStatus(data.status);
        }

        // Find and update the module status
        const module = data.modules?.find((mod) => mod.module_id === moduleID);
        if (module?.status) {
          setModuleStatus(module.status);
        }
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    fetchCourseDetails();
  }, [courseID, moduleID]); // Dependencies ensure re-fetching on changes

  useEffect(() => {
    console.log("In second useEffect");
    setLoading({ isLoading: true, message: "Loading Module..." });
    fetch(process.env.REACT_APP_FASTAPI_URL + `/get_course/${courseID}`)
      .then((res) => res.json())
      .then((data) => {
        setCourse(data);
        for (let i = 0; i < data.modules.length; i++) {
          if (data.modules[i].module_id === moduleID) {
            // setModule("Module", data.modules[i]);
            setModule(data.modules[i]);
            setActiveTab(module_status_enabled_tab_map[data.modules[i].status]);
            break;
          }
        }
      })
      .catch((err) => {
        showNotification("Error fetching course data", "danger");
      })
      .finally(() => {
        setLoading({ isLoading: false, message: "" });
      });
  }, [courseID]);



  // Determine the active step based on module status
  useEffect(() => {
    const stepIndex = mapStatusToStep(moduleStatus.trim());
    setActiveStep(stepIndex); // Set the active step from the module status
    setModuleCompletedTillStep(stepIndex);
  }, [moduleStatus]);

  // Map module status to corresponding step index
  function mapStatusToStep(moduleStatus) {
    return module_status_enabled_tab_map[moduleStatus];
  }

  function getDisplaySteps() {
    return displayModuleStages;
  }

  const handleStepChange = (index) => {
    if (mapStatusToStep(moduleStatus) >= index) {
      setActiveStep(index);
      setActiveTab(index);
    } else {
      const message =
        course_steps[index].lockedMessage || "This step is locked.";
      showNotification(message, "danger");
    }
  };

  // Renders the content based on the selected tab
  const renderContent = () => {
    // Find the key-value pair for the active tab
    const activeStatusEntry = Object.entries(
      module_status_enabled_tab_map
    ).find(([key, value]) => value === activeTab);

    // Destructure the key-value pair
    const activeStatusData = activeStatusEntry
      ? { [activeStatusEntry[0]]: activeStatusEntry[1] }
      : null;

    switch (activeTab) {
      case 0:
        return (
          <Resources
            projectID={courseID}
            moduleID={moduleID}
            projectType="course_designer"
            activeStatusData={activeStatusData}
            moduleStatus={moduleStatus}
          />
        );
      case 1:
        return (
          <OutlineReview
            courseID={courseID}
            moduleID={moduleID}
            activeStatusData={activeStatusData}
            moduleStatus={moduleStatus}
          />
        );
      case 2:
        return (
          <ContentReview
            courseID={courseID}
            moduleID={moduleID}
            activeStatusData={activeStatusData}
            moduleStatus={moduleStatus}
          />
        );
      case 3:
        return (
          <StructureReview
            courseID={courseID}
            moduleID={moduleID}
            activeStatusData={activeStatusData}
            moduleStatus={moduleStatus}
          />
        );
      case 4:
        return <Deliverables courseID={courseID} moduleID={moduleID} />;
      case 5:
        return <Deliverables courseID={courseID} moduleID={moduleID} />;
      default:
        return null;
    }
  };

  if (loading.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress className={classes.loaderClass} />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loading.message}
        </Typography>
      </Box>
    );
  }

  const handleModuleDetailsChange = async (newModuleDetails) => {
    try {
      const formData = new FormData();
      formData.append("course_id", courseID);
      formData.append("module_id", moduleID);
      formData.append("module_name", newModuleDetails.title);
      formData.append("module_description", newModuleDetails.shortDescription);
      await updateModuleDetails(formData);
      // Updating the state correctly
      setModule((prevModule) => ({
        ...prevModule,
        module_name: newModuleDetails.title,
        module_description: newModuleDetails.shortDescription,
      }));
      showNotification("Module details updated successfully", "success");
    } catch (error) {
      showNotification(
        "An error occurred while updating course details.",
        "danger"
      );
    } finally {
    }
  };

  return (
    <Box>
      {/* Display in-between stages */}
      {inBetweenStagesLoaderOptions.hasOwnProperty(moduleStatus) && (
        <Grid container alignItems="center" width="100%">
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
              <Tooltip title="Your job has been submitted and you will be notified when the request is ready" arrow placement="top">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {moduleStatus === "In Publishing Queue" ? (
                    <>
                      <CheckIcon style={{ color: "green", marginRight: "8px" }} />
                      <Typography variant="subtitle2" style={{ marginRight: "8px", cursor: "pointer" }}>
                        {inBetweenStagesLoaderMessage}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="subtitle2" style={{ marginRight: "8px", cursor: "pointer" }}>
                        {inBetweenStagesLoaderMessage}
                      </Typography>
                      <CircularProgress size={20} className={classes.loaderClass} />
                    </>
                  )}
                </div>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      )}
      <AppBar position="static" className={classes.header}>

        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            {/* // TODO: Remove comments post design change approval from professor */}
            {/* Title */}
            <Typography
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bold",
                flexShrink: 0,
                maxWidth: "45%", // Ensures it does not exceed the container width
                wordBreak: "break-word", // Allows long words to break and wrap to the next line if necessary
                overflow: "hidden", // Hides any overflow if text is too long
                whiteSpace: "normal", // Ensures the text wraps
              }}
            >
              {module.module_name}
            </Typography>
            <Stepper
              activeStep={activeStep}
              connector={<QontoConnector />}
              alternativeLabel
              size="small"
              className={classes.stepperRoot}
              style={{ flexGrow: 1 }} // Stepper will take up the remaining space
            >
              {displaySteps.map((label, index) => {
                const isDisabled = index > moduleCompletedTillStep; // Disable steps beyond completed step

                return (
                  <Step
                    key={label}
                    onClick={() => handleStepChange(index)}
                  >
                    <StepLabel
                      StepIconProps={
                        !isDisabled
                          ? {
                            classes: {
                              root: classes.stepIcon, // Default icon color
                              active: classes.activeStepIcon, // Active step circle
                              completed: classes.completedStepIcon, // Completed step color
                            },
                          }
                          : {} // If disabled, no classes applied
                      }
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>


          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Box>{renderContent()}</Box>
    </Box >
  );
}
