// React & Routing Imports
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Material-UI Core Imports
import {
  Grid,
  Dialog,
  Box,
  CircularProgress,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// API Utilities
import {
  fetchTemplateFormData,
  submitTemplateData,
  fetchReports,
  importTemplatesToProjectAPI,
  fetchModelProjects,
} from "views/APIs/APIUtility.js";
import { deleteReportAPI } from "views/APIs/APIUtility";

// Survey Library Imports
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";

// Custom Components & Styles
import TemplateTable from "views/Components/TemplateTable";
import PDFViewer from "views/Components/PDFViewer";
import Button from "components/CustomButtons/Button.js";
import ImportProjectsModal from "views/Components/ImportProjectsModal";
import styles from "assets/jss/material-dashboard-pro-react/components/headingStyle.js";
import QUGreen from "./QUGreen";

const useStyles = makeStyles(styles);
export default function TemplateDesign() {
  const { projectID, templateID } = useParams(); // Get templateID from the URL
  const classes = useStyles();
  const [templateData, setTemplateData] = useState([]);
  const [surveyJSON, setSurveyJson] = useState(new Model());
  const [reports, setReports] = useState([]);
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    message: "",
  });
  const [samplePage, setSamplePage] = useState(false);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [formFields, setFormFields] = useState();
  const [lastReport, setLastReport] = useState();
  const [error, setError] = useState(null);

  const setupSurveyModel = (response) => {
    const surveyModel = new Model(response); // Initialize the survey
    surveyModel.applyTheme(QUGreen);
    surveyModel.showCompletedPage = false;
    surveyModel.onComplete.add(handleSurveyCompletion);
    surveyModel.completeText = "Submit";
    // Ensure navigation buttons are visible
    surveyModel.showNavigationButtons = true;

    if (projectID) {
      // Add Reset Button
      surveyModel.addNavigationItem({
        id: "sv-nav-clear-form",
        title: "Reset Form",
        action: () => {
          surveyModel.clear(true, true); // Clears all form data and resets to the first page
        },
      });
    }

    return surveyModel; // Return the model
  };

  const [allProjects, setAllProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [openProjectsModal, setOpenProjectsModal] = useState(false);

  useEffect(() => {
    try {
      const fetchProjects = async () => {
        const response = await fetchModelProjects();
        setAllProjects(response);
      };
      fetchProjects();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const fetchTemplateData = async () => {
      setLoadingState({ isLoading: true, message: "Loading Template" });
      try {
        const formData = new FormData();
        formData.append("template_id", templateID);
        const response = await fetchTemplateFormData(formData); // API call with templateID

        if (response.length === 0) {
          throw new Error("No template data found.");
        }

        setTemplateData(response[0]);

        setFormFields(response[0]["form_fields"]); // No need to stringify

        const surveyModel = setupSurveyModel(response[0]["form_fields"]);

        if (!projectID) {
          setSamplePage(true);
          surveyModel.mode = "display";
          surveyModel.data = response[0]["sample_data"];
        }
        setSurveyJson(surveyModel);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingState({ isLoading: false, message: "" });
      }
    };

    const fetchTemplateReports = async () => {
      setLoadingState({ isLoading: true, message: "Loading Template Reports" });

      try {
        const formData = new FormData();
        formData.append("project_id", projectID);
        formData.append("template_id", templateID);
        const response = await fetchReports(formData); // API call with templateID

        setReports(response);

        if (response.length > 0) {
          const lastReport = response[response.length - 1];
          setLastReport(lastReport);

          // Ensure lastReport contains data before updating the survey
          // if (lastReport?.original_data) {
          //   setSurveyJson((prevSurveyModel) => {

          //     const updatedSurveyModel = new Model(prevSurveyModel.toJSON());


          //     updatedSurveyModel.data = lastReport.original_data;
          //     // Add Reset Button
          //     updatedSurveyModel.addNavigationItem({
          //       id: "sv-nav-clear-form",
          //       title: "Reset Form",
          //       action: () => {
          //         updatedSurveyModel.clear(true, true); // Clears all form data and resets to the first page
          //       },
          //     });
          //     return updatedSurveyModel;
          //   });
          // } else {

          // }
        } else {
          console.warn("No reports found.");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingState({ isLoading: false, message: "" });
      }
    };

    if (templateID) {
      fetchTemplateData();
      fetchTemplateReports();
    }
  }, [templateID]); // Runs when templateID changes

  useEffect(() => {
    if (formFields && lastReport) {
      const surveyModel = setupSurveyModel(formFields);
      if (lastReport || lastReport.original_data) {
        surveyModel.data = lastReport.original_data;
      }
      setSurveyJson(surveyModel);
    }
  }, [formFields, lastReport]);

  // Function to handle the survey completion
  const handleSurveyCompletion = async (survey, options) => {
    setLoadingState({ isLoading: true, message: "Submitting Report" });
    // const formData = survey.data;  // This will give you the form data as an object
    try {
      const formData = new FormData();
      formData.append("project_id", projectID);
      formData.append("template_id", templateID);
      formData.append("template_data", JSON.stringify(survey.data));

      

      const response = await submitTemplateData(formData); // API call to submit the form data
      window.location.reload();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingState({ isLoading: false, message: "" });
    }
    // You can send this formData to your backend or handle it as needed
  };

  if (loadingState.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loadingState.message}
        </Typography>
      </Box>
    );
  }

  const handleEdit = (item) => {
    const surveyModel = setupSurveyModel(formFields);
    surveyModel.data = item["original_data"];
    setSurveyJson(surveyModel);
  };

  // const handleView = (id) => { };
  const handleView = async (file) => {
    try {
      window.open(file.url, "_blank");
      // setPdfFile(file);
      // setPdfModalOpen(true); // Open the modal for the PDF preview
    } catch (error) {
      window.open(file.url, "_blank");
    }
  };

  const handleDelete = async (item) => {
    setLoadingState({ isLoading: true, message: "Deleting Report" });
    try {
      const formData = new FormData();
      formData.append("project_id", projectID);
      formData.append("template_id", templateID);
      formData.append("report_id", item._id);
      const data = await deleteReportAPI(formData);
      setLoadingState({ isLoading: false, message: "" });
      window.location.reload();
    } catch (err) {
      setError(err.message);
    } finally {
    }
  };

  // const handleDelete = (item) => {

  // };

  const handlePdfClose = () => {
    setPdfModalOpen(false);
    setPdfFile(null);
  };
  const data = [
    {
      name: "Template 1",
      description: "Description of Template 1",
      version: "1.0",
    },
    {
      name: "Template 2",
      description: "Description of Template 2",
      version: "1.0",
    },
  ];

  const handleImport = async () => {
    // Implement the import logic here
    try {
      const formData = new FormData();
      formData.append("template_ids", templateID);
      formData.append("project_id", String(selectedProject));
      const response = await importTemplatesToProjectAPI(formData);
      window.location.href = `/admin/model_home_page/${selectedProject}`;
    } catch (err) {
      console.log(err);
    }
  };

  const handleProjectSelectionModalClose = () => {
    setSelectedProject(null);
    setOpenProjectsModal(false);
  };

  return (
    <>
      <Divider style={{ marginBottom: "20px" }} />

      <Grid container justifyContent="space-between" alignItems="flex-start">
        {/* Title on the left */}
        <Grid item xs={12}>
          <div
            display="flex"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "inline" }}>
              <Typography variant="h5">
                {templateData.name}
              </Typography>
            </div>
            <Button
              style={{ display: "inline" }}
              onClick={() => setOpenProjectsModal(true)}
              color="quCreateColor"
            >
              Add to Project
            </Button>
          </div>
          <Typography variant="body1">{templateData.note}</Typography>
          <Typography variant="body1">{templateData.summary}</Typography>
        </Grid>
      </Grid>

      <Divider style={{
        marginBottom: "20px", marginTop: "10px"
      }} />

      < Survey model={surveyJSON} />

      {!samplePage && (
        <TemplateTable
          data={reports}
          handleEdit={handleEdit}
          handleView={handleView}
          handleDelete={handleDelete}
        // Add any other behavior for the non-samplePage case here
        />
      )}

      {/* {samplePage ? (
        <TemplateTable
          data={data}
          handleEdit={handleEdit}
          handleView={handleView}
          handleDelete={handleDelete}
        // Add any samplePage-specific props or logic here
        />
      ) : (
        <TemplateTable
          data={reports}
          handleEdit={handleEdit}
          handleView={handleView}
          handleDelete={handleDelete}
        // Add any other behavior for the non-samplePage case here
        />
      )} */}

      <ImportProjectsModal
        open={openProjectsModal}
        onClose={handleProjectSelectionModalClose}
        projects={allProjects}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        handleProjectSelection={handleImport}
      />
      {/* PDF Preview Modal */}
      <Dialog
        open={pdfModalOpen}
        onClose={handlePdfClose}
        maxWidth="md"
        fullWidth
      >
        {pdfFile && (
          <PDFViewer
            pdfUrl={pdfFile.url}
            scrollView={false}
            showButtons={true}
          />
        )}
      </Dialog>
    </>
  );
}
