import { quCreateColor } from "assets/jss/material-dashboard-pro-react";

export const dropzoneStyles = ({
    dropzone: {
        minHeight: "100px",
        backgroundColor: "#f0f0f0",
        borderColor: quCreateColor,
        border: "2px dashed",
        borderRadius: "5px",
        padding: "20px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
})