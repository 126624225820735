class BaseDetails {
    constructor(name = "", description = "", template = "", uploadedFiles = []) {
        this.name = name;
        this.description = description;
        this.template = template;
        this.uploadedFiles = uploadedFiles;
    }
}

export default BaseDetails;
