// React and Hooks
import React, { useState, useEffect } from "react";

// Material-UI Components
import { Divider, Grid, Typography, Tabs, Tab } from "@material-ui/core";
import { Create, Share, Publish, List, School } from "@material-ui/icons";

// React Router
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

// Custom Components and Styles
import CreationOptionCard from "../Components/CreationOptionCard";
import { quSandBoxColor } from "assets/jss/material-dashboard-pro-react";
import SearchBar from "material-ui-search-bar";
import { cardData, groups } from "./Constants";
import TabsComponent from "./TabsComponent";
// LandingPage Component
const LandingPage = () => {
  // State to track active tab
  const [activeTab, setActiveTab] = useState(0);
  const [displayCardData, setDisplayCardData] = useState(cardData);
  const [searchValue, setSearchValue] = useState("");

  // Effect to check if user is authenticated
  useEffect(() => {
    Auth.currentAuthenticatedUser().catch((err) => {
      console.log(err);
      window.location.href = "/auth/login-page";
    });
  }, []);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const searchInputValue = (e) => {
    setSearchValue(e);
    if (e == "") {
      setDisplayCardData(cardData);
      return;
    }
    var newList = cardData.filter(
      (card) =>
        card.title.toLowerCase().includes(e.toLowerCase()) ||
        card.description.toLowerCase().includes(e.toLowerCase())
    );
    setDisplayCardData(newList);
  };

  return (
    <div>
      <Divider style={{ marginBottom: "20px" }} />
      {/* Title */}
      <Typography variant="h5">Welcome to QuCreate!</Typography>
      <Typography
        variant="body1"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        Start creating and sharing your ideas with the world.
      </Typography>

      <SearchBar
        style={{ marginBottom: "20px" }}
        value={searchValue}
        onChange={(newValue) => searchInputValue(newValue)}
        onRequestSearch={(newValue) => searchInputValue(newValue)}
      />

      {/* Tabs for Group Selection */}
      <TabsComponent activeTab={activeTab} handleTabChange={handleTabChange} />

      <Divider style={{ margin: "20px 0" }} />

      {/* Cards for the Active Tab */}
      {activeTab === 4 ? (
        // "All" Tab: Original grouped view
        groups.slice(0, 4).map((group) => (
          <div key={group}>
            <Typography
              variant="h5"
              style={{ color: quSandBoxColor, marginBottom: "10px" }}
            >
              {group}
            </Typography>
            <Divider />
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              {displayCardData
                .filter((card) => card.group === group)
                .map((card) => (
                  <Grid key={card.identifier} item xs={12} sm={6} md={4} lg={4}>
                    {!card.inProgress ? (
                      <Link
                        to={{
                          pathname: card.route,
                          state: { identifier: card.identifier },
                        }}
                      >
                        <CreationOptionCard
                          logo={card.logo}
                          title={card.title}
                          description={card.description}
                          identifier={card.identifier}
                          inProgress={card.inProgress}
                        />
                      </Link>
                    ) : (
                      <CreationOptionCard
                        logo={card.logo}
                        title={card.title}
                        description={card.description}
                        identifier={card.identifier}
                        inProgress={card.inProgress}
                      />
                    )}
                  </Grid>
                ))}
            </Grid>
          </div>
        ))
      ) : (
        // Filtered view for individual tabs (Create, Publish, Share)
        <Grid container spacing={2}>
          {displayCardData
            .filter((card) => card.group === groups[activeTab])
            .map((card) => (
              <Grid key={card.identifier} item xs={12} sm={6} md={4} lg={4}>
                {!card.inProgress ? (
                  <Link
                    to={{
                      pathname: card.route,
                      state: { identifier: card.identifier },
                    }}
                  >
                    <CreationOptionCard
                      logo={card.logo}
                      title={card.title}
                      description={card.description}
                      identifier={card.identifier}
                      inProgress={card.inProgress}
                    />
                  </Link>
                ) : (
                  <CreationOptionCard
                    logo={card.logo}
                    title={card.title}
                    description={card.description}
                    identifier={card.identifier}
                    inProgress={card.inProgress}
                  />
                )}
              </Grid>
            ))}
        </Grid>
      )}
    </div>
  );
};

export default LandingPage;
