import React, { useState } from "react";
import { Link, Switch, Route } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types"; // Import PropTypes for prop validation
import { Tooltip, Typography, Divider, Box, IconButton } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js"; // Import Pagination component



import SortIcon from '@material-ui/icons/Sort';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SearchBar from "material-ui-search-bar";

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import TagDialog from "views/Components/TagDialog";
import AssetCard from "views/Components/AssetCard";

const usesweetAlertStyle = makeStyles(sweetAlertStyle);

const AssetDashboard = ({
  path,
  welcomeTitle,
  welcomeSubtitle,
  allAssets,
  setAllAssets,
  initialAssets,
  noAssetMessage,
  linkPath,
  state,
  getStatusIcon,
  CreateAssetCard,
  setCreateAssetModal,
  createAssetButtonText,
  handleSaveLabel,
  asset_identifier,
  handleDeleteAsset,
  handleCloneAsset,
  assetAdded,
  setAssetAdded
}) => {
  // Apply styles
  const sweetAlertClasses = usesweetAlertStyle();

  const [alert, setAlert] = React.useState(null);


  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const assetsPerPage = 5; // Show 6 assets per page

  const [ascendingOrder, setAscendingOrder] = useState(false);
  const [tagDialog, setTagDialog] = useState(false);
  const [tagSelectedAsset, setTagSelectedAsset] = useState(null);
  const [alphabeticallyInc, setAlphabeticallyInc] = useState(false);

  const sortCards = () => {
    const sortedAssets = [...allAssets].sort((a, b) => {
      const dateA = new Date(parseInt(a._id.toString().slice(0, 8), 16) * 1000);
      const dateB = new Date(parseInt(b._id.toString().slice(0, 8), 16) * 1000);
      return ascendingOrder ? dateA - dateB : dateB - dateA;
    });

    setAllAssets(sortedAssets);
    setAscendingOrder(!ascendingOrder);
  };

  const sortCardsAlphabetically = () => {
    const sortedAssets = [...allAssets].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      return alphabeticallyInc ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
    });

    setAllAssets(sortedAssets);
    setAlphabeticallyInc(!alphabeticallyInc);
  };


  // Handle search input value change
  const searchInputValue = (e) => {
    setSearchValue(e);
    if (e.length === 0) {
      setAllAssets(initialAssets);
      return;
    }
    const newList = initialAssets.filter((asset) => {
      const nameMatch = asset.name && asset.name.toLowerCase().includes(e.toLowerCase());
      const descriptionMatch = asset.description && asset.description.toLowerCase().includes(e.toLowerCase());
      const tagMatch =
        asset.tags &&
        asset.tags.some((tag) => tag.toLowerCase().includes(e.toLowerCase()));

      return nameMatch || descriptionMatch || tagMatch;
    });
    setAllAssets(newList);
    setCurrentPage(1); // Reset to first page after search
  };

  // Pagination Logic
  const indexOfLastAsset = currentPage * assetsPerPage;
  const indexOfFirstAsset = indexOfLastAsset - assetsPerPage;
  const currentAssets = allAssets.slice(indexOfFirstAsset, indexOfLastAsset);

  const totalPages = Math.ceil(allAssets.length / assetsPerPage);

  // Generate Pagination Buttons
  const pages = [];
  if (totalPages > 1) {
    pages.push({
      text: "PREV",
      disabled: currentPage === 1,
      onClick: () => setCurrentPage((prev) => Math.max(prev - 1, 1)),
    });

    for (let i = 1; i <= totalPages; i++) {
      pages.push({
        text: i,
        active: i === currentPage,
        onClick: () => setCurrentPage(i),
      });
    }

    pages.push({
      text: "NEXT",
      disabled: currentPage === totalPages,
      onClick: () => setCurrentPage((prev) => Math.min(prev + 1, totalPages)),
    });
  }

  if (assetAdded) {
    const totalPagesAfterAddition = Math.ceil((allAssets.length) / assetsPerPage);

    // Ensure current page doesn't go beyond the last page after deletion
    if (currentPage < totalPagesAfterAddition) {
      setCurrentPage(Math.max(1, totalPagesAfterAddition)); // Go to last available page or page 1
    }
    setAssetAdded(false);
  }

  const handleTags = (asset) => {
    setTagSelectedAsset(asset);
    setTagDialog(true);
  }

  const handleCloseTagDialog = () => {
    setTagDialog(false);
    setTagSelectedAsset(null);
  }

  const handleSaveLabelHelper = async () => {
    const updatedAsset = allAssets.find(asset => asset._id === tagSelectedAsset._id);
    if (updatedAsset) {
      setTagSelectedAsset(updatedAsset);
    }
    await handleSaveLabel(tagSelectedAsset);
  };

  const _handleDeleteAsset = async (asset) => {
    await handleDeleteAsset(asset);
    // Calculate the total pages after deletion
    const totalPagesAfterDeletion = Math.ceil((allAssets.length - 1) / assetsPerPage);

    // Ensure current page doesn't go beyond the last page after deletion
    if (currentPage > totalPagesAfterDeletion) {
      setCurrentPage(Math.max(1, totalPagesAfterDeletion)); // Go to last available page or page 1
    }

    hideAlert();
  };

  const _handleCloneAsset = async (asset) => {
    await handleCloneAsset(asset);
    // Calculate the total pages after deletion
    hideAlert();
  };


  // Show delete confirmation alert
  const deleteAlert = (asset) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => _handleDeleteAsset(asset)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        This action will permanently delete the {asset_identifier} and will not be
        recoverable.
      </SweetAlert>
    );
  };

  // Show clone confirmation alert
  const cloneAlert = (asset) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => _handleCloneAsset(asset)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, clone it!"
        cancelBtnText="Cancel"
        showCancel
      >
        This action will create a clone of the current {asset_identifier}.
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <>
      {alert}
      <Divider style={{ marginBottom: "20px" }} />
      <Typography variant="h5">{welcomeTitle}</Typography>
      <Typography variant="body1" style={{ marginTop: "10px", marginBottom: "20px" }}>
        {welcomeSubtitle}
      </Typography>

      <Switch>
        <Route exact path={path}>
          <GridContainer>
            <GridItem xs={12} sm={8} md={8} lg={10}>
              <SearchBar value={searchValue} onChange={searchInputValue} onRequestSearch={searchInputValue} placeholder={`Search by ${asset_identifier} name or tags`} />
            </GridItem>
            <GridItem xs={12} sm={4} md={4} lg={2} style={{ textAlign: "center" }}>
              <Button color="quCreateColor" fullWidth onClick={() => setCreateAssetModal(true)}>
                {createAssetButtonText}
              </Button>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem item xs={12} style={{ marginTop: "20px", marginBottom: "20px", display: "flex", gap: "20px" }}>
              <Tooltip title="Sort by creation date" placement="top">
                <IconButton color="#20677b" style={{ backgroundColor: ascendingOrder ? quCreateColor : "#f5f5f5" }}>
                  <SortIcon onClick={sortCards} style={{ cursor: "pointer" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Sort alphabetically" placement="top">
                <IconButton style={{ backgroundColor: alphabeticallyInc ? quCreateColor : "#f5f5f5" }}>
                  <SortByAlphaIcon onClick={sortCardsAlphabetically} style={{ cursor: "pointer" }} />
                </IconButton>
              </Tooltip>
            </GridItem>
            {currentAssets.length === 0 && (
              <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "20px" }}>
                <Box display="flex" justifyContent="center">
                  <Typography variant="h5" textAlign="center">
                    {noAssetMessage}
                  </Typography>
                </Box>
              </GridItem>
            )}

            {currentAssets.map((asset, index) => (
              <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                <Link
                  to={{
                    pathname: path + linkPath + asset._id,
                    state: state || undefined,
                  }}
                >
                  <AssetCard
                    asset={asset}
                    deleteAlert={deleteAlert}
                    cloneAlert={cloneAlert}
                    handleTags={handleTags}
                    getStatusIcon={getStatusIcon}
                  />
                </Link>
              </GridItem>
            ))}

            <GridItem xs={12} sm={6} md={6} lg={4}>
              <CreateAssetCard onClick={() => setCreateAssetModal(true)} />
            </GridItem>
          </GridContainer>

          {/* Pagination Component */}
          {totalPages > 1 && (
            <GridContainer style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Pagination pages={pages} color="quCreateColor" />
            </GridContainer>
          )}

        </Route>
      </Switch >

      <TagDialog
        tagDialog={tagDialog}
        handleCloseTagDialog={handleCloseTagDialog}
        tagSelectedAsset={tagSelectedAsset}
        setTagSelectedAsset={setTagSelectedAsset}
        noAssetMessage={"No tags assigned. Add new tags to organize your assets."}
        handleSaveLabel={handleSaveLabelHelper}

      />
    </>
  );
};

// Define prop types and default values
AssetDashboard.propTypes = {
  path: PropTypes.string.isRequired,
  welcomeTitle: PropTypes.string.isRequired,
  welcomeSubtitle: PropTypes.string.isRequired,
  allAssets: PropTypes.array.isRequired,
  setAllAssets: PropTypes.func.isRequired,
  initialAssets: PropTypes.array.isRequired,
  noAssetMessage: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,
  state: PropTypes.object,
  getStatusIcon: PropTypes.func.isRequired,
  CreateAssetCard: PropTypes.func.isRequired,
  setCreateAssetModal: PropTypes.func.isRequired,
  createAssetButtonText: PropTypes.string.isRequired,
};

export default AssetDashboard;
