import React, { useEffect, useState } from "react";
import {
    CircularProgress,
    Typography,
    Box,
    Divider,
    Grid,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// Contexts
import NotificationContext from "views/Components/Context.js";

// Custom Components
import {
    fetchLabAPI,
    updateLabDetails,
    addResourceToLabAPI,
} from "views/APIs/APIUtility";
import HeadingWithShortDescription from "components/Heading/HeadingWithShortDescription";
import WritingTable from "../Writing/WritingTable";
import FileUploadDialog from "../FileUploadDialog";
import { _mapResourceToAppType } from "../CommonFunctions";
import { deleteLabResourceAPI, updateLabStatus } from "views/APIs/APIUtility";
import Button from "components/CustomButtons/Button";
import Alert from "views/Components/Alert";

export default function UploadSourceFile({ labID, setLabName, setActiveStep }) {
    const { showNotification } = React.useContext(NotificationContext);

    const [resourceAddModal, setResourceAddModal] = useState(false);
    const [lab, setLab] = useState(null);
    const [files, setFiles] = useState([]);
    const [newFiles, setNewFiles] = useState([]);
    const [loading, setLoading] = useState({ isLoading: false, message: "" });
    const [newResource, setNewResource] = useState({
        name: "",
        description: "",
        file: null,
    });
    const [showAlert, setShowAlert] = useState(false);

    // Just required but not getting used
    const [selectedResources, setSelectedResources] = useState([]);
    const [replace, setReplace] = useState(false);

    useEffect(() => {
        async function fetchLabDetails() {
            try {
                setLoading({ isLoading: true, message: "Fetching lab details..." });
                const data = await fetchLabAPI(labID);
                console.log("Lab data is: ", data);
                setLab(data);
                handleSetFileResources(data.raw_resources);
                setLoading({ isLoading: false, message: "" }); // Ensure loading state is reset
            } catch (error) {
                console.error("Error in fetchLabDetails:", error);
                showNotification("Error fetching lab details", "danger");
            } finally {
            }
        }

        fetchLabDetails();
    }, [labID]);

    const handleSetFileResources = (resources) => {
        const mappedResources = resources
            .filter((resource) => resource.resource_type === "File")
            .map(_mapResourceToAppType); // Map once
        setFiles(mappedResources);
    };

    const handleUpdateLabDetails = async (updatedData) => {
        try {
            setLoading({ isLoading: true, message: "Updating lab details..." });
            const formData = new FormData();
            formData.append("lab_id", lab._id);
            formData.append("lab_name", updatedData.title);
            formData.append("lab_description", updatedData.shortDescription);
            await updateLabDetails(formData);
            setLabName(updatedData.title);
            setLab({
                ...lab,
                lab_name: updatedData.title,
                lab_description: updatedData.shortDescription,
            });
            setLoading({ isLoading: false, message: "" });
            showNotification("Lab details updated successfully", "success");
        } catch (error) {
            console.error("Error in handleUpdateLabDetails:", error);
            showNotification("Error updating lab details", "danger");
        } finally {
        }
    };

    const handleDeleteResource = async (resource, index) => {
        try {
            const formData = new FormData();
            formData.append("lab_id", labID);
            formData.append("resource_id", resource.id);
            await deleteLabResourceAPI(formData);
            const updatedResources = files.filter((item, idx) => idx !== index);
            setFiles(updatedResources);
            showNotification("Resource deleted successfully", "success");
        } catch (error) {
            showNotification(error, "danger");
        } finally {
        }
    };

    const handleReplace = async (resourceID, file, file_type) => { };

    const handleResourceAdditionModal = () => {
        setResourceAddModal(true);
    };

    const handleFileUploadDialogClose = () => {
        setResourceAddModal(false);
        setNewResource({ name: "", description: "", file: null });
    };
    const handleReplaceResource = async () => { };

    const handleAddResource = async () => {
        if (!newResource.name || !newResource.description || !newResource.file) {
            showNotification("Please fill all the fields", "danger");
            return;
        }
        try {
            setResourceAddModal(false);
            setLoading({ isLoading: true, message: "Adding File..." });

            if (!newResource) {
                throw new Error("No new resource provided.");
            }

            // Initialize FormData for the API request
            const formData = new FormData();

            // Add required fields to FormData
            formData.append("lab_id", labID);
            formData.append("resource_type", "File");
            formData.append("resource_name", newResource.name);
            formData.append("resource_description", newResource.description);
            // Append the new file if it exists
            if (newResource.file) {
                formData.append("resource_file", newResource.file);
            }

            const response = await addResourceToLabAPI(formData);
            console.log("Response is: ", response);

            handleSetFileResources(response.raw_resources);
            showNotification("File Added successfully", "success");
            setNewResource({ name: "", description: "", file: null });
        } catch (error) {
            console.error("Error in replacing resource:", error.message || error);
            showNotification(
                `Failed to replace resource: ${error.message || "Unknown error"}`,
                "danger"
            );
        } finally {
            handleFileUploadDialogClose();
            setLoading({ isLoading: false, message: "" });
        }
    };

    const handleUploadSourceFile = async () => {
        if (files.length === 0) {
            showNotification("Please upload at least one file to get started", "danger");
            return;
        }
        if (lab.status != "In Design Phase") {
            setShowAlert(true);
        }
        else {
            await submitHandler();
        }

    };

    const submitHandler = async () => {
        try {
            setLoading({ isLoading: true, message: "Generating Lab Design..." });
            const formData = new FormData();
            formData.append("lab_id", labID);
            formData.append("lab_design_status", "Lab Design");
            await updateLabStatus(formData);
            window.location.reload();
        }
        catch (error) {
            console.error("Error in handleUploadSourceFile:", error);
            showNotification("Error generating design", "danger");
        }
        finally {
            setLoading({ isLoading: false, message: "" });
        }
    };

    const handleConfirm = () => {
        setShowAlert(false); // Close the alert
        submitHandler();
    };

    const handleCancel = () => {
        setShowAlert(false); // Close the alert
    };

    return (
        <>
            {showAlert && (
                <Alert
                    title="Are you sure?"
                    warning={true}
                    showCancel={true}
                    cancelBtnText="No"
                    confirmBtnText="Yes"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                >
                    Proceeding to the next step will reset your current progress in further steps. Your existing work will be overwritten, and new content will be created. Are you sure you want to continue?
                </Alert>
            )}
            {/* Pass props to FileUploadDialog */}
            <FileUploadDialog
                open={resourceAddModal}
                onClose={handleFileUploadDialogClose}
                newFile={newResource}
                setNewFile={setNewResource}
                onSave={replace ? handleReplaceResource : handleAddResource}
                replace={replace}
                file_type="file"
            />

            {loading.isLoading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="60vh"
                >
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginLeft: 16 }}>
                        {loading.message}
                    </Typography>
                </Box>
            ) : (
                lab && (
                    <>
                        <HeadingWithShortDescription
                            title={lab.lab_name}
                            shortDescription={lab.lab_description}
                            dialogTitle="Edit Lab Details"
                            onChange={handleUpdateLabDetails}
                        />
                        <Divider style={{
                          marginTop: "-20px"
                        }}/>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5" style={{ marginTop: "10px" }}>
                                    Upload resources
                                </Typography>
                                <Typography variant="body1">
                                    Upload source files for the lab.
                                </Typography>
                                <Divider style={{ marginBottom: "20px", marginTop: "10px" }} />
                            </Grid>
                            <WritingTable
                                files={files}
                                handleDeleteResource={handleDeleteResource}
                                handleReplace={handleReplace}
                                handleResourceAddition={handleResourceAdditionModal}
                                setSelectedResources={setNewFiles}
                                selectedResources={selectedResources}
                                selectColumnRequired={false}
                            />
                        </Grid>
                        <Grid container style={{ marginTop: "10px" }}>
                            <Grid item xs={12}>
                                <Button
                                    color="quCreateColor"
                                    style={{ float: "right", fontWeight: "bold" }}

                                    onClick={handleUploadSourceFile}
                                    endIcon={<NavigateNextIcon />}
                                >
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )
            )}
        </>
    );
}
