import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, Icon, Typography, Box, CircularProgress } from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NotificationContext from "views/Components/Context.js";
import Button from "components/CustomButtons/Button.js";
import { CloudDownload, RotateLeft } from "@material-ui/icons";
import SaveIcon from '@material-ui/icons/Save';
import PDFViewer from "views/Components/PDFViewer";
import { makeStyles } from "@material-ui/core/styles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { saveLatestVersionAPI, fetchPodcastAPI, convertToPDFAPI, convertToAudioAPI, updatePodcastDetails } from "views/APIs/APIUtility";
import { format, formatISO } from "date-fns";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HeadingWithShortDescription from "components/Heading/HeadingWithShortDescription";

const identifiers = {
    research_report: "Research Report",
    white_paper: "White Paper",
    project_plan: "Project Plan",
    blog: "Blog",
    news_letter: "Newsletter",
    case_study: "Case Study",
    key_insights: "Key Insights",
    handout: "Handout",
};
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

export default function PodcastBoard(props) {
    const sweetAlertClasses = usesweetAlertStyle();
    const [alert, setAlert] = React.useState(null);
    const location = useLocation();
    let identifier = "research_report";
    if (location.state && location.state.identifier) {
        identifier = location.state.identifier;
    }
    const identifierPrint = identifiers[identifier];
    const { podcastID } = props.match.params;
    const [podcast, setPodcast] = useState({});
    const [loading, setLoading] = useState(true);
    const { showNotification, setNotification } = React.useContext(
        NotificationContext
    );
    const [outline, setTranscript] = useState("");
    const [pdfUrl, setPdfUrl] = useState("");
    const [template, setTemplate] = useState("academic");
    const [version, setVersion] = useState(1);
    const [versionOptions, setVersionOptions] = useState([{
        "podcast_outline": "",
        "version": 1,
        "timestamp": formatISO(new Date()),
    }]);

    useEffect(() => {
        const fetchPodCastData = async () => {
            try {
                const data = await fetchPodcastAPI(podcastID);
                // Check if history is available
                if (!data.history || data.history.length === 0) {
                } else {
                    const latestVersion = data.history[data.history.length - 1];  // Get the last version
                    setVersion(latestVersion.version);  // Set the latest version
                    setVersionOptions(data.history);  // Set all versions
                }
                setPodcast(data);
                setTranscript(data.podcast_transcript);
                // await getPdf(data.podcast_transcript);
            } catch (error) {
                showNotification(error, "danger");
            } finally {
                setLoading(false);
            }
        };

        fetchPodCastData();
    }, [podcastID]);

    const handleSaveChanges = async () => {
        try {
            const formData = new FormData();
            formData.append("writing_id", podcastID);
            formData.append("writing_outline", outline);
            const data = await saveLatestVersionAPI(formData);
            hideAlert();
            showNotification("Changes saved successfully", "success");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        catch (error) {
            showNotification(error, "danger");
        }
        finally {
        }
    }

    const saveChangesAlert = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleSaveChanges()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={
                    sweetAlertClasses.button + " " + sweetAlertClasses.success
                }
                cancelBtnCssClass={
                    sweetAlertClasses.button + " " + sweetAlertClasses.danger
                }
                confirmBtnText="Yes, Save Changes!"
                cancelBtnText="Cancel"
                showCancel
            >
                The changes will be saved as the latest version. Are you sure you want to save these changes?
            </SweetAlert>
        );
    };

    const hideAlert = () => {
        setAlert(null);
    };

    const generateAudio = async (outline) => {
        showNotification("Generating Audio...", "quSandBoxColor");
        const formData = new FormData();
        formData.append("outline_text", outline);

        try {
            const data = await convertToAudioAPI(formData);
            // const audioUrl = data.audio_url;
            // const audioElement = document.getElementById("audio");
            // audioElement.src = audioUrl;
            showNotification("Audio generated successfully", "success");
        } catch (error) {
            showNotification(error, "danger");
        }
    };





    const getPdf = async (markdown = outline, download = false) => {

        if (download) {
            showNotification("Generating PDF...", "quSandBoxColor");
        }
        const formData = new FormData();
        // append writing_id, markdown, template
        formData.append("writing_id", podcastID);
        formData.append("markdown", markdown);
        formData.append("template_name", template);

        try {
            const data = await convertToPDFAPI(formData);
            const url = data;
            // change the name of the file to writing_name
            setPdfUrl(url);

            if (download) {
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", podcast.podcast_name + ".pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
                showNotification("PDF generated successfully", "success");
            }
        }
        catch (error) {
            showNotification(error, "danger");
        }
        finally {
        }
    };

    const templateOptions = [
        "academic",
        "business",
        "technical",
        "whitepaper",
        "presentation",
    ]

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
            >
                <CircularProgress color="quCreateColor" />
                <Typography variant="h6" style={{ marginLeft: 16 }}>
                    Loading Podcast...
                </Typography>
            </Box>
        );
    }

    const downloadAudio = () => {
        // Ensure that podcast.podcast_audio is available
        if (!podcast.podcast_audio) {
            showNotification("Audio source is not available.", "danger");
            return;
        }

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = podcast.podcast_audio;  // The audio source URL
        link.setAttribute("download", `${podcast.podcast_name}.mp3`); // Set the file name with .mp3 extension
        document.body.appendChild(link);  // Append to body to trigger the download
        link.click();  // Simulate click to start the download
        link.remove();  // Remove the link after the download starts
        showNotification("Audio download started.", "success");
    };




    const handleChangeVersion = (event) => {
        setLoading(true);
        const selectedVersion = event.target.value;
        setVersion(selectedVersion);

        // Optionally find and use the selected version's details
        const selectedOption = versionOptions.find(option => option.version === selectedVersion);
        if (selectedOption) {
            // Example: Update outline or fetch corresponding data
            setTranscript(selectedOption.podcast_transcript);
            // getPdf(selectedOption.podcast_transcript);
        }
        setLoading(false);
    };

    const handlePodcastBack = () => {
        window.history.back();
    }

    const handlePodcastDetailsChange = async (newPodcastDetails) => {
        try {
            const formData = new FormData();
            formData.append("podcast_id", podcastID);
            formData.append("podcast_name", newPodcastDetails.title);
            formData.append("podcast_description", newPodcastDetails.shortDescription);
            await updatePodcastDetails(formData);
            // Updating the state correctly
            setPodcast((prevModule) => ({
                ...prevModule,
                podcast_name: newPodcastDetails.title,
                podcast_description: newPodcastDetails.shortDescription,
            }));
            showNotification("Podcast details updated successfully", "success");
        } catch (error) {
            showNotification(
                "An error occurred while updating podcast details.",
                "danger"
            );
        } finally {
        }
    }


    return (
        <div>
            {alert}
            <Divider style={{ marginBottom: "20px" }} />
            <HeadingWithShortDescription
                title={podcast.podcast_name}
                shortDescription={podcast.podcast_description}
                dialogTitle="Edit Podcast Details"
                textAlign="left"
                onChange={handlePodcastDetailsChange}
            />
            <Divider style={{ marginBottom: "20px" }} />
            {/* <div
                style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    float: "right",
                }}
            > */}
            <div style={{
                alignItems: "center",
                gap: "20px",
                marginTop: "10px",
                marginBottom: "10px",
                display: "flex",
            }}>
                <div style={{ float: "left", width: "100%" }}>
                    <audio id="audio" controls type="audio/mpeg" style={{ display: "block", width: "100%" }}>
                        {/* <source src="https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/female-UK.wav" /> */}
                        <source src={podcast.podcast_audio} />
                        {/* <source src={voice.sampleUrl} /> */}
                        Your browser does not support the audio element.
                    </audio>
                </div>

                <div style={{
                    float: "right",
                    alignItems: "center",
                    gap: "20px",
                    display: "flex",
                }}>

                    {/* <Typography variant="body1" style={{ float: "left", maxWidth: "30%" }}>{podcast.podcast_description}</Typography> */}
                    {/* Dropdown to select the version type */}
                    {/* <FormControl style={{ minWidth: "180px", float: "right" }} variant="filled">
                        <InputLabel>Select Version</InputLabel>
                        <Select
                            value={version}
                            renderValue={(selected) => {
                                const selectedOption = versionOptions.find(option => option.version === selected);
                                return selectedOption ? `Version ${selectedOption.version}` : "";
                            }}
                            onChange={handleChangeVersion}
                            fullWidth
                            disableUnderline
                        >
                            {versionOptions.map((option, index) => (
                                <MenuItem key={index} value={option.version}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"> {"Version: " + option.version}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" style={{ fontStyle: "italic" }}>
                                                {"Last Updated: " + format(new Date(option.timestamp), "dd MMM yyyy, hh:mm a")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                    <Button
                        onClick={() => downloadAudio()}
                        color="quSandBoxColor"
                        style={{
                            float: "right",
                        }}
                    >
                        <CloudDownload /> &nbsp;Download
                    </Button>

                    {/* <Button
                        onClick={() => { saveChangesAlert() }}
                        color="quCreateColor"
                        style={{
                            float: "right",
                        }}
                    >
                        <SaveIcon /> &nbsp;Save Changes
                    </Button> */}

                    {/* Dropdown to select the template type */}
                    {/* <FormControl style={{ minWidth: "180px" }} variant="filled">
                        <InputLabel>Select Template</InputLabel>
                        <Select
                            value={template}
                            onChange={(e) => { setTemplate(e.target.value); getPdf(outline, false); }}
                            fullWidth
                            disableUnderline
                        >
                            {templateOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option.charAt(0).toUpperCase() + option.slice(1)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                    {/* </div> */}
                </div>

            </div>


            <Grid container spacing={2} style={{
                position: 'relative', marginTop: "10px",
                marginBottom: "10px",
                fullWidth: "100%",
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div
                        data-color-mode="light"
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        <MDEditor
                            preview="live"
                            value={outline}
                            onChange={(value) => setTranscript(value)}
                            height={600}
                        />
                    </div>
                </Grid>

                {/* Floating Button */}
                {/* <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1000,
                    }}
                >
                    <Button
                        onClick={() => getPdf(outline, false)} // Replace with your update function
                        color="quSandBoxColor"
                        variant="contained"
                        style={{
                            borderRadius: "50%",
                            padding: "12px"
                        }}
                    >
                        <RotateLeft />
                    </Button>
                </div>

                <Grid item xs={12} sm={12} md={12} lg={6}>
                    {pdfUrl && <PDFViewer pdfUrl={pdfUrl} scrollView={true} height={580} width={""} />}
                </Grid> */}
            </Grid>

            <div
                style={{
                    marginTop: "10px",
                }}
            >

                <Button
                    color="quCreateColor"
                    onClick={handlePodcastBack}
                    style={{
                        float: "left",
                        fontWeight: "bold",
                    }}
                    startIcon={<ArrowBackIcon />}
                >
                    Back
                </Button>

                {/* <Button
                    onClick={() => generateAudio(outline)}
                    color="quCreateColor"
                    style={{
                        float: "right",
                    }}
                >
                    <Icon>autorenew</Icon> &nbsp;Generate Audio
                </Button> */}


                {/* <Button
                    onClick={() => getPdf(outline, true)}
                    color="quSandBoxColor"
                    style={{
                        float: "right",
                    }}
                >
                    <CloudDownload /> &nbsp;Download
                </Button>

                <Button
                    onClick={() => { saveChangesAlert() }}
                    color="quCreateColor"
                    style={{
                        float: "right",
                    }}
                >
                    <SaveIcon /> &nbsp;Save Changes
                </Button> */}
            </div>
        </div>
    );
}
