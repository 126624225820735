import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import styles from "assets/jss/material-dashboard-pro-react/components/cardTitleStyle.js";

const useStyles = makeStyles({
    ...styles,

});

export default function CardTitle(props) {
    const classes = useStyles();
    const { className, children, color, ...rest } = props;
    const cardTitleClasses = classNames({
        [classes.cardTitle]: true,
        [classes[color + "CardHeader"]]: color,
        [className]: className !== undefined
    });

    return (
        <Tooltip title={children} arrow>
            <Typography variant="body1" className={cardTitleClasses} {...rest}>
                {children}
            </Typography>
        </Tooltip>
    );
}

CardTitle.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf([
        "warning",
        "success",
        "danger",
        "info",
        "primary",
        "rose",
        "quSandBox",
        "quCreateColor",
    ]),
    children: PropTypes.node
};
