import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import MDEditor from "@uiw/react-md-editor";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";

// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import Button from "components/CustomButtons/Button.js";
// Custom styles
import styles from "assets/jss/material-dashboard-pro-react/components/headingStyle.js";

const useStyles = makeStyles(styles);

export default function HeadingWithDescription(props) {
  // Destructure props
  const {
    textAlign,
    title,
    shortDescription,
    longDescription,
    identifier,
    onChange,
  } = props;

  // Use custom styles
  const classes = useStyles();

  // State to control modal visibility and for editable fields
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [editableTitle, setEditableTitle] = useState(title);
  const [editableShortDescription, setEditableShortDescription] = useState(shortDescription);
  const [editableLongDescription, setEditableLongDescription] = useState(longDescription);

  // Combine the classes with dynamic text alignment
  const heading = classes.heading + " " + cx({
    [classes[textAlign + "TextAlign"]]: textAlign !== undefined,
  });

  // Handle changes for all fields (contenteditable)
  const handleChange = (field) => (event) => {
    // Call the passed `onChange` method with the field name and the new value
    onChange(field, event.target.innerText);
  };

  // Handle modal open
  const handleEditModalOpen = () => {
    setOpenEditModal(true);
  };

  const handlePreviewModalOpen = () => {
    setOpenPreviewModal(true);
  };

  // Handle modal close
  const handleModalClose = () => {
    setOpenEditModal(false);
    setOpenPreviewModal(false);
  };

  // Handle save changes
  const handleSaveChanges = () => {
    // Create a JSON object with all the fields
    const updatedData = {
      title: editableTitle,
      shortDescription: editableShortDescription,
      longDescription: editableLongDescription,
    };

    // Pass the JSON object to the parent via the onChange prop
    onChange(updatedData);

    // Close the edit modal
    setOpenEditModal(false);
  };

  function capitalizeFirstLetter(word) {
    if (!word) return ""; // Handle empty input
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <div className={heading}>
      {/* Title and Icons in the same container */}
      <Grid container justifyContent="space-between" alignItems="flex-start">
        {/* Title on the left */}
        <Grid item xs={12} sm={10}>
          {title !== undefined ? (
            <Typography variant="h5">
              {title}
            </Typography>
          ) : null}
        </Grid>

        {/* Icons on the right */}
        <Grid item xs={12} sm={2} style={{ textAlign: "right" }}>
          <Tooltip title={`Edit ${capitalizeFirstLetter(identifier)} Details`} placement="top">
            <IconButton onClick={handleEditModalOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Preview ${capitalizeFirstLetter(identifier)} Information`} placement="top">
            <IconButton onClick={handlePreviewModalOpen}>
              <BookmarkBorderIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {shortDescription !== undefined ? (
        <Typography variant="body1" >{shortDescription}</Typography>
      ) : null}

      {/* Edit Modal to edit title, short description, and course information */}
      <Dialog
        open={openEditModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Edit {capitalizeFirstLetter(identifier)} Details</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={editableTitle}
            onChange={(e) => setEditableTitle(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Short Description"
            value={editableShortDescription}
            onChange={(e) => setEditableShortDescription(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Typography
            variant="caption"
            style={{
              marginTop: "10px",
              marginBottom: "15px",
              color: "#6b6b6b",
            }}
          >
            {capitalizeFirstLetter(identifier)} Information
          </Typography>
          <div data-color-mode="light">
            <MDEditor
              value={editableLongDescription}
              onChange={setEditableLongDescription}
              height={200}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="quCreateColor">
            Cancel
          </Button>
          <Button onClick={handleSaveChanges} color="quCreateColor">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Modal to preview course information */}
      <Dialog
        open={openPreviewModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{capitalizeFirstLetter(identifier)} Information</DialogTitle>
        <DialogContent>
          <div data-color-mode="light">
            <MDEditor value={longDescription} height={400} preview="preview" hideToolbar />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="quCreateColor">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

HeadingWithDescription.propTypes = {
  title: PropTypes.node,
  shortDescription: PropTypes.node,
  longDescription: PropTypes.node,
  textAlign: PropTypes.oneOf(["right", "left", "center"]),
  identifier: PropTypes.string,
  onChange: PropTypes.func.isRequired, // `onChange` is a required prop
};
