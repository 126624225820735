import React, { useRef, useState } from "react";
import {
    Box,
    Typography,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Radio,
    RadioGroup,
    Paper,
    Icon,
    Divider,
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormHelperText,
} from "@material-ui/core";
import Button from 'components/CustomButtons/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: "20px",
    },
}));


export default function MarkasReviewedModal({
    open,
    onClose,
    voices,
    selectedVoice,
    setSelectedVoice,
    onConfirm,
    handleBooleanChange,
    assessment,
    chatbot,
}) {

    const classes = useStyles();



    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="voice-selection-modal"
            aria-describedby="voice-selection-description"
        >
            <Box
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "60%",
                    backgroundColor: "white",
                    boxShadow: 24,
                    padding: "20px",
                    borderRadius: "10px",
                }}
            >
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Deliverable Requirements
                </Typography>
                <Divider />
                <Typography variant="body1" style={{ marginTop: "20px", marginBottom: "20px" }}>
                    Select a voice to transcribe the audio. Here is the list of available voices:
                </Typography>


                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Voice Name</strong></TableCell>
                                <TableCell><strong>Audio</strong></TableCell>
                                <TableCell><strong>Select</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {voices.map((voice, index) => (
                                <TableRow key={index}>
                                    <TableCell>{voice.name}</TableCell>
                                    <TableCell>
                                        <audio id="audio" controls type="audio/mpeg">
                                            <source src={voice.sampleUrl} />
                                            Your browser does not support the audio element.
                                        </audio>
                                    </TableCell>
                                    <TableCell>
                                        <RadioGroup
                                            value={selectedVoice}
                                            onChange={(e) => setSelectedVoice(e.target.value)}
                                        >
                                            <FormControlLabel
                                                value={voice.name}
                                                control={<Radio />}
                                                label=""
                                            />
                                        </RadioGroup>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Divider />

                <div className={classes.root}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={assessment} onChange={handleBooleanChange} name="assessment" />}
                                label="Do you want Assessment to be included in the Course?"
                                style={{ color: 'black' }}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={chatbot} onChange={handleBooleanChange} name="chatbot" />}
                                label="Do you want ChatBot to be included in the Course?"
                                style={{ color: 'black' }}
                            />
                        </FormGroup>
                    </FormControl>
                </div>






                <Box style={{ textAlign: "right", marginTop: "20px" }}>
                    <Button
                        // color="primary"
                        color="quCreateColor"
                        variant="contained"
                        onClick={onConfirm}
                        disabled={!selectedVoice} // Disable until a voice is selected
                    >
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}
