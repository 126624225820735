class WritingModel {
    constructor() {
        this.name = "";
        this.description = "";
        this.template = [];
        this.uploadedFiles = [];
        this.target_audience = "";
        this.expected_length = "";
        this.tone = "";
        this.outline = "";
    }
}

export default WritingModel;
