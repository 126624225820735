// React Imports
import React, { useEffect, useState } from "react";
// Material UI Imports
import {
  Divider, FormControl, Grid, InputLabel, MenuItem, Select,
  TextField, Typography, CircularProgress, Box, Checkbox, Tooltip
} from "@material-ui/core";
import { CloudDownload, RotateLeft } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

// Custom component imports
import Button from "components/CustomButtons/Button.js";

// Custom plugins and utils
import NotificationContext from "views/Components/Context.js";
import { format, formatISO, set } from "date-fns";
import Alert from "views/Components/Alert";

// API Imports
import {
  generateTechnicalSpecificationsAPI, submitLabForGenerationAPI, generateBusinessUseCaseAPI, fetchLabPromptAPI,
  saveTechnicalSpecificationsAPI, saveBusinessUseCaseAPI, saveIdeaAPI, regenerateFeedbackAPI, fetchLabAPI, convertToPDFForLabAPI
} from "views/APIs/APIUtility";
import useMetaPrompt from "hooks/useMetaPrompt";
import QuStreamlineDialog from "views/Components/QuStreamlineDialog";
import EditorViewer from "views/Components/EditorViewer";

import ModelSelectionModal from "views/Components/ModelSelectionModal";
import SubmitLabModal from "views/Components/SubmitLabModal";

const identifiers = {
  research_report: "Research Report",
  white_paper: "White Paper",
  project_plan: "Project Plan",
  blog: "Blog",
  news_letter: "Newsletter",
  case_study: "Case Study",
  key_insights: "Key Insights",
  handout: "Handout",
  idea: "Idea",
  business: "Business Use Case",
  technical: "Technical Specification",
};
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

export default function LabUseCase({
  projectID,
  projectType,
  setActiveStep,
  useCase,
}) {

  const {
    metapromptCheckbox,
    setMetapromptCheckbox,
    originalMetaPrompt,
    setOriginalMetaPrompt,
    metaPrompt,
    setMetaPrompt,
    metaPromptDialogOpen,
    setMetaPromptDialogOpen,
    requiredMetaPromptParameters,
    setRequiredMetaPromptParameters,
    displayMetaPromptParameters,
    setDisplayMetaPromptParameters,
    notRequiredParameters,
    isDisabled,
    setIsDisabled,
    handleMetaPromptChange,
    checkForDisabled,
    _extractParameters,
    _handleEnhancePrompt,
  } = useMetaPrompt();

  const category = localStorage.getItem("userCategory");
  const { showNotification } = React.useContext(NotificationContext);

  const sweetAlertClasses = usesweetAlertStyle();
  const [alert, setAlert] = React.useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [reviewDialog, setReviewDialog] = useState(false);
  // const [pdfFile, setPdfFile] = useState(null);
  // const [modelSelectionModal, setModelSelectionModal] = useState(false);
  // const [validKey, setValidKey] = useState(false);
  const [loadingState, setLoadingState] = useState({ isLoading: false, message: "" });
  const [modelDetails, setModelDetails] = useState({ company: "", model: "", apiKey: "", name: "", description: "" });

  // const handleModelSelectionModalOpen = () => setModelSelectionModal(true);
  // const handleModelSelectionModalClose = () => {
  //   if (!validKey) {
  //     showNotification("Please select a model and enter an API key to proceed.", "danger");
  //   }
  //   if (modelDetails.model_id && modelDetails.api_key) {
  //     setModelSelectionModal(false);
  //     setValidKey(false);
  //   }
  //   else {
  //     showNotification("Please select a model and enter an API key", "danger");
  //   }
  // };

  // Mappings for API selection and PDF response keys
  const apiMap = {
    idea: saveIdeaAPI,
    business: saveBusinessUseCaseAPI,
    technical: saveTechnicalSpecificationsAPI,
  };

  const pdfMap = {
    idea: "idea_pdf",
    business: "business_use_case_pdf",
    technical: "technical_specifications_pdf",
  };

  // Define messages for different use cases
  const loadingMessages = {
    business: "Loading Business Use Case...",
    technical: "Loading Technical Specifications...",
    idea: "Loading Idea Details...",
    feedback: "Regenerating content with feedback...",
    version_change: "Loading version content...",
    generate_from_idea: "Generating Business Use Case...",
    generate_from_business: "Generating Technical Specifications...",
  };
  const [feedback, setFeedback] = useState("");

  const [outline, setOutline] = useState("# Hello This is sample markdown");
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfLoading, setPdfLoading] = useState({ isLoading: false, message: "" });
  const [version, setVersion] = useState(1);
  const [latestVersion, setLatestVersion] = useState(1);
  const [versionOptions, setVersionOptions] = useState([
    {
      business_use_case: "",
      version: 1,
      timestamp: formatISO(new Date()),
    },
  ]);

  const [lab, setLab] = useState(null);

  useEffect(() => {
    const fetchLabData = async () => {
      try {
        setLoadingState({ isLoading: true, message: loadingMessages[useCase] });
        setPdfLoading({ isLoading: true, message: "Loading PDF..." });
        const data = await fetchLabAPI(projectID);
        setLab(data);

        if (useCase === "idea") {
          setOutline(data.idea);
          setPdfUrl(data.idea_pdf);
          setVersionOptions(data.idea_history);
          const latestVersionObj = data.idea_history[data.idea_history.length - 1];  // Get the last version
          setVersion(latestVersionObj.version);
          setLatestVersion(latestVersionObj.version);  // Set the latest version
        }
        else if (useCase === "business") {
          setOutline(data.business_use_case);
          setPdfUrl(data.business_use_case_pdf);
          setVersionOptions(data.business_use_case_history);

          const latestVersionObj = data.business_use_case_history[data.business_use_case_history.length - 1];  // Get the last version
          setVersion(latestVersionObj.version);
          setLatestVersion(latestVersionObj.version);  // Set the latest version
        } else {
          setOutline(data.technical_specifications);
          setPdfUrl(data.technical_specifications_pdf);
          setVersionOptions(data.technical_specifications_history);
          const latestVersionObj = data.technical_specifications_history[data.technical_specifications_history.length - 1];  // Get the last version
          setVersion(latestVersionObj.version);
          setLatestVersion(latestVersionObj.version);  // Set the latest version
        }
      } catch (error) {
        showNotification(error, "danger");
      } finally {
        setLoadingState({ isLoading: false, message: "" });
        setPdfLoading({ isLoading: false, message: "" });
      }
    };

    const fetchPromptData = async () => {

      try {
        const formData = new FormData();
        formData.append("prompt_type", useCase);
        const data = await fetchLabPromptAPI(formData);
        setMetaPrompt(data);
        setOriginalMetaPrompt(data);
        const parameters = _extractParameters(data);
        setRequiredMetaPromptParameters(parameters);
        setDisplayMetaPromptParameters(parameters);
      }
      catch (error) {
        console.log("error", error);
      };
    };

    fetchLabData();
    fetchPromptData();
  }, [projectID, useCase]);

  const handleSaveChanges = async () => {
    setLoadingState({ isLoading: true, message: "Saving changes..." });

    try {
      const formData = new FormData();
      formData.append("lab_id", projectID);

      // Validate useCase before proceeding
      if (!apiMap[useCase] || !pdfMap[useCase]) {
        throw new Error("Invalid use case selected");
      }

      // Append the correct form field dynamically
      const fieldKey = useCase === "idea"
        ? "idea"
        : useCase === "business"
          ? "business_use_case"
          : "technical_specifications";

      formData.append(fieldKey, outline);

      // Call the appropriate API function
      const data = await apiMap[useCase](formData);

      // Extract the correct PDF URL
      setPdfUrl(data[pdfMap[useCase]]);


      hideAlert();
      showNotification("Changes saved successfully", "success");


      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      showNotification(error.message || "An unexpected error occurred", "danger");
    } finally {
      setLoadingState({ isLoading: false, message: "" });
    }
  };


  const saveChangesAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleSaveChanges()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, Save Changes!"
        cancelBtnText="Cancel"
        showCancel
      >
        The changes will be saved as the latest version. Are you sure you want
        to save these changes?
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const getPdf = async (markdown, download) => {
    if (download) {
      showNotification("Generating PDF...", "quSandBoxColor");
    }
    const formData = new FormData();
    formData.append("lab_id", projectID);
    formData.append("markdown", markdown);
    if (useCase === "idea") {
      formData.append("template_name", "idea");
      formData.append("lab_design_step", 1);
    }
    else if (useCase === "business") {
      formData.append("template_name", "business");
      formData.append("lab_design_step", 2);
    } else if (useCase === "technical") {
      formData.append("template_name", "technical");
      formData.append("lab_design_step", 3);
    }

    try {
      const data = await convertToPDFForLabAPI(formData);
      const url = data;
      setPdfUrl(url);

      if (download) {
        const link = document.createElement("a");
        link.href = url;
        if (useCase === "idea") {
          link.setAttribute("download", "Idea.pdf");
        }
        else if (useCase === "business") {
          link.setAttribute("download", "Business_Use_Case.pdf");
        } else if (useCase === "technical") {
          link.setAttribute("download", "Technical_Specifications.pdf");
        }
        document.body.appendChild(link);
        link.click();
        link.remove();
        showNotification("PDF generated successfully", "success");
      }
    } catch (error) {
      showNotification(error, "danger");
    } finally {
    }
  };

  const regenerateWithFeedback = async () => {
    if (feedback.trim() === "") {
      showNotification("Please enter feedback", "danger");
      return;
    }
    try {
      setLoadingState({ isLoading: true, message: loadingMessages["feedback"] });
      const formData = new FormData();
      formData.append("content", outline);
      if (metapromptCheckbox) {
        formData.append("use_metaprompt", true);
      }
      formData.append("feedback", feedback);

      const response = await regenerateFeedbackAPI(formData);
      setOutline(response);
      await handleRefreshContent(response, false);

      showNotification("Content regenerated successfully", "success");
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      setLoadingState({ isLoading: false, message: "" });
    }
  };

  const LabUseCaseSubmitHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("lab_id", projectID);
      if (useCase === "idea") {
        setLoadingState({ isLoading: true, message: loadingMessages["generate_from_" + useCase] });
        const prompt = metapromptCheckbox ? metaPrompt : originalMetaPrompt;
        formData.append("prompt", prompt);
        formData.append("use_metaprompt", !metapromptCheckbox);
        await generateBusinessUseCaseAPI(formData);
        setActiveStep(2);
      } else if (useCase === "business") {
        setLoadingState({ isLoading: true, message: loadingMessages["generate_from_" + useCase] });
        const prompt = metapromptCheckbox ? metaPrompt : originalMetaPrompt;
        formData.append("prompt", prompt);
        formData.append("use_metaprompt", !metapromptCheckbox);
        await generateTechnicalSpecificationsAPI(formData);

        setActiveStep(3); // Move to next step only after successful API call
      } else {
        if (lab.status === "In Lab Generation Queue") {
          setShowAlert(true);
        }
        else {
          setReviewDialog(true);
          // setModelSelectionModal(true);
        }

      }
    } catch (error) {
      showNotification(error.message || "An error occurred", "danger");
    } finally {
      setMetapromptCheckbox(false);
      setLoadingState({ isLoading: false, message: "" });
    }
  };

  const convertUtcToLocalWithOffset = (utcTimeString, offsetHours = -5) => {
    const date = new Date(utcTimeString); // Convert UTC string to Date object

    // Add the offset hours
    date.setHours(date.getHours() + offsetHours);

    // Format to the user's local timezone
    return date.toLocaleString();
  };

  const submitHandler = async (saveAPIKEY) => {
    // handleReviewDialogClose();
    // setLoadingState({ isLoading: true, message: "Saving Technical Specifications..." });
    const formData = new FormData();
    formData.append("lab_id", projectID);
    formData.append("company", modelDetails.company);
    formData.append("model", modelDetails.model);
    formData.append("key", modelDetails.apiKey);
    formData.append("name", modelDetails.name);
    formData.append("description", modelDetails.description);
    formData.append("type", "llm");
    formData.append("saveAPIKEY", saveAPIKEY);


    await submitLabForGenerationAPI(formData);
    handleReviewDialogClose();
    window.location.reload();
    showNotification(
      "Technical Specifications saved successfully. You will be notified when we create the application for you.",
      "success"
    );
  };


  const handleChangeVersion = async (event) => {
    setLoadingState({ isLoading: true, message: loadingMessages["version_change"] });
    const selectedVersion = event.target.value;
    setVersion(selectedVersion);


    // Optionally find and use the selected version's details
    const selectedOption = versionOptions.find(
      (option) => option.version === selectedVersion
    );
    if (selectedOption) {

      if (useCase === "idea") {
        setOutline(selectedOption.idea);
        await getPdf(selectedOption.idea);
      }
      else if (useCase === "business") {
        setOutline(selectedOption.business_use_case);
        await getPdf(selectedOption.business_use_case);
      } else {
        setOutline(selectedOption.technical_specifications);
        await getPdf(selectedOption.technical_specifications);
      }
    }
    setLoadingState({ isLoading: false, message: "" });
  };


  const handleRefreshContent = async (outline, download) => {
    setPdfLoading({ isLoading: true, message: "Loading PDF..." });
    await getPdf(outline, download);
    setPdfLoading({ isLoading: false, message: "" });
  };

  const handleMetaPromptDialog = async (event) => {
    setMetaPromptDialogOpen(true);
  };

  const metaPromptDialogClose = () => {
    setMetaPromptDialogOpen(false);
    setMetapromptCheckbox(false);
  };

  const _handleSubmission = async () => {
    setMetapromptCheckbox(true);
    setMetaPromptDialogOpen(false);
  };

  const handleConfirm = () => {
    setShowAlert(false); // Close the alert
    setReviewDialog(true);
  };

  const handleCancel = () => {
    setShowAlert(false); // Close the alert
  };

  if (loadingState.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loadingState.message || "Loading..."}
        </Typography>
      </Box>
    );
  }

  const handleReviewDialogClose = () => {
    setModelDetails({ company: "", model: "", apiKey: "", name: "", description: "" });
    setReviewDialog(false);
  };

  return (
    <div>
      <SubmitLabModal
        reviewDialog={reviewDialog}
        handleReviewDialogClose={handleReviewDialogClose}
        lab={lab}
        modelDetails={modelDetails}
        setModelDetails={setModelDetails}
        submitHandler={submitHandler}
      />
      {showAlert && (
        <Alert
          title="Are you sure?"
          warning={true}
          showCancel={true}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        >
          Proceeding to the next step will reset your current progress in further steps. Your existing work will be overwritten, and new content will be created. Are you sure you want to continue?
        </Alert>
      )}



      <QuStreamlineDialog
        metaPromptDialogOpen={metaPromptDialogOpen}
        metaPromptDialogClose={metaPromptDialogClose}
        metaPrompt={metaPrompt}
        setMetaPrompt={setMetaPrompt}
        displayMetaPromptParameters={displayMetaPromptParameters}
        notRequiredParameters={notRequiredParameters}
        handleMetaPromptChange={handleMetaPromptChange}
        handleSubmission={_handleSubmission}
        checkForDisabled={checkForDisabled}
        _handleEnhancePrompt={_handleEnhancePrompt}
        _handleSubmission={_handleSubmission}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />

      {alert}
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div>
          <Typography variant="h5"> {identifiers[useCase]}</Typography>
          <Typography variant="body1">
            {" "}
            {useCase === "business"
              ? "Describe the business use case for the project."
              : useCase === "technical"
                ? "Describe the technical specifications for the project."
                : "Describe the idea for the project."
            }
          </Typography>
        </div>
        <div>
          <Button
            onClick={() => getPdf(outline, true)}
            color="quSandBoxColor"
            style={{
              float: "right",
            }}
          >
            <CloudDownload /> &nbsp;Download
          </Button>

          <Button
            onClick={() => {
              saveChangesAlert();
            }}
            color="quCreateColor"
            style={{
              float: "right",
            }}
            disabled={version !== latestVersion}
          >
            <SaveIcon /> &nbsp;Save Changes
          </Button>
          <FormControl
            style={{ minWidth: "180px", float: "right", marginRight: "10px" }}
          >
            {" "}
            {/* Ensure consistent width */}
            <InputLabel>Select Version</InputLabel>
            <Select
              value={version}
              renderValue={(selected) => {
                // Display only the version number when the dropdown is not open
                const selectedOption = versionOptions.find(
                  (option) => option.version === selected
                );
                return selectedOption
                  ? `Version ${selectedOption.version}`
                  : "";
              }}
              onChange={handleChangeVersion}
              fullWidth
              disableUnderline
            >
              {versionOptions.map((option, index) => (
                <MenuItem key={index} value={option.version}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {" "}
                        {"Version: " + option.version}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption"
                        style={{ fontStyle: "italic" }}
                      >
                        {"Last Updated: " +
                          convertUtcToLocalWithOffset(option.timestamp)}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Divider />

      <EditorViewer
        version={version}
        latestVersion={latestVersion}
        outline={outline}
        setOutline={setOutline}
        pdfUrl={pdfUrl}
        pdfLoading={pdfLoading}
        setPdfLoading={setPdfLoading}
        handleRefreshContent={handleRefreshContent}
      />

      <>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" style={{ marginTop: "10px" }}>
            Feedback
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            variant="body1"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            Regenerate the output with your suggestions.
          </Typography>
        </Grid>
        {/* TextField for user input */}
        <Grid
          item
          xs={12}
          sm={12}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter your feedback here..."
            style={{
              backgroundColor: "white",
              padding: "10px",
            }}
            // Uncomment the following line to enable feedback state
            onChange={(e) => setFeedback(e.target.value)}
          />
        </Grid>
      </>


      {/* <Grid item xs={12} style={{ "display": "flex", "alignItems": "center", verticalAlign: "middle" }}>
          
        </Grid> */}

      <Grid container style={{ marginTop: "10px" }}>

        {/* Right Side - Enable QuStreamline and Next Button */}
        <Grid item xs={12} sm={12}>

          <Button
            color="success"
            startIcon={<RotateLeft />}
            onClick={regenerateWithFeedback}
            style={{ float: "left" }}
          >
            Regenerate
          </Button>

          {/* <ModelSelectionModal
            isOpen={modelSelectionModal}
            onClose={handleModelSelectionModalClose}
            onSubmit={setModelDetails}
            setValidKey={setValidKey}
          /> */}
          {/* Next Button */}
          <Button
            color="quCreateColor"
            onClick={LabUseCaseSubmitHandler}
            endIcon={<NavigateNextIcon />}
            style={{ float: "right", marginLeft: "10px" }}
          >
            {(lab && (lab.status === "In Lab Generation Queue" || lab.status === "Lab Generation Failed" || lab.status === "Review")) ? "Recreate Lab" : "Submit for Creation"}
          </Button>

          {/* QuStreamline Checkbox and Tooltip (Only for idea/business use cases and super_admin category) */}
          {(category === "super_admin" || category === "admin") && (
            (useCase === "idea" || useCase === "business") && (
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  verticalAlign: "middle",
                  marginTop: "5px"
                }}
              >
                <Checkbox color="quCreateColor" checked={metapromptCheckbox} disabled />
                <Typography variant="body2" color="textSecondary">
                  Enable QuStreamline
                </Typography>
                <Tooltip title="QuStreamline streamlines the generation of the query with best practices and guardrails.">
                  <EditIcon
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                    fontSize="small"
                    color="quCreateColor"
                    onClick={handleMetaPromptDialog}
                  />
                </Tooltip>
              </div>
            )
          )}
        </Grid>
      </Grid>
    </div >
  );
}
