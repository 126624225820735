import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  DialogActions,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloudDownload, Visibility, RestorePage } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { useModuleID } from "./Data/customhook";
import FileUploadDialog from "./FileUploadDialog"; // Import the dialog component
import { replaceResourceAPI } from "views/APIs/APIUtility";
import NotificationContext from "views/Components/Context.js";

const useStyles = makeStyles((theme) => ({
  video: {
    width: "100%",
    borderRadius: "8px",
  },
  playButton: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "20px",
    boxSizing: "border-box",
  },
  tableContainer: {
    width: "100%",
    maxHeight: "70vh",
    margin: "0 auto",
  },
}));

export default function VideoPlayer(props) {
  const { showNotification } = React.useContext(NotificationContext);
  const classes = useStyles();

  const courseID = props.courseID;
  const moduleID = props.moduleID;

  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState({
    name: "",
    description: "",
    file: null,
  });
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [replaceVideoIndex, setReplaceVideoIndex] = useState(null);
  const [replace, setReplace] = useState(false);

  const setVideosForCard = () => {
    const card = props.videos;

    if (card) {
      
      const updatedVideo = card.map((video) => {
        return {
          id: video.resource_id,
          name: video.resource_name,
          url: video.resource_link,
          description: video.resource_description,
          type: video.resource_type,
        };
      });
      setVideos(updatedVideo);
    } else {
      
    }
  };

  // Call setFilesForCard whenever the id changes
  useEffect(() => {
    setVideosForCard();
  }, []);

  const handlePreview = (video) => {
    
    setCurrentVideo(video);
    setVideoModalOpen(true);
  };

  const handlePreviewClose = () => {
    setVideoModalOpen(false);
    // setCurrentVideo(null);
  };

  const handleReplace = async (video, index) => {
    setReplaceVideoIndex(index);
    setCurrentVideo({
      name: video.name,
      description: video.description,
      file: null,
    });
    setReplace(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.setAttribute("download", file.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReplaceFile = async () => {
    
    setReplace(false);
    const formData = new FormData();
    formData.append("course_id", courseID);
    formData.append("module_id", moduleID);
    formData.append("resource_id", videos[replaceVideoIndex].id);
    formData.append("resource_name", currentVideo.name);
    formData.append("resource_type", videos[replaceVideoIndex].type);
    formData.append("resource_description", currentVideo.description);

    if (currentVideo.file) {
      
      formData.append("resource_file", currentVideo.file);
    }
    formData.append("course_design_step", 11);
    try {
      await replaceResourceAPI(formData);
      showNotification("Video Updated Successfully", "success");
    } catch (error) {
      console.error("Error in handleUpdateVideo:", error);
    } finally {
      // handleTableState();
      handleSave();
      window.location.reload();
    }
  };

  const handleSave = () => {
    setOpen(false);
  };

  const getFileNameWithoutExtension = (fileName) =>
    fileName.replace(/\.[^/.]+$/, "");

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" style={{ marginTop: "20px" }}>
            Final Video
          </Typography>
          <Typography variant="body1">
            Here is the final video for the module.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className={classes.container}>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Description</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {videos && videos.length > 0 ? (
                    videos.map((video, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Tooltip
                            title={video.name}
                            disableHoverListener={video.name.length < 20}
                          >
                            <div>
                              <span>
                                {getFileNameWithoutExtension(video.name)}
                              </span>
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={video.description}
                            disableHoverListener={video.description.length < 20}
                          >
                            <div>
                              <span>
                                {getFileNameWithoutExtension(video.description)}
                              </span>
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton onClick={() => handleDownload(video)}>
                            <CloudDownload />
                          </IconButton>
                          <IconButton onClick={() => handlePreview(video)}>
                            <Visibility />
                          </IconButton>
                          <IconButton
                            onClick={() => handleReplace(video, index)}
                          >
                            <RestorePage />
                          </IconButton>
                          {/* <IconButton onClick={() => handleDelete(video)}>
                                            <Delete />
                                        </IconButton> */}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No Videos available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {/* Pass props to FileUploadDialog */}
          <FileUploadDialog
            open={open}
            onClose={handleClose}
            newFile={currentVideo}
            setNewFile={setCurrentVideo}
            onSave={replace ? handleReplaceFile : handleSave}
            replace={replace}
            file_type="video"
          />

          {/* Dialog for Video Preview */}
          <Dialog
            open={videoModalOpen}
            onClose={handlePreviewClose}
            maxWidth="md"
            fullWidth
          >
            {/* <IconButton onClick={handlePreviewClose} className={classes.closeButton}>
                        <CloseIcon />
                    </IconButton> */}
            {/* <DialogTitle>{selectedSlide?.title}</DialogTitle> */}
            <DialogContent className={classes.dialogContent}>
              {currentVideo && (
                <div dangerouslySetInnerHTML={{ __html: currentVideo.url }} />
              )}
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
}
