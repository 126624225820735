import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import NotificationContext from "views/Components/Context.js";
// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import register from "assets/img/homescreen.jpeg";
import verify from "assets/img/homescreen.jpeg";
import login from "assets/img/homescreen.jpeg";
import updatePassword from "assets/img/homescreen.jpeg";
import resetPassword from "assets/img/homescreen.jpeg";
import lock from "assets/img/lock.jpeg";
import error from "assets/img/homescreen.jpeg";
import pricing from "assets/img/homescreen.jpeg";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() { };
  });
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    if (window.location.pathname.indexOf("/auth/register-page") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/auth/login-page") !== -1) {
      return login;
    } else if (window.location.pathname.indexOf("/auth/verification-page") !== -1) {
      return verify;
    } else if (window.location.pathname.indexOf("/auth/pricing-page") !== -1) {
      return pricing;
    } else if (window.location.pathname.indexOf("/auth/reset-password-page") !== -1) {
      return resetPassword;
    } else if (window.location.pathname.indexOf("/auth/update-password-page") !== -1) {
      return updatePassword;
    } else if (
      window.location.pathname.indexOf("/auth/lock-screen-page") !== -1
    ) {
      return lock;
    } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
      return error;
    }
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const [notification, setNotification] = React.useState(false);
  const [operationStatus, setOperationStatus] = React.useState("success");
  const [notificationMessage, setNotificationMessage] = React.useState("");

  const showNotification = (message, status) => {
    console.log("Notification triggered:", message, status); // Debugging

    setOperationStatus(status);
    setNotificationMessage(message);
    setNotification(true);
    setTimeout(function () {
      setNotification(false);
    }, 5000);
  };

  return (
    <div>
      <NotificationContext.Provider value={{ showNotification }}>
        <Snackbar
          place="br"
          color={operationStatus}
          icon={AddAlert}
          message={notificationMessage}
          open={notification}
          closeNotification={() => setNotification(false)}
          close
        />
        <AuthNavbar brandText={getActiveRoute(routes)}  {...rest} />
        <div className={classes.wrapper} ref={wrapper}>
          <div
            className={classes.fullPage}
            style={{ backgroundImage: "url(" + getBgImage() + ")" }}
          >
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/auth" to="/auth/login-page" />
            </Switch>
            <Footer white />
          </div>
        </div>
      </NotificationContext.Provider>
    </div>
  );
}
