import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Tooltip,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  Tabs,
  Tab,
  Divider,
  Typography,
} from "@material-ui/core";
// @material-ui/icons
import {
  Refresh,
  DeleteForever,
  Edit,
  Error,
  Check,
  VpnKeyOutlined,
  Close,
  Lock,
  LockOpen,
} from "@material-ui/icons";

import StorageIcon from "@material-ui/icons/Storage";
import EcoIcon from "@material-ui/icons/Eco";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";

// Amplify components
import { Auth } from "aws-amplify";

//dialog components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import availableProviders from "provider.js";
import Heading from "components/Heading/Heading";
import SearchBar from "material-ui-search-bar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(userProfileStyles);
const useModalStyles = makeStyles(modalStyle);
const useFormStyles = makeStyles(regularFormsStyle);
const useextendedFormsStyles = makeStyles(extendedFormsStyle);
const usedashboardStyle = makeStyles(dashboardStyle);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

export default function QuApiVault() {
  const classes = useStyles();
  const history = useHistory();
  const modalClasses = useModalStyles();
  const formClasses = useFormStyles();
  const extendedFormsClasses = useextendedFormsStyles();
  const dashboardClasses = usedashboardStyle();
  const sweetAlertClasses = usesweetAlertStyle();

  const [user, setUser] = React.useState();
  const [alert, setAlert] = React.useState(null);
  const [apiKeys, setAPIKeys] = React.useState([]);
  const images = require.context("assets/img", true);
  const [modal, setModal] = React.useState();
  const [newAppName, setNewAppName] = React.useState("");
  const [appNameState, setappNameState] = React.useState("");
  const [newAPIs, setNewAPIs] = React.useState([]);
  const [newAPIsState, setnewAPIsState] = React.useState("");
  const [newKeyType, setnewKeyType] = React.useState("QuSandbox");
  const [coupon, setcoupon] = React.useState("");
  const [curAPIKey, setcurAPIKey] = React.useState("");
  const [newKeyJson, setnewKeyJson] = React.useState("{}");
  const [newKeyJsonState, setnewKeyJsonState] = React.useState("");
  const [br, setBR] = React.useState(false);
  const [newKeyError, setnewKeyError] = React.useState("");
  const availableAPIs = ["data", "synthesize"];
  const [loading, setLoading] = React.useState(true);
  const handleType = (event) => {
    setnewKeyType(event.target.value);
    if (event.target.value === "Custom") {
      setnewKeyJson("{}");
    }
  };

  const [activeTab, setActiveTab] = useState(0); // Track active tab
  const groups = ["Datasets", "Environment", "QUAPIs", "LLMs"]; // Tab labels including "All"

  // Define categories based on activeTab
  const categories = {
    0: ["Moody's", "FactSet", "S&P", "SEC", "Fred", "Snowflake marketplace"],
    1: ["Google", "Azure", "AWS", "NVIDIA"],
    2: ["MathWorks", "QuSandbox"],
    3: ["Gemini", "Claude", "OpenAI", "R1"],
  };

  const imageSource = {
    OpenAI: "openai.png",
    Gemini: "gemini.png",
    Claude: "claude.png",
    "S&P": "s&p_global.png",
  };

  const [searchValue, setSearchValue] = useState(""); // Search input value
  const [displayKeys, setDisplayKeys] = useState([]); // Displayed courses
  const [filteredApiKeys, setFilteredApiKeys] = useState([]); // Filtered courses

  useEffect(() => {
    setFilteredApiKeys(
      apiKeys.filter((api) => categories[activeTab]?.includes(api.AppName))
    );
    setDisplayKeys(
      apiKeys.filter((api) => categories[activeTab]?.includes(api.AppName))
    );
  }, [apiKeys, activeTab]);
  // Filter API keys based on activeTab category

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update active tab
    setFilteredApiKeys(
      apiKeys.filter((api) => categories[newValue]?.includes(api.AppName))
    );
    setDisplayKeys(
      apiKeys.filter((api) => categories[newValue]?.includes(api.AppName))
    );
  };

  const handleToggle = (value) => {
    const currentIndex = newAPIs.indexOf(value);
    const newChecked = [...newAPIs];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setNewAPIs(newChecked);
    if (newChecked.length === 0) {
      setnewAPIsState("error");
    } else {
      setnewAPIsState("success");
    }
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (user) {
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
        history.push("/auth/login-page");
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + "/keyservice", {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(
        function (res) {
          res.json().then(function (data) {
            console.log("data : ", data)
            setAPIKeys(data.Items);
            setDisplayKeys(data.Items);
            setLoading(false);
          });
        },
        function (err) {
          console.log(err);
        }
      );
    }
  }, [user]);

  const successDelete = (i) => {
    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + "/keyservice", {
        method: "DELETE",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          APIKey: apiKeys[i].APIKey,
        }),
      })
        .then(function (res) {
          res.json().then(function (data) {
            let temp = Array.from(apiKeys);
            temp.splice(i, 1);
            setAPIKeys(temp);
            setDisplayKeys(temp);
          });
        })
        .catch((error) => {
          setnewKeyError(error.message);
          setBR(true);
          setTimeout(function () {
            setBR(false);
            setnewKeyError();
          }, 6000);
        });
    }
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
      >
        The Key has been deleted.
      </SweetAlert>
    );
  };

  const deleteAlert = (index) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(index)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this key!
      </SweetAlert>
    );
  };

  const successRefresh = (i) => {
    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + "/keyservice", {
        method: "PUT",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          APIKey: apiKeys[i].APIKey,
          refresh: true,
          keyType: apiKeys[i].keyType,
          APIs: apiKeys[i].APIs,
          AppName: apiKeys[i].AppName,
          json: apiKeys[i].json,
        }),
      })
        .then(function (res) {
          window.location.reload();
        })
        .catch((error) => {
          setnewKeyError(error.message);
          setBR(true);
          setTimeout(function () {
            setBR(false);
            setnewKeyError();
          }, 6000);
        });
    }
  };

  const refreshAlert = (index) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successRefresh(index)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, refresh it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this key!
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const keyValidation = () => {
    setnewKeyError("");
    if (newAppName.length <= 0) {
      setappNameState("error");
      setnewKeyError("Please input App name.");
      return false;
    } else {
      setnewAPIsState("success");
    }
    if (newKeyType === "QuSandbox") {
      if (newAPIs.length <= 0) {
        setnewAPIsState("error");
        setnewKeyError("Please select at least one APIs.");
        return false;
      } else {
        setnewAPIsState("success");
      }
    } else if (newKeyType === "Custom") {
      if (Object.keys(newKeyJson).length <= 0) {
        setnewKeyJsonState("error");
        setnewKeyError("Please not input empty JSON.");
        return false;
      } else {
        setnewKeyJsonState("success");
      }
    } else {
      for (
        let i = 0;
        i < availableProviders[newKeyType]["attributes"].length;
        i++
      ) {
        if (
          newKeyJson[availableProviders[newKeyType]["attributes"][i]] &&
          newKeyJson[availableProviders[newKeyType]["attributes"][i]].length > 0
        ) {
          setnewKeyJsonState("success");
        } else {
          setnewKeyJsonState("error");
          setnewKeyError("Please input required values.");
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    setappNameState();
    setnewAPIsState();
    setnewKeyJsonState();
  }, [newKeyType]);

  const updateModal = (i) => {
    setnewKeyType(apiKeys[i].keyType);
    setNewAPIs(apiKeys[i].APIs);
    setNewAppName(apiKeys[i].AppName);
    setnewKeyJson(apiKeys[i].json);
    setcurAPIKey(apiKeys[i].APIKey);
    setappNameState();
    setnewAPIsState();
    setnewKeyJsonState();
    setModal("update");
  };

  const createModal = () => {
    setnewKeyType("QuSandbox");
    setNewAPIs([]);
    setNewAppName("");
    setnewKeyJson("{}");
    setcoupon("");
    setappNameState();
    setnewAPIsState();
    setnewKeyJsonState();
    setModal("create");
  };

  const updateKey = () => {
    if (!keyValidation()) {
      return;
    }
    setModal();
    // let apis = []
    // for (let i in newAPIs) {
    //   apis.push(availableAPIs[i])
    // }

    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + "/keyservice", {
        method: "PUT",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          keyType: newKeyType,
          APIs: newAPIs,
          APIKey: curAPIKey,
          AppName: newAppName,
          json: newKeyJson,
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          window.location.reload();
        })
        .catch((error) => {
          setnewKeyError(error.message);
          setBR(true);
          setTimeout(function () {
            setBR(false);
            setnewKeyError();
          }, 6000);
        });
    }
  };

  const createKey = () => {
    if (!keyValidation()) {
      return;
    }
    setModal();
    // let apis = []
    // for (let i in newAPIs) {
    //   apis.push(availableAPIs[i])
    // }
    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + "/keyservice", {
        method: "POST",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          keyType: newKeyType,
          APIs: newAPIs,
          AppName: newAppName,
          json: JSON.parse(newKeyJson),
          coupon: coupon,
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          window.location.reload();
        })
        .catch((error) => {
          setnewKeyError(error.message);
          setBR(true);
          setTimeout(function () {
            setBR(false);
            setnewKeyError();
          }, 6000);
        });
    }
  };

  const toggleKeyShown = (i) => {
    let temp = Array.from(apiKeys);
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      "/keyservice?AppName=" +
      temp[i].AppName,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then(
      function (res) {
        res.json().then(function (data) {
          if (data.Items[0]) {
            temp[i] = data.Items[0];
            temp[i].keyShown = true;
          }
          setAPIKeys(temp);
          setDisplayKeys(temp);
        });
      },
      function (err) {
        console.log(err);
      }
    );
  };

  const toggleKeyHidden = (i) => {
    let temp = Array.from(apiKeys);
    temp[i].keyShown = false;
    temp[i].APIKey = null;
    temp[i].json = null;
    setAPIKeys(temp);
    setDisplayKeys(temp);
  };
  // Handle search input value change
  const searchInputValue = (e) => {
    setSearchValue(e);
    if (e.length === 0) {
      setDisplayKeys(filteredApiKeys);
      return;
    }
    const newList = filteredApiKeys.filter((key) =>
      key.AppName.toLowerCase().includes(e.toLowerCase())
    );
    setDisplayKeys(newList);
  };

  return (
    <div style={{}}>
      <Divider style={{ marginBottom: "20px" }} />
      {/* Title */}
      <Typography variant="h5">Welcome to the QuAPI Vault! </Typography>
      <Typography
        variant="body1"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        Here you can manage your QuAPI keys. You can create new keys, edit or
        delete existing keys.
      </Typography>
      <SearchBar
        value={searchValue}
        onChange={(newValue) => searchInputValue(newValue)}
        onRequestSearch={(newValue) => searchInputValue(newValue)}
      />
      <Snackbar
        place="br"
        color="danger"
        icon={Error}
        message={newKeyError}
        open={br}
        closeNotification={() => setBR(false)}
        close
      />
      <Dialog
        classes={{
          root: modalClasses.center,
          paper: modalClasses.modal,
        }}
        open={modal != null}
        transition={Transition}
        keepMounted
        onClose={() => setModal()}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <CardHeader color="quSandBox" icon>
          <CardIcon color="quSandBox">
            <VpnKeyOutlined />
          </CardIcon>
          <Button
            justIcon
            className={modalClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal()}
            style={{ marginTop: "0px" }}
          >
            <Close className={modalClasses.modalClose} />
          </Button>
          {modal === "create" && (
            <h4 className={classes.cardIconTitle}>Create New Key</h4>
          )}
          {modal === "update" && (
            <h4 className={classes.cardIconTitle}>Edit Key: {curAPIKey}</h4>
          )}
        </CardHeader>

        <form>
          <DialogContent
            id="modal-slide-description"
            className={modalClasses.modalBody}
          >
            <CustomInput
              labelText="App name *"
              id="app_name"
              formControlProps={{
                fullWidth: true,
              }}
              success={appNameState === "success"}
              error={appNameState === "error"}
              inputProps={{
                type: "text",
                onChange: (event) => {
                  if (event.target.value.length > 0) {
                    setappNameState("success");
                  } else {
                    setappNameState("error");
                  }
                  setNewAppName(event.target.value);
                },
                value: newAppName,
              }}
            />
            <FormControl
              fullWidth
              className={extendedFormsClasses.selectFormControl}
            >
              <InputLabel
                htmlFor="simple-select"
                className={extendedFormsClasses.selectLabel}
              >
                Choose Type *
              </InputLabel>
              <Select
                MenuProps={{
                  className: extendedFormsClasses.selectMenu,
                }}
                classes={{
                  select: extendedFormsClasses.select,
                }}
                value={newKeyType}
                onChange={handleType}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select",
                  value: newKeyType,
                }}
              >
                {Object.keys(availableProviders).map((provider, index) => (
                  <MenuItem
                    key={index}
                    classes={{
                      root: extendedFormsClasses.selectMenuItem,
                      selected: extendedFormsClasses.selectMenuItemSelected,
                    }}
                    value={provider}
                  >
                    {provider}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {newKeyType === "QuSandbox" && (
              <GridContainer>
                <GridItem xs={12}>
                  <FormLabel
                    className={
                      classes.labelHorizontal +
                      " " +
                      classes.labelHorizontalRadioCheckbox
                    }
                  >
                    Select supported APIs *:
                  </FormLabel>
                </GridItem>
                {availableAPIs.map((api, index) => (
                  <GridItem key={index} xs={12}>
                    <div
                      className={
                        formClasses.checkboxAndRadio +
                        " " +
                        formClasses.checkboxAndRadioHorizontal
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => handleToggle(api)}
                            checkedIcon={
                              <Check className={formClasses.checkedIcon} />
                            }
                            icon={
                              <Check className={formClasses.uncheckedIcon} />
                            }
                            classes={{
                              checked: formClasses.checked,
                              root: formClasses.checkRoot,
                            }}
                            checked={newAPIs.indexOf(api) !== -1}
                          />
                        }
                        label={api}
                        classes={{
                          label:
                            formClasses.label +
                            (newAPIsState === "error"
                              ? " " + formClasses.labelError
                              : ""),
                          root: formClasses.labelRoot,
                        }}
                      />
                    </div>
                  </GridItem>
                ))}
              </GridContainer>
            )}
            {newKeyType !== "Custom" &&
              newKeyType !== "QuSandbox" &&
              availableProviders[newKeyType]["attributes"].map(
                (attribute, key) => (
                  <CustomInput
                    key={attribute}
                    labelText={attribute + " *"}
                    id={attribute}
                    success={newKeyJsonState === "success"}
                    error={newKeyJsonState === "error"}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      onChange: (event) => {
                        let temp = { ...newKeyJson };
                        temp[attribute] = event.target.value;
                        setnewKeyJson(temp);
                      },
                      value: newKeyJson[attribute] || "",
                    }}
                  />
                )
              )}
            {newKeyType === "Custom" && (
              <CustomInput
                labelText="Custom key json"
                id="custom_key_json"
                formControlProps={{
                  fullWidth: true,
                }}
                success={newKeyJsonState === "success"}
                error={newKeyJsonState === "error"}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  onChange: (event) => {
                    setnewKeyJson(event.target.value);
                    try {
                      JSON.parse(event.target.value);
                      setnewKeyError("");
                      setnewKeyJsonState("success");
                    } catch {
                      setnewKeyJsonState("error");
                      setnewKeyError("Invalid JSON format");
                    }
                  },
                  value: newKeyJson,
                }}
              />
            )}
            {modal === "create" && (
              <CustomInput
                labelText="Coupon"
                id="coupon"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  onChange: (event) => {
                    setcoupon(event.target.value);
                  },
                  value: coupon,
                }}
              />
            )}
            {newKeyError && <code>{newKeyError}</code>}
          </DialogContent>
          <DialogActions
            className={
              modalClasses.modalFooter + " " + modalClasses.modalFooterCenter
            }
          >
            {modal === "create" && (
              <Button onClick={createKey} color="success">
                Create
              </Button>
            )}
            {modal === "update" && (
              <Button onClick={updateKey} color="success">
                Update
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
      {alert}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <StorageIcon fontSize="small" /> Datasets
            </span>
          }
        />
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <EcoIcon fontSize="small" /> Environment
            </span>
          }
        />
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <SettingsApplicationsIcon fontSize="small" /> QuAPIs
            </span>
          }
        />
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <DonutLargeIcon fontSize="small" /> LLMs
            </span>
          }
        />
      </Tabs>
      <Divider style={{ margin: "20px 0" }} />
      <GridContainer>
        {/* <GridItem lg={2} md={4} sm={4} xs={12}>
          <Button color="info" onClick={createModal}>
            Create New Key
          </Button>
        </GridItem> */}
        {loading ? (
          <GridItem xs={12}>
            <Heading title={<CircularProgress />} textAlign="center" />
          </GridItem>
        ) : (
          // <>
          //   {apiKeys &&
          //     apiKeys.map((api, index) => (
          //       <GridItem key={index} xs={12} sm={12} md={6} lg={4}>
          //         <Card style={{ minHeight: "200px" }}>
          //           <CardHeader
          //             color="quSandBox"
          //             stats
          //             icon
          //             style={{ marginTop: ".625rem" }}
          //           >
          //             <CardIcon color="quSandBox">{api.AppName}</CardIcon>
          //             <h3>
          //               {api.keyType && (
          //                 <img
          //                   alt={api.keyType}
          //                   src={images(
          //                     "./" + availableProviders[api.keyType].thumbnail
          //                   )}
          //                   style={{ maxWidth: "30%", maxHeight: "30%" }}
          //                 />
          //               )}
          //               {!api.keyType && (
          //                 <img
          //                   alt="qusandbox logo"
          //                   src={images("./qusandbox_logo_v2.5.png")}
          //                   style={{ maxWidth: "30%", maxHeight: "30%" }}
          //                 />
          //               )}
          //             </h3>
          //           </CardHeader>
          //           <CardBody>
          //             {api.keyShown && (
          //               <GridContainer>
          //                 <GridItem lg={12}>
          //                   <p>QuSandbox Key: </p>
          //                 </GridItem>
          //                 <GridItem lg={12}>
          //                   <SnackbarContent
          //                     message={api.APIKey}
          //                     color="transparent"
          //                     close="copy"
          //                   />
          //                 </GridItem>
          //                 {api.keyType === "QuSandbox" && api.APIs && (
          //                   <GridItem lg={12}>
          //                     <GridContainer>
          //                       <GridItem lg={4}>Support APIs:</GridItem>
          //                       <GridItem lg={8}>
          //                         <ul>
          //                           {api.APIs.map((app, index) => (
          //                             <li key={index}>{app}</li>
          //                           ))}
          //                         </ul>
          //                       </GridItem>
          //                     </GridContainer>
          //                   </GridItem>
          //                 )}
          //                 {api.json &&
          //                   Object.keys(api.json).map((attribute, index) => (
          //                     <GridItem lg={12} key={index}>
          //                       {attribute}: {api.json[attribute]}
          //                     </GridItem>
          //                   ))}
          //                 <GridContainer>
          //                   <GridItem
          //                     xs={12}
          //                     sm={12}
          //                     md={12}
          //                     style={{ textAlign: "center" }}
          //                   >
          //                     <Tooltip
          //                       id="tooltip-top"
          //                       title="Lock"
          //                       placement="bottom"
          //                       classes={{ tooltip: dashboardClasses.tooltip }}
          //                     >
          //                       <Button
          //                         color="transparent"
          //                         onClick={() => toggleKeyHidden(index)}
          //                         size="lg"
          //                         simple
          //                         justIcon
          //                       >
          //                         <Lock />
          //                       </Button>
          //                     </Tooltip>
          //                     <Tooltip
          //                       id="tooltip-top"
          //                       title="Refresh"
          //                       placement="bottom"
          //                       classes={{ tooltip: dashboardClasses.tooltip }}
          //                     >
          //                       <Button
          //                         color="success"
          //                         onClick={() => refreshAlert(index)}
          //                         size="lg"
          //                         simple
          //                         justIcon
          //                       >
          //                         <Refresh />
          //                       </Button>
          //                     </Tooltip>
          //                     <Tooltip
          //                       id="tooltip-top"
          //                       title="Edit"
          //                       placement="bottom"
          //                       classes={{ tooltip: dashboardClasses.tooltip }}
          //                     >
          //                       <Button
          //                         color="info"
          //                         onClick={() => updateModal(index)}
          //                         size="lg"
          //                         simple
          //                         justIcon
          //                       >
          //                         <Edit />
          //                       </Button>
          //                     </Tooltip>
          //                     <Tooltip
          //                       id="tooltip-top"
          //                       title="Delete"
          //                       placement="bottom"
          //                       classes={{ tooltip: dashboardClasses.tooltip }}
          //                     >
          //                       <Button
          //                         color="danger"
          //                         onClick={() => deleteAlert(index)}
          //                         size="lg"
          //                         simple
          //                         justIcon
          //                       >
          //                         <DeleteForever />
          //                       </Button>
          //                     </Tooltip>
          //                   </GridItem>
          //                 </GridContainer>
          //               </GridContainer>
          //             )}
          //           </CardBody>
          //           <CardFooter>
          //             {!api.keyShown && (
          //               <GridContainer justify="center">
          //                 <GridItem
          //                   xs={12}
          //                   sm={12}
          //                   md={12}
          //                   style={{ textAlign: "center" }}
          //                 >
          //                   <div className={classes.center}>
          //                     <Tooltip
          //                       id="tooltip-top"
          //                       title="unlock"
          //                       placement="bottom"
          //                       classes={{ tooltip: dashboardClasses.tooltip }}
          //                     >
          //                       <Button
          //                         color="info"
          //                         onClick={() => toggleKeyShown(index)}
          //                         simple
          //                         justIcon
          //                         size="lg"
          //                       >
          //                         <LockOpen />
          //                       </Button>
          //                     </Tooltip>
          //                   </div>
          //                 </GridItem>
          //               </GridContainer>
          //             )}
          //           </CardFooter>
          //         </Card>
          //       </GridItem>
          //     ))}
          // </>

          <>
            {displayKeys.map((api) => {
              // Get the actual index from the original apiKeys array
              const originalIndex = apiKeys.findIndex(
                (originalApi) => originalApi.AppName === api.AppName
              );

              return (
                <GridItem key={originalIndex} xs={12} sm={12} md={6} lg={4}>
                  <Card style={{ minHeight: "200px" }}>
                    <CardHeader
                      color="quSandBox"
                      stats
                      icon
                      style={{ marginTop: ".625rem" }}
                    >
                      <CardIcon color="quSandBox">{api.AppName}</CardIcon>
                      <h3>
                        {imageSource[api.AppName] ? (
                          <img
                            alt={api.AppName}
                            src={images("./" + imageSource[api.AppName])} // Use imageSource value
                            style={{ maxWidth: "30%", maxHeight: "30%" }}
                          />
                        ) : api.keyType ? (
                          <img
                            alt={api.keyType}
                            src={images(
                              "./" + availableProviders[api.keyType].thumbnail
                            )}
                            style={{ maxWidth: "30%", maxHeight: "30%" }}
                          />
                        ) : (
                          <img
                            alt="qusandbox logo"
                            src={images("./qusandbox_logo_v2.5.png")}
                            style={{ maxWidth: "30%", maxHeight: "30%" }}
                          />
                        )}
                      </h3>
                    </CardHeader>
                    <CardBody>
                      {api.keyShown && (
                        <GridContainer>
                          <GridItem lg={12}>
                            <p>QuSandbox Key: </p>
                          </GridItem>
                          <GridItem lg={12}>
                            <SnackbarContent
                              message={api.APIKey}
                              color="transparent"
                              close="copy"
                            />
                          </GridItem>
                          {api.keyType === "QuSandbox" && api.APIs && (
                            <GridItem lg={12}>
                              <GridContainer>
                                <GridItem lg={4}>Support APIs:</GridItem>
                                <GridItem lg={8}>
                                  <ul>
                                    {api.APIs.map((app, index) => (
                                      <li key={index}>{app}</li>
                                    ))}
                                  </ul>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          )}
                          {api.json &&
                            Object.keys(api.json).map((attribute, index) => (
                              <GridItem lg={12} key={index}>
                                {attribute}: {api.json[attribute]}
                              </GridItem>
                            ))}
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              <Tooltip
                                id="tooltip-top"
                                title="Lock"
                                placement="bottom"
                              >
                                <Button
                                  color="transparent"
                                  onClick={() => toggleKeyHidden(originalIndex)}
                                  size="lg"
                                  simple
                                  justIcon
                                >
                                  <Lock />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                id="tooltip-top"
                                title="Refresh"
                                placement="bottom"
                              >
                                <Button
                                  color="success"
                                  onClick={() => refreshAlert(originalIndex)}
                                  size="lg"
                                  simple
                                  justIcon
                                >
                                  <Refresh />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                id="tooltip-top"
                                title="Edit"
                                placement="bottom"
                              >
                                <Button
                                  color="info"
                                  onClick={() => updateModal(originalIndex)}
                                  size="lg"
                                  simple
                                  justIcon
                                >
                                  <Edit />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                id="tooltip-top"
                                title="Delete"
                                placement="bottom"
                              >
                                <Button
                                  color="danger"
                                  onClick={() => deleteAlert(originalIndex)}
                                  size="lg"
                                  simple
                                  justIcon
                                >
                                  <DeleteForever />
                                </Button>
                              </Tooltip>
                            </GridItem>
                          </GridContainer>
                        </GridContainer>
                      )}
                    </CardBody>
                    <CardFooter>
                      {!api.keyShown && (
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ textAlign: "center" }}
                          >
                            <div className={classes.center}>
                              <Tooltip
                                id="tooltip-top"
                                title="Unlock"
                                placement="bottom"
                              >
                                <Button
                                  color="info"
                                  onClick={() => toggleKeyShown(originalIndex)}
                                  simple
                                  justIcon
                                  size="lg"
                                >
                                  <LockOpen />
                                </Button>
                              </Tooltip>
                            </div>
                          </GridItem>
                        </GridContainer>
                      )}
                    </CardFooter>
                  </Card>
                </GridItem>
              );
            })}
          </>
        )}
      </GridContainer>
    </div>
  );
}
