import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardTitle from "components/Card/CardTitle";
import CardFooter from "components/Card/CardFooter.js";
import VaultCardBody from "components/Card/VaultCardBody";
import EditVaultDialogue from "views/Components/EditVaultDialogue";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LockOpen from "@material-ui/icons/LockOpen";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { getCompanyIconUrl } from "views/Components/Constants.js";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
const useSweetAlertStyle = makeStyles(sweetAlertStyle);

const VaultCard = ({ apiKeyObject, handleDeleteAsset, handleUpdateAsset }) => {
    const sweetAlertClasses = useSweetAlertStyle();
    const [lock, setLock] = useState(true);
    const [modelDetails, setModelDetails] = useState({
        _id: "",
        model: "",
        name: "",
        description: "",
        old_api_key: "",
        new_api_key: "",
    });
    const [editAssetModal, setEditAssetModal] = useState(false);
    const [alert, setAlert] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const assetsPerPage = 5;

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(apiKeyObject.key);
        setSnackbarOpen(true);
    };

    const handleLock = () => setLock(!lock);

    const handleEditAPIKey = () => {
        console.log("In handleEditAPIKey ", apiKeyObject);
        setModelDetails({
            ...modelDetails,
            _id: apiKeyObject._id,
            company: apiKeyObject.company,
            model: apiKeyObject.model,
            name: apiKeyObject.name,
            description: apiKeyObject.description,
            old_api_key: apiKeyObject.key,
        });
        setEditAssetModal(true);
    };

    const deleteAlert = (asset) => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleDelete(asset)}
                onCancel={hideAlert}
                confirmBtnCssClass={
                    sweetAlertClasses.button + " " + sweetAlertClasses.success
                }
                cancelBtnCssClass={
                    sweetAlertClasses.button + " " + sweetAlertClasses.danger
                }
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >
                This action will permanently delete the API Key and will not be recoverable.
            </SweetAlert>
        );
    };

    const handleDelete = async (asset) => {
        await handleDeleteAsset(asset);
        const totalPagesAfterDeletion = Math.ceil((apiKeyObject.length - 1) / assetsPerPage);
        if (currentPage > totalPagesAfterDeletion) {
            setCurrentPage(Math.max(1, totalPagesAfterDeletion));
        }
        hideAlert();
    };

    const hideAlert = () => setAlert(null);

    return (
        <>
            {alert}
            <EditVaultDialogue
                open={editAssetModal}
                handleEditDialogueClose={() => setEditAssetModal(false)}
                modelDetails={modelDetails}
                setModelDetails={setModelDetails}
                handleUpdateAsset={handleUpdateAsset}
            />
            <Card style={{ marginTop: "40px" }}>
                <CardHeader icon text>
                    <CardTitle color="quCreateColor">{apiKeyObject.name}</CardTitle>
                </CardHeader>
                <VaultCardBody
                    image={getCompanyIconUrl(apiKeyObject.company)}
                    locked={lock}
                    handleLock={handleLock}
                    apiKeyObject={apiKeyObject}
                />
                <CardFooter stats style={{ marginTop: "0px", paddingBottom: "10px" }}>
                    <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        {!lock && (
                            <>
                                <Tooltip title="Lock API Key">
                                    <LockOpen style={{ color: "#435966", marginLeft: "10px", cursor: "pointer" }} onClick={handleLock} />
                                </Tooltip>
                                <Tooltip title="Copy API Key">
                                    <IconButton size="small" onClick={handleCopy}>
                                        <FileCopyOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit API Key">
                                    <EditIcon style={{ color: "#435966", marginLeft: "10px", cursor: "pointer" }} onClick={handleEditAPIKey} />
                                </Tooltip>
                                <Tooltip title="Delete API Key">
                                    <DeleteIcon style={{ color: "#435966", marginLeft: "10px", cursor: "pointer" }} onClick={() => deleteAlert(apiKeyObject)} />
                                </Tooltip>
                            </>
                        )}
                    </div>
                </CardFooter>
            </Card>
        </>
    );
};

export default VaultCard;