import React from "react";
import PropTypes from "prop-types";
import { Typography, Avatar, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    typoStyle: {
        // marginTop: "10px",
        marginBottom: "15px",
        paddingLeft: "5px",
        paddingRight: "30px",
        minHeight: "120px",
        maxHeight: "120px",
        overflow: "hidden",
        display: "flex",
        textAlign: "justify",
    },
    root: {
        position: "relative", // Allows absolute positioning inside
        display: "flex",
        padding: "15px",
        paddingRight: "80px",

    },
    avatarContainer: {
        position: "absolute",
        top: "10px", // Adjust spacing from the top
        right: "10px", // Adjust spacing from the right
        padding: "5px",
    },
    avatar: {
        width: 80,  // Set desired width
        height: 45, // Set desired height
    },
});


const NewCardBody = ({ className, children, image }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.root} ${className}`}>
            {/* Tooltip only for Typography */}
            <Tooltip title={children} arrow>
                <Typography variant="body2" className={`${classes.typoStyle} ${className}`}>
                    {children}
                </Typography>
            </Tooltip>

            {/* Avatar fixed at the top-right with an image */}
            <div className={classes.avatarContainer}>
                <img src={image} alt="avatar" className={classes.avatar} />
                {/* <Avatar className={classes.avatar} src={image} variant="square" /> */}
            </div>
        </div>
    );
};

NewCardBody.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    image: PropTypes.string, // New prop for the image URL
};

export default NewCardBody;
