import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
  CircularProgress,
  makeStyles,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import {
  Description,
  Edit,
  Note,
  ViewAgenda,
  DoneAll,
} from "@material-ui/icons";
import NotificationContext from "views/Components/Context.js";
import Resources from "../Resources";
import LectureContentReview from "./LectureContentReview";
import LectureOutlineReview from "./LectureOutlineReview";
import SpeakerNoteReview from "../SpeakerNoteReview";
import LectureStructureReview from "./LectureStructureReview";
import ProjectDeliverables from "../ProjectDeliverables";

const useStyles = makeStyles((theme) => ({
  stepIconRoot: {
    "&$completed": {
      color: theme.palette.success.main,
    },
    "&$active": {
      color: theme.palette.primary.main,
    },
    "&$error": {
      color: theme.palette.error.main,
    },
  },
  stepperRoot: {
    "& .MuiStepIcon-root": {
      color: "#435966", // Gray color for all steps (non-completed)
    },
    "& .MuiStepLabel-root.Mui-completed .MuiStepIcon-root": {
      color: "#435966", // Color for completed step icons (greenish color)
    },
    "& .MuiStepLabel-root.Mui-active .MuiStepIcon-root": {
      color: "#435966", // Active step icon color (greenish color)
    },
    "& .MuiStepLabel-root": {
      color: "#D3D3D3", // Gray color for all step labels (non-completed)
    },
    "& .MuiStepLabel-root.Mui-active": {
      color: "#435966", // Active step label color (greenish color)
    },
    "& .MuiStepLabel-root.Mui-completed": {
      color: "#435966", // Completed step label color (greenish color)
    },
  },
  stepLabel: {
    color: theme.palette.text.primary,
  },
  progressBar: {
    margin: theme.spacing(2, 0),
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
  active: {},
  completed: {},
  error: {},
}));

const lecture_steps = [
  { label: "Upload Resources", icon: <Description />, lockedMessage: "" },
  {
    label: "Outline Review",
    icon: <ViewAgenda />,
    lockedMessage: "Please submit the resources to move on to the next step.",
  },
  {
    label: "Content Review",
    icon: <Edit />,
    lockedMessage:
      "Complete outline review to generate slides for content review.",
  },
  {
    label: "Speaker Note Review",
    icon: <Note />,
    lockedMessage: "Complete content review to proceed to speaker note review.",
  },
  {
    label: "Slide Structure Review",
    icon: <ViewAgenda />,
    lockedMessage: "Complete speaker note review to unlock structure review.",
  },
  {
    label: "Deliverables",
    icon: <DoneAll />,
    lockedMessage: "Complete structure review to unlock deliverables.",
  },
];

function StepIcon(props) {
  const classes = useStyles();
  const { active, completed, icon } = props;

  return (
    <div
      className={`${classes.stepIconRoot} ${active ? classes.active : ""} ${completed ? classes.completed : ""
        }`}
    >
      {icon}
    </div>
  );
}

export default function LectureBoard(props) {
  const classes = useStyles();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([0, 1, 2, 3, 4, 5]);
  const [boardLoading, setBoardLoading] = useState(false); // Initially in loading state
  const { showNotification } = React.useContext(NotificationContext);
  let project_name = "";
  if (location.state && location.state.project_name) {
    project_name = location.state.project_name;
  }

  const { lectureID } = props.match.params;

  const handleStepClick = (index) => {
    if (visitedSteps.includes(index)) {
      setActiveStep(index);
    } else {
      const message =
        lecture_steps[index].lockedMessage || "This step is locked.";
      showNotification(message, "danger");
    }
  };

  useEffect(() => {
    if (!visitedSteps.includes(activeStep)) {
      setVisitedSteps((prev) => [...prev, activeStep]);
    }
  }, [activeStep, visitedSteps]);

  const calculateProgress = () =>
    (visitedSteps.length / lecture_steps.length) * 100;

  const renderContent = () => {
    switch (lecture_steps[activeStep].label) {
      case "Upload Resources":
        return (
          <Resources
            projectID={lectureID}
            setActiveStep={setActiveStep}
            projectType="lecture_designer"
          // setBoardLoading={setBoardLoading}
          />
        );
      case "Outline Review":
        return (
          <LectureOutlineReview
            projectID={lectureID}
            setActiveStep={setActiveStep}
          />
        );
      case "Content Review":
        return (
          <LectureContentReview
            projectID={lectureID}
            setActiveStep={setActiveStep}
          />
        );
      case "Speaker Note Review":
        return (
          <SpeakerNoteReview
            projectID={lectureID}
            setActiveStep={setActiveStep}
          />
        );
      case "Slide Structure Review":
        return (
          <LectureStructureReview
            projectID={lectureID}
            setActiveStep={setActiveStep}
          />
        );
      case "Deliverables":
        return (
          <ProjectDeliverables
            projectID={lectureID}
            projectType="lecture"
            setActiveStep={setActiveStep}
          />
        );
      default:
        return null;
    }
  };

  if (boardLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          Loading Lecture Board...
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      
      {/* AppBar with Navigation Buttons and Title */}
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Typography
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bold",
                flexShrink: 0,
                maxWidth: "45%", // Ensures it does not exceed the container width
                wordBreak: "break-word", // Allows long words to break and wrap to the next line if necessary
                overflow: "hidden", // Hides any overflow if text is too long
                whiteSpace: "normal", // Ensures the text wraps
              }}
            >
              {project_name}
            </Typography>

            <Stepper
              activeStep={activeStep}
              alternativeLabel
              size="small"
              className={classes.stepperRoot}
              style={{ flexGrow: 1 }}
            >
              {lecture_steps.map((step, index) => (
                <Step
                  key={step.label}
                  disabled={index > activeStep}
                  onClick={() => handleStepClick(index)}
                >
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Toolbar>
      </AppBar>

      <Box mt={3}>{renderContent()}</Box>
    </Box>
  );
}
