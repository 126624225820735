import React, { useEffect, useState } from "react";
import {
    Grid, Dialog, TextField,
    DialogActions, DialogContent, CircularProgress, FormGroup, FormControlLabel, Checkbox,
    Typography, Divider, Stepper, Step, StepLabel, Box, FormControl, InputLabel, Select, MenuItem, Tabs, Tab
} from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import Button from "components/CustomButtons/Button.js";
import NotificationContext from "views/Components/Context.js";


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { stepperStyles, QontoConnector } from "views/Styles/stepperStyles";
import { COMPANY_MODELS, getCompanyIcon } from "views/Components/Constants.js";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import { validateKeyAPI } from "views/APIs/APIUtility";
import { fetchQuAPIVaultAPI } from "views/APIs/APIUtility";

const useStyles = makeStyles((theme) => ({
    ...styles,
    ...stepperStyles(theme),
    customCheckbox: {
        color: quCreateColor,
        "&.Mui-checked": {
            color: quCreateColor,
        },
    },
}));

export default function SubmitLabModal({
    reviewDialog,
    handleReviewDialogClose,
    lab,
    modelDetails,
    setModelDetails,
    submitHandler,
}) {
    const classes = useStyles();
    const { showNotification } = React.useContext(NotificationContext);
    const dialogStages = [
        "Model Selection",
        "Review Lab",
        "Submit",
    ];
    const stepperDisplayStages = [
        "Model Selection",
        "Review Lab",
    ];
    const [error, setError] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [currentDialog, setCurrentDialog] = useState(dialogStages[0]);
    const [saveKey, setSaveKey] = useState(false);
    const [loading, setLoading] = useState({ loading: false, message: "" });

    const [companies, setCompanies] = useState(Object.keys(COMPANY_MODELS));
    const [allKeys, setAllKeys] = useState([]);
    const modelOptions = modelDetails.company ? COMPANY_MODELS[modelDetails.company] : [];

    useEffect(() => {
        async function fetchKeys() {
            try {
                const fetchedKeys = await fetchQuAPIVaultAPI();
                console.log("Keys are:", fetchedKeys);

                setAllKeys(fetchedKeys); // Store the fetched keys
            } catch (error) {
                console.error("Error in fetchKeys:", error);
                showNotification("Error fetching keys", "danger");
            }
        }
        fetchKeys();
    }, []);

    const handleNavigation = (event, newValue) => {
        if (!((tabValue === 0 && newValue === 0) || (tabValue === 1 && newValue === 1))) {
            setTabValue(newValue);
            setModelDetails({ company: "", model: "", apiKey: "", name: "" });
            setError("");
        }
    };

    const handleKeySelection = (key) => {
        const selected = allKeys.find((k) => k.name === key);
        console.log("Selected", selected);
        if (selected) {
            setModelDetails({
                company: selected.company,
                model: selected.model,
                apiKey: selected.key,
                name: selected.name,
                description: selected.description,
            });
        }
    };

    const renderDialogContent = () => {
        switch (currentDialog) {
            case "Review Lab":
                return (
                    <div>
                        <Box data-color-mode="light" mb={1}>
                            <Typography variant="body1" gutterBottom>
                                The following model information will serve as the foundation for generating the lab.
                            </Typography>
                            <Box mt={2}>
                                <Typography>Company: {modelDetails.company}</Typography>
                                <Typography>Model: {modelDetails.model}</Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <Box data-color-mode="light" mt={1}>
                            <Typography variant="body1" gutterBottom>
                                The technical specifications outlined below will be used as the basis for lab creation.
                            </Typography>
                            <Box mt={2}>
                                {lab && (
                                    <MDEditor preview="preview" disabled value={lab.technical_specifications} hideToolbar={true} height={500} />
                                )}
                            </Box>
                        </Box>
                    </div>

                );
            case "Model Selection":
                return (
                    <Grid container spacing={2} alignItems="flex-start">
                        {/* Tabs for Model Selection */}
                        <Grid container justifyContent="center" style={{ marginBottom: "16px" }}>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Tabs value={tabValue} onChange={handleNavigation} TabIndicatorProps={{
                                    style: {
                                        backgroundColor: quCreateColor
                                    }
                                }}>
                                    <Tab label="Load Key" value={0} />
                                    <Tab label="Create New Key" value={1} />
                                </Tabs>
                            </Grid>
                        </Grid>

                        {/* Conditionally render content based on active tab */}
                        {tabValue === 1 ? (
                            <Grid container spacing={2} alignItems="flex-start">
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="company-label" required>Select Company</InputLabel>
                                        <Select
                                            labelId="company-label"
                                            id="company-select"
                                            value={modelDetails.company}
                                            onChange={(e) => {
                                                setModelDetails((prev) => ({
                                                    ...prev, // Keep other values
                                                    company: e.target.value,
                                                    model: "",
                                                    apiKey: "",
                                                    name: "",
                                                }));
                                            }}
                                        >
                                            <MenuItem value="">-- Select Company --</MenuItem>
                                            {companies.map((comp) => (
                                                <MenuItem key={comp} value={comp}>
                                                    {getCompanyIcon(comp)} {/* Ensure this function returns an icon */}
                                                    {comp.toUpperCase()}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>


                                {modelDetails.company && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel id="model-label" required>Model</InputLabel>
                                            <Select
                                                labelId="model-label"
                                                id="model-select"
                                                value={modelDetails.model}
                                                // onChange={(e) => setModel(e.target.value)}
                                                onChange={(e) => {
                                                    setModelDetails({ ...modelDetails, model: e.target.value, apiKey: "" });
                                                }}
                                            >
                                                <MenuItem value="">-- Select Model --</MenuItem>
                                                {modelOptions.map((m) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                {modelDetails.model && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            margin="normal"
                                            label="API Key"
                                            type="text"
                                            placeholder="Enter your API key"
                                            value={modelDetails.apiKey}
                                            onChange={(e) => setModelDetails({
                                                ...modelDetails,
                                                apiKey: e.target.value
                                            })}
                                        // onChange={(e) => setApiKey(e.target.value)}
                                        />
                                    </Grid>
                                )}
                                {modelDetails.model && saveKey && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            margin="normal"
                                            label="API Key Name"
                                            type="text"
                                            placeholder="Enter your API key name"
                                            value={modelDetails.name || ""}
                                            onChange={(e) =>
                                                setModelDetails({
                                                    ...modelDetails,
                                                    name: e.target.value
                                                })
                                            }
                                        />
                                    </Grid>
                                )}

                                {modelDetails.model && saveKey && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="API Key Description"
                                            type="text"
                                            placeholder="Enter your API key description"
                                            value={modelDetails.description || ""}
                                            onChange={(e) =>
                                                setModelDetails({
                                                    ...modelDetails,
                                                    description: e.target.value
                                                })
                                            }
                                        />
                                    </Grid>
                                )}

                                {error && (
                                    <Grid item xs={12}>
                                        <Typography color="error" variant="body2">
                                            {error}
                                        </Typography>
                                    </Grid>
                                )}

                            </Grid>
                        ) : (
                            <Grid container spacing={2} alignItems="flex-start">
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="company-label" required>Select API Key</InputLabel>
                                        <Select
                                            labelId="key-label"
                                            id="key-select"
                                            value={modelDetails.company}
                                            onChange={(e) => {
                                                handleKeySelection(e.target.value);
                                                // setCompany(e.target.value);
                                                // setModel("");
                                                // setModelDetails({ model: "", apiKey: "", apiKeyName: "" });
                                            }}
                                        >
                                            <MenuItem value="">-- Select Key --</MenuItem>
                                            {allKeys.map((key) => (
                                                <MenuItem key={key.name} value={key.name}>
                                                    {getCompanyIcon(key.company)}
                                                    {key.name.toUpperCase()}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {modelDetails.company && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel id="model-label" required>Model</InputLabel>
                                            <Select
                                                labelId="model-label"
                                                id="model-select"
                                                value={modelDetails.model}
                                                // onChange={(e) => setModel(e.target.value)}
                                                onChange={(e) => {
                                                    setModelDetails({ ...modelDetails, model: e.target.value });
                                                }}
                                            >
                                                <MenuItem value="">-- Select Model --</MenuItem>
                                                {modelOptions.map((m) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                {modelDetails.model && (
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled
                                            fullWidth
                                            required
                                            margin="normal"
                                            label="API Key"
                                            type="text"
                                            placeholder="Enter your API key"
                                            value={modelDetails.apiKey}
                                        />
                                    </Grid>
                                )}
                                {error && (
                                    <Grid item xs={12}>
                                        <Typography color="error" variant="body2">
                                            {error}
                                        </Typography>
                                    </Grid>
                                )}


                            </Grid>

                        )}

                    </Grid>
                );
            case "Submit":
                return (
                    <div style={{ display: "flex" }}>
                        <CircularProgress size={20} /> &nbsp;
                        <Typography variant="body1">{loading.message}</Typography>
                    </div>
                );
            default:
                return null;
        }
    };

    const handleStep1 = async () => {
        try {
            setError("");
            const res = await validateKey();
            if (res) {
                setActiveStep(1);
                setCurrentDialog(dialogStages[1]);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    const handleStep2 = async () => {
        setLoading({ loading: true, message: "Submitting Lab..." });
        setCurrentDialog(dialogStages[2]);
        const saveAPIKEY = tabValue === 1 && saveKey;
        await submitHandler(saveAPIKEY);
        _handleReviewDialogClose();
        setLoading({ loading: false, message: "" });
    };

    const handleBack = () => {
        if (activeStep === 1) {
            setActiveStep(0);
            setCurrentDialog(dialogStages[0]);
        }
    };

    const validateKey = async () => {
        if (modelDetails.company.trim() === "") {
            setError("Company is required.");
            return false;
        }
        if (!modelDetails.model || modelDetails.model.trim() === "") {
            setError("Model is required.");
            return false;
        }
        if (!modelDetails.apiKey || modelDetails.apiKey.trim() === "") {
            setError("API Key is required.");
            return false;
        }
        if (saveKey && (!modelDetails.name || modelDetails.name.trim() === "")) {
            setError("API Key Name is required.");
            return false;
        }
        const formData = new FormData();
        formData.append("model", modelDetails.model);
        formData.append("key", modelDetails.apiKey);
        const response = await validateKeyAPI(formData);
        console.log("Response", response);
        if (response) {
            // setValidKey(true);
            return true;
        }
        else {
            setError("The provided API key is invalid or does not support the selected model. Please verify and try again.");
            return false;
        }
    }

    const _handleReviewDialogClose = () => {
        handleReviewDialogClose();
        // setCompany("");
        setActiveStep(0);
        setError("");
        setCurrentDialog(dialogStages[0]);
    };

    return (
        <Dialog open={reviewDialog} onClose={_handleReviewDialogClose} aria-labelledby="form-dialog-title" maxWidth="lg" fullWidth>
            {/* Stepper Component */}
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<QontoConnector />}
                className={classes.stepperRoot}
            >
                {stepperDisplayStages.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconProps={{
                                classes: {
                                    root: classes.stepIcon, // Default icon color
                                    active: classes.activeStepIcon, // Active step circle
                                    completed: classes.completedStepIcon, // Completed step color
                                }
                            }}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Divider />
            {/* <DialogTitle id="alert-dialog-title">Review Lab</DialogTitle> */}
            <DialogContent style={{ marginTop: "15px" }}>{renderDialogContent()}</DialogContent>
            <DialogActions
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px 24px",
                    width: "100%",
                }}
            >
                {/* Conditionally render the checkbox on the left */}
                {currentDialog === "Model Selection" && tabValue === 1 && (
                    <Box display="flex" justifyContent="flex-start" width="100%" mb={2}>
                        <FormGroup style={{ flex: 1 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.customCheckbox}
                                        checked={saveKey} // Ensure the checkbox reflects the state
                                        onChange={() => setSaveKey((prev) => !prev)} // Toggle state correctly
                                    />
                                }
                                label="Save APIKey to QUApiVault"
                                style={{
                                    whiteSpace: "nowrap",
                                    marginRight: "16px",
                                    color: "#000", // Ensure black text
                                    fontWeight: "500", // Make it more readable
                                }}
                            />

                        </FormGroup>
                    </Box>
                )}

                {/* Buttons aligned to the right */}
                <div style={{ display: "flex", gap: "12px", justifyContent: "flex-end", width: "100%" }}>
                    {currentDialog !== "Model Selection" && (
                        <Button onClick={handleBack} color="quCreateColor" disabled={loading.loading}>
                            Back
                        </Button>
                    )}
                    <Button onClick={handleReviewDialogClose} color="quSandBoxColor" disabled={loading.loading}>
                        Close
                    </Button>
                    <Button
                        onClick={() => {
                            switch (currentDialog) {
                                case "Model Selection":
                                    return handleStep1();
                                case "Review Lab":
                                    return handleStep2();
                                default:
                                    return null;
                            }
                        }}
                        color="quCreateColor" disabled={loading.loading}
                    >
                        {currentDialog === "Review Lab" ? "Submit" : "Next"}
                    </Button>
                </div>
            </DialogActions>
        </Dialog >
    )
};