import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Box, Typography } from "@material-ui/core";
import { useRouteMatch, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DeliverableTiles from "components/Tiles/DeliverableTiles";
import LinkIcon from '@material-ui/icons/Link';
import ImageIcon from '@material-ui/icons/Image';
import NotesIcon from '@material-ui/icons/Notes';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from "components/CustomButtons/Button.js";

import SlideshowIcon from '@material-ui/icons/Slideshow';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import InfoIcon from '@material-ui/icons/Info';
import LaptopIcon from '@material-ui/icons/Laptop';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Slides from "views/Dashboard/Slides";
import Labs from "./Labs";
import VideoPlayer from "./Videos";
import Assessment from "./Assessment"
import NotificationContext from "views/Components/Context.js";
import { DoneAll } from "@material-ui/icons";
import { Auth } from "aws-amplify";
import { fetchCourseAPI, submitModuleForPublishing } from "views/APIs/APIUtility";
import ExitReviewButton from "components/CustomButtons/ExitReviewButton";
import Alert from "views/Components/Alert";
import ModuleInformationContentReview from "./Course/ModuleInformationContentReview";

export default function Deliverables(props) {
    const history = useHistory();
    const courseID = props.courseID;
    const moduleID = props.moduleID;
    const { showNotification } = React.useContext(NotificationContext);

    const [activeTile, setActiveTile] = useState(null);
    const handleNavigation = (type) => {
        setActiveTile(type);
    };

    const [user, setUser] = useState();

    const [slides, setSlides] = useState([]);
    const [labLinks, setLabLinks] = useState([]);
    const [videos, setVideos] = useState([]);
    const [assessment, setAssessment] = useState([]);
    const [notes, setNotes] = useState([]);
    const [modulesLoading, setModulesLoading] = useState(true);
    const [showAssessment, setShowAssessment] = useState(true);
    const [showStatus, setShowStatus] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const setDeliverableFromCourse = (data) => {
        const modules = data.modules;
        const module = modules.find(module => module.module_id === moduleID);
        setShowAssessment(module.assessment);
        setShowStatus(module.status);

        const resources = module.pre_processed_deliverables;
        const slides = resources.filter(resource => resource.resource_type === "Slide_Generated");
        const labLinks = resources.filter(resource => resource.resource_type === "Link");
        const videos = resources.filter(resource => resource.resource_type === "Video");
        const assessment = resources.filter(resource => resource.resource_type === "Assessment");
        const notes = resources.filter(resource => resource.resource_type === "Note");

        setSlides(slides);
        setLabLinks(labLinks);
        setVideos(videos);
        setAssessment(assessment);
        setNotes(notes);







        // next step: pass the files to the appropriate component
        // check if delete resource works. it is yet to be implemented.
    };
    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const data = await fetchCourseAPI(courseID);
                setDeliverableFromCourse(data);
            } catch (error) {
                showNotification("Error fetching course data", "danger");
            } finally {
                setModulesLoading(false);
            }
        };
        fetchCourseData();
    }, [courseID]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => setUser(user))
            .catch((err) => {
                if (err === "not authenticated") {
                    history.push("/auth/login-page");
                }
            });
    }, [history]);

    const renderContent = () => {
        switch (activeTile) {
            case "Slides":
                return <Slides courseID={props.courseID} moduleID={props.moduleID} filetype="Slide_Generated" slides={slides} />;
            case "Module Information":
                return <ModuleInformationContentReview courseID={props.courseID} moduleID={props.moduleID}
                    filetype="Note" moduleInformation={notes} course_design_step={11} setActiveTab="Module Information" activeStatusData={showStatus} />;
            case "Labs":
                return <Labs courseID={props.courseID} moduleID={props.moduleID} filetype="Link" links={labLinks} />;
            case "Video":
                return <VideoPlayer courseID={props.courseID} moduleID={props.moduleID} filetype="Video" videos={videos} />;
            case "Assessment":
                return <Assessment courseID={props.courseID} moduleID={props.moduleID} filetype="Assessment" assessment={assessment} />;
            default:
                return null;
        }
    };

    const publishModuleHandler = async () => {
        const formData = new FormData();
        formData.append("course_id", props.courseID);
        formData.append("module_id", props.moduleID);

        try {
            const data = await submitModuleForPublishing(formData);
            showNotification("The module has been marked for publishing.", "quSandBoxColor")
            setTimeout(() => {
                window.location.reload();
            }, 2000); // Adjust the time as needed (2000ms = 2 seconds)
        }
        catch (error) {
            showNotification("Error submitting module", "danger");
        }
        finally {
        }
    };

    const unpublishModuleHandler = async () => {
        showNotification("Module marked for unpublish", "success");
        //Todo: Implement remove from post_processing_deliverables queue 
    };

    const verifySubmission = async () => {
        if (showStatus === "Published") {
            setShowAlert(true);
        }
        else {
            publishModuleHandler();
        }
    };

    const handleConfirm = () => {
        setShowAlert(false); // Close the alert
        publishModuleHandler();
    };

    const handleCancel = () => {
        setShowAlert(false); // Close the alert
    };

    if (modulesLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
            >
                <CircularProgress color="quCreateColor" />
                <Typography variant="h6" style={{ marginLeft: 16 }}>
                    Loading Deliverables...
                </Typography>
            </Box>
        );
    }

    return (
        <Box mt={2}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DeliverableTiles
                        active={activeTile}
                        color="warning"
                        alignCenter
                        tabs={[
                            { heading: "Slides", icon: SlideshowIcon, description: "Contains all slides", onClick: () => handleNavigation('Slides') },
                            { heading: "Videos", icon: VideoLibraryIcon, description: "Contains all videos", onClick: () => handleNavigation('Video') },
                            { heading: "Module Information", icon: InfoIcon, description: "Contains module information", onClick: () => handleNavigation('Module Information') },
                            { heading: "Labs", icon: LaptopIcon, description: "Contains all labs", onClick: () => handleNavigation('Labs') },
                            ...(showAssessment
                                ? [{ heading: "Assessment", icon: AssessmentIcon, description: "Contains all assessments", onClick: () => handleNavigation('Assessment') }]
                                : [])
                        ]}
                    />
                </Grid>
            </Grid>
            {renderContent()}

            <Grid item xs={12} sm={12}>
                <Grid container alignItems="center" spacing={3}>
                    {/* Left-aligned Button */}
                    <Grid item xs={12} sm={4} style={{ display: "flex", justifyContent: "flex-start" }}>
                        <ExitReviewButton courseID={props.courseID} />
                    </Grid>

                    {/* Center-aligned Content (if needed in future) */}
                    <Grid item xs={12} sm={4} style={{ display: "flex", justifyContent: "center" }}>
                    </Grid>

                    {/* Right-aligned Buttons */}
                    <Grid item xs={12} sm={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                        {showStatus === "Published" && (
                            <Button
                                color="warning"
                                variant="contained"
                                style={{ marginRight: "10px" }}
                                onClick={unpublishModuleHandler}
                            >
                                Unpublish Module
                            </Button>
                        )}

                        <Button
                            color="info"
                            variant="contained"
                            onClick={verifySubmission}
                        >
                            <DoneAll /> &nbsp; Publish Module
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            {showAlert && (
                <Alert
                    title="Are you sure?"
                    warning={true}
                    showCancel={true}
                    cancelBtnText="No"
                    confirmBtnText="Yes"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                >
                    You already have a submission for this module. If you wish to make another submission, your module will be republished with new content.
                </Alert>
            )}
        </ Box>
    );
}
