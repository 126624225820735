import { StepConnector } from "@material-ui/core";

import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import { withStyles } from "@material-ui/core/styles";
// stepperStyles.js
export const stepperStyles = (theme) => ({
    stepIcon: { //impportant
        color: "#4D8FA1 !important", // Default (inactive) icon color 
    },
    activeStepIcon: {
        color: quCreateColor + " !important", // Active step icon color
        backgroundColor: quCreateColor + " !important", // Ensures background is colored
        borderRadius: "50%", // Makes it a perfect circle
        width: "30px", // Adjust size as needed
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    completedStepIcon: { //Important
        color: quCreateColor + " !important", // Completed step icon color
    },
});

export const QontoConnector = withStyles({
    active: { //Important
        "& $line": {
            borderColor: quCreateColor, // Active step line color
        },
    },
    completed: { // Important
        "& $line": {
            borderColor: quCreateColor, // Ensure completed steps have the same color
        },
    },
    line: { // Important
        borderColor: "#dcdcdc", // Default inactive step color (gray)
        borderTopWidth: 3,
        borderRadius: 1,
    },
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
})(StepConnector);