import React, { useState, useEffect } from 'react';
import MDEditor from '@uiw/react-md-editor';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Grid, Typography, Divider, Box, CircularProgress,
    Tooltip,
} from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { InfoOutlined } from '@material-ui/icons';
import { is } from 'date-fns/locale';

export default function QuStreamlineDialog({ metaPromptDialogOpen, metaPromptDialogClose, originalMetaPrompt, metaPrompt, setMetaPrompt, displayMetaPromptParameters, notRequiredParameters,
    handleMetaPromptChange, checkForDisabled, _handleEnhancePrompt, _handleSubmission, isDisabled, setIsDisabled }) {

    const [loading, setLoading] = useState({ loading: false, message: "" });
    const category = localStorage.getItem("userCategory");


    const handleEnhancePrompt = async () => {
        setIsDisabled(true);
        setLoading({ loading: true, message: "Enhancing Prompt..." });
        await _handleEnhancePrompt();
        setLoading({ loading: false, message: "" });
        // buttonsDisabled();
    };

    const buttonsDisabled = () => {
        if (loading.loading) {
            return setIsDisabled(true);
        }
        setIsDisabled(checkForDisabled());
    };

    const handleClose = () => {
        setLoading({ loading: false, message: "" });
        metaPromptDialogClose();
    };

    const handleConfirm = () => {
        setMetaPrompt(metaPrompt);
        _handleSubmission();
    };

    const _handleMetaPromptChange = (value) => {
        handleMetaPromptChange(value);
        // buttonsDisabled();
    };

    const getPreviewValue = () => {
        if (category === "super_admin") { return "edit" }
        else { return "preview" }
    };

    const handleButtonDisable = () => {
        if (category === "super_admin") { return isDisabled }
        else return true;
    };

    const handleResetPrompt = () => {
        _handleMetaPromptChange(originalMetaPrompt);
    };

    return (

        <Dialog
            open={metaPromptDialogOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">
                <Typography variant="h5">Edit the Prompt</Typography>
                <Divider />
            </DialogTitle>

            <DialogContent>
                {loading.loading ? (
                    // Show loading state
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                        <CircularProgress color="quCreateColor" />
                        <Typography variant="h6" style={{ marginLeft: 16 }}>
                            {loading.message}
                        </Typography>
                    </Box>
                ) : (
                    // Show dialog content
                    <>
                        <Grid container>

                            <Grid item xs={12}>
                                <MDEditor
                                    value={metaPrompt}
                                    onChange={_handleMetaPromptChange}
                                    height={250}
                                    hideToolbar={getPreviewValue() === "preview"}
                                    {...(getPreviewValue() === "preview" ? { preview: "preview" } : {})}
                                />
                            </Grid>

                        </Grid>

                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <Typography variant="body1">
                                    Required Parameters for QuStreamline:
                                </Typography>
                            </Grid>


                            {displayMetaPromptParameters.length > 0 && (
                                <Grid item xs={12}>
                                    {displayMetaPromptParameters.map((param, index) => {
                                        // Define tooltip messages based on param.message
                                        let tooltipMessage = "";
                                        if (param.message === "Missing parameters") {
                                            tooltipMessage = `Ensure the parameter is present in the prompt enclosed in curly brackets. Example: {${param.name}}. This is a required parameter.`;
                                        } else if (param.message === "Extra parameters") {
                                            tooltipMessage = `The parameter appears more than once in the prompt. Ensure it occurs exactly once. This is a required parameter.`;
                                        }

                                        return (
                                            <Grid container alignItems="center" spacing={1} key={index}>
                                                {/* Status Icon */}
                                                <Grid item>
                                                    {param.message === "Correct" ? (
                                                        <CheckIcon style={{ color: "green" }} />
                                                    ) : (
                                                        <CloseIcon style={{ color: "red" }} />
                                                    )}
                                                </Grid>

                                                {/* Parameter Name */}
                                                <Grid item>
                                                    <Typography variant="body1">{param.name}</Typography>
                                                </Grid>

                                                {/* Message */}
                                                <Grid item>
                                                    <Typography variant="body2" style={{ color: "gray" }}>
                                                        {param.message}
                                                    </Typography>
                                                </Grid>

                                                {/* Tooltip Icon (Only if message is Missing/Extra parameters) */}
                                                {param.message !== "Correct" && (
                                                    <Grid item>
                                                        <Tooltip title={tooltipMessage}>
                                                            <InfoOutlined
                                                                style={{ color: "gray", marginTop: "5px" }}
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            )}


                            {notRequiredParameters.length > 0 && (
                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <Typography variant="body1">Not Required Parameters:</Typography>
                                    <div>
                                        {notRequiredParameters.map((param, index) => {
                                            const tooltipMessage = `{${param.name}} is not required in the prompt. Kindly remove it.`;

                                            return (
                                                <Grid container alignItems="center" spacing={1} key={index}>
                                                    {/* Status Icon */}
                                                    <Grid item>
                                                        <CloseIcon style={{ color: "red" }} />
                                                    </Grid>

                                                    {/* Parameter Name */}
                                                    <Grid item>
                                                        <Typography variant="body2">{param.name}</Typography>
                                                    </Grid>

                                                    {/* Message */}
                                                    <Grid item>
                                                        <Typography variant="body2" style={{ color: "gray" }}>
                                                            Extra parameters
                                                        </Typography>
                                                    </Grid>

                                                    {/* Tooltip Icon */}
                                                    <Grid item>
                                                        <Tooltip title={tooltipMessage}>
                                                            <InfoOutlined
                                                                style={{ color: "gray", marginTop: "5px" }}
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </div>
                                </Grid>
                            )}

                        </Grid>
                    </>
                )}
            </DialogContent>


            <DialogActions>
                <Button onClick={handleClose} color="quCreateColor">Cancel</Button>
                <Button color="quCreateColor" onClick={handleResetPrompt} disabled={(getPreviewValue() === "preview" ? true : false)}>Reset Prompt</Button>
                <Button color="quCreateColor" disabled={handleButtonDisable()} onClick={handleEnhancePrompt}>Enhance Prompt</Button>
                <Button color="quCreateColor" disabled={handleButtonDisable()} onClick={handleConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}
