import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SlidesContentReview from "../SlidesContentReview";
import ModuleInformationContentReview from "./ModuleInformationContentReview";
import LabsContentReview from "../LabsContentReview";
import Button from "components/CustomButtons/Button";
import { Auth } from "aws-amplify";
import NotificationContext from "views/Components/Context.js";
import { Divider, Grid, Typography, Box, CircularProgress } from "@material-ui/core";
import { fetchCourseAPI } from "views/APIs/APIUtility";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    ...styles,
})

export default function ContentReview({ courseID, moduleID, activeStatusData, moduleStatus }) {
    const classes = useStyles();
    const { showNotification } = React.useContext(NotificationContext);
    const [user, setUser] = useState();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState("Module Information");

    const [moduleInformation, setModuleInformation] = useState([]);
    const [slides, setSlides] = useState([]);
    const [labs, setLabs] = useState([]);
    const [loading, setLoading] = useState({ loading: false, message: "" });
    const [module, setModule] = useState({});

    const setContentReviewFromCourse = (data) => {
        const modules = data.modules;
        const module = modules.find(module => module.module_id === moduleID);
        const resources = module.pre_processed_content;
        const slides = resources.filter(resource => resource.resource_type === "Slide_Content");
        const links = resources.filter(resource => resource.resource_type === "Link");
        const notes = resources.filter(resource => resource.resource_type === "Note");
        setModule(module);
        setSlides(slides);
        setLabs(links);
        setModuleInformation(notes);
    };

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                setLoading({ loading: true, message: "Fetching content information..." });
                const data = await fetchCourseAPI(courseID);
                setContentReviewFromCourse(data);
            } catch (error) {
                showNotification("Error fetching course data", "danger");
            } finally {
                // setModulesLoading(false);
                setLoading({ loading: false, message: "" });
            }
        };
        fetchCourseData();
    }, [courseID]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => setUser(user))
            .catch((err) => {
                if (err === "not authenticated") {
                    history.push("/auth/login-page");
                }
            });
    }, [history]);

    const renderContent = () => {
        if (loading.loading) {
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="60vh"
                >
                    <CircularProgress color="quCreateColor" className={classes.loaderClass} />
                    <Typography variant="h6" style={{ marginLeft: 16 }}>
                        {loading.message}
                    </Typography>
                </Box>
            );
        }

        switch (activeTab) {
            case "Module Information":
                return <ModuleInformationContentReview
                    courseID={courseID} moduleID={moduleID} filetype="Note"
                    course_design_step={5} setActiveTab={setActiveTab} activeStatusData={activeStatusData}
                    moduleStatus={moduleStatus} moduleInformation={moduleInformation} setModuleInformation={setModuleInformation}

                />;
            case "Slides":
                return <SlidesContentReview courseID={courseID} moduleID={moduleID} filetype="Slide_Content"
                    slides={slides} setActiveTab={setActiveTab} moduleStatus={moduleStatus}
                    module={module}
                />;
            // case "Labs":
            //     return <LabsContentReview courseID={props.courseID} moduleID={props.moduleID} filetype="Link" links={labs} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <div>
                <Grid container display="flex" justify="space-between" style={{ marginTop: "20px" }}>
                    <Grid item xs={4}>
                        <Typography variant="body1" style={{ marginTop: "10px", fontWeight: "bold", fontSize: "20px" }}>Content Review</Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10px" }}>
                        <Button color="quCreateColor" style={activeTab === "Module Information" ? { textDecoration: "underline" } : {}} onClick={() => setActiveTab("Module Information")}>Module Information</Button>
                        <Button color="quCreateColor" style={activeTab === "Slides" ? { textDecoration: "underline" } : {}} onClick={() => setActiveTab("Slides")}>Slides</Button>
                        {/* <Button color="quSandBoxColor" style={activeTab === "Labs" ? { textDecoration: "underline" } : {}} onClick={() => setActiveTab("Labs")}>Labs</Button> */}
                    </Grid>
                </Grid>
                <Divider />
            </div>

            {renderContent()}
        </div>
    );
}
