import React, { useEffect, useState } from "react";
import { Grid, Box, CircularProgress, Typography } from "@material-ui/core";
import NotificationContext from "views/Components/Context.js";
import Button from "components/CustomButtons/Button.js";
import ArtifactStructureReview from "./ArtifactStructureReview";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SaveIcon from '@material-ui/icons/Save';
import MarkasReviewedModal from "views/Components/MarkasReviewedModal";
import DoneIcon from '@material-ui/icons/Done';
import ExitReviewButton from "components/CustomButtons/ExitReviewButton";
import { submitModuleForDeliverables } from "views/APIs/APIUtility";
import Alert from "views/Components/Alert";

export default function SlidesStructureReview(props) {
    
    const [slides, setSlides] = React.useState([]);
    const { showNotification } = React.useContext(NotificationContext);

    const [reviewModal, setReviewModal] = useState(false);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingState, setLoadingState] = useState({
        isLoading: false,
        message: "",
    });
    const [showAlert, setShowAlert] = useState(false);

    const voices = [
        // { name: "Female UK", sampleUrl: voices_dir("./female-UK.mp3") }
        { name: "Female UK", sampleUrl: "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/female-UK.wav" },
        { name: "Male UK", sampleUrl: "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/male-UK.wav" },
        { name: "Female US", sampleUrl: "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/female-US.wav" },
        { name: "Male US", sampleUrl: "https://qucoursify.s3.us-east-1.amazonaws.com/sample-voices/male-US.wav" },
    ];


    const [state, setState] = React.useState({
        assessment: true,
        chatbot: false,
    });

    const handleBooleanChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const { assessment, chatbot } = state;


    const setSlidesForCard = () => {
        setLoadingState({ isLoading: true, message: "Loading Slides..." });
        const card = props.slides;

        if (card) {
            
            const updatedSlides = card.map((slide) => {
                return {
                    id: slide.resource_id,
                    name: slide.resource_name,
                    url: slide.resource_link,
                    description: slide.resource_description,
                    type: slide.resource_type,
                };
            });
            setSlides(updatedSlides);
            setLoadingState({ isLoading: false, message: "" });
        } else {
            
        }
    };

    // Call setFilesForCard whenever the id changes
    useEffect(() => {
        setSlidesForCard();
    }, []);

    const handleSaveChanges = () => {
        setReviewModal(false);
    };

    const handleConfirmVoice = async () => {
        setReviewModal(false);
        setLoadingState({ isLoading: true, message: "Submitting Structure Content for Deliverables..." });
        const formData = new FormData();
        formData.append("course_id", props.courseID);
        formData.append("module_id", props.moduleID);
        
        formData.append("voice_name", selectedVoice);
        formData.append("assessment", assessment);
        formData.append("chatbot", chatbot);

        try {
            const data = await submitModuleForDeliverables(formData);
            setLoadingState({ isLoading: false, message: "" });
            showNotification("Marked as reviewed. You will be notified when the content is ready for the final deliverables.", "quSandBoxColor");
            setTimeout(() => {
                window.location.reload();
            }, 2000); // Adjust the time as needed (2000ms = 2 seconds)
        }
        catch (error) {
            showNotification("Error submitting module", "danger");
        }
        finally {

        }
    };


    const verifySubmission = async () => {
        if (props.moduleStatus != "Structure Review") {
            setShowAlert(true);
        }
        else {
            setReviewModal(true);
        }
    };

    const handleConfirm = () => {
        setShowAlert(false); // Close the alert
        setReviewModal(true);
    };

    const handleCancel = () => {
        setShowAlert(false); // Close the alert
    };


    if (loadingState.isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
            >
                <CircularProgress color="quCreateColor" />
                <Typography variant="h6" style={{ marginLeft: 16 }}>
                    {loadingState.message}
                </Typography>
            </Box>
        );
    }

    return (
        <div>
            {showAlert && (
                <Alert
                    title="Are you sure?"
                    warning={true}
                    showCancel={true}
                    cancelBtnText="No"
                    confirmBtnText="Yes"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                >
                    You already have a submission for this module. If you wish to make another submission, your module will be submitted for final deliverables.
                </Alert>
            )}
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" style={{
                        marginTop: "20px"
                    }}>Slides Structure Review</Typography>
                    <Typography variant="body1">
                        Review the the slides by downloading it and checking the content.
                        You can replace the slide deck by uploading another one in its place.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <ArtifactStructureReview
                        files={slides} courseID={props.courseID} moduleID={props.moduleID} courseDesignStep={8}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={3} style={{ marginTop: "10px" }}>
                        <Grid item xs={12} sm={4} style={{
                            textAlign: "left"
                        }}>
                            <ExitReviewButton courseID={props.courseID} />
                        </Grid>
                        <Grid item xs={12} sm={4} style={{
                            textAlign: "center"
                        }}>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{
                            textAlign: "right"
                        }}>


                            <Button
                                color="info"
                                onClick={verifySubmission}
                                // onClick={() => setReviewModal(true)}
                                style={{
                                    float: "right",
                                }}
                                endIcon={<DoneIcon />}
                            >Mark as Reviewed</Button>
                            &nbsp;
                            {/* <Button
                                color="success"
                                onClick={handleSaveChanges}
                                style={{
                                    float: "right",
                                }}
                                startIcon={<SaveIcon />}
                            >Save Changes</Button>
                            &nbsp; */}
                            <Button
                                color="quSandBoxColor"
                                onClick={() => props.setActiveTab("Module Information")}
                                style={{
                                    float: "right",
                                }}
                                startIcon={<NavigateBeforeIcon />}
                            >Previous</Button>
                            &nbsp;
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* Voice Selection Modal */}
            <MarkasReviewedModal
                open={reviewModal}
                onClose={() => setReviewModal(false)}
                voices={voices}
                selectedVoice={selectedVoice}
                setSelectedVoice={setSelectedVoice}
                onConfirm={handleConfirmVoice}
                handleBooleanChange={handleBooleanChange}
                assessment={assessment}
                chatbot={chatbot}
            />
        </div>

    );
}