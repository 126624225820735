import React, { useState } from 'react';

import { Grid, Typography, Card, Box, CircularProgress, Divider } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { saveLabInstructionsAPI } from "views/APIs/APIUtility";

// Contexts
import NotificationContext from "views/Components/Context.js";

import Button from "components/CustomButtons/Button";
import MDEditor from '@uiw/react-md-editor';

const GeneralRequirements = ({ description, handleNavigation, labID, setStage, setActiveStep, instructions, setInstructions }) => {

    const { showNotification } = React.useContext(NotificationContext);
    const [loading, setLoading] = useState({ isLoading: false, message: "" });

    const handleNext = () => {
        handleSave();
        setStage(1);
        handleNavigation("Data Requirements");
    };

    const handleSave = async () => {
        //TODO: Implement the save functionality
        try {
            setLoading({ isLoading: true, message: "Saving Learning Outcomes..." });
            const formData = new FormData();
            formData.append("lab_id", labID);
            formData.append("instructions", JSON.stringify(instructions)); // Convert instructions to JSON string

            await saveLabInstructionsAPI(formData);
        }
        catch (error) {
            console.error("Error in handleSave:", error);
            showNotification("Error saving lab details", "danger");
        }
        finally {
            setLoading({ isLoading: false, message: "" });
        }
    };

    const handleBack = () => {
        setActiveStep(0);
    };

    const handleLearningOutcomes = (event) => {
        setInstructions({ ...instructions, learningOutcomes: event });
    };

    return (
        <div>
            {loading.isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginLeft: 16 }}>
                        {loading.message}
                    </Typography>
                </Box>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" >
                                General Requirements
                            </Typography>
                            <Typography variant="body1" style={{ marginBottom: "10px" }}>
                                {description}
                            </Typography>
                            <Divider style={{ marginBottom: "10px" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ marginBottom: "10px" }}>
                                Enter the Learning Outcomes for the Application
                            </Typography>
                            <Grid item xs={12} sm={12}>
                                <Card>
                                    <div data-color-mode="light">
                                        <MDEditor
                                            value={instructions.learningOutcomes}
                                            onChange={(event) => handleLearningOutcomes(event)}
                                        />
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: "10px" }}>
                        <Grid item xs={12}>
                            <Button color="quCreateColor" style={{ float: 'right', fontWeight: 'bold' }}
                                endIcon={<NavigateNextIcon />} onClick={handleNext}
                            >
                                Next
                            </Button>
                            <Button color="quCreateColor" style={{ float: 'right', fontWeight: 'bold' }}
                                startIcon={<SaveIcon />} onClick={handleSave}
                            >
                                Save
                            </Button>

                            <Button color="quCreateColor" style={{ float: "left", fontWeight: "bold" }}
                                startIcon={<ArrowBackIcon />} onClick={handleBack}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </div >
    );
};

export default GeneralRequirements;