import React, { useState } from 'react';
// React Router
import { Link } from "react-router-dom";
import AssetModalCard from "../Components/AssetModalCard";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, IconButton, List, ListItem, Avatar, ListItemAvatar, ListItemText, Divider, Box } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";

import { cardDataAsset, groupsAsset } from "./Constants";
import TabsComponent from "./TabsComponent";
import { quSandBoxColor } from "assets/jss/material-dashboard-pro-react";
import { Delete, Visibility, GridOn, ViewList, RestorePage } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { quCreateColor } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative', // For positioning the delete icon
        width: '100%',
        height: '250px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
    },
    media: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    image: {
        width: '100%',
        maxHeight: '80vh',
        borderRadius: '8px',
        objectFit: 'contain',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogImage: {
        width: '100%',
        maxHeight: '80vh',
        objectFit: 'contain',
        borderRadius: '8px',
    },
    iconButton: {
        marginTop: theme.spacing(2),
    },
    activeIcon: {
        color: quCreateColor,
        fontSize: '2rem',
    },
    inactiveIcon: {
        color: theme.palette.text.secondary,
        fontSize: '2rem',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tableContainer: {
        width: '100%',
        maxHeight: '70vh',
        margin: '0 auto',
    },
    deleteIcon: {
        position: 'absolute', // Position the delete icon at the bottom right
        bottom: 8,
        right: 8,
        // color: theme.palette.error.main,
    },
    headerCell: {
        padding: '26px', // You can adjust this for additional spacing if needed
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '10px',
        marginBottom: '10px',
        boxSizing: 'border-box',
    },
    tableCell: {
        maxWidth: "200px", // Add maxWidth to ensure text truncation
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));


const CreateAssetDialog = ({ open, handleClose, submitModuleLoading, name, setName, brief, setBrief, addAsset }) => {
    const classes = useStyles();
    const [gridView, setGridView] = useState(false);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        // maxWidth="md"
        // fullWidth
        >
            <DialogTitle id="form-dialog-title" style={{ paddingBottom: "0px" }}>
                <div style={{ fontSize: "30px" }}>Create Asset</div>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <div className={classes.container}>
                    <Grid item xs={12} className={classes.titleContainer}>
                        {/* List View Button */}
                        <IconButton
                            onClick={() => setGridView(false)}
                            className={!gridView ? classes.activeIcon : classes.inactiveIcon}
                        >
                            <ViewList />
                        </IconButton>
                        {/* Grid View Button */}
                        <IconButton
                            onClick={() => setGridView(true)}
                            className={gridView ? classes.activeIcon : classes.inactiveIcon}
                        >
                            <GridOn />
                        </IconButton>

                    </Grid>
                </div>

                {gridView ? (
                    // Grid View Layout 
                    <Grid container spacing={2}>
                        {cardDataAsset.map((card, index) => (
                            <Grid item key={index} xs={12} sm={4} md={4} lg={4}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <img
                                        src={card.logo}
                                        alt="card logo"
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            margin: "10px",  // This ensures consistent spacing between image and box
                                            cursor: "pointer",
                                            borderRadius: "12px",  // This will round the corners
                                            objectFit: "cover"     // Maintain the aspect ratio of the image
                                        }}
                                        onClick={() => addAsset(card)}  // Added onClick to the image
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                ) : (
                    <List>
                        {cardDataAsset.map((card, index) => (
                            <ListItem
                                key={index}
                                className={classes.listItem}
                                style={{
                                    borderRadius: "8px",
                                    marginBottom: "8px",
                                    padding: "8px",
                                }}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    width="100%"
                                    bgcolor="white"
                                    padding={2}
                                    boxShadow={3}
                                    onClick={() => addAsset(card)} // Click event added to the whole box
                                    style={{ cursor: "pointer" }} // Pointer cursor to indicate clickability
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            variant="rounded"
                                            src={card.logo}
                                            alt="card logo"
                                            style={{ width: "70px", height: "70px", padding: "8px" }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText primary={card.title} secondary={card.description} />
                                </Box>
                            </ListItem>
                        ))}
                    </List>

                )}
            </DialogContent>
        </Dialog>
    );
};

export default CreateAssetDialog;
