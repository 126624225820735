import {
    quSandBoxCardHeader,
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
    grayColor,
    quCreateColorCardHeader,
} from "assets/jss/material-dashboard-pro-react.js";
const cardTitleStyle = {
    cardTitle: {
        "&$quSandBoxCardHeader,&$quCreateColorCardHeader,&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
            borderRadius: "3px",
            backgroundColor: grayColor[0],
            padding: "15px",
            marginTop: "-20px",
            marginRight: "15px",
            float: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%" // Ensures it stays within the card width
        }
    },
    quSandBoxCardHeader,
    quCreateColorCardHeader,
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader
};

export default cardTitleStyle;
