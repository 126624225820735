import React from "react";

export const COMPANY_MODELS = {
    Openai: [
        "openai/o1-mini",
        "openai/o1-preview",
        "openai/gpt-3.5-turbo-1106",
        "openai/gpt-3.5-turbo",
        "openai/gpt-3.5-turbo-0301",
        "openai/gpt-3.5-turbo-0613",
        "openai/gpt-3.5-turbo-16k",
        "openai/gpt-3.5-turbo-16k-0613",
        "openai/gpt-4o-mini",
        "openai/gpt-4o-mini-2024-07-18",
        "openai/gpt-4o",
        "openai/gpt-4o-2024-08-06",
        "openai/gpt-4o-2024-05-13",
        "openai/gpt-4-turbo",
        "openai/gpt-4-turbo-preview",
        "openai/gpt-4-0125-preview",
        "openai/gpt-4-1106-preview",
        "openai/gpt-4",
        "openai/gpt-4-0314",
        "openai/gpt-4-0613",
        "openai/gpt-4-32k",
        "openai/gpt-4-32k-0314",
        "openai/gpt-4-32k-0613",
        "openai/gpt-4-vision-preview",
    ],
    Gemini: [
        "gemini/gemini-pro",
        "gemini/gemini-1.5-pro-latest",
        "gemini/gemini-2.0-flash",
        "gemini/gemini-2.0-flash-exp",
        "gemini/gemini-2.0-flash-lite-preview-02-05",
    ],
    Deepseek: [
        "deepseek/deepseek-chat",
        "deepseek/deepseek-coder",
        "deepseek/deepseek-reasoner",
    ],
    Groq: [
        "groq/llama-3.1-8b-instant",
        "groq/llama-3.1-70b-versatile",
        "groq/llama3-8b-8192",
        "groq/llama3-70b-8192",
        "groq/llama2-70b-4096",
        "groq/mixtral-8x7b-32768",
        "groq/gemma-7b-it",
    ],
    Cohere: [
        "command-r-plus-08-2024",
        "command-r-08-2024",
        "command-r-plus",
        "command-r",
        "command-light",
        "command-nightly",
    ],
    Mistral: [
        "mistral/mistral-small-latest",
        "mistral/mistral-medium-latest",
        "mistral/mistral-large-2407",
        "mistral/mistral-large-latest",
        "mistral/open-mistral-7b",
        "mistral/open-mixtral-8x7b",
        "mistral/open-mixtral-8x22b",
        "mistral/codestral-latest",
        "mistral/open-mistral-nemo",
        "mistral/open-mistral-nemo-2407",
        "mistral/open-codestral-mamba",
        "mistral/codestral-mamba-latest",
    ],
    Anthropic: [
        "claude-3-5-sonnet",
        "claude-3-haiku",
        "claude-3-opus",
        "claude-3-5-sonnet-20240620",
        "claude-3-sonnet",
        "claude-2.1",
        "claude-2",
        "claude-instant-1.2",
        "claude-instant-1",
    ],
};

export const getCompanyIcon = (comp) => {
    // Could return real icons or different emojis. Example:
    switch (comp) {
        case "Openai":
            return <img src="https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/openai.png" width="15px" style={{ marginRight: "5px" }} alt="openai-logo" />
        case "Gemini":
            return <img src="https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/gemini-color.png" width="15px" style={{ marginRight: "5px" }} alt="gemini-logo" />
        case "Deepseek":
            return <img src="https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/deepseek-color.png" width="15px" style={{ marginRight: "5px" }} alt="deepseek-logo" />
        case "Groq":
            return <img src="https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/groq.png" width="15px" style={{ marginRight: "5px" }} alt="groq-logo" />
        case "Cohere":
            return <img src="https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/cohere-color.png" width="15px" style={{ marginRight: "5px" }} alt="cohere-logo" />
        case "Mistral":
            return <img src="https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/mistral-color.png" width="15px" style={{ marginRight: "5px" }} alt="mistral-logo" />
        case "Anthropic":
            return <img src="https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/anthropic.png" width="15px" style={{ marginRight: "5px" }} alt="anthropic-logo" />
        default:
            return <img src="random_logo.png" alt="unknown-logo" />
    }
};

export const getCompanyIconUrl = (comp) => {
    switch (comp) {
        case "Openai":
            return "https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/openai.png";
        case "Gemini":
            return "https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/gemini-color.png";
        case "Deepseek":
            return "https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/deepseek-color.png";
        case "Groq":
            return "https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/groq.png";
        case "Cohere":
            return "https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/cohere-color.png";
        case "Mistral":
            return "https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/mistral-color.png";
        case "Anthropic":
            return "https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/light/anthropic.png";
        default:
            return "random_logo.png";
    }
};



