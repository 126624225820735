import React, { useState } from "react";
import {
    Grid,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import NotificationContext from "views/Components/Context.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import StepConnector from "@material-ui/core/StepConnector";


const QontoConnector = withStyles({
    active: {
        color: quCreateColor,
    },
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    active: {
        "& $line": {
            borderColor: "#784af4",
        },
    },
    completed: {
        "& $line": {
            borderColor: "#784af4",
        },
    },
    line: {
        borderColor: quCreateColor,
        borderTopWidth: 3,
        borderRadius: 1,
    },
    stepLabel: {
        color: quCreateColor,
    },
})(StepConnector);

const usesweetAlertStyle = makeStyles(sweetAlertStyle);
export default function CreateTemplateProjectModal({
    newProjectDetails,
    setNewProjectDetails,
    createProjectModal,
    handleCreateProjectModalClose,
    handleCreateProject,
    labelName,
    labelDescription,
    placeholderName,
    placeholderDescription,

}) {
    const { showNotification } = React.useContext(NotificationContext);


    const dialogStages = [
        "Basic Details",
        "Submit",
    ];
    const stepperDisplayStages = [
        "Basic Details",
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [currentDialog, setCurrentDialog] = useState(dialogStages[0]);
    const [courseOutlineLoader, setCourseOutlineLoader] = React.useState(false);


    // Handle next actions on dialogs
    const handleStep1 = () => {
        if (newProjectDetails.name === "" || newProjectDetails.description === "") {
            showNotification(
                "Please make sure all fields, including the course name and description, are properly filled out.",
                "danger"
            );
            return;
        }
        handleCreation();
        // setActiveStep(1);
        // setCurrentDialog(dialogStages[1]);
    };

    const handleCreation = async () => {
        setActiveStep(1);
        setCurrentDialog(dialogStages[1]);
        await handleCreateProject();
        handleModalClose();
    };

    const handleModalClose = () => {
        setActiveStep(0);
        setCurrentDialog(dialogStages[0]);
        // setCourseOutlineLoader(false);
        handleCreateProjectModalClose();
    };

    const renderDialogContent = () => {
        switch (currentDialog) {
            case "Basic Details":
                return (
                    <div>
                        <Typography variant="h5" style={{ marginBottom: "10px" }}>
                            Enter the Project details:
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="courseName"
                            label={labelName}
                            placeholder={placeholderName}
                            type="text"
                            fullWidth
                            required
                            value={newProjectDetails.name}
                            onChange={(event) =>
                                setNewProjectDetails({
                                    ...newProjectDetails,
                                    name: event.target.value,
                                })
                            }
                        />
                        <TextField
                            margin="dense"
                            id="brief"
                            label={labelDescription}
                            placeholder={placeholderDescription}
                            type="text"
                            fullWidth
                            multiline
                            required
                            rows={4}
                            value={newProjectDetails.description}
                            onChange={(event) =>
                                setNewProjectDetails({
                                    ...newProjectDetails,
                                    description: event.target.value,
                                })
                            }
                        />
                    </div>
                );
            case "Submit":
                return (
                    <div style={{ display: "flex" }}>
                        <CircularProgress size={20} /> &nbsp;
                        <Typography variant="body1">Creating project for you...</Typography>
                    </div>
                );
            default:
                return null;
        }
    };


    return (
        <div>
            {alert}

            <Dialog
                open={createProjectModal}
                onClose={handleCreateProjectModalClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth
            >
                {/* Stepper Component */}
                {/* <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<QontoConnector />}
                >
                    {stepperDisplayStages.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper> */}

                {/* <Divider /> */}
                <DialogTitle id="form-dialog-title">
                    <div style={{ fontSize: "30px" }}>
                        {newProjectDetails.name
                            ? newProjectDetails.name
                            : "Create a New Project"}
                    </div>
                </DialogTitle>
                <DialogContent>{renderDialogContent()}</DialogContent>
                <DialogActions>

                    <Button
                        onClick={handleModalClose}
                        color="quCreateColor"
                    // disabled={courseOutlineLoader || courseCreationLoader}
                    >
                        Cancel
                    </Button>

                    <Button
                        onClick={() => {
                            switch (currentDialog) {
                                case "Basic Details":
                                    return handleStep1();
                                default:
                                    return null;
                            }
                        }}
                        // disabled={courseOutlineLoader || courseCreationLoader}
                        color="quCreateColor"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
