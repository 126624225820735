import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Tooltip,
  Divider,
  Chip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NotificationContext from "views/Components/Context.js";
import Button from "components/CustomButtons/Button.js";
import { DoneAll, FileCopy, RotateLeft, SpeakerNotes, Visibility } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import CreateIssueForm from "./Lab/CreateIssueForm";
import { createGitHubIssueAPI, getIssuesAPI } from "views/APIs/APIUtility";
import MDEditor from "@uiw/react-md-editor";

const usesweetAlertStyle = makeStyles(sweetAlertStyle);

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "20px",
    boxSizing: "border-box",
  },
  title: { marginBottom: "20px" },
  button: {
    marginBottom: "20px",
    padding: "15px 30px",
    fontSize: "1.2rem",
  },
  tableContainer: {
    width: "100%",
    maxHeight: "70vh",
    margin: "0 auto",
  },
  dialogContent: {
    width: "500px",
    maxWidth: "90vw",
  },
  dialogButtonSpacing: { margin: "10px 0" },
  urlCell: {
    wordBreak: "break-word",
    maxWidth: "200px",
    textAlign: "center",
  },
  centeredCell: {
    textAlign: "center",
    padding: "16px", // Ensures equal padding inside each cell
  },
  rightAlignedTitleCell: {
    textAlign: "right",
    padding: "16px", // Ensures equal padding inside each cell
  },
  rightAlignedCell: {
    textAlign: "right",
    padding: "16px", // Ensures equal padding inside each cell
  },
});

export default function ReviewLabProject({
  projectID,
  projectType,
  labDetails,
  setActiveStep,
  deliverables = false,
}) {
  const classes = useStyles();
  const { showNotification, setNotification } = React.useContext(
    NotificationContext
  );
  const [issueForm, setIssueForm] = useState(false);
  const [issues, setIssues] = useState([]);
  const [issueObject, setIssueObject] = React.useState({
    title: "",
    description: `<!-- For Feature Requests / Enhancements -->
### User Story
As a (type of user), I want (some goal) so that (some reason)

### Description
What’s it all about?

<!-- For Bugs -->
### What should happen:
(Describe what you expect to happen here)

### What happened instead:
(Describe what happened instead)

### How to reproduce this bug:
(Provide steps to reproduce the bug)
    `,
    uploadedFiles: [],
    labels: [],
  });

  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const data = await getIssuesAPI(projectID);
        setIssues(data);
      } catch (error) {
        showNotification("Error fetching issues", "danger");
      }
    };
    fetchIssues();
  }, []);


  const [links, setLinks] = useState([
    {
      name: "Streamlit Application",
      description:
        "The streamlit application link for the hosted lab.",
      url:
        labDetails.lab_url || "https://qulabs.streamlit.app/?id=6744d0c0360a36f97f90d355",
      viewAction: () => {
        window.open(labDetails.lab_url || "https://qulabs.streamlit.app/?id=6744d0c0360a36f97f90d355");
      }
    },
    {
      name: "Github Repository",
      description:
        "The GitHub repository link for the lab.",
      url: labDetails.repo_url || "",
      viewAction: () => {
        let url = `https://codesandbox.io/p/sandbox/github/${process.env.REACT_APP_GITHUB_USERNAME}/${projectID}`;
        window.open(url || "");
      },
    },
    {
      name: "Documentation Codelab",
      description:
        "The codelab link for the lab.",
      url: labDetails.documentation_url || "",
      viewAction: () => {
        window.open(labDetails.documentation_url || "");
      },
    },
    {
      name: "User Guide Codelab",
      description:
        "The user guide link for the lab.",
      url: labDetails.user_guide_url || "",
      viewAction: () => {
        window.open(labDetails.user_guide_url || "");
      },
    }
  ]);

  const ReviewLabProjectSubmitHandler = () => {
    if (deliverables) {
      showNotification(
        "Your project has been marked for publishing.",
        "success"
      );
    } else {
      setActiveStep(4);
    }
  };

  const addFeedback = async () => {
    showNotification(
      "Your feedback has been saved and will be taken into consideration.",
      "success"
    );
  };

  const newIssue = async () => {
    setIssueForm(true);
  };

  const handleClose = () => {
    setIssueForm(false);
    setIssueObject({
      title: "",
      description: `
    <!-- For Feature Requests / Enhancements -->
    ### User Story
    As a (type of user), I want (some goal) so that (some reason)

    ### Description
    What’s it all about?

    <!-- For Bugs -->
    ### What should happen:
    (Describe what you expect to happen here)

    ### What happened instead:
    (Describe what happened instead)

    ### How to reproduce this bug:
    (Provide steps to reproduce the bug)
  `,
      uploadedFiles: [],
      labels: [],
    });
  };

  const createNewIssue = async () => {
    try {
      const formData = new FormData();
      formData.append("lab_id", projectID)
      formData.append("issue_title", issueObject.title);
      formData.append("issue_description", issueObject.description);
      formData.append("labels", issueObject.labels);
      issueObject.uploadedFiles.forEach((file) => {
        formData.append("uploaded_files", file);
      });

      // API call to create a new issue
      const data = await createGitHubIssueAPI(formData);
      showNotification("Issue created successfully", "success");
      setIssues([...issues,
      {
        title: issueObject.title,
        description: issueObject.description,
        state: "open",
        labels: issueObject.labels
      }
      ]);
    }
    catch (error) {
      showNotification("Error creating issue", "danger");
    }
    finally {
      handleClose();
    }
  };

  return (
    <Grid container>
      {!deliverables && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Typography variant="h5">Review Lab</Typography>
            <Typography variant="body1">
              Review the generated code lab & application links for the project.
            </Typography>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
          </Grid>
        </>
      )}
      {deliverables && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Typography variant="h5">Final Deliverables</Typography>
            <Typography variant="body1">
              Review the final deliverables for the project.
            </Typography>
          </Grid>
          <Divider />
        </>
      )}

      <Grid item xs={12} sm={12}>
        {links.length > 0 && (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Description</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Link</strong>
                  </TableCell>
                  <TableCell>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {links.map((link, index) => (
                  <TableRow key={index}>
                    {" "}
                    {/* Using link.id instead of index */}
                    <TableCell className={classes.tableCell}>
                      <Tooltip title={link.name}>
                        <span>{link.name}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Tooltip title={link.description}>
                        <span>{link.description}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Tooltip title={link.url}>
                          <span>{link.url}</span>
                        </Tooltip>
                      </a>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <IconButton
                        onClick={() => {
                          link.viewAction();
                        }}
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(link.url);
                          showNotification("Link copied to clipboard", "success");
                        }}
                      >
                        <FileCopy />
                      </IconButton>
                      <IconButton
                        onClick={newIssue}

                      >
                        <SpeakerNotes />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        style={{ marginTop: "20px", marginBottom: "10px" }}
      >
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

        <Typography variant="h5">Previous Feedback</Typography>
        <Typography variant="body1">
          You can check your previous feedback and issues here.
        </Typography>
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

        <TableContainer component={Paper} className={classes.tableContainer} style={{ marginTop: "10px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Description</strong></TableCell>
                <TableCell><strong>Labels</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {issues.length > 0 ? (
                issues.map((issue, index) => (
                  <TableRow key={index}>
                    <TableCell>{issue.title}</TableCell>
                    <TableCell width={"50%"}>
                      <div data-color-mode="light">
                        <MDEditor preview="preview" disabled value={issue.description} height={100} hideToolbar={true} />
                      </div>
                    </TableCell>
                    <TableCell>{issue.labels ?
                      (<>
                        {
                          issue.labels.map((label, index) => (
                            <Chip key={index} label={label} style={{ margin: "2px" }} color="QuCreateColor" />
                          ))
                        }
                      </>) : (<></>)
                    }</TableCell>
                    <TableCell>{issue.state === "open" ? "Open" : "Closed"}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No Open Issues/Bugs found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {!deliverables && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            {/* <Button
              color="quCreateColor"
              onClick={ReviewLabProjectSubmitHandler}
              style={{
                float: "right",
              }}
              startIcon={<DoneAll />}
            >
              Mark as reviewed
            </Button> */}
            {/* <Button
              color="success"
              style={{
                float: "right",
              }}
              onClick={newIssue}
            >
             Add Feedback
            </Button> */}
          </Grid>
        </>
      )}
      {deliverables && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <Button
            color="quCreateColor"
            onClick={ReviewLabProjectSubmitHandler}
            style={{
              float: "right",
            }}
            startIcon={<DoneAll />}
          >
            Publish
          </Button>
        </Grid>
      )}

      <Dialog open={issueForm} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CreateIssueForm
            issueObject={issueObject}
            setIssueObject={setIssueObject}
            createNewIssue={createNewIssue}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
