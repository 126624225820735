import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

// @material-ui/core components
import {
  createLabAPI,
  fetchAllLabsAPI, cloneEntryAPI,
  deleteLabAPI, updateLabTagsAPI,
} from "views/APIs/APIUtility";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import { Dialog, TextField, DialogActions, DialogContent, DialogTitle, CircularProgress, Typography, Box } from "@material-ui/core";

// core components

import Button from "components/CustomButtons/Button.js";
import NotificationContext from "views/Components/Context.js";
import { CloudUpload, Create, DoneAll } from "@material-ui/icons";
import CreateCourseCard from "views/Components/CreateCourseCard";
import AssetDashboard from "views/Components/AssetDashboard";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import CreateLabModal from "views/Components/CreateLabModal";
import LabDetails from "../Models/LabDetails";
import { fetchImageAsFile } from "views/Dashboard/Course/CourseConstants";
const useStyles = makeStyles({
  ...styles,
})

export default function LabsDesign() {

  const images = require.context("assets/img/create_cards_16_9/", true);

  // state variables
  const { path, url } = useRouteMatch();
  const classes = useStyles();
  const [createLabModal, setCreateLabModal] = React.useState(false);
  const [newLabDetails, setNewLabDetails] = React.useState(new LabDetails());
  const [iconImage, setIconImage] = React.useState();
  const [allLabs, setAllLabs] = React.useState([]);
  const [initialLabs, setInitialLabs] = React.useState([]);
  const [loading, setLoading] = React.useState({ loading: false, message: "" });

  const [template, setTemplate] = React.useState(
  );
  const [labAdded, setLabAdded] = React.useState(false);
  const { showNotification } = React.useContext(NotificationContext);

  const handleIconUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIconImage(file);
    }
  };

  const handleDeleteLab = async (labData) => {
    const formData = new FormData();
    formData.append("lab_id", labData._id);

    try {
      const data = await deleteLabAPI(formData);
      setAllLabs(allLabs.filter((lab) => lab._id !== labData._id));
    } catch (error) {
      showNotification(error, "danger");
    } finally { }
  };

  const handleCloneCourse = async (courseData) => {
    try {
      setLoading({ loading: true, message: "Cloning Lab..." });
      const formData = new FormData();
      formData.append("collection", "lab_design");
      formData.append("id", courseData._id);

      const data = await cloneEntryAPI(formData);
      setAllLabs((prevCourses) => [...prevCourses, _mapResourceToAppType(data)]);
      setLabAdded(true);
      showNotification("Lab cloned successfully", "success");
    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally {
      setLoading({ loading: false, message: "" });
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      // Fetch all labs
      try {
        setLoading({ loading: true, message: "Loading Labs..." });
        const data = await fetchAllLabsAPI();
        const mappedData = data.map(_mapResourceToAppType);
        setAllLabs(mappedData);
        setInitialLabs(mappedData);
      } catch (error) {
        showNotification(error, "danger");
      } finally {
        setLoading({ loading: false, message: "" });
      }
    };

    fetchData();
  }, []);

  const [courseOutlineLoader, setCourseOutlineLoader] = React.useState(false);

  const generateCourseOutline = () => {
    setCourseOutlineLoader(true);
    const formData = new FormData();

    // Attach uploaded files
    newLabDetails.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });


    formData.append("instructions", newLabDetails.template);

    fetch(process.env.REACT_APP_FASTAPI_URL + "/generate_course_outline", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setNewLabDetails({ ...newLabDetails, labOutline: data });
        // setCourseOutline(data);
        setCourseOutlineLoader(false);
      })
      .catch((error) => {
        showNotification(error, "danger");
        setCourseOutlineLoader(false);
      });
  };

  const handleCreateLab = async () => {
    setLoading({ loading: true, message: "Creating Lab..." });
    const formData = new FormData();
    formData.append("lab_name", newLabDetails.name);
    formData.append("lab_description", newLabDetails.description);
    formData.append("lab_outline", newLabDetails.labOutline);
    newLabDetails.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append(
      "lab_image",
      iconImage || (await fetchImageAsFile(images("./labs.png")))
    );

    try {
      const data = await createLabAPI(formData);

      setAllLabs([...allLabs, _mapResourceToAppType(data)]);
      setLabAdded(true);
      showNotification("Lab created successfully", "success");
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      handleCreateLabModalClose();
      setLoading({ loading: false, message: "" });
    }
  };

  const clearCreateModal = () => {
    setNewLabDetails(new LabDetails());
    setIconImage(null);
  };

  const handleCreateLabModalClose = () => {
    setCreateLabModal(false);
    // setModal(false);
    clearCreateModal();
  };

  const handleFileUpload = (files) => {
    setNewLabDetails({ ...newLabDetails, uploadedFiles: files });
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "In Design Phase":
        return <Create style={{ color: "#435966" }} />;
      case "Ready for Publishing":
        return <CloudUpload style={{ color: "#435966" }} />;
      case "Published":
        return <DoneAll style={{ color: "#435966" }} />;
      default:
        return <Create style={{ color: "#435966" }} />;
    }
  };

  const _mapResourceToAppType = (resource) => {
    // Destructure the resource to exclude course_name and course_description
    const { lab_name, lab_description, lab_image, ...rest } = resource;

    return {
      ...rest,               // Spread all remaining properties
      name: lab_name,     // Add name property
      description: lab_description, // Add description property
      image: lab_image,   // Add image property
    };
  };

  const handleSaveLabel = async (labData) => {
    try {
      const formData = new FormData();
      formData.append("lab_id", labData._id);
      if (labData.tags.length === 0) {
        formData.append("tags", []);
      }
      // Append each tag separately
      else {
        labData.tags.forEach(tag => formData.append("tags", tag));
      }
      await updateLabTagsAPI(formData);
      setAllLabs((prevLabs) =>
        prevLabs.map((lab) =>
          lab._id === labData._id ? { ...lab, tags: labData.tags } : lab
        )
      );
    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally { }
  };

  if (loading.loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" className={classes.loaderClass} />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loading.message}
        </Typography>
      </Box>
    );
  }

  return (
    <div>

      <CreateLabModal
        newLabDetails={newLabDetails}
        setNewLabDetails={setNewLabDetails}
        createLabModal={createLabModal}
        handleCreateLabModalClose={handleCreateLabModalClose}
        handleCreateLab={handleCreateLab}
      />

      <AssetDashboard
        path={path}
        welcomeTitle="Welcome to the Lab Design Dashboard!"
        welcomeSubtitle="Start by creating a new lab or reviewing your existing labs."
        allAssets={allLabs}
        setAllAssets={setAllLabs}
        initialAssets={initialLabs}
        noAssetMessage="Create or Review your labs to get started."
        linkPath="/lab/"
        getStatusIcon={getStatusIcon}
        CreateAssetCard={CreateCourseCard}
        setCreateAssetModal={setCreateLabModal}
        createAssetButtonText="Create Lab"
        handleSaveLabel={handleSaveLabel}
        asset_identifier="lab"
        handleDeleteAsset={handleDeleteLab}
        handleCloneAsset={handleCloneCourse}
        assetAdded={labAdded}
        setAssetAdded={setLabAdded}
      />
    </div>
  );
}
