import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Grid, Box, Typography } from "@material-ui/core";
import { useRouteMatch, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ResourceTiles from "components/Tiles/ResourceTiles";
import LinkIcon from "@material-ui/icons/Link";
import ImageIcon from "@material-ui/icons/Image";
import NotesIcon from "@material-ui/icons/Notes";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Button from "components/CustomButtons/Button.js";

import SlideshowIcon from "@material-ui/icons/Slideshow";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import LaptopIcon from "@material-ui/icons/Laptop";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Slides from "views/Dashboard/Slides";
import Labs from "./Labs";
import VideoPlayer from "./Videos";
import Assessment from "./Assessment";
import NotificationContext from "views/Components/Context.js";
import { DoneAll } from "@material-ui/icons";
import { Auth } from "aws-amplify";
import { fetchLectureAPI, fetchCourseAPI } from "views/APIs/APIUtility";
import DeliverableTiles from "components/Tiles/DeliverableTiles";
// import DeliverableTiles from "components/Tiles/DeliverableTiles";

export default function ProjectDeliverables(props) {
  const history = useHistory();
  const projectID = props.projectID;
  const moduleID = props.moduleID;
  const projectType = props.projectType;

  const { showNotification } = React.useContext(NotificationContext);

  const [activeTile, setActiveTile] = useState(null);
  const handleNavigation = (type) => {
    setActiveTile(type);
  };

  const [user, setUser] = useState();

  const [slides, setSlides] = useState([]);
  const [labLinks, setLabLinks] = useState([]);
  const [videos, setVideos] = useState([]);
  const [assessment, setAssessment] = useState([]);
  const [notes, setNotes] = useState([]);
  const [modulesLoading, setModulesLoading] = useState(true);

  useEffect(() => {
    const setDeliverableFromProject = (data) => {
      // Use let instead of const for resources and modules
      let resources = [];
      let modules = [];

      // Check the project type
      if (projectType === "lecture") {
        if (!data.pre_processed_deliverables || data.pre_processed_deliverables.length === 0) {
          showNotification("No deliverables found for this project", "warning");
          return;
        }
        resources = data.pre_processed_deliverables; // In case of lecture, use the pre_processed_deliverables directly
      } else {
        modules = data.modules; // For course, find the specific module and use pre_processed_deliverables
        const module = modules.find((module) => module.module_id === moduleID);
        if (module) {
          resources = module.pre_processed_deliverables;
        }
      }


      // Filter resources based on resource types
      const slides = resources.filter(
        (resource) => resource.resource_type === "Slide_Generated"
      );
      const labLinks = resources.filter(
        (resource) => resource.resource_type === "Link"
      );
      const videos = resources.filter(
        (resource) => resource.resource_type === "Video"
      );
      const assessment = resources.filter(
        (resource) => resource.resource_type === "Assessment"
      );
      const notes = resources.filter(
        (resource) => resource.resource_type === "Note"
      );

      // Update state with filtered resources
      setSlides(slides);
      setLabLinks(labLinks);
      setVideos(videos);
      setAssessment(assessment);
      setNotes(notes);
    };

    const fetchData = async () => {
      try {
        let data = null;
        if (projectType === "lecture") {
          
          data = await fetchLectureAPI(projectID); // Fetch data for lecture
        } else {
          data = await fetchCourseAPI(projectID); // Fetch data for course
        }
        setDeliverableFromProject(data); // Set the fetched data into the state
      } catch (error) {
        showNotification("Error fetching project data", "danger"); // Show error message
      } finally {
        setModulesLoading(false); // Ensure loading state is turned off
      }
    };

    fetchData(); // Call the fetchData function to start fetching data
  }, [projectID, moduleID, projectType]); // Dependency array, effect runs when any of these values change

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch((err) => {
        if (err === "not authenticated") {
          history.push("/auth/login-page");
        }
      });
  }, [history]);

  const renderContent = () => {
    switch (activeTile) {
      case "Slides":
        return (
          <Slides
            courseID={props.projectID}
            moduleID={props.moduleID}
            filetype="Slide_Generated"
            slides={slides}
            idenitfier="lecture"
          />
        );
      // case "Labs":
      //     return <Labs courseID={props.projectID} moduleID={props.moduleID} filetype="Link" links={labLinks} />;
      case "Video":
        return (
          <VideoPlayer
            courseID={props.projectID}
            moduleID={props.moduleID}
            filetype="Video"
            videos={videos}
            idenitfier="lecture"
          />
        );
      // case "Assessment":
      //     return <Assessment courseID={props.projectID} moduleID={props.moduleID} filetype="Assessment" assessment={assessment} />;
      default:
        return null;
    }
  };

  return (
    <Box mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* <DeliverableTiles */}
          <DeliverableTiles
            active={activeTile}
            color="warning"
            alignCenter
            tabs={[
              {
                heading: "Slides",
                icon: SlideshowIcon,
                description: "Contains all slides",
                onClick: () => handleNavigation("Slides"),
              },
              {
                heading: "Videos",
                icon: VideoLibraryIcon,
                description: "Contains all videos",
                onClick: () => handleNavigation("Video"),
              },
              // { heading: "Labs", icon: LaptopIcon, description: "Contains all labs", onClick: () => handleNavigation('Labs') },
              // { heading: "Assessment", icon: AssessmentIcon, description: "Contains all assessments", onClick: () => handleNavigation('Assessment') }
            ]}
          />
        </Grid>
      </Grid>
      {renderContent()}
      <Button
        color="quSandBoxColor"
        style={{
          float: "right",
          marginTop: "20px",
        }}
        onClick={() => {
          showNotification(
            "The module has been marked for publishing.",
            "quSandBoxColor"
          );
        }}
      >
        <DoneAll /> &nbsp; Publish Module
      </Button>
    </Box>
  );
}
