import { Auth } from "aws-amplify";

const getUser = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser(); // Await the promise
        return user.username; // Return the username
    } catch (err) {
        console.log("Error in getUser: ", err);
        return null; // Handle the case where user is not authenticated
    }
};


export const fetchContentAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/fetch_note`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (err) {
        throw new Error(`Error fetching file: ${err.message}`);
    }
};

export const replaceResourceAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/replace_resources_in_module`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Replace: ${error.message}`);
    }
};

export const replaceLectureResourceAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/replace_resources_in_lecture`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Replace: ${error.message}`);
    }
};

export const deleteResourceAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_resources_from_module`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Delete: ${error.message}`);
    }
};

export const deleteLectureResourceAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_resources_from_lecture`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Delete: ${error.message}`);
    }
};

export const addResourceToModuleAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/add_resources_to_module`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in adding resource to module: ${error.message}`);
    }
};

export const addToWaitlistAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/add_user_to_project_waitlist`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Add to Waitlist: ${error.message}`);
    }
};

export const registerUser = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/register_user`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Register User: ${error.message}`);
    }
};

export const fetchAllUsersAPI = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/users`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Users: ${error.message}`);
    }
};

export const fetchUserAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/user`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching User details: ${error.message}`);
    }
};

export const fetchQuAPIVaultAPI = async () => {
    const formData = new FormData();
    formData.append("username", await getUser());
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/quAPIVault`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching QuAPIVault details: ${error.message}`);
    }
};

export const updateUserCategoryAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_category`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Updating User Category: ${error.message}`);
    }
};


export const createCourseAPI = async (formData) => {
    formData.append("username", await getUser());
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/create_course`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Creating Course: ${error.message}`);
    }
};

export const fetchAllCoursesAPI = async () => {
    const formData = new FormData();
    const username = await getUser();
    formData.append("username", username);

    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/courses`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching All Courses: ${error.message}`);
    }
};


export const updateCourseDetails = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_course_info`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Updating Course Information: ${error.message}`);
    }
};

export const addModuleToCourse = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/add_module`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Adding Module to Course: ${error.message}`);
    }
};

export const updateModuleDetails = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_module_info`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Updating Module Information: ${error.message}`);
    }
};

export const updateSelectedLabsInModule = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_selected_labs_info`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Updating Selected Labs in Module: ${error.message}`);
    }
};


export const fetchCourseAPI = async (courseID) => {
    console.log(getUser());
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/get_course/${courseID}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Course Details: ${error.message}`);
    }
};

export const deleteCourseAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_course`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Deleting Course: ${error.message}`);
    }
};

export const createLectureAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/create_lecture`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Creating Lecture: ${error.message}`);
    }
};

export const createLabAPI = async (formData) => {
    const username = await getUser();
    formData.append("username", username);
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/create_lab`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Creating Lab: ${error.message}`);
    }
};

export const updateLabDetails = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_lab_info`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Updating Lab Information: ${error.message}`);
    }
};

export const updateLabTagsAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_lab_tags`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Updating Lab Tags: ${error.message}`);
    }
};

export const updateCourseTagsAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_course_tags`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Updating Course Tags: ${error.message}`);
    }
};

export const updatePodcastTagsAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_podcast_tags`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Updating Podcast Tags: ${error.message}`);
    }
};

export const updateWritingTagsAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_writing_tags`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Updating Writing Tags: ${error.message}`);
    }
};

export const fetchLectureAPI = async (lectureID) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/get_lecture/${lectureID}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Lecture: ${error.message}`);
    }
};

export const fetchAllLecturesAPI = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/lectures`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching All Lectures: ${error.message}`);
    }
};

export const fetchAllLabsAPI = async () => {
    try {
        const formData = new FormData();
        formData.append("username", await getUser());
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/labs`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching All Labs: ${error.message}`);
    }
};

export const deleteLectureAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_lecture`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Deleting Lecture: ${error.message}`);
    }
};

export const deleteLabAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_lab`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Deleting Lab: ${error.message}`);
    }
};

export const addResourceToLectureAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/add_resources_to_lecture`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in adding resource to Lecture: ${error.message}`);
    }
};

export const fetchPDFAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/fetch_pdf`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response;
    } catch (err) {
        throw new Error(`Error fetching file: ${err.message}`);
    }
};

export const submitModuleForOutlineGeneration = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/submit_module_for_outline_generation`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(
            `Error submitting module for outline generation": ${error.message}`
        );
    }
};

export const submitModuleForContentGeneration = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/submit_module_for_content_generation`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(
            `Error submitting module for content generation": ${error.message}`
        );
    }
};

export const submitModuleForStructureGeneration = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/submit_module_for_structure_generation`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(
            `Error submitting module for structure generation": ${error.message}`
        );
    }
};

export const submitModuleForDeliverables = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/submit_module_for_deliverables_generation`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(
            `Error submitting module for final deliverables": ${error.message}`
        );
    }
};

export const submitModuleForPublishing = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/submit_for_publishing_pipeline`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(
            `Error submitting module for Publishing": ${error.message}`
        );
    }
};

export const submitCourseForPublishing = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/submit_course_for_publishing_pipeline`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(
            `Error submitting module for Publishing": ${error.message}`
        );
    }
};

export const fetchQUSkillBridgeCourseID = async (courseID) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/fetch_quskillbridge_course_id/${courseID}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Course Details: ${error.message}`);
    }
};

export const generateCourseOutlineAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/generate_course_outline`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error generating course outline": ${error.message}`);
    }
};

export const createWritingAPI = async (formData) => {
    try {
        formData.append("username", await getUser());
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/create_writing`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error creating writing ${error.message}`);
    }
};

export const fetchWritingTemplatesAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/generate_templates`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error creating writing templates ${error.message}`);
    }
};

export const addResourcesToWritingAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/add_resources_to_writing`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error add resource to writing ${error.message}`);
    }
};

export const regenerateWritingAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/regenerate_outline`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error regenerating outline to writing ${error.message}`);
    }
};

export const deleteWritingResourceAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_resources_from_writing`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error deleting resource from writing ${error.message}`);
    }
};

export const saveLatestVersionAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/save_writing`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error saving to latest version": ${error.message}`);
    }
};

export const fetchAllWritingsAPI = async () => {
    try {
        const formData = new FormData();
        formData.append("username", await getUser());
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/writings`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching All Writings: ${error.message}`);
    }
};

export const fetchWritingAPI = async (writingId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/get_writing/${writingId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Writing: ${error.message}`);
    }
};

export const convertToPDFAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/convert_to_pdf`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Converting to PDF": ${error.message}`);
    }
};

export const deleteWritingAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_writing`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error deleting writing ${error.message}`);
    }
};

export const addArtifactToCourse = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/add_artifact_to_course`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error adding external entity to course: ${error.message}`);
    }
};

{
    /* Labs Designer APIs*/
}
export const fetchLabAPI = async (labID) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/get_lab/${labID}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Lab: ${error.message}`);
    }
};

export const addResourceToLabAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/add_resources_to_lab`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in adding resource to Lab: ${error.message}`);
    }
};

export const replaceLabResourceAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/replace_resources_in_lab`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Replacing Resource in Labs: ${error.message}`);
    }
};

export const deleteLabResourceAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_resources_from_lab`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Deleting Resource from Labs: ${error.message}`);
    }
};

export const saveConceptLabIdeaAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/save_concept_lab_idea`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error saving business use case": ${error.message}`);
    }
};

export const saveIdeaAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/save_concept_lab_idea`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error saving idea": ${error.message}`);
    }
};

export const saveBusinessUseCaseAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/save_business_use_case`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error saving business use case": ${error.message}`);
    }
};

export const saveTechnicalSpecificationsAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/save_technical_specifications`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error saving business use case": ${error.message}`);
    }
};

export const regenerateFeedbackAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/regenerate_with_feedback`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Regenerating Feedback: ${error.message}`);
    }
};

export const convertToPDFForLabAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/convert_to_pdf_for_lab`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Converting to PDF for Lab: ${error.message}`);
    }
};

export const generateConceptLabIdeaAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/generate_idea_for_concept_lab`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Submitting Resources in Labs: ${error.message}`);
    }
};

export const generateBusinessUseCaseAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/generate_business_use_case_for_lab`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Replacing Resource in Labs: ${error.message}`);
    }
};

export const generateTechnicalSpecificationsAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/generate_technical_specifications_for_lab`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Generating Technical Specification in Labs: ${error.message}`);
    }
};

export const saveLabInstructionsAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/save_lab_instructions`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Replacing Resource in Labs: ${error.message}`);
    }
};

export const generateWritingOutlineAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/writing_outline`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error generating outline: ${error.message}`);
    }
};

export const submitLabForGenerationAPI = async (formData) => {
    try {
        formData.append("username", await getUser());
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/submit_lab_for_generation`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error submitting Lab for Generation": ${error.message}`);
    }
};

export const createGitHubIssueAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/create_github_issue`,
            {
                method: "POST",
                body: formData,
            }
        );
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Creating GitHub Issue": ${error.message}`);
    }
};

export const createPodcastAPI = async (formData) => {
    try {
        formData.append("username", await getUser());
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/create_podcast`,
            {
                method: "POST",
                body: formData,
            }
        );
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Creating Podcast: ${error.message}`);
    }
};

export const fetchAllPodcastsAPI = async () => {
    try {
        const formData = new FormData();
        formData.append("username", await getUser());
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/podcasts`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching All Podcasts: ${error.message}`);
    }
};

export const generatePodcastOutlineAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/generate_podcast_outline`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error generating podcast outline": ${error.message}`);
    }
};

export const fetchPodcastAPI = async (podcastID) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/get_podcast/${podcastID}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Podcast Details: ${error.message}`);
    }
};

export const deletePodcastAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_podcast`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Deleting Podcast: ${error.message}`);
    }
};

export const updatePodcastDetails = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_podcast_info`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Updating Podcast Information: ${error.message}`);
    }
};

export const convertToAudioAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/generate_audio_for_podcast`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error generating podcast audio": ${error.message}`);
    }
};

export const fetchNotifications = async (username) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/notifications/${username}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Notifications: ${error.message}`);
    }
};

export const markNotificationAsReadAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/toggle_notification_status`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Marking Notifications as Read": ${error.message}`);
    }
};

// To be implemented:

export const fetchTemplatesAPI = async () => {
    /*
        Returns a list of the templates objects:
        template object contains:
        {
            _id: str,
            name: str,
            note: str
        }
      */
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/templates`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Templates: ${error.message}`);
    }
};

export const fetchTemplateDetailsAPI = async (templateID) => {
    /*
        Returns a template object:
        template object contains:
        {
            _id: str,
            name: str,
            note: str,
            form_fields: list of form_field objects
        }
        */
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/template_details`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ template_id: templateID }),
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Template Details: ${error.message}`);
    }
};

export const fetchSampleDataAPI = async (templateID) => {
    /*
            Returns a list of sample data objects:
            sample data object contains:
            {
                sample_data: dict
            }
            */
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/sample_data`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ template_id: templateID }),
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Sample Data: ${error.message}`);
    }
};

export const fetchSampleReportAPI = async (templateID) => {
    /*
            Returns a list of sample report objects:
            sample report object contains:
            {
                sample_report: dict of id, name, url
            }
            */
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/sample_report`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ template_id: templateID }),
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Sample Report: ${error.message}`);
    }
};

export const createModelProjectAPI = async (formData) => {
    try {
        formData.append("username", await getUser());
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/create_model_project`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Creating Model Project: ${error.message}`);
    }
};

export const fetchModelProjects = async () => {
    try {
        const formData = new FormData();
        formData.append("username", await getUser());
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/model_projects`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Model Projects: ${error.message}`);
    }
};

export const deleteReportAPI = async (formData) => {
    /*
                        Requires:
                        project_id: str
                        template_id: str
                        report_id: str
    
                        Returns True if successful
                        */
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_report`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Deleting Report: ${error.message}`);
    }
};

export const deleteModelProjectAPI = async (formData) => {
    /*
                                Requires:
                                project_id: str
    
                                Returns True if successful
                                */
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_model_project`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Deleting Model Project: ${error.message}`);
    }
};

export const importTemplatesToProjectAPI = async (formData) => {
    /*
                                    Requires:
                                    project_id: str
                                    template_ids: list of str
    
                                    Returns project object if successful
                                    */
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/import_templates_to_project`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Importing Templates to Project: ${error.message}`);
    }
};

export const saveProjectTemplateDataAPI = async (formData) => {
    /*
        Requires:
        project_id: str
        template_id: str
        template_data: list of dict
    
        Returns list of reports if successful
        */
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/save_project_template_data`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Saving Project Template Data: ${error.message}`);
    }
};

export const consolidateReportsAPI = async (formData) => {
    /*
            Requires:
            project_id: str
    
            Returns url of the consolidated report
            */
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/consolidate_reports`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Consolidating Reports: ${error.message}`);
    }
};

export const getCompletionStatusAPI = async (formData) => {
    /*
            Requires:
            project_id: str
    
            Returns completion status of the project True or False
            */
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/get_completion_status`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Getting Completion Status: ${error.message}`);
    }
};

export const fetchTemplates = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/templates`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching All Templates: ${error.message}`);
    }
};

export const fetchReports = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/project_template_reports`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Reports: ${error.message}`);
    }
};

export const fetchTemplateFormData = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/template_details`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(
            `Error in Fetching Template's form data": ${error.message}`
        );
    }
};

export const submitTemplateData = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/save_project_template_data`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Saving Response": ${error.message}`);
    }
};

export const fetchModelProjectAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/model_project`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Model Project": ${error.message}`);
    }
};


export const rewriteWritingAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/rewrite_writing`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error rewriting writing ${error.message}`);
    }
}

export const enhancePromptAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/generate_prompt`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error generating prompt ${error.message}`);
    }
}


export const fetchCourseOutlineMetaPromptAPI = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/outline_prompt`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Prompt: ${error.message}`);
    }
};

export const fetchPodcastPromptAPI = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/podcast_prompt`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Prompt: ${error.message}`);
    }
};

export const fetchWritingPromptAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/prompt`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Prompt: ${error.message}`);
    }
};

export const fetchLabPromptAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/lab_prompt`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Prompt: ${error.message}`);
    }
};

export const updateSelectedIdea = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_selected_idea`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error updating selected idea: ${error.message}`);
    }
};

export const updateLabIdeas = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_lab_ideas`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error updating lab ideas: ${error.message}`);
    }
};

export const updateLabStatus = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/update_lab_design_status`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error updating lab status: ${error.message}`);
    }
};

export const getLabIdeas = async (labID) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/get_lab_ideas/${labID}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error fetching lab ideas: ${error.message}`);
    }
};

export const getIssuesAPI = async (lab_id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/get_lab_issues/${lab_id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Issues: ${error.message}`);
    }
}

export const validateKeyAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/validate_key`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error updating lab status: ${error.message}`);
    }
}

export const getTemplatesAPI = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/get_templates`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Templates: ${error.message}`);
    }
}


export const validateTemplateAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/validate_template`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error validating template: ${error.message}`);
    }
}

export const storeCustomTemplateAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/store_custom_template`,
            {
                method: "POST",
                body: formData,

            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error storing custom template: ${error.message}`);
    }
}

export const createQUApiVaultKey = async (formData) => {
    try {
        formData.append("username", await getUser());
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/create_quAPIVault`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error submitting key for API Vault": ${error.message}`);
    }
};

export const editQUApiVaultKey = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/edit_quAPIVault`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error submitting key for API Vault": ${error.message}`);
    }
};

export const deleteQUApiVaultKey = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/delete_quAPIVault`,
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error submitting key for API Vault": ${error.message}`);
    }
};

export const cloneEntryAPI = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/clone_entry`,
            {
                method: "POST",
                body: formData,

            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error cloning entry: ${error.message}`);
    }
}