import adminErrorPage from "views/Pages/AdminErrorPage.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import VerificationPage from "views/Pages/VerificationPage";
import RegisterPage from "views/Pages/RegisterPage.js";
import UpdatePasswordPage from "views/Pages/UpdatePasswordPage";
import PricingPage from "views/Pages/PricingPage";
import ResetPasswordPage from "views/Pages/ResetPasswordPage";
import UserProfile from "views/Pages/UserProfile.js";
import QuApiVault from "views/Dashboard/QuApiVault.js";
import NewQuApiVault from "views/Dashboard/NewQuApiVault.js";

// core components/views for Admin layout
import Projects from "views/Dashboard/Projects";
import Experiments from "views/Dashboard/Experiments";
import UserTablePage from "views/Pages/UserTablePage";
import CourseProjects from "views/Dashboard/CourseProjects";
import CourseDesign from "views/Dashboard/Course/CourseDesign";
import ModulesDesign from "views/Dashboard/ModulesDesign";
import ModulesBoard from "views/Dashboard/Course/ModulesBoard";
import LandingPage from "views/Dashboard/LandingPage";
import LectureDesign from "views/Dashboard/Lecture/LectureDesign";
import LectureBoard from "views/Dashboard/Lecture/LectureBoard";
import WritingsBoard from "views/Dashboard/Writing/WritingsBoard";
import WritingPublisher from "views/Dashboard/Writing/WritingPublisher";
import LabsDesign from "views/Dashboard/Lab/LabsDesign";
import LabsBoard from "views/Dashboard/Lab/LabsBoard";
import PodcastDesign from "views/Dashboard/Podcast/PodcastDesign";
import PodcastBoard from "views/Dashboard/Podcast/PodcastBoard";
import QuTemplates from "views/Dashboard/QUTemplates";
import TemplateDesign from "views/Dashboard/Templates/TemplateDesign";
import ModelHomePage from "views/Pages/ModelHomePage";
import ModelPage from "views/Pages/ModelPage";
import HomePage from "views/Pages/HomePage";

var dashRoutes = [
  {
    path: "/auth/login-page",
    hidden: true,
    name: "Login Page",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/error-page",
    hidden: true,
    name: "error",
    component: ErrorPage,
    layout: "/auth",
  },
  {
    path: "/error-page",
    hidden: true,
    name: "error",
    component: adminErrorPage,
    layout: "/admin",
  },
  {
    path: "/login-page",
    hidden: true,
    name: "Login Page",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/verification-page",
    hidden: true,
    name: "Verification Page",
    component: VerificationPage,
    layout: "/auth",
  },
  {
    path: "/register-page",
    hidden: true,
    name: "Register Page",
    component: RegisterPage,
    layout: "/auth",
  },
  {
    path: "/reset-password-page",
    hidden: true,
    name: "Reset Password Page",
    component: ResetPasswordPage,
    layout: "/auth",
  },
  {
    path: "/update-password-page",
    hidden: true,
    name: "Update Password Page",
    component: UpdatePasswordPage,
    layout: "/auth",
  },
  {
    path: "/pricing-page",
    hidden: true,
    name: "Pricing Page",
    component: PricingPage,
    layout: "/auth",
  },
  {
    path: "/user-page",
    hidden: true,
    name: "User Profile",
    mini: "UP",
    component: UserProfile,
    layout: "/admin",
    redirect: "/auth/login-page",
  },

  {
    path: "/qu-api-vault",
    hidden: true,
    name: "QuApiVault",
    mini: "AV",
    // component: QuApiVault,
    component: NewQuApiVault,
    layout: "/admin",
    redirect: "/auth/login-page",
  },
  {
    path: "/users",
    hidden: false,
    name: "Users",
    mini: "U",
    component: UserTablePage,
    layout: "/admin",
    redirect: "/auth/login-page",
    userTypes: ["admin"],
  },
  {
    path: "/landing-page",
    name: "QuCreate",
    hidden: true,
    component: LandingPage,
    layout: "/admin",
  },
  {
    path: "/home-page",
    name: "Home",
    hidden: true,
    component: HomePage,
    layout: "/admin",
  },
  {
    path: "/templates/:templateID",
    name: "Template",
    hidden: true,
    component: TemplateDesign,
    layout: "/admin",
  },
  {
    path: "/templates",
    name: "QuModelStudio",
    hidden: true,
    component: QuTemplates,
    layout: "/admin",
  },
  {
    path: "/model_home_page/:projectID/templates/:templateID",
    name: "Template",
    hidden: true,
    component: TemplateDesign,
    layout: "/admin",
  },
  {
    path: "/model_home_page/:projectID",
    name: "Project",
    hidden: true,
    component: ModelPage,
    layout: "/admin",
  },
  {
    path: "/model_home_page",
    name: "Model Projects",
    hidden: true,
    component: QuTemplates,
    layout: "/admin",
  },

  {
    path: "/projects/experiments/:projectID",
    name: "Experiments",
    hidden: true,
    component: Experiments,
    layout: "/admin",
  },
  {
    path: "/writings/:writingID",
    name: "Writings 2",
    hidden: true,
    component: WritingPublisher,
    layout: "/admin",
  },
  {
    path: "/writings",
    name: "Writings Dashboard",
    hidden: true,
    component: WritingsBoard,
    layout: "/admin",
  },
  {
    path: "/research_report/:writingID",
    name: "Research Report",
    hidden: true,
    component: WritingPublisher,
    layout: "/admin",
  },
  {
    path: "/research_report",
    name: "Research Report Dashboard",
    hidden: true,
    component: WritingsBoard,
    layout: "/admin",
  },
  {
    path: "/white_paper/:writingID",
    name: "White Paper",
    hidden: true,
    component: WritingPublisher,
    layout: "/admin",
  },
  {
    path: "/white_paper",
    name: "White Paper Dashboard",
    hidden: true,
    component: WritingsBoard,
    layout: "/admin",
  },
  {
    path: "/blog/:writingID",
    name: "blog",
    hidden: true,
    component: WritingPublisher,
    layout: "/admin",
  },
  {
    path: "/blog",
    name: "Blog Dashboard",
    hidden: true,
    component: WritingsBoard,
    layout: "/admin",
  },
  {
    path: "/news_letter/:writingID",
    name: "Newsletter",
    hidden: true,
    component: WritingPublisher,
    layout: "/admin",
  },
  {
    path: "/news_letter",
    name: "Newsletter Dashboard",
    hidden: true,
    component: WritingsBoard,
    layout: "/admin",
  },
  {
    path: "/case_study/:writingID",
    name: "Case Study",
    hidden: true,
    component: WritingPublisher,
    layout: "/admin",
  },
  {
    path: "/case_study",
    name: "Case Study Dashboard",
    hidden: true,
    component: WritingsBoard,
    layout: "/admin",
  },
  {
    path: "/key_insights/:writingID",
    name: "Key Insights",
    hidden: true,
    component: WritingPublisher,
    layout: "/admin",
  },
  {
    path: "/key_insights",
    name: "Key Insights Dashboard",
    hidden: true,
    component: WritingsBoard,
    layout: "/admin",
  },
  {
    path: "/handout/:writingID",
    name: "Handout",
    hidden: true,
    component: WritingPublisher,
    layout: "/admin",
  },
  {
    path: "/handout",
    name: "Handout Dashboard",
    hidden: true,
    component: WritingsBoard,
    layout: "/admin",
  },
  {
    path: "/project_plan/:writingID",
    name: "Project Plan",
    hidden: true,
    component: WritingPublisher,
    layout: "/admin",
  },
  {
    path: "/project_plan",
    name: "Project Plan Dashboard",
    hidden: true,
    component: WritingsBoard,
    layout: "/admin",
  },
  {
    path: "/project-design/courses/:courseID/module/:moduleID",
    name: "Module Design",
    hidden: true,
    component: ModulesBoard,
    layout: "/admin",
  },
  {
    path: "/project-design/courses/:courseID",
    name: "Project Design",
    hidden: true,
    component: ModulesDesign,
    layout: "/admin",
  },
  {
    path: "/project-design",
    name: "Project Design Dashboard",
    hidden: true,
    component: CourseDesign,
    layout: "/admin",
  },
  {
    path: "/templatedesign",
    name: "Template Design Dashboard",
    hidden: true,
    component: TemplateDesign,
    layout: "/admin",
  },
  {
    path: "/podcastdesign/podcast/:podcastID",
    name: "Podcast Design",
    hidden: true,
    component: PodcastBoard,
    layout: "/admin",
  },
  {
    path: "/podcastdesign",
    name: "Podcast Design Dashboard",
    hidden: true,
    component: PodcastDesign,
    layout: "/admin",
  },
  {
    path: "/labdesign/lab/:labID",
    name: "Labs Design Dashboard",
    hidden: true,
    component: LabsBoard,
    layout: "/admin",
  },
  {
    path: "/labdesign",
    name: "Concept Labs",
    hidden: true,
    component: LabsDesign,
    layout: "/admin",
  },
  {
    path: "/module/:lectureID",
    name: "Module Design Dashboard",
    hidden: true,
    component: LectureBoard,
    layout: "/admin",
  },
  {
    path: "/module",
    name: "Module Design Dashboard",
    hidden: true,
    component: LectureDesign,
    layout: "/admin",
  },

  {
    path: "/projects",
    name: "Projects",
    hidden: true,
    component: Projects,
    layout: "/admin",
  },
  {
    path: "/courseprojects",
    name: "Courses",
    hidden: true,
    component: CourseProjects,
    layout: "/admin",
  },
  // {
  //   collapse: true,
  //   hidden: true, //Disabled QuToolBox
  //   name: "QuToolBox",
  //   icon: AppsIcon,
  //   layout: "/admin",
  //   views: [
  //     {
  //       path: "/QuToolBox/Data",
  //       name: "Data",
  //       icon: StorageIcon,
  //       component: QUApi,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/QuToolBox/Explore",
  //       name: "Explore",
  //       icon: ExploreIcon,
  //       component: QUApi,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/QuToolBox/Processing",
  //       name: "Data Processing",
  //       icon: CompareArrowsIcon,
  //       component: QUApi,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/QuToolBox/Modeling-Tools",
  //       name: "Modeling Tools",
  //       icon: BuildIcon,
  //       component: QUApi,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/QuToolBox/Models",
  //       name: "Models",
  //       icon: ViewModuleIcon,
  //       component: QUApi,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/QuToolBox/Explain",
  //       name: "Explain",
  //       icon: AssessmentIcon,
  //       component: QUApi,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/QuToolBox/Fairness",
  //       name: "Fairness and Bias",
  //       icon: ThumbsUpDownIcon,
  //       component: QUApi,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/QuToolBox/Report",
  //       name: "Report",
  //       icon: TableChartIcon,
  //       component: QUApi,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/QuToolBox/Audit-Checklist",
  //       name: "Audit Checklist",
  //       icon: PlaylistAddCheckIcon,
  //       component: APIDetails,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/QuToolBox/Case-studies",
  //       name: "Case studies",
  //       icon: WorkIcon,
  //       component: QUApi,
  //       layout: "/admin",
  //     },
  //     // {
  //     //   path: "/resources",
  //     //   name: "Resources",
  //     //   icon: WorkIcon,
  //     //   component: Resources,
  //     //   layout: "/admin",
  //     // },
  //   ],
  // },
];
export default dashRoutes;
