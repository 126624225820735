// React and Hooks
import React, { useEffect, useState, useCallback } from "react";

// React Router
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";

// Material-UI Core Components
import {
  CircularProgress,
  Tooltip,
  Typography,
  Icon,
  Divider,
  Grid,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Material-UI Icons
import { CloudQueue, Create, DoneAll, RateReview } from "@material-ui/icons";

// Custom Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeadingWithDescription from "components/Heading/HeadingWithDescription.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// Custom Styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

// Context
import NotificationContext from "views/Components/Context.js";

// Modals
import ImportTemplatesModal from "views/Components/ImportTemplatesModal";

// API Utilities
import {
  fetchModelProjectAPI,
  fetchTemplates,
  importTemplatesToProjectAPI,
  getCompletionStatusAPI,
  consolidateReportsAPI,
} from "views/APIs/APIUtility.js";
import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles(styles);
const usedashboardStyle = makeStyles(dashboardStyle);
export default function ModelPage() {
  // Import images from assets
  const images = require.context("assets/img", true);

  // Get routing info from React Router
  const { path, url } = useRouteMatch();

  // Material-UI styling classes
  const dashboardClasses = usedashboardStyle();
  const classes = useStyles();

  // Get projectID from URL parameters
  const { projectID } = useParams();

  // Declare state variables
  const [project, setProject] = useState();
  const [completionStatus, setCompletionStatus] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    message: "",
  });
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [importTemplatesModal, setImportTemplatesModal] = useState(false);
  const [error, setError] = useState(null);
  const [displayTemplates, setDisplayTemplates] = useState();
  const [searchValue, setSearchValue] = useState("");

  // Notification context for displaying alerts
  const { showNotification } = React.useContext(NotificationContext);

  // Array of Material-UI icons representing different statuses
  const statusIcons = [
    <Create />,
    <CloudQueue />,
    <RateReview />,
    <CloudQueue />,
    <RateReview />,
    <CloudQueue />,
    <RateReview />,
    <CloudQueue />,
    <DoneAll />,
  ];

  // Function to return a status icon based on template status (hardcoded for now)
  const getStatusIcon = (status) => {
    return statusIcons[0];
  };

  // Fetch project details and return the list of template IDs for the project
  const fetchProject = useCallback(async () => {
    if (!projectID) return; // Exit if no projectID provided
    setLoadingState({ isLoading: true, message: "Loading Project..." });

    const projectTemplates = [];
    try {
      const formData = new FormData();
      formData.append("project_id", projectID);
      const data = await fetchModelProjectAPI(formData);

      // Store project data in state
      setProject(data);
      data.templates.forEach((template) => {
        projectTemplates.push(template._id);
      });
      setDisplayTemplates(data.templates);
    } catch (err) {
      console.error("Error fetching project:", err);
      setError(err.message || "Failed to load project data.");
    } finally {
      setLoadingState({ isLoading: false, message: "" });
      return projectTemplates;
    }
  }, [projectID]);

  // Fetch templates that are not already associated with the project
  const fetchTemplatesAPI = useCallback(async (projectTemplateIDs) => {
    setLoadingState({ isLoading: true, message: "Fetching templates..." });

    try {
      const response = await fetchTemplates();
      // Exclude template IDs already present in the project
      const excludedTemplateIDs = projectTemplateIDs || [];
      const filteredTemplates = response.filter(
        (template) => !excludedTemplateIDs.includes(template._id)
      );

      setTemplates(filteredTemplates);
    } catch (err) {
      console.error("Error fetching templates:", err);
      setError(err.message || "Failed to load templates.");
    } finally {
      setLoadingState({ isLoading: false, message: "" });
    }
  }, []);

  // useEffect: fetch project details and templates when projectID changes
  useEffect(() => {
    if (projectID) {
      const fetchData = async () => {
        const projectTemplates = await fetchProject(); // Fetch project details and template IDs
        fetchTemplatesAPI(projectTemplates); // Fetch templates excluding the ones already in the project
      };
      fetchData();
    }
  }, [projectID, fetchProject, fetchTemplatesAPI]);

  // Handler for updating project details (currently empty)
  const handleProjectDetailsChange = () => { };

  // Handler for template selection: import selected templates for the project
  const handleTemplateSelection = async () => {
    try {
      const formData = new FormData();
      formData.append("project_id", projectID);
      formData.append("template_ids", selectedTemplates);
      await importTemplatesToProjectAPI(formData);
    } catch (err) {
      console.error("Error importing template:", err);
    } finally {
      setLoadingState({ isLoading: false, message: "" });
      handleTemplateSelectionModalClose();
      window.location.reload();
    }
  };

  // useEffect: fetch the project completion status when projectID updates
  useEffect(() => {
    const fetchData = async () => {
      const formData = new FormData();
      formData.append("project_id", projectID);
      const response = await getCompletionStatusAPI(formData);
      setCompletionStatus(response);
    };
    fetchData();
  }, [projectID]);

  // Handler for closing the template selection modal and clearing selections
  const handleTemplateSelectionModalClose = () => {
    setSelectedTemplates([]);
    setImportTemplatesModal(false);
  };


  const searchInputValue = (e) => {
    setSearchValue(e);
    if (e === "") {
      setDisplayTemplates(project.templates);
      return;
    }
    const newList = project.templates.filter((template) => {
      return template.name.toLowerCase().includes(e.toLowerCase()) || template.note.toLowerCase().includes(e.toLowerCase());
    });
    setDisplayTemplates(newList);
  };

  // If loading or project data not present, display a loading indicator
  if (loadingState.isLoading || !project) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress color="quCreateColor" />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loadingState.message}
        </Typography>
      </Box>
    );
  }

  // Function to consolidate project reports and open the report URL in a new tab
  const consolidateReports = async () => {
    try {
      const formData = new FormData();
      formData.append("project_id", projectID);
      const responseUrl = await consolidateReportsAPI(formData);
      window.open(responseUrl, "_blank");
    } catch (err) {
      showNotification("Error consolidating reports. Please try again later.", "danger");
    }
  };

  return (
    <div>
      <Divider style={{ marginBottom: "20px" }} />

      <Switch>
        <Route exact path={path}>
          <HeadingWithDescription
            title={project.name}
            shortDescription={project.description}
            longDescription={project.description}
            textAlign="left"
            onChange={handleProjectDetailsChange}
          />

          <SearchBar
            style={{ marginBottom: "20px" }}
            value={searchValue}
            onChange={(newValue) => searchInputValue(newValue)}
            onRequestSearch={(newValue) => searchInputValue(newValue)}
          />
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} style={{ textAlign: "center" }}>
              <Button
                color="info"
                onClick={() => setImportTemplatesModal(true)}
                size="lg"
                className={classes.buttons}
              >
                <Icon>import_export</Icon> &nbsp; Import Templates
              </Button>
              {/* <Tooltip
                title="You can consolidate the reports after completion of all steps"
                placement="top"
              >
                <div style={{ display: "inline" }}>
                  <Button
                    color="info"
                    // onClick={() => setImportTemplatesModal(true)}
                    size="lg"
                    onClick={() => consolidateReports()}
                    disabled={!completionStatus}
                    className={classes.buttons}
                  >
                    Consolidate Reports
                  </Button>
                </div>
              </Tooltip> */}
            </GridItem>
          </GridContainer>

          <GridContainer>
            {project.templates.length === 0 ? (
              <h3>Import a template to get started.</h3>
            ) : (
              <Grid container spacing={2}>
                {displayTemplates.map((template, index) => (
                  <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                    <Link
                      to={{
                        pathname: `${url}/templates/${template._id}`,
                      }}
                    // onClick={() => setModuleID(module.module_id)} // Set the id on click
                    >
                      <Card style={{ minHeight: "245px", maxHeight: "245px" }}>
                        <CardHeader stats icon>
                          <Tooltip
                            title={template.name}
                            placement="bottom"
                            classes={{ tooltip: dashboardClasses.tooltip }}
                          >
                            <div>
                              <CardIcon color="quSandBox">
                                {template.name.substring(0, 30) +
                                  (template.name.length > 30 ? "..." : "")}
                              </CardIcon>
                            </div>
                          </Tooltip>
                          <h3 className={classes.cardTitle}>
                            <small>
                              <img
                                src={images("./qucreate_logo.png")}
                                alt="Template Image"
                                className={classes.thumbnail}
                                style={{
                                  width: "100px",
                                }}
                              />
                            </small>
                          </h3>
                        </CardHeader>
                        <CardBody
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        />
                        <CardFooter>
                          <Tooltip
                            title={template.note}
                            placement="bottom"
                            classes={{ tooltip: dashboardClasses.tooltip }}
                          >
                            <h5
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                width: "100%",
                                cursor: "pointer",
                              }}
                            >
                              {template.note}
                            </h5>
                          </Tooltip>
                        </CardFooter>
                        <CardFooter stats style={{}}>
                          <div>
                            {getStatusIcon(template.status)} {template.status}
                          </div>
                        </CardFooter>
                      </Card>
                    </Link>
                  </GridItem>
                ))}
                {/* {project.consolidated_report && (
                  <GridItem
                    key={project.templates.length}
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                  >
                    <Link
                      to={{
                        pathname: `${url}/consolidated_report`,
                      }}
                      // onClick={() => setModuleID(module.module_id)} // Set the id on click
                    >
                      <Card style={{ minHeight: "245px", maxHeight: "245px" }}>
                        <CardHeader stats icon>
                          <Tooltip
                            title="Consolidated Report"
                            placement="bottom"
                            classes={{ tooltip: dashboardClasses.tooltip }}
                          >
                            <div>
                              <CardIcon color="quSandBox">
                                Consolidated Report
                              </CardIcon>
                            </div>
                          </Tooltip>
                          <h3 className={classes.cardTitle}>
                            <small>
                              <img
                                src={images("./qucreate_logo.png")}
                                alt="Template Image"
                                className={classes.thumbnail}
                                style={{
                                  width: "100px",
                                }}
                              />
                            </small>
                          </h3>
                        </CardHeader>
                        <CardBody
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        />
                        <CardFooter>
                          <Tooltip
                            title="Consolidated Report"
                            placement="bottom"
                            classes={{ tooltip: dashboardClasses.tooltip }}
                          >
                            <h5
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                width: "100%",
                                cursor: "pointer",
                              }}
                            >
                              Consolidated Report
                            </h5>
                          </Tooltip>
                        </CardFooter>
                        <CardFooter stats style={{}}>
                          <div>
                            <DoneAll /> Completed
                          </div>
                        </CardFooter>
                      </Card>
                    </Link>
                  </GridItem>
                )} */}
              </Grid>
            )}
          </GridContainer>
        </Route>
      </Switch>

      <ImportTemplatesModal
        open={importTemplatesModal}
        onClose={handleTemplateSelectionModalClose}
        templates={templates}
        selectedTemplates={selectedTemplates}
        setSelectedTemplates={setSelectedTemplates}
        handleTemplateSelection={handleTemplateSelection}
      />
    </div>
  );
}
