// React and hooks
import React, { useEffect } from "react";

// Date utility functions from date-fns
import {
  addHours,
  startOfHour,
  subHours,
  eachHourOfInterval,
  differenceInHours,
  format,
  roundToNearestMinutes,
  addMinutes,
  subMinutes,
  differenceInMinutes,
  startOfDay,
  startOfTomorrow,
  subDays,
  getHours,
  addDays,
  differenceInDays,
} from "date-fns";

// Material-UI core components and icons
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// Layout components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// AWS Amplify for authentication
import { Auth } from "aws-amplify";

// Charting components from Recharts
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  // BarChart, Bar,
} from "recharts";

// React Table component
import ReactTable from "components/ReactTable/ReactTable.js";

// Styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
// import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';

const useFormStyles = makeStyles(regularFormsStyle);
const useextendedFormsStyles = makeStyles(extendedFormsStyle);

// var Chartist = require("chartist");
// require('chartist-plugin-legend');

export default function LogChart() {
  // Constants, Styles, Colors and State Variables
  const COLOR = [
    "#55acee",
    "#dd4b39",
    "#0976b4",
    "#cc2127",
    "#e52d27",
    "#35465c",
    "#1769ff",
    "#ea4c89",
    "#ff4500",
    "#3b5998",
  ];

  // Material-UI style hooks
  const extendedFormsClasses = useextendedFormsStyles();
  const formClasses = useFormStyles();

  // State variables for UI feedback and data storage
  const [alert, setAlert] = React.useState();
  const [logs, setlogs] = React.useState([]);
  const [samples, setsamples] = React.useState([]);
  const [columns, setcolumns] = React.useState([]);
  const [chart, setchart] = React.useState();
  const [subChart, setsubChart] = React.useState();
  const [operations, setoperations] = React.useState([]);
  const [resources, setresources] = React.useState([]);
  const [operation, setoperation] = React.useState("All");
  const [resource, setresource] = React.useState("All");

  // State variables for time period selection and handling
  const [unit, setunit] = React.useState(60);
  const [length, setlength] = React.useState(23);
  const [timeSelect, settimeSelect] = React.useState("1d");
  const [startHour, setstartHour] = React.useState();
  const [endHour, setendtHour] = React.useState();

  // Mapping of time options to unit and length
  const timeMap = {
    "1h": [5, 12],
    "3h": [15, 12],
    "12h": [60, 12],
    "1d": [60, 23],
    "1w": [720, 14],
    "1m": [1440, 30],
  };

  // Event Handlers for Select Changes and Period Change
  const handleResourceChange = (event) => {
    setresource(event.target.value);
  };

  const handleOperationChange = (event) => {
    setoperation(event.target.value);
  };

  // Change time period selection and reset filters
  const periodChange = (selectedTime) => {
    settimeSelect(selectedTime);
    setunit(timeMap[selectedTime][0]);
    setlength(timeMap[selectedTime][1]);
    setresource("All");
    setoperation("All");
  };

  // Helper functions to format time periods for charts
  const sixtyMinHelper = (time) => {
    return { time: format(time, "HH"), value: 0 };
  };

  const minHelper = (time) => {
    return { time: format(time, "HH:mm"), value: 0 };
  };

  const hourHelper = (time) => {
    return { time: format(time, "do H"), value: 0 };
  };

  const dateHelper = (time) => {
    return { time: format(time, "do"), value: 0 };
  };

  // Fetch logs from backend API when startHour is set
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      let token = user.signInUserSession.accessToken.jwtToken;
      if (!startHour) {
        return;
      }

      fetch(
        process.env.REACT_APP_BACK_END_URL +
          "/logservice?time=" +
          startHour.toISOString(),
        {
          method: "GET",
          mode: "cors",
          credentials: "omit",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      ).then(
        (res) => {
          res.json().then((data) => {
            setlogs(data.Items);
            setsamples(data.Items);
            // Build table columns based on first log's keys
            if (data.Items.length > 0) {
              const temp = [];
              Object.keys(data.Items[0]).map((key) =>
                temp.push({ Header: key, accessor: key })
              );
              setcolumns(temp);
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }, [startHour]);

  // Compute startHour and endHour based on selected time unit
  useEffect(() => {
    let start, end;
    if (unit === 60) {
      // Hourly: Round to next hour and subtract
      end = addHours(startOfHour(new Date()), 1);
      start = subHours(end, length);
    } else if (unit === 15 || unit === 5) {
      // Minutes: Round to nearest minute and subtract defined intervals
      end = addMinutes(roundToNearestMinutes(new Date()), 1);
      start = subMinutes(end, unit * length);
    } else if (unit === 720) {
      // Half-day periods: Calculate based on 12-hour chunks
      const noonIndex = parseInt(getHours(new Date()) / 12);
      end = addHours(startOfDay(new Date()), 12 * noonIndex);
      start = subHours(end, (unit * length) / 60);
    } else if (unit === 1440) {
      // Daily: Start of tomorrow and subtract days
      end = startOfTomorrow(new Date());
      start = subDays(end, length);
    }
    setstartHour(start);
    setendtHour(end);
  }, [timeSelect]);

  // Process logs to generate the main chart data series
  useEffect(() => {
    if (!logs || logs.length === 0) {
      setAlert("No Logs Availabe");
      return;
    } else {
      setAlert();
    }

    const series = [];
    const legends = [];
    const resourcesMap = {};

    logs.forEach((log) => {
      // Build resource mapping to filter later
      if (!(log["log_resource"] in resourcesMap)) {
        resourcesMap[log["log_resource"]] = [];
      }
      resourcesMap[log["log_resource"]].push(log);

      // Build a new series if this operation is encountered for the first time
      let rowIndex = legends.indexOf(log["log_operation"]);
      if (rowIndex === -1) {
        legends.push(log["log_operation"]);
        let period;
        if (unit === 60) {
          period = eachHourOfInterval({
            start: startHour,
            end: endHour,
          }).map((x) => sixtyMinHelper(x));
        } else if (unit === 15 || unit === 5) {
          period = [];
          for (let i = 0; i < length; i++) {
            period.push(addMinutes(startHour, unit * i));
          }
          period = period.map((x) => minHelper(x));
        } else if (unit === 720) {
          period = [];
          for (let i = 0; i < length; i++) {
            period.push(addHours(startHour, (unit * i) / 60));
          }
          period = period.map((x) => hourHelper(x));
        } else if (unit === 1440) {
          period = [];
          for (let i = 0; i < length; i++) {
            period.push(addDays(startHour, i));
          }
          period = period.map((x) => dateHelper(x));
        }
        series.push(period);
        rowIndex = legends.length - 1;
      }

      // Calculate column index for current log based on its timestamp
      const log_time = new Date(log["log_time"] + " UTC");
      let col;
      if (unit === 60) {
        const passHours = differenceInHours(endHour, log_time);
        col = length - passHours - 1;
      } else if (unit === 15 || unit === 5) {
        const passMin = differenceInMinutes(endHour, log_time);
        col = length - parseInt(passMin / unit) - 1;
      } else if (unit === 720) {
        const passHours = differenceInHours(endHour, log_time);
        col = length - parseInt(passHours / 12) - 1;
      } else if (unit === 1440) {
        const passDays = differenceInDays(endHour, log_time);
        col = length - passDays - 1;
      }
      // Update count if index is within bounds
      if (col >= 0 && col < length) {
        series[rowIndex][col]["value"] += 1;
      }
    });
    setresources(resourcesMap);
    setoperations(legends);
    setchart(series);
  }, [logs]);

  // Generate sub-chart series based on selected resource
  useEffect(() => {
    if (!resource || resource === "All") {
      return;
    }
    const series = [];
    // Initialize series for each operation
    operations.forEach(() => {
      let period;
      if (unit === 60) {
        period = eachHourOfInterval({
          start: startHour,
          end: endHour,
        }).map((x) => sixtyMinHelper(x));
      } else if (unit === 15 || unit === 5) {
        period = [];
        for (let i = 0; i < length; i++) {
          period.push(addMinutes(startHour, unit * i));
        }
        period = period.map((x) => minHelper(x));
      } else if (unit === 720) {
        period = [];
        for (let i = 0; i < length; i++) {
          period.push(addHours(startHour, (unit * i) / 60));
        }
        period = period.map((x) => hourHelper(x));
      } else if (unit === 1440) {
        period = [];
        for (let i = 0; i < length; i++) {
          period.push(addDays(startHour, i));
        }
        period = period.map((x) => dateHelper(x));
      }
      series.push(period);
    });
    // Process logs for the selected resource only
    resources[resource].forEach((log) => {
      const rowIndex = operations.indexOf(log["log_operation"]);
      const log_time = new Date(log["log_time"] + " UTC");
      let col;
      if (unit === 60) {
        const passHours = differenceInHours(endHour, log_time);
        col = length - passHours - 1;
      } else if (unit === 15 || unit === 5) {
        const passMin = differenceInMinutes(endHour, log_time);
        col = length - parseInt(passMin / unit) - 1;
      } else if (unit === 720) {
        const passHours = differenceInHours(endHour, log_time);
        col = length - parseInt(passHours / 12) - 1;
      } else if (unit === 1440) {
        const passDays = differenceInDays(endHour, log_time);
        col = length - passDays - 1;
      }
      if (col >= 0 && col < length) {
        series[rowIndex][col]["value"] += 1;
      }
    });
    setsubChart(series);
  }, [resource]);

  // Filter log samples based on selected operation and resource
  useEffect(() => {
    if (operation === "All" && resource === "All") {
      setsamples(logs);
    } else if (resource !== "All" && operation === "All") {
      setsamples(resources[resource]);
    } else if (resource === "All" && operation !== "All") {
      const filtered = logs.filter((item) => item.log_operation === operation);
      setsamples(filtered);
    } else {
      const filtered = resources[resource].filter(
        (item) => item.log_operation === operation
      );
      setsamples(filtered);
    }
  }, [operation, resource]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6} lg={2}>
        <div>
          <FormControl
            fullWidth
            className={extendedFormsClasses.selectFormControl}
          >
            <InputLabel
              htmlFor="operation"
              className={extendedFormsClasses.selectLabel}
            >
              Choose Operation
            </InputLabel>
            <Select
              MenuProps={{
                className: extendedFormsClasses.selectMenu,
              }}
              classes={{
                select: extendedFormsClasses.select,
              }}
              onChange={handleOperationChange}
              value={operation}
              inputProps={{
                name: "operation",
                id: "operation",
                value: operation,
              }}
            >
              <MenuItem
                classes={{
                  root: extendedFormsClasses.selectMenuItem,
                }}
                value="All"
              >
                All
              </MenuItem>
              {operations &&
                operations.map((op, index) => (
                  <MenuItem
                    key={index}
                    classes={{
                      root: extendedFormsClasses.selectMenuItem,
                      selected: extendedFormsClasses.selectMenuItemSelected,
                    }}
                    value={op}
                  >
                    {op}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={2}>
        <div>
          <FormControl
            fullWidth
            className={extendedFormsClasses.selectFormControl}
          >
            <InputLabel
              htmlFor="resource"
              className={extendedFormsClasses.selectLabel}
            >
              Choose Resource
            </InputLabel>
            <Select
              MenuProps={{
                className: extendedFormsClasses.selectMenu,
              }}
              classes={{
                select: extendedFormsClasses.select,
              }}
              onChange={handleResourceChange}
              value={resource}
              inputProps={{
                name: "resource",
                id: "resource",
                value: resource,
              }}
            >
              <MenuItem
                classes={{
                  root: extendedFormsClasses.selectMenuItem,
                }}
                value="All"
              >
                All
              </MenuItem>
              {resources &&
                Object.keys(resources).map((res, i) => (
                  <MenuItem
                    key={i}
                    classes={{
                      root: extendedFormsClasses.selectMenuItem,
                      selected: extendedFormsClasses.selectMenuItemSelected,
                    }}
                    value={res}
                  >
                    {res}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} lg={8}>
        <div
          className={
            formClasses.checkboxAndRadio +
            " " +
            formClasses.checkboxAndRadioHorizontal
          }
          style={{
            float: "right",
          }}
        >
          {Object.keys(timeMap).map((temp, i) => (
            <FormControlLabel
              key={i}
              control={
                <Radio
                  checked={timeSelect === temp}
                  onChange={() => periodChange(temp)}
                  value="a"
                  name="radio button demo"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={formClasses.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={formClasses.radioChecked} />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot,
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot,
              }}
              label={temp}
            />
          ))}
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        {/* <ResponsiveContainer width="95%" height={300} >
                    <BarChart>
                        <XAxis dataKey="time" type="category" allowDuplicatedCategory={false} />
                        <YAxis dataKey="value" />
                        <CartesianGrid />
                        <Tooltip />
                        <Legend />
                        {
                            (!operation || operation === 'All') && (!resource || resource === 'All') && chart && chart.map((s, i) => (
                                <Bar dataKey="value" data={s} name={operations[i]} fill={COLOR[i]} key={operations[i]} />
                            ))
                        }
                        {
                            (operation && operation !== 'All') && (!resource || resource === 'All') && chart && chart.map((s, i) => (
                                operations[i] === operation ?
                                    <Bar dataKey="value" data={s} name={operations[i]} fill={COLOR[i]} key={operations[i]} />
                                    : null
                            ))
                        }
                        {
                            (!operation || operation === 'All') && (resource && resource !== 'All') && subChart && subChart.map((s, i) => (
                                <Bar dataKey="value" data={s} name={operations[i]} fill={COLOR[i]} key={operations[i]} />
                            ))
                        }
                        {
                            (operation && operation !== 'All') && (resource && resource !== 'All') && subChart && subChart.map((s, i) => (
                                operations[i] === operation ?
                                    <Bar dataKey="value" data={s} name={operations[i]} fill={COLOR[i]} key={operations[i]} />
                                    : null
                            ))
                        }
                    </BarChart>
                </ResponsiveContainer> */}
        <div>{alert}</div>
        {!alert && (
          <ResponsiveContainer width="95%" height={300}>
            <LineChart>
              <XAxis
                dataKey="time"
                type="category"
                allowDuplicatedCategory={false}
                label={{
                  value: "Time",
                  position: "insideBottomRight",
                  offset: 0,
                }}
              />
              <YAxis
                dataKey="value"
                allowDecimals={false}
                label={{ value: "Count", position: "insideTopLeft", offset: 0 }}
              />
              <CartesianGrid />
              <Tooltip />
              <Legend />
              {(!operation || operation === "All") &&
                (!resource || resource === "All") &&
                chart &&
                chart.map((s, i) => (
                  <Line
                    dataKey="value"
                    data={s}
                    name={operations[i]}
                    stroke={COLOR[i]}
                    key={operations[i]}
                  />
                ))}
              {operation &&
                operation !== "All" &&
                (!resource || resource === "All") &&
                chart &&
                chart.map((s, i) =>
                  operations[i] === operation ? (
                    <Line
                      dataKey="value"
                      data={s}
                      name={operations[i]}
                      stroke={COLOR[i]}
                      key={operations[i]}
                    />
                  ) : null
                )}
              {(!operation || operation === "All") &&
                resource &&
                resource !== "All" &&
                subChart &&
                subChart.map((s, i) => (
                  <Line
                    dataKey="value"
                    data={s}
                    name={operations[i]}
                    stroke={COLOR[i]}
                    key={operations[i]}
                  />
                ))}
              {operation &&
                operation !== "All" &&
                resource &&
                resource !== "All" &&
                subChart &&
                subChart.map((s, i) =>
                  operations[i] === operation ? (
                    <Line
                      dataKey="value"
                      data={s}
                      name={operations[i]}
                      stroke={COLOR[i]}
                      key={operations[i]}
                    />
                  ) : null
                )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        {logs.length > 0 && (
          <ReactTable columns={columns} data={samples}></ReactTable>
        )}
      </GridItem>
    </GridContainer>
  );
}
