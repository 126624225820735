import React, { useEffect } from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Amplify components
import { Auth } from "aws-amplify";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import QUApi from "views/Dashboard/QUApi.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import NotificationContext from "views/Components/Context.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import { AddAlert } from "@material-ui/icons";

let ps; // Use let instead of var for better scoping

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(
    require("assets/img/homescreen.jpeg")
  );
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  const [user, setUser] = React.useState();
  const [userType, setUserType] = React.useState();
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(
    require("assets/img/qucreate_logo.png")
  );
  const [notification, setNotification] = React.useState(false);
  const [operationStatus, setOperationStatus] = React.useState("success");
  const [notificationMessage, setNotificationMessage] = React.useState("");
  // styles
  const classes = useStyles();

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (
          user.signInUserSession.accessToken.payload.hasOwnProperty(
            "cognito:groups"
          )
        ) {
          setUserType(
            user.signInUserSession.accessToken.payload["cognito:groups"][0]
          );
        }
        return user;
      })
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/auth/login-page";
      });
  }, []);

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  // ref for main panel div
  const mainPanel = React.useRef(); // Use useRef

  // function to initialize PerfectScrollbar
  const initializePerfectScrollbar = () => {
    if (mainPanel.current) {
      
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    } else {
      console.error("mainPanel.current is null, retrying initialization...");
      setTimeout(initializePerfectScrollbar, 100); // Retry after 100ms
    }
  };

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    

    if (navigator.platform.indexOf("Win") > -1) {
      initializePerfectScrollbar();
    }

    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1 && ps) {
        
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }); // Empty dependency array to run only on mount

  // functions for changing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const [breadCrumbs, setBreadCrumbs] = React.useState();

  // 2) matchRoute utility
  function matchRoute(routePath, currentPath) {
    const routeSegments = routePath.split("/").filter(Boolean);
    const currentSegments = currentPath.split("/").filter(Boolean);

    if (routeSegments.length !== currentSegments.length) return false;
    for (let i = 0; i < routeSegments.length; i++) {
      const rSeg = routeSegments[i];
      const cSeg = currentSegments[i];
      if (!rSeg.startsWith(":") && rSeg !== cSeg) {
        return false;
      }
    }
    return true;
  }

  // 3) replaceParams utility
  function replaceParams(routePath, currentPath) {
    const routeSegments = routePath.split("/").filter(Boolean);
    const currentSegments = currentPath.split("/").filter(Boolean);

    const replaced = routeSegments.map((seg, i) => {
      if (seg.startsWith(":")) {
        return currentSegments[i];
      }
      return seg;
    });

    return "/" + replaced.join("/");
  }

  // 4) getBreadcrumbList
  function getBreadcrumbList(currentRoute, routes) {
    const segments = currentRoute.split("/").filter(Boolean);
    segments.shift(); // Remove first empty string
    const breadcrumbList = [];

    for (let i = 0; i < segments.length; i++) {
      const partialPath = "/" + segments.slice(0, i + 1).join("/");
      const matchedRoute = routes.find((r) => matchRoute(r.path, partialPath));
      // skip home-page
      if (matchedRoute && matchedRoute.path === "/home-page") {
        continue;
      }
      if (matchedRoute) {
        const actualPath = replaceParams(matchedRoute.path, partialPath);
        breadcrumbList.push({ name: matchedRoute.name, path: actualPath });
      }
    }

    return breadcrumbList;
  }

  useEffect(() => {
    const currentRoute = window.location.pathname;
    const topLevel = { name: "Home", path: "/home-page" };
    const breadcrumbList = getBreadcrumbList(currentRoute, routes);

    setBreadCrumbs(breadcrumbList);
    
  }, [window.location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Page Not Found";
    if (window.location.href.includes("QuToolBox/all")) {
      return "All";
    }
    if (/\/admin\/courseprojects\/[^/]+/.test(window.location.href)) {
      return "Module";
    }

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.hasOwnProperty("userTypes") &&
        !prop["userTypes"].includes(userType)
      ) {
        return null;
      }
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const showNotification = (message, status) => {
    setOperationStatus(status);
    setNotificationMessage(message);
    setNotification(true);
    setTimeout(function () {
      setNotification(false);
    }, 5000);
  };

  return (
    <div className={classes.wrapper}>
      <NotificationContext.Provider value={{ showNotification }}>
        <Snackbar
          place="br"
          color={operationStatus}
          icon={AddAlert}
          message={notificationMessage}
          open={notification}
          closeNotification={() => setNotification(false)}
          close
        />
        {user && (
          <>
            <Sidebar
              routes={routes}
              logoText={""}
              logo={logo}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color={color}
              bgColor={bgColor}
              miniActive={miniActive}
              {...rest}
            />
            <div className={mainPanelClasses} ref={mainPanel}>
              <AdminNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                brandText={getActiveRoute(routes)}
                breadCrumbs={breadCrumbs}
                handleDrawerToggle={handleDrawerToggle}
                user={user}
                {...rest}
              />
              {getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>
                      {/* <Redirect from="/admin/QuToolBox/Audit-Checklist" to="/admin/QuToolBox/Audit-Checklist/QuAudit/1.0.0" /> */}
                      {getRoutes(routes)}
                      <Route path="/admin/QuToolBox/all" component={QUApi} />
                      <Redirect from="/admin" to="/admin/project-design" />
                    </Switch>
                  </div>
                </div>
              ) : (
                <div className={classes.map}>
                  <Switch>
                    {/* <Redirect from="/admin/QuToolBox/Audit-Checklist" to="/admin/QuToolBox/Audit-Checklist/QuToolBox/1.0.0" /> */}
                    {getRoutes(routes)}
                    <Route path="/admin/QuToolBox/all" component={QUApi} />
                    <Redirect from="/admin" to="/admin/project-design" />
                  </Switch>
                </div>
              )}
              {getRoute() ? <Footer fluid /> : null}
            </div>
          </>
        )}
      </NotificationContext.Provider>
    </div>
  );
}
