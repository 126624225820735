import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NotificationContext from "views/Components/Context.js";
import ArtifactStructureReview from "./ArtifactStructureReview";

export default function Slides(props) {
  const [slides, setSlides] = React.useState([]);
  const { showNotification } = React.useContext(NotificationContext);
  const { courseID, moduleID } = props;
  const [isInitialized, setIsInitialized] = useState(courseID && moduleID);
  const setSlidesForCard = () => {
    const card = props.slides;

    if (card) {
      
      const updatedSlides = card.map((slide) => {
        return {
          id: slide.resource_id,
          name: slide.resource_name,
          url: slide.resource_link,
          description: slide.resource_description,
          type: slide.resource_type,
        };
      });
      setSlides(updatedSlides);
    } else {
      
    }
  };

  // Call setFilesForCard whenever the id changes
  useEffect(() => {
    setSlidesForCard();
    setIsInitialized(true);
  }, []);

  // Ensure props are fully initialized before rendering ArtifactStructureReview

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" style={{ marginTop: "20px" }}>
            Final Slides
          </Typography>
          <Typography variant="body1">
            Here is the final slide deck generated for the module.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          {isInitialized ? (
            <ArtifactStructureReview
              files={slides}
              courseID={courseID}
              moduleID={moduleID}
              courseDesignStep={11}
            />
          ) : (
            <Typography variant="body1">Loading...</Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
