import React from "react";
import { Modal, Box, Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import templates from "views/Dashboard/Data/templates";

const useStyles = makeStyles((theme) => ({
  modalRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh", // Ensures the modal takes full screen
    padding: theme.spacing(2),
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: "80%",
    maxWidth: "800px", // Max width of modal
  },
  dialogTitle: {
    fontWeight: "600",
    fontSize: "1.25rem",
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  resourceTile: {
    height: "100%", // Ensure all tiles have the same height
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // To ensure the content inside each tile is spaced well
    alignItems: "center",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      boxShadow: theme.shadows[5],
      cursor: "pointer",
    },
  },
  cancelButton: {
    marginTop: theme.spacing(2),
    textTransform: "none",
  },
}));

const TaskDefinitionModal = ({ open, onClose, setTaskDefinition }) => {
  const classes = useStyles();

  const handleTemplateSelect = (templateContent) => {
    setTaskDefinition(templateContent);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.modalRoot}>
      <Box className={classes.modalContent}>
        {/* Modal Title */}
        <Typography className={classes.dialogTitle}>
          Available Templates
        </Typography>

        {/* Grid for Resource Tiles */}
        <Grid container spacing={3} justifyContent="center">
          {templates.map((template, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                className={classes.resourceTile}
                onClick={() => handleTemplateSelect(template.content)}
              >
                {/* Icon and Heading */}
                <Box mb={2}>
                  <template.icon fontSize="large" />
                </Box>
                <Typography variant="body1">{template.heading}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {template.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Cancel Button */}
        <Box textAlign="center" width="100%">
          <Button
            onClick={onClose}
            color="secondary"
            variant="outlined"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TaskDefinitionModal;
