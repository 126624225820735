// Module Status Map
export const module_status_enabled_tab_map = {
    "Raw Resources": 0,
    "In Design Phase": 0,
    "In Outline Generation Queue": 0,
    "Outline Review": 1,
    "In Content Generation Queue": 1,
    "Content Review": 2,
    "Pre Processed Content": 2,
    "Post Processed Content": 2,
    "In Structure Generation Queue": 2,
    "Structure Review": 3,
    "Pre Processed Structure": 3,
    "Post Processed Structure": 3,
    "In Deliverables Generation Queue": 3,
    "Deliverables Review": 4,
    "Pre Processed Deliverables": 4,
    "Post Processed Deliverables": 4,
    "In Publishing Queue": 4,
    "Published": 5,
};

export const inBetweenStagesLoaderOptions = {
    "In Outline Generation Queue": "We are generating the outline for you...",
    "In Content Generation Queue": "We are generating the content for you...",
    "In Structure Generation Queue": "We are generating the structure for you...",
    "In Deliverables Generation Queue": "We are generating the final deliverables for you...",
    "In Publishing Queue": "Module marked ready for publishing",
};

export const course_steps = [
    {
        lockedMessage: "",
    },
    {
        lockedMessage:
            "Please submit the resources and the instructions to unlock this step.",
    },
    {
        lockedMessage: "Please finish the outline review to unlock this step.",
    },
    {
        lockedMessage: "Please finish the content review to unlock this step.",
    },
    {
        lockedMessage: "Please finish the structure review to unlock this step.",
    },
];

// Fetch image as file
export async function fetchImageAsFile(imageUrl) {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], "qucreate_logo.png", { type: blob.type });
    return file;
}