// React and Hooks
import React, { useEffect, useState } from "react";

// Material-UI Components
import {
  Divider,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Tabs,
  Tab,
} from "@material-ui/core";
import { AccountTree, ViewModule } from "@material-ui/icons";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// React Router
import { Link } from "react-router-dom";

// Images
import Design from "assets/img/templates/Design.png";
import Planning from "assets/img/templates/Planning.png";
import Reporting from "assets/img/templates/Reporting.png";
import Tracking from "assets/img/templates/Tracking.png";
import Validation from "assets/img/templates/Validation.png";
import Risk from "assets/img/templates/Risk.png";
import Inventory from "assets/img/templates/Inventory.png";
import Description from "assets/img/templates/Description.png";
import Functionality from "assets/img/templates/Functionality.png";
import Model from "assets/img/templates/Model.png";

// Custom Components
import CreationOptionCard from "../Components/CreationOptionCard";
import ModelHomePage from "views/Pages/ModelHomePage";

// API Utilities
import { fetchTemplates } from "views/APIs/APIUtility";
import SearchBar from "material-ui-search-bar";

const QuTemplates = () => {
  // State variables
  const [templates, setTemplates] = useState([]); // Default to empty array
  const [error, setError] = useState(null); // Error state
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    message: "",
  }); // Loading state

  // Mapping template names to images
  const name_image_mapping = {
    "Model Inventory Record": Inventory,
    "Model Description Record": Description,
    "Model Function Description Record": Functionality,
    "Model Requirements and Design": Design,
    "Model Test Plan": Planning,
    "Model Testing Report": Reporting,
    "Model Issue Tracking Record": Tracking,
    "Model Review and Validation Report": Validation,
    "Model Review, Verification and Validation Plan": Validation,
    "Model Risk Management Summary": Risk,
    default: Model,
  };

  // State for active tab
  const [activeTab, setActiveTab] = useState(0);
  const [displayTemplates, setDisplayTemplates] = useState(templates);
  const [searchValue, setSearchValue] = useState("");
  const [createProjectModalOpen, setCreateProjectModalOpen] = React.useState(false);

  // Fetch templates from API
  useEffect(() => {
    const fetchTemplatesAPI = async () => {
      setLoadingState({ isLoading: true, message: "Fetching templates..." });

      try {
        const response = await fetchTemplates(); // Fetch API data
        setTemplates(response);
        setDisplayTemplates(response);
        setLoadingState({ isLoading: false, message: "" });
      } catch (err) {
        setError(err.message);
        setLoadingState({
          isLoading: false,
          message: "Failed to load templates",
        });
      }
    };

    fetchTemplatesAPI();
  }, []); // Dependency array to avoid infinite loops

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update active tab
  };

  const searchInputValue = (e) => {
    setSearchValue(e);
    if (e === "") {
      setDisplayTemplates(templates);
      return;
    }
    var newList = templates.filter((item) => {
      return (
        item.name.toLowerCase().includes(e.toLowerCase()) ||
        item.note.toLowerCase().includes(e.toLowerCase())
      );
    });
    setDisplayTemplates(newList);
  };

  // Show loading spinner if data is being fetched
  if (loadingState.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loadingState.message}
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <Divider style={{ marginBottom: "20px" }} />
      {/* Title */}
      <Typography variant="h5">Welcome to QuModelStudio!</Typography>
      <Typography
        variant="body1"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        Here, you can create, manage, and deploy AI and Machine Learning model
        reports using our templates. Click on the tabs below to get started.
      </Typography>
      <GridContainer>
        <GridItem xs={12} sm={8} md={8} lg={10}>
          <SearchBar
            style={{ marginBottom: "20px" }}
            value={searchValue}
            onChange={(newValue) => searchInputValue(newValue)}
            onRequestSearch={(newValue) => searchInputValue(newValue)}
          />
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={2} style={{ textAlign: "center" }} >
          <Button
            color="quCreateColor"
            fullWidth
            onClick={() => setCreateProjectModalOpen(true)} // Example of handling state
          >
            Create Project
          </Button>
        </GridItem>


      </GridContainer>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <AccountTree fontSize="small" /> Projects
            </span>
          }
        />
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <ViewModule fontSize="small" /> Templates
            </span>
          }
        />
      </Tabs>

      <Divider style={{ margin: "20px 0" }} />

      {activeTab === 0 ? (
        <>
          <ModelHomePage searchValue={searchValue} createProjectModalInitialState={createProjectModalOpen} setCreateProjectModalInitialState={setCreateProjectModalOpen} />
        </>
      ) : (
        <>
          {/* Show error message */}
          {error && <Typography color="error">Error: {error}</Typography>}

          {/* Render templates only if they exist */}
          {!loadingState.isLoading && !error && templates.length > 0 && (
            <Grid container spacing={2}>
              {displayTemplates.map((template) => (
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Link
                    to={{
                      pathname: "templates/" + template._id,
                    }}
                  >
                    <CreationOptionCard
                      // key={template._id} // Fix: Add unique key
                      logo={
                        name_image_mapping[template.name] ||
                        name_image_mapping.default
                      }
                      title={template.name}
                      description={template.note}
                      identifier={template._id}
                      inProgress={false} // Default value
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default QuTemplates;
