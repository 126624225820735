// React
import React from "react";

// Material-UI Core Components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";

// Material-UI Icons
import { Edit, Delete } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";

// Material-UI Styles
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tableContainer: {
    width: "100%",
    maxHeight: "70vh",
    margin: "0 auto",
    marginTop: "20px",
  },
  headerCell: {
    padding: "26px",
  },
  tableCell: {
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  noDataMessage: {
    padding: "20px",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#666",
  },
});

function getCreationDateFromObjectId(objectId) {
  // Convert ObjectId to a Date
  return new Date(
    parseInt(objectId.substring(0, 8), 16) * 1000
  ).toLocaleString();
}

export default function TableComponent({
  data,
  handleEdit,
  handleView,
  handleDelete,
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>
              <strong>Version</strong>
            </TableCell>
            <TableCell>
              <strong>Created at</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((item, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}>
                  <Tooltip
                    title={item.name}
                    disableHoverListener={item.name.length < 20}
                  >
                    <div>
                      <span>{item.name}</span>
                    </div>
                  </Tooltip>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <span>{index + 1}.0</span>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <span>{getCreationDateFromObjectId(item._id)}</span>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(item)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleView(item)}>
                    <GetAppIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(item)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} className={classes.noDataMessage}>
                No reports found. Complete the above form to get started.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
