import React, { useState } from "react";
import { Box, Card, Divider, Grid, Icon, Input, Typography } from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor"
import PDFViewer from "views/Components/PDFViewer";


export default function ReviewPPT(props) {
    
    return (
        <Card style={{
            padding: "20px"
        }}>
            <Grid container spacing={3}>
                {/* <Grid item xs={12} sm={12}>
                    <iframe
                        src={props.PPTURL}
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        title="PowerPoint Presentation"
                    />
                </Grid> */}
                <Grid item xs={12} sm={12}>
                    <PDFViewer pdfUrl={props.PDFURL} activeSlide={props.activeSlide} showButtons={props.showButtons} />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <label>Speaker Notes</label>
                    <Input variant="outlined" placeholder="Speaker Notes" fullWidth multiline rows={6}
                        onChange={(e) => props.setSpeakerNotes(e.target.value)} value={props.speakerNotes} disabled={!props.isNotesEditable} // Default is editable
                    />
                </Grid>
            </Grid>
        </Card>
    );
}

ReviewPPT.defaultProps = {
    isNotesEditable: true, // Default behavior is editable
};