import React, { useState } from "react";

// Material UI Core Components
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Dialog,
  Tooltip,
  Checkbox,
  Grid,
  DialogContent,
} from "@material-ui/core";

// Material UI Icons
import {
  Delete,
  Visibility,
  GetApp,
  RestorePage,
  Add,
} from "@material-ui/icons";

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';

// Material UI Styles
import { makeStyles } from "@material-ui/core/styles";

// Custom Components
import PDFViewer from "views/Components/PDFViewer";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "20px",
    boxSizing: "border-box",
  },
  addButton: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    padding: "10px",
    margin: "10px",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
  },
  title: { marginBottom: "20px" },
  tableContainer: {
    width: "100%",
    maxHeight: "70vh",
    margin: "0 auto",
  },
  dialogContent: {
    width: "500px",
    maxWidth: "90vw",
  },
  dialogButtonSpacing: { margin: "10px 0" },
  pdfViewer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  pdfPage: {
    margin: "10px 0",
  },
  headerCell: {
    padding: "26px", // Adjust for additional spacing if needed
  },
  tableCell: {
    maxWidth: "200px", // Add maxWidth to ensure text truncation
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center", // Center the buttons
    gap: "10px", // Space out buttons if needed
  },
  addResourceRow: {
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  dialogImage: {
    width: '100%',
    maxHeight: '80vh',
    objectFit: 'contain',
    borderRadius: '8px',
  },
});

const getFileNameWithoutExtension = (fileName) =>
  fileName.replace(/\.[^/.]+$/, "");

export default function WritingTable({
  files,
  handleDeleteResource,
  handleReplace,
  handleResourceAddition,
  setSelectedResources,
  selectedResources,
  selectColumnRequired = true,
  viewActionRequired = true,
  atleastOneFileRequired = true,
}) {
  // Initialize Material UI styles for the component
  const classes = useStyles();

  // State for controlling the PDF preview modal and the selected PDF file
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  // Handler to preview a PDF file in a modal dialog
  const handlePreview = async (file) => {
    try {
      if (file.type === "File") {
        setPdfFile(file); // Set the selected file to be previewed
        setPdfModalOpen(true); // Open the modal containing the PDF viewer
      }
      if (file.type === "Image") {
        setCurrentImage(file.url);
        setImageModalOpen(true);
      }
    } catch (error) {
      // If an error occurs, open the file in a new browser tab as a fallback
      window.open(file.url, "_blank");
    }
  };

  // Handler to download a file by dynamically creating an anchor element
  const handleDownload = (file) => {
    const link = document.createElement("a");
    link.href = file.url; // Set the URL of the file
    link.download = file.name; // Set the filename for download
    link.click(); // Programmatically trigger the download
  };

  // Handler to close the PDF preview modal and reset the selected file
  const handlePdfClose = () => {
    setPdfModalOpen(false); // Close the PDF preview modal
    setPdfFile(null); // Reset the PDF file state
  };

  const handleImagePreviewClose = () => {
    setImageModalOpen(false);
    setCurrentImage(null);
  };

  return (
    <Grid container>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {selectColumnRequired && <TableCell style={{ width: "5%" }}><strong>Select</strong></TableCell>}
              <TableCell style={{ width: "40%" }}><strong>Name</strong></TableCell>
              <TableCell style={{ width: "40%" }}><strong>Description</strong></TableCell>
              <TableCell style={{ width: "20%" }} className={classes.headerCell}><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Display message when no files are uploaded */}
            {files.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} style={{ textAlign: "center", padding: "16px" }}>
                  <Typography variant="body1">
                    No files uploaded. You can upload files using the button below.
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {/* Render files if they exist */}
            {files.map((file, index) => (
              <TableRow key={index}>
                {selectColumnRequired && (
                  <TableCell className={classes.tableCell} style={{ width: "5%" }}>
                    <Checkbox
                      type="checkbox"
                      checked={selectedResources.map((r) => r.id).includes(file.id)}
                      onChange={() => {
                        if (selectedResources.map((r) => r.id).includes(file.id)) {
                          setSelectedResources(selectedResources.filter((r) => r.id !== file.id));
                        } else {
                          setSelectedResources([...selectedResources, file]);
                        }
                      }}
                      color="quCreateColor"
                    />
                  </TableCell>
                )}
                <TableCell className={classes.tableCell} style={{ width: "40%" }}>
                  <Tooltip title={file.name} disableHoverListener={file.name.length < 20}>
                    <div>
                      <span>{getFileNameWithoutExtension(file.name)}</span>
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.tableCell} style={{ width: "40%" }}>
                  <Tooltip title={file.description} disableHoverListener={file.description.length < 20}>
                    <div>
                      <span>{file.description}</span>
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ width: "20%" }}>
                  {viewActionRequired && (
                    <IconButton onClick={() => handlePreview(file)}>
                      <Visibility />
                    </IconButton>
                  )}
                  <IconButton onClick={() => handleDownload(file)}>
                    <GetApp />
                  </IconButton>
                  <Tooltip title={(atleastOneFileRequired && files.length === 1) ? "This resource cannot be deleted as at least 1 file is required" : ""}>
                    <span>
                      <IconButton onClick={() => handleDeleteResource(file, index)} disabled={atleastOneFileRequired ? files.length === 1 : false}>
                        <Delete />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}

            {/* Row for adding a resource */}
            <TableRow className={classes.addResourceRow}>
              <TableCell colSpan={4} style={{ textAlign: "center" }}>
                <IconButton
                  color="info"
                  size="medium"
                  style={{
                    backgroundColor: quCreateColor,
                    borderRadius: "50%",
                    color: "white",
                  }}
                  onClick={handleResourceAddition}
                >
                  <CloudUploadIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* PDF Preview Modal */}
      <Dialog open={pdfModalOpen} onClose={handlePdfClose} maxWidth="md" fullWidth>
        {pdfFile && <PDFViewer pdfUrl={pdfFile.url} scrollView={false} showButtons={true} />}
      </Dialog>

      {/* Dialog for Image Preview */}
      <Dialog open={imageModalOpen} onClose={handleImagePreviewClose} maxWidth="md" fullWidth>
        <DialogContent>
          {currentImage && (
            <img
              src={currentImage}
              alt="Preview"
              className={classes.dialogImage}
            />
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
