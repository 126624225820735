import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch, Link, useRouteMatch } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  CircularProgress,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  Icon,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import {
  CloudQueue,
  Create,
  Done,
  DoneAll,
  HourglassEmpty,
  RateReview,
} from "@material-ui/icons";

import { fetchImageAsFile } from "views/Dashboard/Course/CourseConstants";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardTitle from "components/Card/CardTitle";
import NewCardBody from "components/Card/NewCardBody";
import HeadingWithDescription from "components/Heading/HeadingWithDescription.js";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

// context
import NotificationContext from "views/Components/Context.js";

// colors
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";


// data and utilities
import { moduleStages } from "./Data/moduleStages";
import {
  fetchAllLecturesAPI,
  fetchAllWritingsAPI,
  addArtifactToCourse,
  submitCourseForPublishing,
  fetchQUSkillBridgeCourseID,
  fetchAllLabsAPI, fetchWritingTemplatesAPI,
  fetchAllPodcastsAPI, createPodcastAPI, createLabAPI, createWritingAPI,
  updateCourseDetails, addModuleToCourse
} from "views/APIs/APIUtility";
import SearchBar from "material-ui-search-bar";
import ReactTable from "components/ReactTable/ReactTable";
import CreateAssetDialog from "./CreateAssetDialog";
// Modals
import CreatePodcastModal from "views/Components/CreatePodastModal";
import CreateLabModal from "views/Components/CreateLabModal";
import CreateWritingModal from "views/Components/CreateWritingModal.js";
import CreateModuleModal from "views/Components/CreateModuleModal.js";

// Models
import BaseDetails from "./Models/BaseDetails";
import PodcastDetails from "./Models/PodcastDetails";
import LabDetails from "./Models/LabDetails";
import WritingModel from "./Models/WritingModel";
import { image_name, identifiers } from "./Constants";
import { set } from "date-fns";




const useStyles = makeStyles(styles);
const usedashboardStyle = makeStyles(dashboardStyle);



export default function ModulesDesign(props) {
  // Import necessary hooks and styles
  const dashboardClasses = usedashboardStyle();
  const classes = useStyles();
  const images = require.context("assets/img", true);
  const assetImages = require.context("assets/img/create_cards_16_9", true);
  // State variables for managing UI and data
  const [modulesLoading, setModulesLoading] = useState(true);
  const [moduleModal, setModuleModal] = useState(false);
  const [assetModal, setAssetModal] = useState(false);
  const [additionalArtifacts, setAdditionalArtifacts] = useState([]);
  const [name, setName] = useState("");
  const [brief, setBrief] = useState("");
  const [project, setProject] = useState({});
  const [course, setCourse] = useState({});
  const [submitModuleLoading, setSubmitModuleLoading] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [importLoading, setImportLoading] = useState({ loading: false, message: "" });
  const [selectedArtifact, setSelectedArtifact] = useState(null);
  const [artifacts, setArtifacts] = useState([]);
  const [selectedTab, setSelectedTab] = useState("All");
  const [displayModules, setDisplayModules] = useState();
  const [displayAdditionalArtifacts, setDisplayAdditionalArtifacts] = useState();
  const [searchValue, setSearchValue] = useState("");

  // Context and route match hooks
  const { showNotification } = React.useContext(NotificationContext);
  const { path, url } = useRouteMatch();
  const { courseID } = props.match.params;

  // Log the course ID for debugging
  // Writing ID state (note: TODO; remove writing_id later)
  const [writingId, setWritingId] = React.useState("");
  const [currentDialog, setCurrentDialog] = React.useState("Create Writing");
  const [identifier, setIdentifier] = React.useState("research_report");
  const [identifierPrint, setIdentifierPrint] = React.useState(
    identifiers["research_report"]
  );
  const [writingCreationLoader, setWritingCreationLoader] = React.useState({
    isLoading: false,
    message: "",
  });

  // state variables
  const [createPodcastModal, setCreatePodcastModal] = React.useState(false);
  const [newPodcastDetails, setNewPodcastDetails] = React.useState(new PodcastDetails());
  const [newLabDetails, setNewLabDetails] = React.useState(new LabDetails());
  const [newWritingDetails, setNewWritingDetails] = React.useState(new WritingModel());
  const [moduleDetails, setModuleDetails] = React.useState(new BaseDetails());


  const [podcastCreationLoader, setPodcastCreationLoader] = React.useState(false);
  const [iconImage, setIconImage] = React.useState();

  const [createLabModal, setCreateLabModal] = React.useState(false);
  const [createWritingModal, setCreateWritingModal] = React.useState(false);

  const handleIconUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIconImage(file);
    }
  };

  // Define table columns
  const columns = useMemo(
    () => [
      {
        Header: "Select",
        id: "select",
        Cell: ({ row }) => (
          <input
            type="radio"
            name="rowSelection"
            checked={selectedArtifact === row.original.artifact_id}
            onChange={() => setSelectedArtifact(row.original.artifact_id)}
          />
        ),
        disableSortBy: true,
        disableFilters: true
      },
      { Header: "Name", accessor: "artifact_name" },
      { Header: "Description", accessor: "artifact_description" },
      { Header: "Type", accessor: "artifact_type" }
    ],
    [selectedArtifact]
  );

  // Fetch course data on component mount
  useEffect(() => {
    fetch(process.env.REACT_APP_FASTAPI_URL + `/get_course/${courseID}`)
      .then((res) => res.json())
      .then((data) => {
        setCourse(data);
        // console.log("Additional Artifacts: ", data.additional_artifacts);
        const additional_artifacts = [];
        if (data.additional_artifacts) {
          data.additional_artifacts.forEach((artifact) => {
            if ("lecture_name" in artifact) {
              additional_artifacts.push({
                artifact_id: artifact._id,
                artifact_name: artifact.lecture_name,
                artifact_description: artifact.lecture_description,
                artifact_image: artifact.lecture_image,
                artifact_type: "Lecture",
              });
            } else if ("writing_name" in artifact) {
              additional_artifacts.push({
                artifact_id: artifact._id,
                artifact_name: artifact.writing_name,
                artifact_description: artifact.writing_description,
                artifact_image: artifact.writing_image,
                // artifact_type: "Writing",
                artifact_type: artifact.identifier,
              });
            }
            else if ("podcast_name" in artifact) {
              additional_artifacts.push({
                artifact_id: artifact._id,
                artifact_name: artifact.podcast_name,
                artifact_description: artifact.podcast_description,
                artifact_image: artifact.podcast_image,
                // artifact_type: "Writing",
                artifact_type: "podcast",
              });
            } else if ("lab_name" in artifact) {
              additional_artifacts.push({
                artifact_id: artifact._id,
                artifact_name: artifact.lab_name,
                artifact_description: artifact.lab_description,
                artifact_image: artifact.lab_image,
                artifact_type: "Lab",
              });
            } else {

            }
          });
          setDisplayModules(data.modules);
          setAdditionalArtifacts(additional_artifacts);
          setDisplayAdditionalArtifacts(additional_artifacts);
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("Error fetching course data", "danger");
      })
      .finally(() => {
        setModulesLoading(false);
      });
  }, [courseID]);

  // Status icons for different module stages
  const statusIcons = [
    <Create />,
    <CloudQueue />,
    <RateReview />,
    <CloudQueue />,
    <RateReview />,
    <CloudQueue />,
    <RateReview />,
    <CloudQueue />,
    <RateReview />,
    <CloudQueue />,
    <DoneAll />,
  ];

  // Get the status icon based on the module status
  const getStatusIcon = (status) => {
    const index = moduleStages.indexOf(status);
    return statusIcons[index];
  };


  // Add a new module to the course
  const addModule = async () => {
    setSubmitModuleLoading(true);
    try {
      const formData = new FormData();
      formData.append("course_id", courseID);
      formData.append("module_name", moduleDetails.name);
      formData.append("module_description", moduleDetails.description);

      const response = await addModuleToCourse(formData);
      // console.log("Response is: ", response);

      if (response.modules) {
        setCourse(response);
        setDisplayModules(response.modules);
      }
      showNotification("Module added successfully", "success");
    } catch (err) {
      showNotification("Error adding module", "danger");
    } finally {
      setSubmitModuleLoading(false);
      handleAssetModalClose();
    }
  };

  const addAsset = async (card) => {
    setAssetModal(false);
    if (card.identifier === "podcast") {
      setCreatePodcastModal(true);
    }
    else if (card.identifier === "labs") {
      setCreateLabModal(true);
    }
    else if (Object.keys(identifiers).includes(card.identifier)) {
      setIdentifier((prev) => {
        setIdentifierPrint(identifiers[card.identifier]);
        setCreateWritingModal(true); // Now that states are set, open the modal
        return card.identifier;
      });
    }
    else if (card.identifier === "module") {
      setModuleModal(true);
    }
  };


  // Get a list of unique artifact types
  const artifactTypes = [
    "All",
    ...new Set(artifacts.map((artifact) => artifact.artifact_type)),
  ];

  // Filter artifacts based on selected tab
  const filteredArtifacts =
    selectedTab === "All"
      ? artifacts
      : artifacts.filter((artifact) => artifact.artifact_type === selectedTab);

  // Import an artifact to the course
  const importArtifact = async () => {
    try {
      setImportLoading({ loading: true, message: "Importing artifact..." });
      const artifact_type = filteredArtifacts.find(
        (artifact) => artifact.artifact_id === selectedArtifact
      ).artifact_type;
      await handleImportArtifact(courseID, selectedArtifact, artifact_type);
    }
    catch (error) {
      showNotification("Error importing artifact", "danger");
    }
    finally {
      setImportLoading({ loading: false, message: "" });
    }

  };

  const handleImportArtifact = async (courseID, artifact_id, artifact_type) => {
    try {
      const formData = new FormData();
      formData.append("course_id", courseID);
      formData.append("artifact_id", artifact_id);
      formData.append("artifact_type", artifact_type);
      await addArtifactToCourse(formData);
      window.location.reload();
    }
    catch (error) {
      return error;
    }

  };

  // Fetch all artifacts (writings, lectures, labs)
  const getArtifacts = async () => {
    const writings = await fetchAllWritingsAPI();
    // const lectures = await fetchAllLecturesAPI();
    const labs = await fetchAllLabsAPI();
    const podcasts = await fetchAllPodcastsAPI();
    const artifacts = [];
    writings.forEach((writing) => {
      artifacts.push({
        artifact_id: writing._id,
        artifact_name: writing.writing_name,
        artifact_type: identifiers[writing.identifier] || "Writing",
        artifact_description: writing.writing_description,
      });
    });
    // lectures.forEach((lecture) => {
    //   artifacts.push({
    //     artifact_id: lecture._id,
    //     artifact_name: lecture.lecture_name,
    //     artifact_type: "Lecture",
    //     artifact_description: lecture.lecture_description,
    //   });
    // });
    labs.forEach((lab) => {
      artifacts.push({
        artifact_id: lab._id,
        artifact_name: lab.lab_name,
        artifact_type: "Lab",
        artifact_description: lab.lab_description,
      });
    });
    podcasts.forEach((podcast) => {
      artifacts.push({
        artifact_id: podcast._id,
        artifact_name: podcast.podcast_name,
        artifact_type: "Podcast",
        artifact_description: podcast.podcast_description,
      });
    });
    setArtifacts(artifacts);
  };

  // Fetch artifacts on component mount
  useEffect(() => {
    getArtifacts();
  }, []);

  // Handle publishing the course
  const handlePublishCourse = async () => {
    const form = new FormData();
    try {
      form.append("course_id", courseID);
      await submitCourseForPublishing(form);
    } catch (err) {
      showNotification("Error publishing course", "danger");
    } finally {
      showNotification(
        "Your course is marked for publishing. You will be notified shortly.",
        "success"
      );
    }
  };

  // Handle previewing the course
  const handlePreviewCourse = async () => {
    try {
      const response = await fetchQUSkillBridgeCourseID(courseID);
      if (response) {
        window.open(
          `https://quskillbridge.qusandbox.com/course-content/${response}`,
          "_blank"
        );
      } else {
        showNotification("Failed to fetch course preview.", "danger");
      }
    } catch (error) {
      showNotification(
        "An error occurred while fetching the course preview.",
        "danger"
      );
    }
  };

  // Handle updating course details
  const handleCourseDetailsChange = async (courseDetails) => {
    try {
      const formData = new FormData();
      formData.append("course_id", courseID);
      formData.append("course_name", courseDetails.title);
      formData.append("course_description", courseDetails.shortDescription);
      formData.append("course_outline", courseDetails.longDescription);

      await updateCourseDetails(formData);
      setCourse((prevCourse) => ({
        ...prevCourse,
        course_name: courseDetails.title,
        course_description: courseDetails.shortDescription,
        course_outline: courseDetails.longDescription,
      }));
      showNotification("Course details updated successfully", "success");
    } catch (error) {
      showNotification(
        "An error occurred while updating course details.",
        "danger"
      );
    } finally {
    }
  };

  const searchInputValue = (e) => {
    setSearchValue(e);
    if (e == "") {
      setDisplayModules(course.modules);
      setDisplayAdditionalArtifacts(additionalArtifacts);
      return;
    }
    var newList = course.modules.filter(
      (module) =>
        module.module_name.toLowerCase().includes(e.toLowerCase()) ||
        module.module_description.toLowerCase().includes(e.toLowerCase())

    );
    setDisplayModules(newList);
    var newAdditionalList = additionalArtifacts.filter(
      (artifact) =>
        artifact.artifact_name.toLowerCase().includes(e.toLowerCase()) ||
        artifact.artifact_description.toLowerCase().includes(e.toLowerCase())
    );
    setDisplayAdditionalArtifacts(newAdditionalList);
  };

  const handleAssetModalClose = () => {
    // Close the main modal
    setAssetModal(false);

    //Initialize the sub modals to false
    setCreatePodcastModal(false);
    setCreateLabModal(false);
    setCreateWritingModal(false);
    setModuleModal(false);
    //Miscealenous
    setWritingCreationLoader({ isLoading: false, message: "" });
    setCurrentDialog("Create Writing");
    setIconImage(null);
    // Reset objects
    setNewPodcastDetails(new PodcastDetails());
    setNewLabDetails(new LabDetails());
    setNewWritingDetails(new WritingModel());
    setModuleDetails(new BaseDetails());
  };

  const handleCreatePodcast = async (modulesAtCreation = true) => {
    setPodcastCreationLoader(true);
    const imageToUse =
      iconImage || (await fetchImageAsFile(assetImages("./podcast.png")));
    // const imageToUse = iconImage || images("./qusandbox_logo_v2.5.png");
    const formData = new FormData();
    formData.append("podcast_name", newPodcastDetails.name);
    formData.append("podcast_description", newPodcastDetails.description);
    formData.append("podcast_image", imageToUse);
    formData.append("podcast_transcript", newPodcastDetails.podcastOutline);
    newPodcastDetails.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const data = await createPodcastAPI(formData);
      await handleImportArtifact(courseID, data._id, "Podcast");
      // setAllPodcasts([...allPodcasts, _mapResourceToAppType(data)]);
      // setPodcastAdded(true);
      window.location.reload();
      showNotification("Podcast created successfully", "success");
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      setPodcastCreationLoader(false);
      handleAssetModalClose();
    }
  };

  const handleCreateLab = async () => {
    // setLoading({ loading: true, message: "Creating Lab..." });
    const formData = new FormData();
    formData.append("lab_name", newLabDetails.name);
    formData.append("lab_description", newLabDetails.description);
    formData.append("lab_outline", newLabDetails.labOutline);
    newLabDetails.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append(
      "lab_image",
      iconImage || (await fetchImageAsFile(assetImages("./labs.png")))
    );

    try {
      const data = await createLabAPI(formData);
      await handleImportArtifact(courseID, data._id, "Lab");
      // setAllLabs([...allLabs, _mapResourceToAppType(data)]);
      // setLabAdded(true);
      showNotification("Lab created successfully", "success");
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      handleAssetModalClose();
      // setLoading({ loading: false, message: "" });
    }
  };

  const handleCreateWriting = async () => {
    // console.log("Starting handleCreateWriting...");
    try {
      // console.log("Identifier:", identifier);
      // console.log("Identifier Print:", identifierPrint);
      // console.log("Writing ID:", writingId);
      // console.log("New Writing Details:", newWritingDetails);

      // Fetch image
      // console.log("Fetching image...");
      const imageToUse = iconImage || (await fetchImageAsFile(assetImages(image_name[identifier])));
      // console.log("Image fetched successfully:", imageToUse);

      // Prepare form data
      const formData = new FormData();
      formData.append("writing_id", writingId);
      formData.append("writing_name", newWritingDetails.name);
      formData.append("writing_description", newWritingDetails.description);
      formData.append("writing_image", imageToUse);
      formData.append("writing_outline", newWritingDetails.outline);
      formData.append("identifier", identifier);

      // Append additional files
      // console.log("Uploading files:", newWritingDetails.uploadedFiles);
      newWritingDetails.uploadedFiles.forEach((file) => {
        formData.append("files", file);
      });

      // console.log("Sending API request to create writing...");
      const data = await createWritingAPI(formData);
      // console.log("API Response:", data);
      // console.log("Importing artifact...");
      await handleImportArtifact(courseID, data._id, "Writing");
      // console.log("Artifact imported successfully.");

      showNotification(identifierPrint + " created successfully", "success");
    } catch (error) {
      console.error("Error occurred:", error);
      showNotification(error, "danger");
    } finally {
      // console.log("Resetting loader and closing modal...");
      handleAssetModalClose();
    }
  };


  const handleGenerateTemplate = async (prompt, use_metaprompt) => {
    try {
      setWritingCreationLoader({
        isLoading: true,
        message: `Let’s create some templates for the ${identifierPrint}...`,
      });
      const formData = new FormData();
      formData.append("identifier", identifier);
      // Append any additional files
      newWritingDetails.uploadedFiles.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("target_audience", newWritingDetails.target_audience);
      formData.append("expected_length", newWritingDetails.expected_length);
      formData.append("tone", newWritingDetails.tone);

      formData.append("prompt", prompt);
      formData.append("use_metaprompt", use_metaprompt);
      const data = await fetchWritingTemplatesAPI(formData);
      const formattedTemplates = (data.templates || []).map((item) => ({
        template_name: (item.template_name || "Unnamed")
          .replace(/\s*template\s*$/i, "")
          .trim(),
        template_content: item.template_content || "No content available",
      }));

      // Update state with the new template data
      setNewWritingDetails((prevState) => ({
        ...prevState,
        template: formattedTemplates,
      }));
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      setWritingCreationLoader({ isLoading: false, message: "" });
    }
  };


  // Show loading spinner while modules are loading
  if (modulesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" className={classes.loaderClass} />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          Loading Modules...
        </Typography>
      </Box>
    );
  }



  return (
    <div>
      <CreatePodcastModal
        newPodcastDetails={newPodcastDetails}
        setNewPodcastDetails={setNewPodcastDetails}
        iconImage={iconImage}
        createPodcastModal={createPodcastModal}
        handleCreatePodcastModalClose={handleAssetModalClose}
        handleCreatePodcast={handleCreatePodcast}
        handleIconUpload={handleIconUpload}
        podcastCreationLoader={podcastCreationLoader}
      />

      <CreateLabModal
        newLabDetails={newLabDetails}
        setNewLabDetails={setNewLabDetails}
        createLabModal={createLabModal}
        handleCreateLabModalClose={handleAssetModalClose}
        handleCreateLab={handleCreateLab}
      />

      <CreateWritingModal
        identifier={identifier}
        identifierPrint={identifierPrint}
        newWritingDetails={newWritingDetails}
        setNewWritingDetails={setNewWritingDetails}
        writingId={writingId}
        setWritingId={setWritingId}
        createWritingModal={createWritingModal}
        handleCreateWritingModalClose={handleAssetModalClose}
        handleCreateWriting={handleCreateWriting}
        handleIconUpload={handleIconUpload}
        iconImage={iconImage}
        writingCreationLoader={writingCreationLoader}
        setWritingCreationLoader={setWritingCreationLoader}
        currentDialog={currentDialog}
        setCurrentDialog={setCurrentDialog}
        handleGenerateTemplate={handleGenerateTemplate}
      />

      <CreateModuleModal
        moduleModal={moduleModal}
        handleClose={handleAssetModalClose}
        submitModuleLoading={submitModuleLoading}
        moduleDetails={moduleDetails}
        setModuleDetails={setModuleDetails}
        addModule={addModule}
      />

      {/* Dialog for importing artifact */}
      <Dialog
        open={importModal}
        onClose={() => setImportModal(false)}
        maxWidth="lg"
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: "0px" }}>
          <div style={{ fontSize: "30px" }}>Import Artifact</div>
          <Divider style={{ margin: "10px 0" }} />
          <Typography variant="body1" style={{ marginTop: "10px" }}>
            You can import an artifact from the list below to add it to your project. Please select an artifact and click "Import" to proceed.
          </Typography>
        </DialogTitle>
        {importLoading.loading ? (
          <>
            <DialogContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px 0"
              }}
            >
              <CircularProgress size={20} style={{ marginRight: "10px" }} />
              <Typography variant="body1" style={{ fontSize: "16px" }}>
                {importLoading.message}
              </Typography>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogContent>
              {/* Tabs for artifact types */}
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: quCreateColor,
                    marginBottom: "10px",
                  }
                }}
              >
                {artifactTypes.map((type) => (
                  <Tab key={type} label={type} value={type} />
                ))}
              </Tabs>

              {/* Table for artifacts */}
              {/* <Table
                size="small"
                style={{
                  marginTop: "10px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Artifact Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Artifact Description</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Artifact Type</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Select</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredArtifacts.map((artifact, index) => (
                    <TableRow key={index}>
                      <TableCell>{artifact.artifact_name}</TableCell>
                      <TableCell>{artifact.artifact_description}</TableCell>
                      <TableCell>{artifact.artifact_type}</TableCell>
                      <TableCell>
                        <input
                          type="radio"
                          name="artifact"
                          value={artifact.artifact_id}
                          onChange={(event) =>
                            setSelectedArtifact(event.target.value)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> */}
              <ReactTable columns={columns} data={filteredArtifacts} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setImportModal(false)}
                color="quSandBoxColor"
                disabled={importLoading.loading}
              >
                Cancel
              </Button>
              <Button
                onClick={importArtifact}
                color="quCreateColor"
                disabled={importLoading.loading || !selectedArtifact}
              >
                Import
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <CreateAssetDialog
        open={assetModal}
        handleClose={handleAssetModalClose}
        submitModuleLoading={submitModuleLoading}
        name={name}
        setName={setName}
        brief={brief}
        setBrief={setBrief}
        addAsset={addAsset}
      />

      {alert}

      <Switch>
        <Route exact path={path}>
          <div>
            <Divider style={{ marginBottom: "20px" }} />
            <HeadingWithDescription
              title={course.course_name}
              shortDescription={course.course_description}
              longDescription={course.course_outline}
              textAlign="left"
              identifier="project"
              onChange={handleCourseDetailsChange}
            />
            <Divider style={{ marginBottom: "20px" }} />
            <SearchBar
              style={{ marginBottom: "20px" }}
              value={searchValue}
              onChange={(newValue) => searchInputValue(newValue)}
              onRequestSearch={(newValue) => searchInputValue(newValue)}
            />
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} style={{ textAlign: "center" }}>
              <Button
                color="info"
                // onClick={handleImportArtifactModalOpen}
                onClick={() => setImportModal(true)}
                size="lg"
                className={classes.buttons}
              >
                <Icon>import_export</Icon> &nbsp; Import Artifact
              </Button>
              <Button
                color="info"
                onClick={() => setAssetModal(true)}
                size="lg"
                className={classes.buttons}
              >
                <Icon>add</Icon> &nbsp; Create
              </Button>
              <Tooltip
                title={
                  course.status !== "Ready for Publishing" &&
                    !course.modules.some(
                      (module) => module.status === "In Publishing Queue"
                    )
                    ? "You need to publish at least 1 module in order to publish the course."
                    : ""
                }
                arrow
                disableHoverListener={
                  !(
                    course.status !== "Ready for Publishing" &&
                    !course.modules.some(
                      (module) => module.status === "In Publishing Queue"
                    )
                  )
                }
              >
                <span>
                  <Button
                    color="info"
                    size="lg"
                    className={classes.buttons}
                    disabled={
                      course.status !== "Ready for Publishing" &&
                      !course.modules.some(
                        (module) =>
                          module.status === "In Publishing Queue" ||
                          module.status === "Published"
                      )
                    }
                    onClick={handlePublishCourse}
                  >
                    Publish Project
                  </Button>
                </span>
              </Tooltip>

              {/* <Tooltip
                title={
                  course.status !== "Published"
                    ? "The course must be published on QUSkillBridge to enable this functionality."
                    : ""
                }
                arrow
              >
                <span>
                  <Button
                    color="info"
                    size="lg"
                    className={classes.buttons}
                    disabled={course.status !== "Published"}
                    onClick={handleUnpublishCourse}
                  >
                    Unpublish Course
                  </Button>
                </span>
              </Tooltip> */}

              <Tooltip
                title={
                  course.status !== "Published"
                    ? "The course must be published on QUSkillBridge to enable preview functionality."
                    : ""
                }
                arrow
              >
                <span>
                  <Button
                    color="info"
                    size="lg"
                    className={classes.buttons}
                    disabled={course.status !== "Published"}
                    onClick={handlePreviewCourse}
                  >
                    Preview Project
                  </Button>
                </span>
              </Tooltip>
            </GridItem>
          </GridContainer>

          <GridContainer xs={{ border: 5 }}>
            {course.modules.length === 0 && additionalArtifacts.length === 0 ? (
              <h3>No modules found. Add a module to start editing.</h3>
            ) : null}
            {displayModules.map((module, index) => (
              <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                <Link
                  to={{
                    pathname: `${url}/module/${module.module_id}`,
                    state: {
                      project: project,
                    },
                  }}
                // onClick={() => setModuleID(module.module_id)} // Set the id on click
                >
                  <Card style={{ marginTop: "40px" }}>
                    <CardHeader icon text>
                      {/* <CardTitle color="quCreateColor">{asset.name + asset.name + asset.name + asset.name + asset.name + asset.name + asset.name + asset.name}</CardTitle> */}
                      <CardTitle color="quCreateColor">{module.module_name}</CardTitle>
                    </CardHeader>
                    <NewCardBody image={course.course_image || images("./qucreate_logo.png")}>
                      {/* {asset.description + asset.description + asset.description} */}
                      {module.module_description}
                    </NewCardBody>

                    <CardFooter stats style={{ marginTop: "0px", paddingBottom: "10px" }}>
                      <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        {/* Status on the left */}
                        <Tooltip title={module.status} arrow>
                          <Typography
                            variant="body2"
                            style={{
                              color: "#435966",
                              whiteSpace: "nowrap", // Prevent text from wrapping
                              overflow: "hidden", // Hide overflowing text
                              textOverflow: "ellipsis", // Add ellipsis if text overflows
                              maxWidth: "calc(100% - 60px)", // Ensure the text does not go past icons
                            }}
                          >
                            {getStatusIcon(module.status)} &nbsp; {module.status}
                          </Typography>
                        </Tooltip>
                      </div>
                    </CardFooter>
                  </Card>
                </Link>
              </GridItem>
            ))}

            {displayAdditionalArtifacts.map((artifact, index) => {
              // Determine the appropriate path based on the artifact type
              let artifactPath = `/admin/${artifact.artifact_type}/${artifact.artifact_id}`;
              if (artifact.artifact_type === "Writing") {
                artifactPath = `/admin/writings/${artifact.artifact_id}`;
              } else if (artifact.artifact_type === "podcast") {
                artifactPath = `/admin/podcastdesign/podcast/${artifact.artifact_id}`;
              }
              else if (artifact.artifact_type === "Lab") {
                artifactPath = `/admin/labdesign/lab/${artifact.artifact_id}`;
              }

              return (
                <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                  <Link
                    to={{
                      pathname: artifactPath,
                      state: { project },
                    }}
                  >
                    <Card style={{ marginTop: "40px" }}>
                      <CardHeader icon text>
                        <CardTitle color="quCreateColor">{artifact.artifact_name}</CardTitle>
                      </CardHeader>

                      <NewCardBody image={course.course_image || images("./qucreate_logo.png")}>
                        {artifact.artifact_description}
                      </NewCardBody>

                      <CardFooter stats style={{ marginTop: "0px", paddingBottom: "10px" }}>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                          {/* Status Tooltip */}
                          <Tooltip title={artifact.status} arrow>
                            <Typography
                              variant="body2"
                              style={{
                                color: "#435966",
                                whiteSpace: "nowrap", // Prevent text wrapping
                                overflow: "hidden", // Hide overflowing text
                                textOverflow: "ellipsis", // Add ellipsis if text overflows
                                maxWidth: "calc(100% - 60px)", // Ensure text does not go past icons
                              }}
                            >
                              {identifiers[artifact.artifact_type]}
                            </Typography>
                          </Tooltip>
                        </div>
                      </CardFooter>
                    </Card>
                  </Link>
                </GridItem>
              );
            })}

          </GridContainer>
        </Route>
      </Switch>
    </div>
  );
}
