import React from "react";
import { Grid, Dialog, DialogTitle, DialogContent, TextField, Typography, Box, Chip, CircularProgress, IconButton } from "@material-ui/core";
import NotificationContext from "views/Components/Context.js";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        marginTop: "10px",
    },
}));

export default function TagDialog({
    tagDialog,
    handleCloseTagDialog,
    tagSelectedAsset,
    setTagSelectedAsset,
    noAssetMessage,
    handleSaveLabel,
}) {
    const { showNotification } = React.useContext(NotificationContext);
    const classes = useStyles();
    const [newTag, setNewTag] = React.useState("");

    const handleCloseTagDialogHelper = async () => {
        setNewTag("");
        handleCloseTagDialog();
        await handleSaveLabel(tagSelectedAsset);
    };

    const updateLabels = (e) => {
        if (e.trim() === "") {
            showNotification("Please enter a valid tag", "danger");
            return;
        }

        const newTags = e.split(",").map(tag => tag.trim()); // Split the new tags by comma and trim spaces

        // Combine the old tags with the new ones, ensuring no duplicates
        const updatedTags = [...new Set([...tagSelectedAsset.tags, ...newTags])];

        setTagSelectedAsset({
            ...tagSelectedAsset,
            tags: updatedTags, // Update the tags with the merged list
        });
        setNewTag("");
    };

    const addTag = () => {
        console.log("Inside addTag", newTag);
        if (newTag.trim() === "") {
            showNotification("Please enter a valid tag", "danger");
            return;
        }

        // Combine the old tags with the new one, ensuring no duplicates
        const updatedTags = [...new Set([...tagSelectedAsset.tags, newTag.trim()])];

        // Update the state with the new tags list
        setTagSelectedAsset((prevState) => ({
            ...prevState,
            tags: updatedTags, // Update the tags with the merged list
        }));

        // Clear the newTag input
        setNewTag("");
    };

    const handleLabelDelete = (tagToDelete) => {
        setTagSelectedAsset({
            ...tagSelectedAsset,
            tags: tagSelectedAsset.tags.filter(tag => tag !== tagToDelete),
        });
    };

    return (
        <Dialog open={tagDialog} onClose={handleCloseTagDialogHelper} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="form-dialog-title">Edit Tags</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1" gutterBottom>
                    Create new Tags
                </Typography>

                {/* Layout for SearchBar and IconButton on the same line */}
                <Box display="flex" alignItems="center" width="100%" style={{ marginTop: "15px" }}>
                    <TextField
                        value={newTag}
                        placeholder="Machine Learning | Python | AI | Data Science"
                        label="Tag"
                        type="text"
                        id="outlined-basic"
                        fullWidth
                        style={{ flexGrow: 1, marginRight: "10px" }}
                        variant="outlined"
                        onChange={(e) => setNewTag(e.target.value)} // Make sure the newTag state is updated
                    />
                    <IconButton sx={{ ml: 2 }} onClick={addTag} style={{ backgroundColor: quCreateColor }}> {/* onClick on the IconButton */}
                        <AddIcon />
                    </IconButton>
                </Box>


                {/* Tag Display */}
                {tagSelectedAsset?.tags && tagSelectedAsset.tags.length > 0 ? (
                    <div className={classes.root}>
                        {tagSelectedAsset.tags.map((tag, index) => (
                            <Chip key={index} label={tag} sx={{ fontSize: "14px", padding: "4px 8px" }} onDelete={() => handleLabelDelete(tag)} />
                        ))}
                    </div>
                ) : (
                    <Grid container justifyContent="center" alignItems="center" style={{ marginTop: "15px" }}>
                        <Typography variant="body2" color="textSecondary">
                            {noAssetMessage}
                        </Typography>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};
