import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
} from "@material-ui/core";

const ImportTemplatesModal = ({
  open,
  onClose,
  templates,
  selectedTemplates,
  setSelectedTemplates,
  handleTemplateSelection,
}) => {
  // Function to handle selecting/deselecting a template
  const handleCheckboxChange = (templateId) => {
    // If the template is already selected, remove it from selectedTemplates
    if (selectedTemplates.includes(templateId)) {
      setSelectedTemplates(selectedTemplates.filter((id) => id !== templateId));
    } else {
      // Otherwise, add it to selectedTemplates
      setSelectedTemplates([...selectedTemplates, templateId]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Select Templates to Import</DialogTitle>
      <DialogContent>
        {
          // If no templates are found, display a message
          templates.length === 0 && (
            <p>
              All the available templates have been imported for the current
              project.
            </p>
          )
        }
        {
          // If no templates are found, display a message
          templates.length !== 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.map((template) => (
                  <TableRow key={template.id}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        onChange={() => handleCheckboxChange(template._id)} // Handle the checkbox change
                        checked={selectedTemplates.includes(template._id)} // Check if the template is selected
                      />
                    </TableCell>
                    <TableCell>{template.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleTemplateSelection} // Trigger the import process
          color="primary"
          disabled={selectedTemplates.length === 0} // Disable the Confirm button if no templates are selected
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportTemplatesModal;
