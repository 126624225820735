// React Imports
import React, { useEffect } from "react";

// Material-UI Core Imports
import {
  Grid, Chip, Box, Typography,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Card, Divider, Tooltip, TextField, Checkbox,
} from "@material-ui/core";

// Contexts
import NotificationContext from "views/Components/Context.js";

// Custom Components
import Button from "../../../components/CustomButtons/Button.js";
import EditIcon from '@material-ui/icons/Edit';

import QuStreamlineDialog from "views/Components/QuStreamlineDialog.js";
import useMetaPrompt from "hooks/useMetaPrompt.js";

import { fetchWritingPromptAPI } from "views/APIs/APIUtility";

const RegenerateAndSaveModal = ({
  rnsModalOpen,
  RNSType,
  handleRNSModalClose,
  setSelectedResources,
  setCommitMessage,
  resources,
  message,
  commitMessage,
  onSubmit,
  scratchCheck,
  setScratchCheck,
  setFeedback,
}) => {

  const {
    metapromptCheckbox,
    setMetapromptCheckbox,
    originalMetaPrompt,
    setOriginalMetaPrompt,
    metaPrompt,
    setMetaPrompt,
    metaPromptDialogOpen,
    setMetaPromptDialogOpen,
    requiredMetaPromptParameters,
    setRequiredMetaPromptParameters,
    displayMetaPromptParameters,
    setDisplayMetaPromptParameters,
    notRequiredParameters,
    isDisabled,
    setIsDisabled,
    handleMetaPromptChange,
    checkForDisabled,
    _extractParameters,
    _handleEnhancePrompt,
  } = useMetaPrompt();

  const category = localStorage.getItem("userCategory");

  const { showNotification } = React.useContext(NotificationContext);
  // const [useMetaPrompt, setUseMetaPrompt] = React.useState(false);


  // Fetch writing data and initialize component state
  useEffect(() => {
    const getPrompt = async () => {
      try {
        const formData = new FormData();
        formData.append("identifier", "regenerate");
        const data = await fetchWritingPromptAPI(formData);
        setMetaPrompt(data);
        setOriginalMetaPrompt(data);
        const parameters = _extractParameters(data);
        setRequiredMetaPromptParameters(parameters);
        setDisplayMetaPromptParameters(parameters);
      }
      catch (error) {
        console.log("error", error);
      }
    };

    getPrompt();
  }, []);

  const _handleConfirm = () => {
    if (RNSType == "save" && commitMessage.trim() === "") {
      showNotification("Please enter a message to save the draft", "danger");
      return;
    }
    const prompt = metapromptCheckbox ? metaPrompt : originalMetaPrompt;
    onSubmit(prompt, !metapromptCheckbox);
    // setUseMetaPrompt(false);
  };

  const handleMetaPromptDialog = async (event) => {
    setMetaPromptDialogOpen(true);
  };

  const metaPromptDialogClose = () => {
    setMetaPromptDialogOpen(false);
    setMetapromptCheckbox(false);
  };

  const _handleSubmission = async () => {
    setMetapromptCheckbox(true);
    setMetaPromptDialogOpen(false);
  };

  return (
    <div>
      <QuStreamlineDialog
        metaPromptDialogOpen={metaPromptDialogOpen}
        metaPromptDialogClose={metaPromptDialogClose}
        metaPrompt={metaPrompt}
        setMetaPrompt={setMetaPrompt}
        displayMetaPromptParameters={displayMetaPromptParameters}
        notRequiredParameters={notRequiredParameters}
        handleMetaPromptChange={handleMetaPromptChange}
        handleSubmission={_handleSubmission}
        checkForDisabled={checkForDisabled}
        _handleEnhancePrompt={_handleEnhancePrompt}
        _handleSubmission={_handleSubmission}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />    <Dialog
        open={rnsModalOpen}
        onClose={handleRNSModalClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h5">
            {RNSType === "regenerate" ? "Regenerate Draft" : "Save Draft"}
          </Typography>
          <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {RNSType === "regenerate"
                  ? "Provide your feedback to refine and regenerate the draft:"
                  : "Enter a message to save your draft:"}
              </Typography>
              {RNSType === "regenerate" ? (
                <>
                  {" "}
                  {/* TextField for user input */}
                  <Grid item xs={12} sm={12}>
                    <Card>
                      <TextField
                        multiline
                        fullWidth
                        rows={4}
                        style={{ padding: "10px" }}
                        placeholder="Example: Add abstract section."
                        onChange={(e) => setFeedback(e.target.value)} // Update feedback state when user types
                      />
                    </Card>
                  </Grid>
                </>
              ) : (
                <input
                  type="text"
                  placeholder="Enter message here"
                  value={RNSType === "regenerate" ? message : commitMessage}
                  onChange={(e) => setCommitMessage(e.target.value)}
                  style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
                />
              )}
            </Grid>
            <Grid item>
              {RNSType === "regenerate" ? (
                <>
                  <div>
                    {resources.length > 0 ? (
                      <Typography>
                        Please note: The entire draft will be regenerated using
                        the selected resources, incorporating your current
                        feedback.
                      </Typography>
                    ) : (
                      <Typography>
                        Please note: The draft will be updated based on the
                        previous outline and feedback only.
                      </Typography>
                    )}
                  </div>
                  <div>
                    <Box>
                      {resources.map((resource, index) => (
                        <Chip
                          key={index}
                          label={resource.name}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                          onDelete={() => {
                            setSelectedResources(
                              resources.filter((r) => r.id !== resource.id)
                            );
                          }}
                        />
                      ))}
                    </Box>
                  </div>
                  {(category === "super_admin" || category === "admin") && (
                    <Grid item xs={12} style={{ display: "flex", alignItems: "center", verticalAlign: "middle" }}>
                      <Checkbox color="quCreateColor" checked={metapromptCheckbox} disabled style={{ paddingLeft: "0px", marginLeft: "0px" }} />
                      <Typography variant="body2" color="textSecondary">
                        Enable QuStreamline
                      </Typography>
                      <Tooltip title="QuStreamline streamlines the generation of the query with best practices and guardrails.">
                        <EditIcon
                          style={{ verticalAlign: "middle", marginLeft: "5px" }}
                          fontSize="small"
                          color="quCreateColor"
                          onClick={handleMetaPromptDialog}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                </>
              ) : (
                <></>
              )}
            </Grid>
            {/* <Grid item>
            <Checkbox
              color="red"
              checked={scratchCheck}
              onChange={(e) => setScratchCheck(e.target.checked)}
              display="inline"
              style={{ marginLeft: "0px", paddingLeft: "0px" }}
            />
            <Typography display="inline" style={{ verticalAlign: "middle" }}>
              Regenerate the draft from scratch.
            </Typography>
          </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCommitMessage("");
              handleRNSModalClose();
            }}
            color="quCreateColor"
          >
            Cancel
          </Button>

          {RNSType === "regenerate" ? (
            <Button color="quCreateColor" onClick={_handleConfirm}>
              Regenerate
            </Button>
          ) : (
            <Button color="quCreateColor" onClick={_handleConfirm}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RegenerateAndSaveModal;
