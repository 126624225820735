import React, { useState } from "react";
import {
  Grid, Dialog, TextField,
  DialogActions, DialogContent, DialogTitle, CircularProgress,
  Typography, Divider, Stepper, Step, StepLabel, Checkbox, Tooltip
} from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import Button from "components/CustomButtons/Button.js";
import { CloudUpload } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import NotificationContext from "views/Components/Context.js";
import { DropzoneArea } from "material-ui-dropzone";
import { generateCourseOutlineAPI, fetchCourseOutlineMetaPromptAPI } from "views/APIs/APIUtility";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "views/Components/Alert";
import QuStreamlineDialog from "./QuStreamlineDialog";
import useMetaPrompt from "hooks/useMetaPrompt";


import { stepperStyles, QontoConnector } from "views/Styles/stepperStyles";
import { dropzoneStyles } from "views/Styles/dropzoneStyles";

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...stepperStyles(theme),
  ...dropzoneStyles,
}));

const usesweetAlertStyle = makeStyles(sweetAlertStyle);
export default function CreateCourseModal({
  newCourseDetails,
  setNewCourseDetails,
  iconImage,
  createCourseModal,
  handleCreateCourseModalClose,
  handleCreateCourse,
  handleIconUpload,
  courseCreationLoader,
}) {
  const {
    metapromptCheckbox,
    setMetapromptCheckbox,
    originalMetaPrompt,
    setOriginalMetaPrompt,
    metaPrompt,
    setMetaPrompt,
    metaPromptDialogOpen,
    setMetaPromptDialogOpen,
    requiredMetaPromptParameters,
    setRequiredMetaPromptParameters,
    displayMetaPromptParameters,
    setDisplayMetaPromptParameters,
    notRequiredParameters,
    isDisabled,
    setIsDisabled,
    handleMetaPromptChange,
    checkForDisabled,
    _extractParameters,
    _handleEnhancePrompt,
  } = useMetaPrompt();
  const classes = useStyles();
  const { showNotification } = React.useContext(NotificationContext);
  const category = localStorage.getItem("userCategory");

  const sweetAlertClasses = usesweetAlertStyle();

  const dialogStages = [
    "Basic Details",
    "Instructions & Files",
    "Project Outline",
    "Submit",
  ];
  const stepperDisplayStages = [
    "Basic Details",
    "Instructions & Files",
    "Project Outline",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [currentDialog, setCurrentDialog] = useState(dialogStages[0]);
  const [courseOutlineLoader, setCourseOutlineLoader] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const [backAlert, setBackAlert] = useState(false);


  const generateCourseOutline = async () => {
    setCourseOutlineLoader(true);
    const formData = new FormData();
    newCourseDetails.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("instructions", newCourseDetails.template);
    prompt = metapromptCheckbox ? metaPrompt : originalMetaPrompt;
    formData.append("prompt", prompt);
    formData.append("use_metaprompt", !metapromptCheckbox);
    try {
      const data = await generateCourseOutlineAPI(formData);
      setNewCourseDetails({ ...newCourseDetails, courseOutline: data });
      setCourseOutlineLoader(false);
    } catch (error) {
      showNotification(error, "danger");
      setCourseOutlineLoader(false);
    }
  };

  // Function to handle file upload
  const handleFileUpload = (files) => {
    setNewCourseDetails({ ...newCourseDetails, uploadedFiles: files });
  };

  // Handle next actions on dialogs
  const handleStep1 = async () => {
    if (newCourseDetails.name.trim() === "" || newCourseDetails.description.trim() === "") {
      showNotification(
        "Please make sure all fields, including the project name and description, are properly filled out.",
        "danger"
      );
      return;
    }
    await getPrompt();
    setActiveStep(1);
    setCurrentDialog(dialogStages[1]);
  };

  const handleStep2 = async () => {
    if (newCourseDetails.template.trim() === "") {
      showNotification(
        "Please fill in the instructions for generating the project outline.",
        "danger"
      );
      return;
    }
    // Reassign the updated template after replacing the placeholders
    newCourseDetails.template = newCourseDetails.template
      .replace("{{description}}", newCourseDetails.description)
      .replace("{{name}}", newCourseDetails.name);
    setActiveStep(2);
    setCurrentDialog(dialogStages[2]);
    await generateCourseOutline();

  };

  const handleStep3 = async () => {
    if (newCourseDetails.courseOutline.trim() === "") {
      showNotification("Please fill in the project outline.", "danger");
      return;
    }
    await handleCreation();
  };

  const handleCreation = async (includeModules = true) => {
    setActiveStep(3);
    setCurrentDialog(dialogStages[3]);
    await handleCreateCourse(includeModules);
    handleModalClose();
  };

  const handleModalClose = () => {
    setActiveStep(0);
    setCurrentDialog(dialogStages[0]);
    setCourseOutlineLoader(false);
    handleCreateCourseModalClose();
  };

  const handleIncludeModules = async (includeModules) => {
    hideAlert();
    await handleCreation(includeModules);
  };

  const noModuleAlert = () => {
    if (newCourseDetails.name.trim() === "" || newCourseDetails.description.trim() === "") {
      showNotification(
        "Please make sure all fields, including the project name and description, are properly filled out.",
        "danger"
      );
      return;
    }
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleIncludeModules(false)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, Create project!"
        cancelBtnText="Cancel"
        showCancel
      >
        This action will create a project with no modules in it.
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const getPrompt = async () => {
    try {
      const data = await fetchCourseOutlineMetaPromptAPI();
      setMetaPrompt(data);
      setOriginalMetaPrompt(data);
      const parameters = _extractParameters(data);
      setRequiredMetaPromptParameters(parameters);
      setDisplayMetaPromptParameters(parameters);
    }
    catch (error) {
      console.log("error", error);
    }
  };

  const handleBack = async () => {
    if (activeStep === 1) {
      setNewCourseDetails({
        ...newCourseDetails,
        template: "# Instructions\n - Course Name: {{name}} \n - Course Description: {{description}} \n- Number of modules: 5\n- Course requirements: Create an engaging and comprehensive project outline that contains the above specified number of modules.\n- Module structure:\n  - Description: Provide a brief summary of each module that encapsulates the main topics and key takeaways.\n  - Learning objectives: Include a list of specific, actionable objectives for each module. Clearly outline what learners will gain or accomplish by completing the module.\n- Objective focus: Ensure that learning objectives are concise, measurable, and relevant to the overall course content.",
      });
    }
    if (activeStep === 2) {
      setNewCourseDetails({ ...newCourseDetails, courseOutline: "" });
    }
    setActiveStep(activeStep - 1);
    setCurrentDialog(dialogStages[activeStep - 1]);
  };

  const handleMetaPromptDialog = async (event) => {
    setMetaPromptDialogOpen(true);
  };

  const handleConfirm = () => {
    setBackAlert(false); // Close the alert
    handleBack();
  };

  const handleCancel = () => {
    setBackAlert(false); // Close the alert
  };

  const metaPromptDialogClose = () => {
    setMetaPromptDialogOpen(false);
    setMetapromptCheckbox(false);
  };

  const _handleSubmission = async () => {
    setMetapromptCheckbox(true);
    setMetaPromptDialogOpen(false);
  };

  const renderDialogContent = () => {
    switch (currentDialog) {
      case "Basic Details":
        return (
          <div>
            <Typography variant="h5" style={{ marginBottom: "10px" }}>
              Enter the project details:
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Project Name"
              placeholder="AI and Machine Learning in Finance"
              type="text"
              fullWidth
              required
              value={newCourseDetails.name}
              onChange={(event) =>
                setNewCourseDetails({
                  ...newCourseDetails,
                  name: event.target.value,
                })
              }
            />
            <TextField
              margin="dense"
              id="description"
              label="Brief Description"
              placeholder="This project provides a comprehensive introduction to the fundamentals of Artificial Intelligence (AI) and Machine Learning (ML) in finance."
              type="text"
              fullWidth
              multiline
              required
              rows={4}
              value={newCourseDetails.description}
              onChange={(event) =>
                setNewCourseDetails({
                  ...newCourseDetails,
                  description: event.target.value,
                })
              }
            />
            {/* Icon Image Upload */}
            <Button
              variant="contained"
              component="label"
              color="info"
              style={{ marginTop: "20px" }}
            >
              <CloudUpload /> &nbsp; Upload Image
              <input
                type="file"
                hidden
                accept="image/*"
                required
                onChange={handleIconUpload}
              />
            </Button>
            {iconImage && (
              <div style={{ marginTop: "10px" }}>
                <strong>Uploaded Image:</strong> {iconImage.name}
              </div>
            )}
            <Typography
              variant="caption"
              style={{ display: "block", marginTop: "5px" }}
            >
              A 16:9 aspect ratio image is recommended
            </Typography>
          </div>
        );
      case "Instructions & Files":
        return (
          <Grid container spacing={1} alignItems="flex-start">
            {/* Title for the Template Editing */}
            <Grid item xs={12}>
              <Typography variant="h5" style={{ marginBottom: "10px" }}>
                Edit the instructions to customize it to your requirements:
              </Typography>
            </Grid>

            {/* MDEditor for Template Editing */}
            <Grid item xs={12} style={{ flexGrow: 1 }}>
              <div data-color-mode="light">
                <MDEditor
                  value={newCourseDetails.template
                    .replace("{{name}}", newCourseDetails.name)
                    .replace("{{description}}", newCourseDetails.description)}
                  onChange={(event) =>
                    setNewCourseDetails({
                      ...newCourseDetails,
                      template: event,
                    })
                  }
                  height={250}
                />
              </div>
            </Grid>

            {(category === "super_admin" || category === "admin") && (
              <Grid item xs={12} style={{ display: "flex", alignItems: "center", verticalAlign: "middle" }}>
                <Checkbox color="quCreateColor" checked={metapromptCheckbox} disabled style={{ paddingLeft: "0px", marginLeft: "0px" }} />
                <Typography variant="body2" color="textSecondary">
                  Enable QuStreamline
                </Typography>
                <Tooltip title="QuStreamline streamlines the generation of the query with best practices and guardrails.">
                  <EditIcon
                    style={{ verticalAlign: "middle", marginLeft: "5px" }}
                    fontSize="small"
                    color="quCreateColor"
                    onClick={handleMetaPromptDialog}
                  />
                </Tooltip>
              </Grid>
            )}

            {/* <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Button color="quCreateColor" onClick={handleMetaPrompt}>View/Edit Prompt</Button>
            </Grid> */}

            {/* Title for File Upload */}
            <Grid item xs={12} style={{ flexGrow: 1 }}>
              <Typography
                variant="h5"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Upload supporting files to generate the project outline:
              </Typography>
            </Grid>

            {/* DropzoneArea for File Upload */}
            <Grid item xs={12}>
              <DropzoneArea
                initialFiles={newCourseDetails.uploadedFiles}
                onChange={handleFileUpload}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                maxFileSize={50000000}
                acceptedFiles={["application/pdf"]}
                previewText="Uploaded files"
                dropzoneText={
                  <div style={{ textAlign: "center" }}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      style={{ marginBottom: "10px" }}
                    >
                      Acceptable formats: .pdf
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Drag and drop or click here to upload files.
                    </Typography>
                  </div>
                }
                filesLimit={100}
                dropzoneClass={classes.dropzone}
              />
            </Grid>
          </Grid>
        );
      case "Project Outline":
        return (
          <div>
            <Typography variant="h5" style={{ marginBottom: "10px" }}>
              Project Outline:
            </Typography>
            {courseOutlineLoader ? (
              <div style={{ display: "flex" }}>
                <CircularProgress size={20} /> &nbsp;
                <Typography variant="body1">
                  Generating project outline...
                </Typography>
              </div>
            ) : (
              <div data-color-mode="light">
                <MDEditor
                  value={newCourseDetails.courseOutline}
                  onChange={(event) =>
                    setNewCourseDetails({
                      ...newCourseDetails,
                      courseOutline: event,
                    })
                  }
                  height={400}
                />
              </div>
            )}
          </div>
        );
      case "Submit":
        return (
          <div style={{ display: "flex" }}>
            <CircularProgress size={20} /> &nbsp;
            <Typography variant="body1">Creating project for you...</Typography>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {alert}
      {/* create a dialog having two mdeditors in preview mode showing the project outline and the metaprompt response side by side in two grids */}
      {backAlert && (
        <Alert
          title="Are you sure?"
          warning={true}
          showCancel={true}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        >
          If you proceed, some changes made at this step will be lost. Are you
          sure you want to continue?
        </Alert>
      )}
      <Dialog
        open={createCourseModal}
        onClose={handleModalClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        {/* Stepper Component */}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<QontoConnector />}
          className={classes.stepperRoot}
        >
          {stepperDisplayStages.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.stepIcon, // Default icon color
                    active: classes.activeStepIcon, // Active step circle
                    completed: classes.completedStepIcon, // Completed step color
                  }
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Divider />
        <DialogTitle id="form-dialog-title">
          <div style={{ fontSize: "30px" }}>
            {newCourseDetails.name
              ? newCourseDetails.name
              : "Create a New Project"}
          </div>
        </DialogTitle>
        <DialogContent>{renderDialogContent()}</DialogContent>
        <DialogActions>
          {currentDialog !== "Basic Details" && (
            <Button
              onClick={() => setBackAlert(true)}
              color="quCreateColor"
              disabled={courseOutlineLoader || courseCreationLoader}
            >
              Back
            </Button>
          )}
          <Button
            onClick={handleModalClose}
            color="quCreateColor"
            disabled={courseOutlineLoader || courseCreationLoader}
          >
            Cancel
          </Button>
          {currentDialog === "Basic Details" && (
            <Button
              onClick={() => {
                noModuleAlert();
              }}
              color="quCreateColor"
            >
              Create Empty project
            </Button>
          )}
          <Button
            onClick={() => {
              switch (currentDialog) {
                case "Basic Details":
                  return handleStep1();
                case "Instructions & Files":
                  return handleStep2();
                case "Project Outline":
                  return handleStep3();
                default:
                  return null;
              }
            }}
            disabled={courseOutlineLoader || courseCreationLoader}
            color="quCreateColor"
          >
            {currentDialog === "Project Outline" ? "Submit" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>

      <QuStreamlineDialog
        metaPromptDialogOpen={metaPromptDialogOpen}
        metaPromptDialogClose={metaPromptDialogClose}
        originalMetaPrompt={originalMetaPrompt}
        metaPrompt={metaPrompt}
        setMetaPrompt={setMetaPrompt}
        displayMetaPromptParameters={displayMetaPromptParameters}
        notRequiredParameters={notRequiredParameters}
        handleMetaPromptChange={handleMetaPromptChange}
        handleSubmission={_handleSubmission}
        checkForDisabled={checkForDisabled}
        _handleEnhancePrompt={_handleEnhancePrompt}
        _handleSubmission={_handleSubmission}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />

    </div >
  );
}
