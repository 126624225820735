// React
import React, { useEffect } from "react";

// Material-UI Components
import { Divider, Grid, Typography } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// React Router
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
const useStyles = makeStyles(styles);

export function ArcadeEmbed() {
  return (
    <div style={{ position: 'relative', paddingBottom: 'calc(47.5% + 41px)', height: 0, width: '100%' }}>
      <iframe
        src="https://demo.arcade.software/ZjxyGaUnODhF0vD86Tt7?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true"
        title="QuIP - QuCoursify"
        frameBorder="0"
        loading="lazy"
        allowFullScreen
        allow="clipboard-write"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', colorScheme: 'light' }}
      />
    </div>
  )
}


/**
 * HomePage component renders the main landing page for the QuCreate platform.
 *
 * The page includes:
 * - A welcome message
 * - An introduction to QuCreate
 * - An interactive demo embed
 * - Information about QuCreate and its features
 * - Links to various sections of the platform
 *
 * @component
 * @example
 * return (
 *   <HomePage />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 */


const HomePage = () => {
  // Effect to check if user is authenticated
  const classes = useStyles();

  const [userAuthenticated, setUserAuthenticated] = React.useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch((err) => {
      console.log(err);
      window.location.href = "/auth/login-page";
    });
  }, []);

  return (
    <div>
      <Divider style={{ marginBottom: "20px" }} />
      {/* Title */}
      <Typography variant="h5">Welcome to QuCreate!</Typography>
      <Typography variant="body1" style={{ marginTop: "10px" }}>
        {" "}
        QuCreate is a powerful platform for creating and sharing interactive
        content. Whether you want to create courses, blogs, research reports, or
        more, QuCreate makes it easy and seamless.
      </Typography>
      <Typography variant="body1" style={{ marginTop: "10px" }}>
        Explore:
        <ul>
          {/* <li>
            <Link to="/admin/qu-api-vault">
              <b>QuApiVault</b>
            </Link>
            : Store all your APIs in one place.
            <br />
          </li>
          <li>
            <Link to="/admin/templates">
              <b>QuModelStudio</b>
            </Link>
            : Develop, test, and validate model reports.
            <br />
          </li> */}
          <li>
            <Link to="/admin/landing-page" className={classes.linkText}>
              <b>QuCreate</b>
            </Link>
            : Create courses, blogs, and research reports.
            <br />
          </li>
          <li>
            <a href="https://quskillbridge.qusandbox.com/" target="_blank" className={classes.linkText}>
              <b>QuSkillBridge</b>
            </a>
            : Learn and upskill with our learning platform.
          </li>
        </ul>
      </Typography>
      <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        {/* Interactive Demo */}
        <Grid item xs={12} md={12}>
          <ArcadeEmbed />
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
