
import React, { useEffect, useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";

// Amplify components
import { Auth } from "aws-amplify";
import {
  FormControl,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  Slide,
  Grid,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import ClearIcon from "@material-ui/icons/Clear";
import { useRouteMatch, useLocation } from "react-router-dom";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import CardIcon from "components/Card/CardIcon";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import NotificationContext from "views/Components/Context.js";
import Alert from "views/Components/Alert.js";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import Close from "@material-ui/icons/Close";


import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(userProfileStyles);
const useModalStyles = makeStyles(modalStyle);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

export default function Audit(props) {
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const sweetAlertClasses = usesweetAlertStyle();
  const { showNotification } = React.useContext(NotificationContext);

  const [reportVersionState, setReportVersionState] = React.useState("");
  const [reportNameState, setReportNameState] = React.useState("");
  const [reportContactState, setReportContactState] = React.useState("");
  const [reportOwnerState, setReportOwnerState] = React.useState("");

  const [alert, setAlert] = React.useState();
  const { params } = useRouteMatch();
  const [user, setUser] = useState();
  const [noteRows, setNoteRows] = React.useState([]);
  const [createAuditModal, setCreateAuditModal] = useState(false);
  const [checkedQLDB, setCheckedQLDB] = React.useState(false);
  const [reportParameters, setReportParameters] = React.useState({});
  const [reportDetails, setReportDetails] = React.useState({});
  const [reportError, setReportError] = React.useState();

  const [modal, setModal] = React.useState();
  const [form, setForm] = React.useState();

  const [auditHTML, setAuditHTML] = React.useState("");
  const [auditHeight, setAuditHeight] = React.useState("0");


  const detailsSchema = [
    {
      "name": "Summary",
      "description": "This audit checklist summarizes the assessments conducted as a part of QuantUniversity's Audit of the Breast Cancer Detection Model"
    },
    {
      "name": "Data",
      "description": "The Data audit was conducted as per the attached Data Report"
    },
    {
      "name": "Models",
      "description": "The Model audit was conducted as per the attached Data Report"
    },
    {
      "name": "Environment",
      "description": "The Environment audit was conducted as per the attached Data Report"
    },
    {
      "name": "Pipeline",
      "description": "The Pipeline audit was conducted as per the attached Data Report"
    },
    {
      "name": "Explainability",
      "description": "The Explainability audit was conducted as per the attached Data Report"
    },
    {
      "name": "Fairness and Bias",
      "description": "The Fairness an Bias audit was conducted as per the attached Data Report"
    },
    {
      "name": "Findings",
      "description": "Findings"
    },
    {
      "name": "Recommendations",
      "description": "Recommendations"
    }
  ]

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
        if (err === "not authenticated") {
          window.location.href = "/auth/login-page";
        }
      });
  }, []);

  useEffect(() => {
    setReportDetails(JSON.parse(JSON.stringify(props.audit.Content.report_details)))
    setReportParameters(JSON.parse(JSON.stringify(props.audit.Content.report_parameters)))
  }, [])

  // useEffect(() => {
  //   setForm(

  // }, [reportParameters])

  useEffect(() => {
    if (Object.keys(props.audit).length !== 0 && user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + `/experiment/${params.exp_id}/audit/${props.audit.PK.split("#")[1]}`, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(
        function (res) {
          res.json().then(function (data) {
            
            fetch(
              data.s3_link,
              {
                method: "GET",
                mode: "cors",
                credentials: "omit",
                cache: "no-cache",
              }
            ).then((res) => {
              res.text().then((data) => {
                setAuditHTML(data);
              });
            });
          })
        },
        function (err) {
          console.log(err);
        }
      )
    }
  }, [user])


  const resizeAuditIframe = (e) => {
    const frame = e.target;
    setAuditHeight(frame.contentWindow.document.documentElement.scrollHeight);
  };

  const hideAlert = () => {
    setAlert();
  };

  const createReport = () => {

  }

  const editReport = () => {

  }

  const openCreateReport = () => {
    setModal('create')
  }

  const openEditReport = () => {
    setModal('edit')
  }

  const deleteAlert = (noteData) => {
    setAlert(
      <Alert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteNote(noteData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this Note!
      </Alert>
    );
  };

  const handleDeleteNote = (note) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${params.exp_id}/note/${note.PK.split("#")[1]}`,
      {
        method: "DELETE",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function () {
        let data = props.notes.filter((item) => note.PK !== item.PK);
        props.setNotes(data);
        showNotification("Note deleted successfully.", "quSandBoxColor");
      })
      .catch((error) => {
        showNotification("Delete failed.", "danger");
        console.log(error);
      });
    hideAlert();
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  return (
    <div className={classes.root}>
      {alert}
      <Dialog
        classes={{
          paper: modalClasses.modal,
        }}
        open={modal != null}
        transition={Transition}
        scroll='body'
        keepMounted
        onClose={() => setModal()}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <CardHeader color="quSandBox" icon>
          <CardIcon color="quSandBox" style={{ marginTop: "-40px" }}>
            <PlaylistAddCheckIcon />
          </CardIcon>
          <Button
            justIcon
            className={modalClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal()}
            style={{ marginTop: "0px" }}
          >
            <Close className={modalClasses.modalClose} />
          </Button>
          {modal === "create" && (
            <h4 className={classes.cardIconTitle}>Create Audit Report</h4>
          )}
          {modal === "edit" && (
            <h4 className={classes.cardIconTitle}>Edit Audit Report</h4>
          )}
        </CardHeader>
        <form>
          <DialogContent
            id="modal-slide-description"
            className={modalClasses.modalBody}
          >
            <CustomInput
              labelText="Name"
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              success={reportNameState === "success"}
              error={reportNameState === "error"}
              inputProps={{
                type: "text",
                onChange: (event) => {
                  if (event.target.value.length > 0) {
                    setReportNameState("success");
                  } else {
                    setReportNameState("error");
                  }
                  setReportParameters(event.target.value);
                },
                value: reportParameters.name || "",
              }}
            />
            <CustomInput
              labelText="Version"
              id="version"
              formControlProps={{
                fullWidth: true,
              }}
              success={reportVersionState === "success"}
              error={reportVersionState === "error"}
              inputProps={{
                type: "text",
                onChange: (event) => {
                  if (event.target.value.length > 0) {
                    setReportVersionState("success");
                  } else {
                    setReportVersionState("error");
                  }
                  setReportParameters(event.target.value);
                },
                value: reportParameters.version ? reportParameters.version.name : "1.0.0",
              }}
            />
            <CustomInput
              labelText="Owner"
              id="owner"
              formControlProps={{
                fullWidth: true,
              }}
              success={reportOwnerState === "success"}
              error={reportOwnerState === "error"}
              inputProps={{
                type: "text",
                onChange: (event) => {
                  if (event.target.value.length > 0) {
                    setReportOwnerState("success");
                  } else {
                    setReportOwnerState("error");
                  }
                  setReportParameters(event.target.value);
                },
                value: reportParameters.owners ? reportParameters.owners[0].name : "",
              }}
            />
            <CustomInput
              labelText="Contact"
              id="contact"
              formControlProps={{
                fullWidth: true,
              }}
              success={reportContactState === "success"}
              error={reportContactState === "error"}
              inputProps={{
                type: "text",
                onChange: (event) => {
                  if (event.target.value.length > 0) {
                    setReportContactState("success");
                  } else {
                    setReportContactState("error");
                  }
                  setReportParameters(event.target.value);
                },
                value: reportParameters.owners ? reportParameters.owners[0].contact : "",
              }}
            />
            {detailsSchema.map(
              (detail, key) => (
                <CustomInput
                  key={detail.name}
                  labelText={detail.name}
                  id={detail.name}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    placeholder: detail.description,
                    onChange: (event) => {
                      let tmpDetails = reportDetails;
                      reportDetails[key].description = event.target.value;
                    },
                    value: reportDetails[key] ? reportDetails[key].description : "",
                  }}
                />
              )
            )}
            {reportError && <code>{reportError}</code>}
          </DialogContent>
          <DialogActions
            className={
              modalClasses.modalFooter + " " + modalClasses.modalFooterCenter
            }
          >
            {modal === "create" && (
              <Button onClick={createReport} color="success">
                Create
              </Button>
            )}
            {modal === "edit" && (
              <Button onClick={editReport} color="success">
                Edit
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
      <Card className={classes.card}>
        <CardHeader icon>
          <CardIcon color="quSandBox">
            <PlaylistAddCheckIcon />
          </CardIcon>

          <h4 className={classes.cardIconTitle}>Audit Checklist</h4>
          <GridContainer
            justify="space-between"
          >
            <GridItem xs={12} sm={12} md={6}>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} >
              {
                props.audit.QLDBReference &&
                <div>
                  <div className={classes.block} style={{ float: "right" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedQLDB}
                          onChange={event => setCheckedQLDB(event.target.checked)}
                          value="checkedQLDB"
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      labelPlacement='start'
                      label={checkedQLDB ? "Switch to view the current report" : "Switch to browse the versioned history in QuTrack"}
                    />
                  </div>
                </div>
              }
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <iframe
              id="data"
              srcDoc={auditHTML}
              width="100%"
              frameBorder="0"
              height={`${auditHeight}px`}
              scrolling="no"
              onLoad={resizeAuditIframe}
            ></iframe>
            {
              props.audit ?
                <Button
                  color="info"
                  size="lg"
                  onClick={openEditReport}
                >
                  Edit Audit Report
                </Button>
                :
                <Button
                  color="info"
                  size="lg"
                  onClick={openCreateReport}
                >
                  Create Audit Report
                </Button>
            }

          </GridContainer>
        </CardBody>
      </Card>

    </div>
  );
}
