import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import { Divider, Grid, Box, CircularProgress, Typography } from "@material-ui/core";
import ModuleInformationContentReview from "./ModuleInformationContentReview";
import SlidesStructureReview from "../SlidesStructureReview";
import LabstructureReview from "./LabsStructureReview";
import { Auth } from "aws-amplify";
import NotificationContext from "views/Components/Context.js";
import { fetchCourseAPI } from "views/APIs/APIUtility";

export default function StructureReview({ courseID, moduleID, activeStatusData, moduleStatus }) {
    const { showNotification } = React.useContext(NotificationContext);
    const [user, setUser] = useState();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState("Module Information");

    const [moduleInformation, setModuleInformation] = useState([]);
    const [slides, setSlides] = useState([]);
    const [labs, setLabs] = useState([]);
    const [modulesLoading, setModulesLoading] = useState(true);

    const setContentReviewFromCourse = (data) => {
        const modules = data.modules;
        const module = modules.find(module => module.module_id === moduleID);
        const resources = module.pre_processed_structure;
        const slides = resources.filter(resource => resource.resource_type === "Slide_Generated");
        const links = resources.filter(resource => resource.resource_type === "Link");
        const notes = resources.filter(resource => resource.resource_type === "Note");

        setSlides(slides);
        setLabs(links);
        setModuleInformation(notes);
    };

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const data = await fetchCourseAPI(courseID);
                setContentReviewFromCourse(data);
            } catch (error) {
                showNotification("Error fetching course data", "danger");
            } finally {
                setModulesLoading(false);
            }
        };
        fetchCourseData();
    }, [courseID]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => setUser(user))
            .catch((err) => {
                if (err === "not authenticated") {
                    history.push("/auth/login-page");
                }
            });
    }, [history]);

    const renderContent = () => {
        if (modulesLoading) {
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="60vh"
                >
                    <CircularProgress color="quCreateColor" />
                    <Typography variant="h6" style={{ marginLeft: 16 }}>
                        Loading Content...
                    </Typography>
                </Box>
            );
        }

        switch (activeTab) {
            case "Module Information":
                return <ModuleInformationContentReview
                    courseID={courseID} moduleID={moduleID} filetype="Note"
                    moduleInformation={moduleInformation} course_design_step={8} setActiveTab={setActiveTab} activeStatusData={activeStatusData}
                />;
            case "Slides":
                return <SlidesStructureReview courseID={courseID} moduleID={moduleID} filetype="slides"
                    slides={slides} setActiveTab={setActiveTab} moduleStatus={moduleStatus}
                />;
            // case "Labs":
            //     return <LabstructureReview courseID={props.courseID} moduleID={props.moduleID} filetype="Link" links={labs} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Grid container display="flex" justify="space-between" style={{
                marginTop: "20px"
            }}>
                <Grid item xs={4}>
                    <Typography variant="body1" style={{
                        marginTop: "10px",
                        fontWeight: "bold",
                        fontSize: "20px"
                    }}>Structure Review</Typography>
                </Grid>
                <Grid item xs={8} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "10px"
                }}>

                    <Button color="quCreateColor" style={activeTab === "Module Information" ? {
                        textDecoration: "underline"
                    } : {}} onClick={() => setActiveTab("Module Information")}>Module Information</Button>
                    <Button color="quCreateColor" style={activeTab === "Slides" ? {
                        textDecoration: "underline"
                    } : {}} onClick={() => setActiveTab("Slides")}>Slides</Button>
                    {/* <Button color="quSandBoxColor" style={activeTab === "Labs" ? {
                        textDecoration: "underline"
                    } : {}} onClick={() => setActiveTab("Labs")}>Labs</Button> */}
                </Grid>
            </Grid>
            <Divider />

            {renderContent()}
        </div>

    );
}