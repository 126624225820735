// React
import React from "react";

// External Libraries
import { Icon } from "@material-ui/core";
import { Link } from "react-router-dom";

// Internal Components
import Button from "components/CustomButtons/Button.js";

const ExitReviewButton = ({ courseID }) => (
  <Link
    to={`/admin/project-design/courses/${courseID}`}
    style={{ textDecoration: "none" }} // Optional: Remove underline for Link
  >
    <Button color="danger">
      <Icon>close</Icon>&nbsp;Exit Review
    </Button>
  </Link>
);

export default ExitReviewButton;
