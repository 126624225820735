// React and React Router imports
import React, { useEffect, useState } from "react";
import { Route, Switch, Link, useRouteMatch } from "react-router-dom";

// Material-UI Core Components
import {
  Box,
  CircularProgress,
  Typography,
  Tooltip,
} from "@material-ui/core";

// Material-UI Icons
import DeleteIcon from "@material-ui/icons/Delete";

// Third-party Components
import SearchBar from "material-ui-search-bar";
import SweetAlert from "react-bootstrap-sweetalert";

// Material-UI Styles
import { makeStyles } from "@material-ui/core/styles";

// Custom Styles
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// Custom Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CreateCourseCard from "views/Components/CreateCourseCard";

// Context
import NotificationContext from "views/Components/Context.js";

// API Utilities
import {
  fetchModelProjects,
  createModelProjectAPI,
  deleteModelProjectAPI,
} from "views/APIs/APIUtility.js";

// Modals
import CreateTemplateProjectModal from "views/Components/CreateTemplateProjectModal";

const useStyles = makeStyles(styles);
const usedashboardStyle = makeStyles(dashboardStyle);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

export default function ModelHomePage({ searchValue, createProjectModalInitialState, setCreateProjectModalInitialState }) {
  // Load images and apply Material-UI styling
  const images = require.context("assets/img", true);
  const classes = useStyles();
  const dashboardClasses = usedashboardStyle();
  const sweetAlertClasses = usesweetAlertStyle();

  // Get notification function from context and routing path
  const { showNotification } = React.useContext(NotificationContext);
  const path = useRouteMatch().path;


  // State for managing projects list
  const [allProjects, setAllProjects] = React.useState([]);
  const [initialProjects, setInitialProjects] = React.useState([]);
  const [error, setError] = useState(null);

  // State for project creation modal and new project details
  const [newProjectDetails, setNewProjectDetails] = React.useState({
    name: "",
    description: "",
  });
  const [createProjectModal, setCreateProjectModal] = React.useState(false);

  // State for managing loading state and alert component
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    message: "",
  });
  const [alert, setAlert] = React.useState(null);

  // Function to delete a project using API and update the project lists
  const handleDeleteProject = async (projectData) => {
    setLoadingState({ isLoading: true, message: "Deleting Project" });
    try {
      const formData = new FormData();
      formData.append("project_id", projectData._id);
      const response = await deleteModelProjectAPI(formData);
      if (response !== true) {
        showNotification("Failed to delete project", "danger");
      }
      // Remove deleted project from the list
      const remainingProjects = allProjects.filter(
        (project) => project._id !== projectData._id
      );
      setAllProjects(remainingProjects);
    } catch (error) {
      showNotification(
        `Failed to delete project: ${error.message || error}`,
        "danger"
      );
    } finally {
      setLoadingState({ isLoading: false, message: "" });
      hideAlert();
    }
  };

  // Helper function to hide alert messages
  const hideAlert = () => {
    setAlert(null);
  };

  // Display confirmation alert before deleting a project
  const deleteAlert = (projectData) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteProject(projectData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        This action will permanantly delete the project and will not be recoverable.
      </SweetAlert>
    );
  };

  // Fetch projects from the API when the component mounts
  useEffect(() => {
    const fetchProjects = async () => {
      setLoadingState({ isLoading: true, message: "Loading Projects" });
      try {
        const data = await fetchModelProjects(); // API call to load projects
        setAllProjects(data);
        setInitialProjects(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingState({ isLoading: false, message: "" });
      }
    };
    fetchProjects();
  }, []); // Empty dependency array to run only on mount

  // Function to handle project creation via API
  const handleCreateProject = async () => {
    setLoadingState({ isLoading: true, message: "Creating Project" });
    try {
      // Prepare form data for API request
      const formData = new FormData();
      formData.append("project_name", newProjectDetails.name);
      formData.append("project_description", newProjectDetails.description);
      const data = await createModelProjectAPI(formData);
      // Append the newly created project to the list
      setAllProjects((prevProjects) => [...prevProjects, data]);
      showNotification("Project created successfully", "success");
    } catch (error) {
      showNotification(
        `Failed to create project: ${error.message || error}`,
        "danger"
      );
    } finally {
      setLoadingState({ isLoading: false, message: "" });
      handleCreateProjectModalClose();
    }
  };

  // Closes the project creation modal and clears the new project details
  const handleCreateProjectModalClose = () => {
    setCreateProjectModal(false);
    setCreateProjectModalInitialState(false);
    setNewProjectDetails({
      name: "",
      description: "",
    });
  };

  useEffect(() => {
    if (searchValue === "") {
      setAllProjects(initialProjects);
    } else {
      const newList = initialProjects.filter((project) =>
        (project.name &&
          project.name.toLowerCase().includes(searchValue.toLowerCase())) ||
        (project.description &&
          project.description.toLowerCase().includes(searchValue.toLowerCase()))
      );
      setAllProjects(newList);
    }
  }, [searchValue]);

  // Render a loading indicator if data is still loading
  if (loadingState.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loadingState.message}
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      {/* Show alerts */}
      {alert}
      <CreateTemplateProjectModal
        newProjectDetails={newProjectDetails}
        setNewProjectDetails={setNewProjectDetails}
        createProjectModal={createProjectModal || createProjectModalInitialState}
        handleCreateProjectModalClose={handleCreateProjectModalClose}
        handleCreateProject={handleCreateProject}
        labelName="Project Name"
        placeholderName="AI Credit Risk Project"
        labelDescription="Project Description"
        placeholderDescription="AI-powered system for assessing and predicting credit risk efficiently."
      />
      {/* Actual Page starts here */}
      <Switch>
        <Route exact path={path}>
          <GridContainer>
            {/* <GridItem
              xs={12}
              sm={4}
              md={4}
              lg={2}
              style={{ textAlign: "center" }}
            >
              <Button
                color="quCreateColor"
                fullWidth
                onClick={() => setCreateProjectModal(true)}
              >
                Create Project
              </Button>
            </GridItem> */}
          </GridContainer>
          <GridContainer>
            {allProjects.length === 0 && (
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  marginTop: "20px",
                }}
              >
                <Box display="flex" justifyContent="center" >
                  <Typography variant="h5" textAlign="center">
                    Create or Review your current Model Risk Evaluation Project
                  </Typography>
                </Box>
              </GridItem>
            )}

            {allProjects.map((templateProject, index) => (
              <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                <Link
                  to={{
                    pathname: "/admin/model_home_page/" + templateProject._id,
                  }}
                >
                  <Card
                    style={{
                      minHeight: "245px",
                      maxHeight: "245px",
                      marginTop: "40px",
                    }}
                  >
                    <CardHeader stats icon>
                      <CardIcon color="quSandBox">
                        {templateProject.name}
                      </CardIcon>
                      <h3
                        className={classes.cardTitle}
                        style={{
                          zIndex: 100,
                        }}
                      >
                        <small>
                          <img
                            src={images("./qucreate_logo.png")}
                            // src={course.course_image}
                            alt=""
                            className={classes.thumbnail}
                            style={{
                              width: "100px",
                            }}
                          />
                        </small>
                      </h3>
                    </CardHeader>
                    <CardBody
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    />
                    <CardFooter>
                      <Tooltip
                        id="tooltip-top"
                        title={templateProject.description}
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                      >
                        <h5
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          {templateProject.description
                            .split(" ")
                            .slice(0, 10)
                            .join(" ") +
                            (templateProject.description.split(" ").length > 10
                              ? "..."
                              : "")}
                        </h5>
                      </Tooltip>
                    </CardFooter>
                    <CardFooter
                      stats
                      style={{ marginTop: "0px", paddingBottom: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <FileCopyIcon
                                                        style={{ color: "#435966" }}
                                                        onClick={(e) => e.preventDefault(cloneAlert(course))}
                                                    /> */}
                        {/* <p>
                          {getStatusIcon(course.status)} &nbsp;
                          {course.status}
                        </p> */}
                        <DeleteIcon
                          style={{
                            color: "#435966",
                          }}
                          //To be implemented using Library Alert. Commented for now.
                          onClick={(e) => {
                            e.preventDefault();
                            deleteAlert(templateProject);
                          }}
                        />
                      </div>
                    </CardFooter>
                  </Card>
                </Link>
              </GridItem>
            ))}
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <CreateCourseCard onClick={() => setCreateProjectModal(true)} />
              {/* <CreateTemplateProjectModal
                newProjectDetails={newProjectDetails}
                setNewProjectDetails={setNewProjectDetails}
                createProjectModal={createProjectModal}
                handleCreateProjectModalClose={handleCreateProjectModalClose}
                handleCreateProject={handleCreateProject}
              /> */}
            </GridItem>
          </GridContainer>
        </Route>
      </Switch>
    </div>
  );
}
