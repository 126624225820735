import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Grid,
  Icon,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  IconButton,
} from "@material-ui/core";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DoneIcon from "@material-ui/icons/Done";
import SaveIcon from "@material-ui/icons/Save";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Button from "components/CustomButtons/Button.js";
import ExitReviewButton from "components/CustomButtons/ExitReviewButton";
import Alert from "views/Components/Alert";
import NotificationContext from "views/Components/Context.js";
import ReviewSlide from "./ReviewSlide";

import { dropzoneStyles } from "views/Styles/dropzoneStyles";

import {
  replaceResourceAPI,
  submitModuleForStructureGeneration,
  getTemplatesAPI,
  validateTemplateAPI,
  storeCustomTemplateAPI,
} from "views/APIs/APIUtility";
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { CloudDownload, GetApp, RotateLeft } from "@material-ui/icons";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...dropzoneStyles,
}));

export default function SlidesContentReview(props) {
  const { showNotification } = useContext(NotificationContext);

  // Existing states
  const [slideHeaders, setSlideHeaders] = useState([]);
  const [slideContents, setSlideContents] = useState([]);
  const [speakerNotes, setSpeakerNotes] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loadingState, setLoadingState] = useState({ isLoading: false, message: "" });
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("style-99");
  const [templateUrl, setTemplateUrl] = useState("");

  // NEW: For the eye-icon preview flow
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [showEnlargedDialog, setShowEnlargedDialog] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState("");
  const [module, setModule] = useState(props.module);


  useEffect(() => {
    const setSlidesandFetchData = async () => {
      setLoadingState({ isLoading: true, message: "Loading Slides..." });
      const card = props.slides[0];
      if (card) {
        const slidesDataLink = card.resource_link;
        const data = await fetchSlidesContent(slidesDataLink);
        const localHeaders = [];
        const localContent = [];
        const localNotes = [];
        data.forEach((slide) => {
          localHeaders.push(slide.slide_header);
          localContent.push(slide.slide_content);
          localNotes.push(slide.speaker_notes);
        });
        setSlideHeaders(localHeaders);
        setSlideContents(localContent);
        setSpeakerNotes(localNotes);
        setIsLoading(false);
        setLoadingState({ isLoading: false, message: "" });
      } else {
        console.warn("No slide data found.");
      }
    };
    setSlidesandFetchData();
  }, [props.slides]);

  const fetchSlidesContent = async (fileUrl) => {
    const formData = new FormData();
    formData.append("url", fileUrl);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_FASTAPI_URL}/fetch_quizdata`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      return data;
    } catch (err) {
      console.error("Error fetching file:", err);
    }
  };

  const convertDataToJSON = (data) => {
    const jsonString = JSON.stringify(data);
    const blob = new Blob([jsonString], { type: "application/json" });
    const file = new File([blob], "data.json", { type: "application/json" });
    return file;
  };

  const writeFile = () => {
    const data = [];
    for (let i = 0; i < slideHeaders.length; i++) {
      data.push({
        slide_header: slideHeaders[i],
        slide_content: slideContents[i],
        speaker_notes: speakerNotes[i],
        notes: notes[i],
      });
    }
    return convertDataToJSON(data);
  };

  const handleSaveChanges = async () => {
    setLoadingState({ isLoading: true, message: "Saving changes..." });
    const file = writeFile();
    const formData = new FormData();
    formData.append("course_id", props.courseID);
    formData.append("module_id", props.moduleID);
    formData.append("resource_type", "Slide_Content");
    formData.append(
      "resource_description",
      props.slides[0].resource_description
    );
    formData.append("resource_link", props.slides[0].resource_link);
    formData.append("resource_id", props.slides[0].resource_id);
    formData.append("resource_name", props.slides[0].resource_name);
    formData.append("resource_file", file);
    formData.append("course_design_step", 5);

    try {
      await replaceResourceAPI(formData);
      showNotification("Changes saved successfully", "success");
    } catch (error) {
      console.error("Error in handleSaveChanges:", error);
      showNotification("Error saving changes", "danger");
    }
    finally {
      setLoadingState({ isLoading: false, message: "" });
    }
  };

  const submitForStructureReviewHandler = async () => {
    // await handleSaveChanges();
    setLoadingState({
      isLoading: true,
      message: "Submitting for structure review...",
    });
    const formData = new FormData();
    formData.append("course_id", props.courseID);
    formData.append("module_id", props.moduleID);
    formData.append("template_url", templateUrl);

    try {
      await submitModuleForStructureGeneration(formData);
      setLoadingState({ isLoading: false, message: "" });
      showNotification(
        "Marked as reviewed. You will be notified when the content is ready for the structure review.",
        "success"
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      showNotification("Error submitting module", "danger");
    }
  };

  const verifySubmitAction = () => {
    if (props.moduleStatus != "Content Review") {
      setShowAlert(true);
    } else {
      submitForStructureReviewHandler();
    }
  };

  const verifySubmission = () => {
    setShowTemplateDialog(true);
  };

  const handleConfirm = () => {
    setShowAlert(false);
    submitForStructureReviewHandler();
  };

  const handleCancel = () => {
    setShowAlert(false);
  };

  const handleRemoveSlide = () => {
    if (slideHeaders.length === 0) return;
    setSlideHeaders((prev) => prev.filter((_, idx) => idx !== activeSlide));
    setSlideContents((prev) => prev.filter((_, idx) => idx !== activeSlide));
    setSpeakerNotes((prev) => prev.filter((_, idx) => idx !== activeSlide));
    setNotes((prev) => prev.filter((_, idx) => idx !== activeSlide));
    setActiveSlide((prev) => Math.max(0, prev - 1));
  };

  const handleAddSlide = () => {
    setSlideHeaders((prev) => {
      const newHeaders = [...prev];
      newHeaders.splice(activeSlide + 1, 0, "");
      return newHeaders;
    });
    setSlideContents((prev) => {
      const newContents = [...prev];
      newContents.splice(activeSlide + 1, 0, "");
      return newContents;
    });
    setSpeakerNotes((prev) => {
      const newNotes = [...prev];
      newNotes.splice(activeSlide + 1, 0, "");
      return newNotes;
    });
    setNotes((prev) => {
      const newNotes = [...prev];
      newNotes.splice(activeSlide + 1, 0, "");
      return newNotes;
    });
    setActiveSlide((prev) => prev + 1);
  };

  const handleTemplateDialogClose = () => {
    setShowTemplateDialog(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchTemplates = async () => {
    try {
      let data = await getTemplatesAPI();

      // 1) Sort the templates by name, ensuring "style-99" is always at the top.
      data.sort((a, b) => {
        if (a.name === "style-99") return -1;
        if (b.name === "style-99") return 1;
        const numA = parseInt(a.name.split("-")[1], 10);
        const numB = parseInt(b.name.split("-")[1], 10);
        return numA - numB;
      });

      // 2) Sort the .png files in each template's files.pngs array
      data.forEach((template) => {
        template.files.pngs.sort((a, b) => a.localeCompare(b));
      });

      // 3) Use the sorted data
      console.log(data);
      setTemplates(data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };


  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template.name);
    setTemplateUrl(template.files.source);
  };

  const handleCustomTemplateUpload = async (file) => {
    console.log("File uploaded:", file);
    if (file.length === 0) return;
    file = file[0]
    const formData = new FormData();
    formData.append("template_file", file);

    try {
      const isValid = await validateTemplateAPI(formData);
      if (!isValid) {
        showNotification("Invalid template. Please download the source template and try again.", "danger");
        return;
      }
      formData.append("course_id", props.courseID);
      formData.append("module_id", props.moduleID);
      const response = await storeCustomTemplateAPI(formData);
      // for (let pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }
      setTemplateUrl(response.url);
      showNotification("Template uploaded successfully", "success");
    } catch (error) {
      console.error("Error uploading template:", error);
      showNotification("Error uploading template", "danger");
    }
  };

  // NEW: Eye icon => open the “preview template” dialog
  const handleOpenPreviewDialog = (e, template_name) => {
    e.stopPropagation();
    setSelectedTemplate(template_name);
    setShowPreviewDialog(true);
  };

  // Clicking on the 4 preview slides => open the “enlarged” dialog
  const handleOpenEnlarged = (imgSrc) => {
    setEnlargedImage(imgSrc);
    setShowEnlargedDialog(true);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleNextSlide = async () => {
    // await handleSaveChanges();
    setActiveSlide(activeSlide + 1);
  };

  const handlePreviousSlide = async () => {
    // await handleSaveChanges();
    setActiveSlide(activeSlide - 1)
  };

  const handlePreviousTab = async () => {
    // await handleSaveChanges();
    props.setActiveTab("Module Information")
  };


  if (loadingState.isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress color="quCreateColor" className={classes.loaderClass} />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loadingState.message}
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      {showAlert && (
        <Alert
          title="Are you sure?"
          warning={true}
          showCancel={true}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        >
          You already have a submission for this module. If you wish to make
          another submission, your module will be submitted for structure
          review.
        </Alert>
      )}

      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={10} sm={10}>
          <Typography variant="h5">Slides Content Review</Typography>
          <Typography variant="body1">
            Review the content for the slides including the speaker notes and
            the slide content.
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10px" }}
        >
          <Button
            color="danger"
            style={{ float: "right" }}
            startIcon={<RemoveIcon />}
            onClick={handleRemoveSlide}
            disabled={slideHeaders.length === 1}
          >
            Remove Slide
          </Button>
          <Button
            color="success"
            style={{ float: "right" }}
            startIcon={<AddIcon />}
            onClick={handleAddSlide}
          >
            Add Slide
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={12}>
          <ReviewSlide
            slideHeader={slideHeaders[activeSlide]}
            slideContent={slideContents[activeSlide]}
            speakerNotes={speakerNotes[activeSlide]}
            setSlideHeader={(value) => {
              setSlideHeaders(
                slideHeaders.map((item, index) => (index === activeSlide ? value : item))
              );
            }}
            setSlideContent={(value) => {
              setSlideContents(
                slideContents.map((item, index) => (index === activeSlide ? value : item))
              );
            }}
            setSpeakerNotes={(value) => {
              setSpeakerNotes(
                speakerNotes.map((item, index) => (index === activeSlide ? value : item))
              );
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} justify="center">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Button
                style={{ float: "right" }}
                color="quSandBoxColor"
                disabled={activeSlide === 0}
                onClick={handlePreviousSlide}
              >
                <Icon>arrow_back</Icon>
                &nbsp; Previous Slide
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1" style={{ textAlign: "center", marginTop: "10px" }}>
                Slide {activeSlide + 1} of {slideHeaders.length}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                style={{ float: "left" }}
                color="quSandBoxColor"
                disabled={activeSlide === slideHeaders.length - 1}
                onClick={handleNextSlide}
              >
                Next Slide&nbsp;
                <Icon>arrow_forward</Icon>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} style={{ textAlign: "left" }}>
              <ExitReviewButton courseID={props.courseID} />
            </Grid>
            <Grid item xs={12} sm={4} style={{ textAlign: "center" }} />
            <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
              <Button
                color="info"
                onClick={verifySubmission}
                style={{ float: "right" }}
                endIcon={<DoneIcon />}
              >
                Mark as Reviewed
              </Button>
              &nbsp;
              <Button
                color="success"
                onClick={handleSaveChanges}
                style={{ float: "right" }}
                startIcon={<SaveIcon />}
              >
                Save Changes
              </Button>
              &nbsp;
              <Button
                color="quSandBoxColor"
                onClick={handlePreviousTab}
                style={{ float: "right" }}
                startIcon={<NavigateBeforeIcon />}
              >
                Previous
              </Button>
              &nbsp;
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/** Existing Template-Selection Dialog */}
      <Dialog
        open={showTemplateDialog}
        onClose={handleTemplateDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Choose a Template</DialogTitle>
        <DialogContent>
          <Tabs value={tabValue} onChange={handleTabChange} TabIndicatorProps={{ style: { backgroundColor: quCreateColor } }}>
            <Tab label="Choose Template" />
            <Tab label="Upload Custom Template" />
          </Tabs>
          {tabValue === 0 && (
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              {templates.map((template) => (
                <Grid item xs={4} key={template.name}>
                  <Card
                    onClick={() => handleTemplateSelect(template)}
                    style={{
                      border: selectedTemplate === template.name ? "2px solid blue" : "none",
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        // Fit to content
                        style={{ objectFit: "contain", width: "100%", height: "auto", maxHeight: 200 }}
                        image={template.files.pngs[0]}
                        title={template.name}
                      />
                      <CardContent>
                        {/* Name + Eye Icon */}
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Typography variant="h6">{template.name}</Typography>
                          <IconButton onClick={(e) => handleOpenPreviewDialog(e, template.name)}>
                            <VisibilityIcon />
                          </IconButton>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          {tabValue === 1 && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Typography variant="body1">
                Download the source file below and edit your design to create your own custom template.
              </Typography>
              <Grid container spacing={2} justify="center" display="flex" justifyContent="center">
                <Grid item xs={4} fullWidth>
                  <Button
                    color="quCreateColor"
                    href={templates.find((template) => template.name === "style-0")?.files.source}
                    style={{ marginTop: "10px", width: "100%" }}
                    startIcon={<CloudDownload />}
                  >
                    Download Source Template
                  </Button>
                </Grid>
                {
                  module.template_link &&
                  (<Grid item xs={4} fullWidth>
                    <Button

                      color="quCreateColor"
                      href={module.template_link}
                      style={{ marginTop: "10px", width: "100%" }}
                      startIcon={<GetApp />}
                    >
                      Download Previous Template
                    </Button>
                  </Grid>)
                }
                {
                  module.template_link &&
                  (<Grid item xs={4} fullWidth>
                    <Button
                      color="quCreateColor"
                      onClick={() => { setTemplateUrl(module.template_link); handleTemplateDialogClose(); verifySubmitAction(); showNotification("Using Previously Uploaded Template", "success") }}
                      style={{ marginTop: "10px", width: "100%" }}
                      startIcon={<RotateLeft />}
                    >
                      Use Previous template
                    </Button>
                  </Grid>)
                }

              </Grid>

              <DropzoneArea
                acceptedFiles={[".pptx"]}
                filesLimit={1}
                useChipsForPreview
                showPreviewsInDropzone={false}
                dropzoneClass={classes.dropzone}
                maxFileSize={50000000}
                showPreviews={true}
                dropzoneText="Drag and drop or click to upload the edited .pptx file here."
                onChange={handleCustomTemplateUpload}
                style={{ marginTop: "20px" }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTemplateDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowTemplateDialog(false);
              verifySubmitAction();
            }}
            color="quCreateColor"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/** NEW: Dialog for “Template Preview” (eye icon) */}
      <Dialog
        open={showPreviewDialog}
        onClose={() => setShowPreviewDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Template Preview</DialogTitle>
        <DialogContent>
          {/* Show four preview cards */}
          <Grid container spacing={2}>
            {
              templates.find((template) => template.name === selectedTemplate)?.files.pngs.map((imgSrc, index) => (
                <Grid item xs={3} key={imgSrc}>
                  <Card>
                    <CardActionArea onClick={() => handleOpenEnlarged(imgSrc)}>
                      <CardMedia
                        component="img"
                        // Fit to content
                        style={{ objectFit: "contain", width: "100%", height: "auto", maxHeight: 200 }}
                        image={imgSrc}
                        title={selectedTemplate}
                      />
                      <CardContent>
                        <Typography variant="body1">{["Title Slide", "Content Slide", "Section Header Slide", "Two Content Slide"][index]}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPreviewDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/** NEW: Dialog for Enlarged Image */}
      <Dialog
        open={showEnlargedDialog}
        onClose={() => setShowEnlargedDialog(false)}
        maxWidth="lg"
      >
        <DialogContent>
          <img
            src={enlargedImage}
            alt="Enlarged"
            style={{ maxWidth: "100%", height: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEnlargedDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
