import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { Select, MenuItem, Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Timeline from "@material-ui/icons/Timeline";

// Amplify components
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/generic-user-icon-19.jpg";
// import avatar from "assets/img/faces/Sri-headshot.png"
// import logFormat from "views/Pages/LogDashboard.js"
// import LogChart from "views/Components/LogDashboard.js"
import LogChart from "views/Components/LogChart.js"
import { AddAlert } from "@material-ui/icons";
import { fetchAllUsersAPI, updateUserCategoryAPI } from "../APIs/APIUtility"
import ReactTable from "components/ReactTable/ReactTable";

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  // const [preferredUsername, setpreferredUsername] = React.useState("");
  const [br, setBR] = React.useState(false);
  const [message, setmessage] = React.useState("Your profile is updated.")
  const [status, setstatus] = React.useState("success")
  const [quUsers, setQuUsers] = React.useState([])
  const [showTable, setShowTable] = React.useState(false)
  const [loading, setLoading] = useState({ isLoading: false, message: "" });

  // Define table columns
  const columns = useMemo(
    () => [
      { Header: "Email", accessor: "email" },
      { Header: "First Name", accessor: "first_name" },
      { Header: "Last Name", accessor: "last_name" },
      { Header: "Registration Date", accessor: "registration_date" },
      // { Header: "Category", accessor: "category" },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ row }) =>
          row.original.category === "super_admin" ? (
            <span>Super Admin</span> // Display text if super_admin
          ) : (
            <Select
              value={row.original.category}
              onChange={(e) => updateCategory(row.original.username, e.target.value)}
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="super_admin">Super Admin</MenuItem>
            </Select>
          ),
      },
    ],
    []
  );

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
        setEmail(user.attributes.email);
        setFirstName(user.attributes.given_name);
        setLastName(user.attributes.family_name);
        setPhone(user.attributes.phone_number);
        const quCreateUsers = await fetchAllUsersAPI();

        quCreateUsers.forEach((quCreateUser) => {
          if (quCreateUser.username === user.username && quCreateUser.category === "super_admin") {
            setShowTable(true);
            setQuUsers(quCreateUsers);
            return;
          }
        });
      } catch (err) {
        console.error("Error fetching user data: ", err);
        history.push("/auth/login-page");
      }
    };

    fetchUserData();
  }, [history, setUser, setEmail, setFirstName, setLastName, setPhone]);

  const updateProfile = async () => {
    let result = await Auth.updateUserAttributes(user, {
      'given_name': firstName,
      'family_name': lastName,
      'phone_number': phone,
      // 'preferred_username': preferredUsername
    });
    if (result === "SUCCESS") {
      setstatus("quSandBoxColor")
      setmessage("SUCCESS: Your profile is updated.")
      showNotification('br')
    } else {
      setstatus("danger")
      setmessage("FAILED: Retry or Contact info@qusandbox.com for help.")
      showNotification('br')
    }
  }

  const showNotification = place => {
    setBR(true);
    setTimeout(function () {
      setBR(false);
    }, 6000);
  }

  const updateCategory = async (username, newCategory) => {
    try {
      setLoading({ isLoading: true, message: "Updating user category..." });
      const formData = new FormData();
      formData.append("username", username);
      formData.append("category", newCategory);
      const res = await updateUserCategoryAPI(formData);
      setQuUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.username === username ? { ...user, category: newCategory } : user
        )
      );
    } catch (err) {
      console.error("Error updating user category: ", err);
    }
    finally {
      setLoading({ isLoading: false, message: "" });
    }
  };

  if (loading.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loading.message}
        </Typography>
      </Box>
    );
  }


  return (
    <div>
      <Snackbar
        place="br"
        color={status}
        message={message}
        icon={AddAlert}
        open={br}
        closeNotification={() => setBR(false)}
        close
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader icon>
              <CardIcon color="quSandBox">
                <PermIdentity />
              </CardIcon>

            </CardHeader>
            <CardBody>
              <GridContainer>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Login Username/Email"
                    disabled
                    value={email}
                    id="username"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>

                {/* <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Searchable Username"
                    value={preferredUsername}
                    onChange={(e) => setpreferredUsername(e.target.value)}
                    id="username"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem> */}

              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    id="first-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    id="phone-number"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>

              </GridContainer>

              <Button color="info" className={classes.updateProfileButton} onClick={updateProfile}>
                Update Profile
              </Button>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              {/* <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6> */}
              <h4 className={classes.cardTitle}>{firstName + ' ' + lastName}</h4>
              <p className={classes.description}>
                {email}
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {showTable && (

        <Card>

          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CardBody profile>
                  <ReactTable columns={columns} data={quUsers} />
                </CardBody>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>


      )}

      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                API Usage Logs
              </h4>
            </CardHeader>
            <CardBody>
              <LogChart></LogChart>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer> */}
      {/* have a footer at the bottom  */}
      <div style={{ position: "flex", fontStyle: "italic", float: "bottom" }}>
        By logging in to your account, you acknowledge and agree to adhere to the{" "}
        <a href="https://www.quantuniversity.com/privacy.html" target="_blank">
          Privacy Policy
        </a>{" "}
        and
        <a href="/terms" target="_blank">{" "}
          Terms and Conditions
        </a>{" "}
        of QuantUniversity LLC. Please review these documents to understand your rights and obligations while using QuCreate, QuSkillbridge, and QuStudio.

      </div>

    </div>
  );
}
