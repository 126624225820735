import React, { useState } from "react";
import {
    Box,
    Typography,
    TextField,
    Grid,
    Checkbox,
    Menu,
    MenuItem,
    Chip
} from "@material-ui/core";
import { Label } from "@material-ui/icons";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import { makeStyles } from "@material-ui/core/styles";
import NotificationContext from "views/Components/Context.js";
import MDEditor from "@uiw/react-md-editor";
import { DropzoneArea } from "material-ui-dropzone";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "60%",
        margin: "auto",
        marginTop: theme.spacing(5),
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
    },
    formField: {
        marginBottom: theme.spacing(2),
    },
    labelSelector: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        cursor: "pointer",
    },
    labelMenu: {
        width: 200,
    },
    dropzone: {
        minHeight: "100px",
        backgroundColor: "#f0f0f0",
        borderColor: quCreateColor,
        border: "2px dashed",
        borderRadius: "5px",
        padding: "20px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const CreateIssueForm = ({ issueObject, setIssueObject, createNewIssue }) => {
    const classes = useStyles();
    const { showNotification } = React.useContext(NotificationContext);
    const [labelsMenuAnchor, setLabelsMenuAnchor] = useState(null);

    const labels = [
        { id: "bug", name: "Bug", color: "red" },
        { id: "documentation", name: "Documentation", color: "blue" },
        { id: "enhancement", name: "Enhancement", color: "green" },
        { id: "question", name: "Question", color: "purple" },
        { id: "help_wanted", name: "Help wanted", color: "black" },
    ];

    const handleOpenLabelsMenu = (event) => {
        setLabelsMenuAnchor(event.currentTarget);
    };

    const handleCloseLabelsMenu = () => {
        setLabelsMenuAnchor(null);
    };

    // Function to handle file upload
    const handleFileUpload = (files) => {
        setIssueObject({ ...issueObject, uploadedFiles: files });
    };


    return (
        <div>


            <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={12}>
                    <Typography variant="h5" >
                        Title:
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Enter a title"
                        placeholder="For e.g., Bug: Login button does not respond after entering valid credentials"
                        type="text"
                        fullWidth
                        required
                        value={issueObject.title}
                        onChange={(event) => setIssueObject({ ...issueObject, title: event.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.labelSelector} onClick={handleOpenLabelsMenu}>
                        <Label />
                        <Typography style={{ marginLeft: "5px" }}>Add labels to this issue</Typography>
                        <Box ml={2} display="flex" flexWrap="wrap">
                            {issueObject.labels.map((label) => (
                                <Chip
                                    key={label}
                                    label={label}
                                    size="small"
                                    style={{ margin: "4px", backgroundColor: "#f0f0f0" }}
                                    onDelete={() => {
                                        const updatedLabels = issueObject.labels.filter((l) => l !== label);
                                        setIssueObject({ ...issueObject, labels: updatedLabels });
                                    }} // Allow removal of labels via chips
                                />
                            ))}
                        </Box>
                    </Box>
                    <Menu
                        anchorEl={labelsMenuAnchor}
                        keepMounted
                        open={Boolean(labelsMenuAnchor)}
                        onClose={handleCloseLabelsMenu}
                        className={classes.labelMenu}
                        PopoverClasses={{
                            width: "200px",
                        }}
                    >
                        {labels.map((label) => (
                            <MenuItem
                                key={label.id}
                                onClick={() => {
                                    const labelExists = issueObject.labels.includes(label.name);
                                    // Ensure we are adding/removing valid labels
                                    if (label.name.trim()) {  // Skip empty labels
                                        const updatedLabels = labelExists
                                            ? issueObject.labels.filter((l) => l !== label.name) // Remove label
                                            : [...issueObject.labels, label.name]; // Add label

                                        setIssueObject({ ...issueObject, labels: updatedLabels.filter(l => l.trim()) }); // Remove any empty labels
                                    }
                                }}
                            >
                                <span style={{ width: "200px" }}>
                                    <Checkbox style={{ marginLeft: 0, paddingLeft: 0 }} color="quCreateColor" checked={issueObject.labels.includes(label.name)} />
                                    {label.name}
                                </span>
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ marginTop: "10px" }}>
                        Description:
                    </Typography>
                </Grid>

                {/* MDEditor for Template Editing */}
                <Grid item xs={12} style={{ flexGrow: 1 }}>
                    <div data-color-mode="light">
                        <MDEditor
                            value={issueObject.description}
                            onChange={(value) => setIssueObject({ ...issueObject, description: value })}
                            height={250}
                        />
                    </div>
                </Grid>

                {/* Title for File Upload */}
                <Grid item xs={12} style={{ flexGrow: 1 }}>
                    <Typography
                        variant="h5"
                        style={{ marginTop: "10px" }}
                    >
                        Upload supporting image/screenshots:
                    </Typography>
                </Grid>

                {/* DropzoneArea for File Upload */}
                <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" style={{ marginBottom: "10px" }}>
                        Acceptable formats: PNG, JPG, JPEG (Max size: 5 MB)
                    </Typography>
                    <DropzoneArea
                        initialFiles={issueObject.uploadedFiles}
                        onChange={handleFileUpload}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        useChipsForPreview
                        maxFileSize={5000000} // Restrict max file size (e.g., 5MB)
                        acceptedFiles={["image/jpeg", "image/png", "image/gif", "image/jpg"]} // Restrict to image types
                        previewText="Uploaded images"
                        dropzoneText="Drag and drop or click here to upload images."
                        filesLimit={100}
                        dropzoneClass={classes.dropzone}
                    />
                </Grid>

                <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="quCreateColor" onClick={createNewIssue}>
                        Submit Feedback
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default CreateIssueForm;
