import React, { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Divider,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import { validateKeyAPI } from "views/APIs/APIUtility";
import { COMPANY_MODELS, getCompanyIcon } from "views/Components/Constants";
import Button from "components/CustomButtons/Button.js";

export default function EditVaultDialogue({
    open,
    handleEditDialogueClose,
    modelDetails,
    setModelDetails,
    handleUpdateAsset,
}) {
    const [error, setError] = useState("");
    const companies = Object.keys(COMPANY_MODELS);
    const modelOptions = modelDetails.company ? COMPANY_MODELS[modelDetails.company] : [];
    const handleEditAsset = async () => {
        try {
            const response = await validateKey();
            if (response) {
                await handleUpdateAsset(modelDetails);
            }
        } catch (error) {
            setError(
                "New API Key verification successful. QU Servers are unable to complete your request at the moment. Please try again later."
            );
        }
    };

    const handleCloseDialog = () => {
        setError("");
        handleEditDialogueClose();
    };

    const validateKey = async () => {
        console.log("In validate ", modelDetails);
        if (!modelDetails.name || modelDetails.name.trim() === "") {
            setError("API Key Name is required.");
            return false;
        }
        if (!modelDetails.new_api_key || modelDetails.new_api_key.trim() === "") {
            setError("New API Key is required.");
            return false;
        }

        const formData = new FormData();
        formData.append("model", modelDetails.model);
        formData.append("key", modelDetails.new_api_key);

        const response = await validateKeyAPI(formData);
        console.log("Response", response);
        if (response) {
            return true;
        } else {
            setError(
                "The provided API key is invalid or does not support the selected model. Please verify and try again."
            );
            return false;
        }
    };

    return (
        <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
            <DialogTitle>Edit Model</DialogTitle>
            <Divider />
            <DialogContent>
                <FormControl fullWidth margin="normal" disabled>
                    <InputLabel>Select Company</InputLabel>
                    <Select value={modelDetails.company} disabled>
                        <MenuItem value={modelDetails.company}>
                            {getCompanyIcon(modelDetails.company)}
                            {modelDetails.company}
                        </MenuItem>
                    </Select>
                </FormControl>


                {modelDetails.company && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Model</InputLabel>
                        <Select
                            value={modelDetails.model}
                            onChange={(e) => setModelDetails({
                                ...modelDetails,
                                model: e.target.value
                            })}
                        >
                            <MenuItem value="">-- Select Model --</MenuItem>
                            {modelOptions.map((m) => (
                                <MenuItem key={m} value={m}>

                                    {m}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <TextField
                    fullWidth
                    disabled
                    margin="normal"
                    label="Old API Key"
                    type="text"
                    value={"****"+modelDetails.old_api_key.slice(-4)}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="New API Key"
                    type="text"
                    onChange={(e) =>
                        setModelDetails({ ...modelDetails, new_api_key: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="API Key Name"
                    type="text"
                    value={modelDetails.name}
                    onChange={(e) =>
                        setModelDetails({ ...modelDetails, name: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="API Key Description"
                    type="text"
                    value={modelDetails.description}
                    onChange={(e) =>
                        setModelDetails({ ...modelDetails, description: e.target.value })
                    }
                />
                {error && (
                    <Typography color="error" variant="body2">
                        {error}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="quSandBoxColor">
                    Cancel
                </Button>
                <Button onClick={handleEditAsset} color="quCreateColor">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
