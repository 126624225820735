import BaseDetails from "./BaseDetails";

class LabDetails extends BaseDetails {
    constructor() {
        super(
            "",
            "",
            `# Instructions
- Number of modules: 5
- Course requirements: Create an engaging and comprehensive lab outline that contains 5 modules.
- Module structure:
  - Description: Provide a brief summary of each module that encapsulates the main topics and key takeaways.
  - Learning objectives: Include a list of specific, actionable objectives for each module. Clearly outline what learners will gain or accomplish by completing the module.
- Objective focus: Ensure that learning objectives are concise, measurable, and relevant to the overall lab content.`
        );
        this.labOutline = "";
    }
}

export default LabDetails;
