import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

export default function CreateModuleModal({
    moduleModal,
    handleClose,
    submitModuleLoading,
    moduleDetails,
    setModuleDetails,
    addModule,
}) {
    return (
        <Dialog open={moduleModal} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle id="form-dialog-title" style={{ paddingBottom: "0px" }}>
                <div style={{ fontSize: "30px" }}>Add a New Module</div>
            </DialogTitle>

            {submitModuleLoading ? (
                <DialogContent style={{ display: "flex" }}>
                    <CircularProgress size={20} /> &nbsp;
                    <Typography variant="body1">Adding module...</Typography>
                </DialogContent>
            ) : (
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        onChange={(event) => setModuleDetails({ ...moduleDetails, name: event.target.value })}
                        value={moduleDetails.name}
                        fullWidth
                        required
                    />
                    <TextField
                        margin="dense"
                        id="brief"
                        label="Brief Description"
                        type="text"
                        value={moduleDetails.description}
                        onChange={(event) => setModuleDetails({ ...moduleDetails, description: event.target.value })}
                        fullWidth
                        multiline
                        required
                        rows={3}
                    />
                </DialogContent>
            )}

            <DialogActions>
                <Button onClick={handleClose} color="secondary" disabled={submitModuleLoading}>
                    Cancel
                </Button>
                <Button onClick={addModule} color="quCreateColor" disabled={submitModuleLoading}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
