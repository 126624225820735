import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

// @material-ui/core components
import { CircularProgress, Typography, Box } from "@material-ui/core";
import { CloudUpload, Create, DoneAll, Error } from "@material-ui/icons";

// core components
import CreateCourseCard from "views/Components/CreateCourseCard";
import CreateCourseModal from "views/Components/CreateCourseModal";

// context and APIs
import NotificationContext from "views/Components/Context.js";
import { createCourseAPI, fetchAllCoursesAPI, deleteCourseAPI, updateCourseTagsAPI, cloneEntryAPI } from "views/APIs/APIUtility";
import AssetDashboard from "views/Components/AssetDashboard";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";

import { fetchImageAsFile } from "./CourseConstants";

const useStyles = makeStyles({
  ...styles,
})

export default function CourseDesign() {
  // Import images from assets
  const images = require.context("assets/img/create_cards_16_9/", true);
  const path = useRouteMatch().path;
  const classes = useStyles();

  // Context and state variables
  const { showNotification } = React.useContext(NotificationContext);

  // State variables for course creation and management
  const [createCourseModal, setCreateCourseModal] = React.useState(false);
  const [newCourseDetails, setNewCourseDetails] = React.useState({
    name: "",
    description: "",
    template: "# Instructions\n - Course Name: {{name}} \n - Course Description: {{description}} \n- Number of modules: 5\n- Course requirements: Create an engaging and comprehensive course outline that contains the above specified number of modules.\n- Module structure:\n  - Description: Provide a brief summary of each module that encapsulates the main topics and key takeaways.\n  - Learning objectives: Include a list of specific, actionable objectives for each module. Clearly outline what learners will gain or accomplish by completing the module.\n- Objective focus: Ensure that learning objectives are concise, measurable, and relevant to the overall course content.",
    courseOutline: "",
    uploadedFiles: [],
  });
  const [iconImage, setIconImage] = React.useState();
  const [allCourses, setAllCourses] = React.useState([]);
  const [initialCourses, setInitialCourses] = React.useState([]);
  const [loading, setLoading] = React.useState({ loading: false, message: "" });
  const [courseCreationLoader, setCourseCreationLoader] = React.useState(false);
  const [courseAdded, setCourseAdded] = React.useState(false);

  // Handle icon upload
  const handleIconUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIconImage(file);
    }
  };

  // Handle course deletion
  const handleDeleteCourse = async (courseData) => {
    const formData = new FormData();
    formData.append("course_id", courseData._id);
    try {
      await deleteCourseAPI(formData);
      setAllCourses(allCourses.filter((course) => course._id !== courseData._id));
    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally { }
  };

  // Handle course cloning
  const handleCloneCourse = async (courseData) => {
    try {
      setLoading({ loading: true, message: "Cloning Project..." });
      const formData = new FormData();
      formData.append("collection", "course_design");
      formData.append("id", courseData._id);

      const data = await cloneEntryAPI(formData);
      setAllCourses((prevCourses) => [...prevCourses, _mapResourceToAppType(data)]);
      setCourseAdded(true);
      showNotification("Course cloned successfully", "success");
    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally {
      setLoading({ loading: false, message: "" });
    }

  };

  useEffect(() => {
    const fetchAllCourses = async () => {
      try {
        setLoading({ loading: true, message: "Loading Projects..." });
        const data = await fetchAllCoursesAPI();

        const mappedData = data.map(_mapResourceToAppType);

        setAllCourses(mappedData);
        setInitialCourses(mappedData);
      } catch (error) {
        showNotification(error, "danger");
      } finally {
        setLoading({ loading: false, message: "" });
      }
    };

    fetchAllCourses();
  }, []);

  // Handle course creation
  const handleCreateCourse = async (modulesAtCreation = true) => {
    setCourseCreationLoader(true);

    try {
      // Set course image, using a default if none is provided
      const imageToUse =
        iconImage || (await fetchImageAsFile(images("./course.png")));

      // Prepare form data
      const formData = new FormData();
      formData.append("course_name", newCourseDetails.name);
      formData.append("course_description", newCourseDetails.description);
      formData.append("course_image", imageToUse);

      // Handle course outline and modules creation
      if (!modulesAtCreation) {
        formData.append("course_outline", "");
        formData.append("modulesAtCreation", modulesAtCreation);
      } else {
        formData.append("course_outline", newCourseDetails.courseOutline);
      }

      // Attach uploaded files
      newCourseDetails.uploadedFiles.forEach((file) => {
        formData.append("files", file);
      });

      // Create course via API call
      const data = await createCourseAPI(formData);
      setAllCourses((prevCourses) => [...prevCourses, _mapResourceToAppType(data)]);
      setCourseAdded(true);
      showNotification("Course created successfully", "success");
    } catch (error) {
      showNotification(
        `Failed to create course: ${error.message || error}`,
        "danger"
      );
    } finally {
      setCourseCreationLoader(false);
      handleCreateCourseModalClose();
    }
  };

  const handleSaveLabel = async (data) => {
    try {
      const formData = new FormData();
      formData.append("course_id", data._id);
      if (data.tags.length === 0) {
        formData.append("tags", []);
      }
      // Append each tag separately
      else {
        data.tags.forEach(tag => formData.append("tags", tag));
      }
      await updateCourseTagsAPI(formData);
      setAllCourses((prevCourse) =>
        prevCourse.map((course) =>
          course._id === data._id ? { ...course, tags: data.tags } : course
        )
      );
    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally {
    }
  };

  // Close course creation modal and reset state
  const handleCreateCourseModalClose = () => {
    setCreateCourseModal(false);
    setIconImage(null);
    setNewCourseDetails({
      name: "",
      description: "",
      template: "# Instructions\n - Course Name: {{name}} \n - Course Description: {{description}} \n- Number of modules: 5\n- Course requirements: Create an engaging and comprehensive course outline that contains the above specified number of modules.\n- Module structure:\n  - Description: Provide a brief summary of each module that encapsulates the main topics and key takeaways.\n  - Learning objectives: Include a list of specific, actionable objectives for each module. Clearly outline what learners will gain or accomplish by completing the module.\n- Objective focus: Ensure that learning objectives are concise, measurable, and relevant to the overall course content.",
      courseOutline: "",
      uploadedFiles: [],
    });
  };

  // Get status icon based on course status
  const getStatusIcon = (status) => {
    switch (status) {
      case "In Design Phase":
        return <Create style={{ color: "#435966" }} />;
      case "Ready for Publishing":
        return <CloudUpload style={{ color: "#435966" }} />;
      case "Published":
        return <DoneAll style={{ color: "#435966" }} />;
      case "Publishing Failed":
        return <Error style={{ color: "#435966" }} />;
      default:
        return null;
    }
  };

  const _mapResourceToAppType = (resource) => {
    // Destructure the resource to exclude course_name and course_description
    const { course_name, course_description, course_image, ...rest } = resource;

    return {
      ...rest,               // Spread all remaining properties
      name: course_name,     // Add name property
      description: course_description, // Add description property
      image: course_image,   // Add image property
    };
  };

  // Show loading spinner while fetching courses
  if (loading.loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" className={classes.loaderClass} />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loading.message}
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <CreateCourseModal
        newCourseDetails={newCourseDetails}
        setNewCourseDetails={setNewCourseDetails}
        iconImage={iconImage}
        createCourseModal={createCourseModal}
        handleCreateCourseModalClose={handleCreateCourseModalClose}
        handleCreateCourse={handleCreateCourse}
        handleIconUpload={handleIconUpload}
        courseCreationLoader={courseCreationLoader}
      />

      <AssetDashboard
        path={path}
        welcomeTitle="Welcome to the Project Design Dashboard!"
        welcomeSubtitle="Start by creating a new project or reviewing your existing projects."
        allAssets={allCourses}
        setAllAssets={setAllCourses}
        initialAssets={initialCourses}
        noAssetMessage="Create or Review your projects to get started."
        linkPath="/courses/"
        getStatusIcon={getStatusIcon}
        CreateAssetCard={CreateCourseCard}
        setCreateAssetModal={setCreateCourseModal}
        createAssetButtonText="Create Project"
        handleSaveLabel={handleSaveLabel}
        asset_identifier="project"
        handleDeleteAsset={handleDeleteCourse}
        handleCloneAsset={handleCloneCourse}
        assetAdded={courseAdded}
        setAssetAdded={setCourseAdded}
      />
    </div>
  );
}
