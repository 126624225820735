import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "react-router-dom"; // Import Link for routing

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";

// material-ui icons
import MenuIcon from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

// core components
import Button from "components/CustomButtons/Button.js";


import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import { Breadcrumbs, Grid, Typography, Divider } from "@material-ui/core";
import PostAddIcon from '@material-ui/icons/PostAdd';
import SchoolIcon from '@material-ui/icons/School';
import CodeIcon from '@material-ui/icons/Code';
import { fetchNotifications, markNotificationAsReadAPI } from "views/APIs/APIUtility";
import { formatDistanceToNow } from 'date-fns'; // Using date-fns to format the date

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  const classes = useStyles();
  const { color, rtlActive, brandText, user } = props;
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });

  const breadCrumbs = props.breadCrumbs || [];
  // Sample data for unread and read notifications

  // Utility function to handle icon, label, and link based on notification type
  const getNotificationDetails = (type, projectID, module_id) => {
    switch (type) {
      case "course_module":
        return {
          icon: PostAddIcon,
          label: "Module",
          link: `/admin/project-design/courses/${projectID}`, // Include module_id for course_module
        };
      case "course":
        return {
          icon: SchoolIcon,
          label: "Course",
          link: `/admin/project-design/courses/${projectID}`, // Only projectID for course
        };
      case "lab":
        return {
          icon: CodeIcon,
          label: "Lab",
          link: `/admin/labdesign/lab/${projectID}`, // Only projectID for lab
        };
      default:
        return {
          icon: SchoolIcon, // Default icon
          label: "Notification",
          link: "#",
        };
    }
  };

  const convertUtcToLocalWithOffset = (utcTimeString, offsetHours = 0) => {
    const date = new Date(utcTimeString); // Convert UTC string to Date object

    // Add the offset hours
    date.setHours(date.getHours() + offsetHours);

    // Format to the user's local timezone
    return date;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);

  React.useEffect(() => {
    // Fetch notifications
    const fetchUserNotifications = async () => {
      try {
        const response = await fetchNotifications(user.username);
        setNotifications(response);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchUserNotifications();
  }, [user]);

  // create a websocket for notifications and set new notifications on message
  React.useEffect(() => {
    if (!user)
      return;

    const wsUrl = `${process.env.REACT_APP_FASTAPI_URL.replace("http", "ws")}/ws/notifications/${user.username}`;
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => { }
    ws.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);

      // For example, if event.data is "Task abc completed!"
      setNotifications((prev) => [newNotification, ...prev]);
    };
    ws.onclose = () => { }

    return () => ws.close();

  }, [user]);

  // Handle notification click (open the dropdown without marking as read yet)
  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close popover & mark all notifications as read
  const handleNotificationClose = async () => {
    try {
      if (hasUnreadNotifications) {
        const formData = new FormData();
        const notificationIds = notifications.map(notification => notification._id);
        formData.append("notification_list", notificationIds);
        await markNotificationAsReadAPI(formData);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
    finally {
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => ({ ...notification, read: true }))
      );
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    setHasUnreadNotifications(notifications.some((notification) => notification.read === false));
  }, [notifications]);

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>

        <div className={classes.flex}>
          {/* Breadcrumbs */}
          <Breadcrumbs
            aria-label="breadcrumb"
            separator="/"
            style={{
              marginLeft: "10px",
              fontWeight: "bold",
            }}
          >
            {breadCrumbs.map((crumb, idx) => {
              const isLast = idx === breadCrumbs.length - 1;
              return isLast ? (
                <Typography key={crumb.path}>{crumb.name}</Typography>
              ) : (
                <Link key={crumb.path} to={"/admin" + crumb.path} className={classes.linkText}>
                  {crumb.name}
                </Link>
              );
            })}
          </Breadcrumbs>
        </div>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <MenuIcon />
          </Button>
        </Hidden>

        <IconButton color="inherit" onClick={handleNotificationClick}>
          {hasUnreadNotifications ? (
            <div style={{ position: "relative" }}>
              <NotificationsIcon />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  backgroundColor: "red",
                }}
              />
            </div>
          ) : (
            <NotificationsNoneIcon />
          )}
        </IconButton>

        {/* Notification Popover */}
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleNotificationClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          classes={{ paper: classes.popoverPaper }}
          disableScrollLock
        >
          {notifications.length > 0 ? (
            notifications.map((notification, index) => {
              const { type, message, read, project_id, module_id = null, creation_date } = notification;

              // Get icon, label, and link from the utility function
              const { icon: IconComponent, label, link } = getNotificationDetails(type, project_id, module_id);

              // Format the creation date using date-fns
              const formattedDate = formatDistanceToNow(convertUtcToLocalWithOffset(creation_date)) + " ago";

              return (
                <>
                  <Link to={link} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <MenuItem
                      key={index}
                      onClick={handleNotificationClose}
                      style={{
                        backgroundColor: read ? "#ffefef" : "#e0f7fa", // Light red for read, light blue for unread
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography variant="caption">
                            <IconComponent style={{ fontSize: 12 }} />
                            &nbsp;{label}
                          </Typography>
                          <Typography variant="caption">
                            {formattedDate}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">{message}</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Link>

                  {/* Divider after each notification except the last one */}
                  {index < notifications.length - 1 && <Divider />}
                </>
              );
            })
          ) : (
            <MenuItem>No new notifications</MenuItem>
          )}
        </Popover>

      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
