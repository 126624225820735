import React, { useState, useEffect } from 'react';
import { Divider, Grid, Box, CircularProgress, Typography } from '@material-ui/core';
import StorageIcon from '@material-ui/icons/Storage';
import BarChartIcon from '@material-ui/icons/BarChart';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import BuildIcon from '@material-ui/icons/Build';

// Custom Components
import DataRequirements from "./DataRequirements";
import GeneralRequirements from "./GeneralRequirements";
import VisualizationRequirements from "./VisualizationRequirements";
import LabDesignTiles from 'components/Tiles/LabDesignTiles';
import AdditionalDetails from './AdditionalDetails';

import { fetchLabAPI } from "views/APIs/APIUtility";

// Contexts
import NotificationContext from "views/Components/Context.js";

import { _mapResourceToAppType } from "../CommonFunctions";

export default function LabDesignStep({ labID, setStage, setActiveStep }) {
    const { showNotification } = React.useContext(NotificationContext);

    const [activeTile, setActiveTile] = useState("General Requirements");
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState({ isLoading: false, message: "" });
    const [lab, setLab] = useState(null);
    const [instructions, setInstructions] = useState({});
    const [newResource, setNewResource] = useState({ name: "", description: "", file: null });
    const [replace, setReplace] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        async function fetchLabDetails() {
            try {
                setLoading({ isLoading: true, message: "Fetching Data Requirements..." });
                const data = await fetchLabAPI(labID);
                console.log("Lab data is:", data);
                setLab(data);
                setInstructions(data.instructions);
                handleSetFileResources(data.raw_resources);
                setLoading({ isLoading: false, message: "" });
            } catch (error) {
                console.error("Error in fetchLabDetails:", error);
                showNotification("Error fetching lab details", "danger");
            }
        }
        fetchLabDetails();
    }, [labID]);

    const handleSetFileResources = (resources) => {
        console.log("Resources are:", resources);
        const mappedResources = resources
            .filter(resource => resource.resource_type === "Dataset" || resource.resource_type === "Image")
            .map(_mapResourceToAppType);
        setFiles(mappedResources);
    };

    const descriptions = {
        resources: "Add the learning outcomes for the lab.",
        datasets: "Add the data creation strategy for the lab. You can also add your own datasets.",
        visualization: "Add any visualization requirements for the lab. You can also add images/screenshots.",
        additional: "Add any additional details for the lab."
    };

    const handleNavigation = (tile) => {
        console.log("Setting active tile to:", tile);
        setActiveTile(tile);
        setActiveTab(["General Requirements", "Data Requirements", "Visualization Requirements", "Additional Details"].indexOf(tile));
    };

    const renderContent = () => {
        const contentMap = {
            "General Requirements": (
                <GeneralRequirements
                    setActiveStep={setActiveStep}
                    description={descriptions.resources}
                    handleNavigation={handleNavigation}
                    labID={labID}
                    setStage={setStage}
                    instructions={instructions}
                    setInstructions={setInstructions}
                />
            ),
            "Data Requirements": (
                <DataRequirements
                    description={descriptions.datasets}
                    handleNavigation={handleNavigation}
                    handleSetFileResources={handleSetFileResources}
                    labID={labID}
                    setStage={setStage}
                    newResource={newResource}
                    setNewResource={setNewResource}
                    replace={replace}
                    setReplace={setReplace}
                    files={files.filter(file => file.type === "Dataset")}
                    setFiles={setFiles}
                    instructions={instructions}
                    setInstructions={setInstructions}
                />
            ),
            "Visualization Requirements": (
                <VisualizationRequirements
                    description={descriptions.visualization}
                    handleNavigation={handleNavigation}
                    handleSetFileResources={handleSetFileResources}
                    labID={labID}
                    setStage={setStage}
                    newResource={newResource}
                    setNewResource={setNewResource}
                    replace={replace}
                    setReplace={setReplace}
                    files={files.filter(file => file.type === "Image")}
                    setFiles={setFiles}
                    instructions={instructions}
                    setInstructions={setInstructions}
                />
            ),
            "Additional Details": (
                <AdditionalDetails
                    description={descriptions.additional}
                    handleNavigation={handleNavigation}
                    lab={lab}
                    labID={labID}
                    setStage={setStage}
                    files={files}
                    instructions={instructions}
                    setInstructions={setInstructions}
                />
            )
        };
        return contentMap[activeTile] || null;
    };

    if (loading.isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <CircularProgress color="quCreateColor" />
                <Typography variant="h6" style={{ marginLeft: 16 }}>{loading.message}</Typography>
            </Box>
        );
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <LabDesignTiles
                        color="warning"
                        alignCenter
                        active={activeTab}
                        tabs={[
                            { heading: "General Requirements", icon: BuildIcon, description: descriptions.resources, onClick: () => handleNavigation("General Requirements") },
                            { heading: "Data Requirements", icon: StorageIcon, description: descriptions.datasets, onClick: () => handleNavigation("Data Requirements") },
                            { heading: "Visualization Requirements", icon: BarChartIcon, description: descriptions.visualization, onClick: () => handleNavigation("Visualization Requirements") },
                            { heading: "Additional Details", icon: MenuBookIcon, description: descriptions.additional, onClick: () => handleNavigation("Additional Details") }
                        ]}
                    />
                </Grid>
            </Grid>
            <Divider style={{ margin: "16px 0" }} />
            {renderContent()}
        </>
    );
}