// React and Amplify
import React, { useEffect } from "react";
import { Auth } from "aws-amplify";

// Material-UI components and styles
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

// SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

// Context and utilities
import NotificationContext from "views/Components/Context.js";
import { addToWaitlistAPI } from "views/APIs/APIUtility.js";

// Constants
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
const useStyles = makeStyles(styles);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

const CreationOptionCard = ({
  logo,
  title,
  description,
  identifier,
  inProgress = false,
}) => {
  const { showNotification } = React.useContext(NotificationContext);
  const [alert, setAlert] = React.useState(null);
  const [user, setUser] = React.useState("");
  const classes = useStyles();
  const sweetAlertClasses = usesweetAlertStyle();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (user) {
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Determine the icon based on the title
  let IconComponent;
  let buttonText = "Explore";

  // if (title === "Course") {
  //     IconComponent = <BookIcon />; // BookIcon for "Research Report"
  //     buttonText = "Go to Course Designer";
  // } else if (title === "Research Report") {
  //     IconComponent = <Icon>content_copy</Icon>;
  // } else if (title === "Create a Video") {
  //     IconComponent = <VideocamIcon />; // VideocamIcon for "Create a Video"
  // } else {
  //     IconComponent = (
  //         <img
  //             src={logo}
  //             alt={`${title} logo`}
  //             style={{ height: '75px', width: '75px', objectFit: 'contain' }} // Default image
  //         />
  //     );
  // }
  IconComponent = (
    <img
      src={logo}
      alt={`${title} logo`}
      style={{ height: "75px", width: "75px", objectFit: "contain" }} // Default image
    />
  );

  const waitlistAlert = (title, description, logo) => {
    setAlert(
      <SweetAlert
        custom
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        customIcon={logo} // Add your custom icon here
        onCancel={() => hideAlert()} // Set alert to null on outside click
        closeOnClickOutside={true}
        onConfirm={() => handleWaitlist(title)}
        // onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Add Me to the Waitlist!"
        confirmBtnStyle={{ backgroundColor: quCreateColor }}
      // cancelBtnText="Cancel"
      // showCancel
      >
        This feature is currently in progress and will be available soon.
      </SweetAlert>
    );
  };

  const handleWaitlist = async (title) => {
    const date = new Date().toISOString().split("T")[0];
    const formData = new FormData();
    formData.append("user_id", user.username);
    formData.append("user_email", user.attributes.email);
    formData.append("project_id", identifier);
    formData.append("project_name", title);
    formData.append("date", date);
    try {
      const data = await addToWaitlistAPI(formData);

      // If the backend returns success
      showNotification(
        "You have been successfully added to the waitlist. We will let you know soon!",
        "success"
      );
    } catch (error) {
      // If the error message indicates user is already on the waitlist (409 Conflict)
      if (error.message.includes("409")) {
        showNotification(
          "You are already on the waitlist for this project. We will let you know soon!",
          quCreateColor
        );
      } else {
        // Handle other errors
        console.error("Error adding to waitlist:", error);
        showNotification(
          error.message || "Something went wrong. Please try again later.",
          "danger"
        );
      }
    } finally {
      hideAlert();
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <Card style={{ maxWidth: 500, height: "auto" }}>
      <CardHeader color="quCreateColor" stats icon>
        <CardIcon color="quCreateColor">
          {IconComponent} {/* Render the appropriate icon */}
        </CardIcon>

        {/* Tooltip for Title text */}
        <Tooltip title={title} placement="top" arrow>
          <h3
            className={classes.cardTitle}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingRight: 15,
              paddingTop: 15,
            }}
          >
            {title}
          </h3>
        </Tooltip>
        <p className={classes.cardCategory} style={{ paddingRight: 15, height: "50px", overflow: "hidden" }}>
          {description}
        </p>
      </CardHeader>

      <CardFooter stats>
        <div
          className={classes.stats}
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {/* Conditional rendering of Button width based on title */}
          {/* {(title === "White Paper" || title === "Project Plan" || title === "Create a Video") ? (
                        <Button variant="contained" color="quCreateColor" onClick={() => waitlistAlert(title, description, logo)}>
                            {buttonText}
                        </Button>
                    ) : (
                        <Button variant="contained" color="quCreateColor" style={{ width: '100%' }} onClick={() => waitlistAlert(title, description, logo)}>
                            {buttonText}
                        </Button>
                    )} */}
          <Button
            variant="contained"
            color="quCreateColor"
            onClick={() =>
              inProgress ? waitlistAlert(title, description, logo) : null
            }
          >
            {buttonText}
          </Button>
        </div>
      </CardFooter>
      {alert}
    </Card >
  );
};

export default CreationOptionCard;
