import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, TextField, Radio, RadioGroup, FormControlLabel, Box, CircularProgress, Divider } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from '@material-ui/icons/Save';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Button from "components/CustomButtons/Button";

import WritingTable from "../Writing/WritingTable";

import { fetchLabAPI, deleteLabResourceAPI, addResourceToLabAPI, saveLabInstructionsAPI } from "views/APIs/APIUtility";
import { _mapResourceToAppType } from "../CommonFunctions"
// Contexts
import NotificationContext from "views/Components/Context.js";
import FileUploadDialog from "../FileUploadDialog";
import MDEditor from '@uiw/react-md-editor';

const VisualizationRequirements = ({ description, handleNavigation, handleSetFileResources, labID, setStage,
    newResource, setNewResource, replace, setReplace, files, setFiles, instructions, setInstructions }) => {
    const { showNotification } = React.useContext(NotificationContext);
    const [datasetType, setDatasetType] = useState('Synthetic');
    const [lab, setLab] = useState(null);
    const [details, setDetails] = useState('');
    const types = ["Synthetic", "Real"];
    const [loading, setLoading] = useState({ isLoading: false, message: "" });

    const [newFiles, setNewFiles] = useState([]);
    const [selectedResources, setSelectedResources] = useState([]);
    const [resourceAddModal, setResourceAddModal] = useState(false);


    const handleDatasetTypeChange = (event) => {
        setDatasetType(event.target.value);
    };

    const handleDetailsChange = (event) => {
        setDetails(event.target.value);
    };

    const handleNext = () => {
        handleSave();
        setStage(3);
        handleNavigation("Additional Details");
    };

    const handleBack = () => {
        setStage(1);
        handleNavigation("Data Requirements");
    };

    const handleDeleteResource = async (resource, index) => {
        try {
            const formData = new FormData();
            formData.append("lab_id", labID);
            formData.append("resource_id", resource.id);
            await deleteLabResourceAPI(formData);
            const updatedResources = files.filter((item, idx) => idx !== index);
            setFiles(updatedResources);
            showNotification("Resource deleted successfully", "success");
        } catch (error) {
            showNotification(error, "danger");
        } finally {
        }
    };

    const handleReplace = async (resourceID, file, file_type) => { };


    const handleVisualizationDetailsChange = (event) => {
        setInstructions({ ...instructions, visualizationDetails: event });
    };


    const handleSave = async () => {
        //TODO: Implement the save functionality
        try {
            setLoading({ isLoading: true, message: "Saving Data Requirements..." });
            const formData = new FormData();
            formData.append("lab_id", labID);
            formData.append("instructions", JSON.stringify(instructions)); // Convert instructions to JSON string

            // Display the key/value pairs
            for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            const response = await saveLabInstructionsAPI(formData);
        }
        catch (error) {
            console.error("Error in handleSave:", error);
            showNotification("Error saving lab details", "danger");
        }
        finally {
            setLoading({ isLoading: false, message: "" });
        }
    };

    const handleAddResource = async () => {
        if (!newResource.name || !newResource.description || !newResource.file) {
            showNotification("Please fill all the fields", "danger");
            return;
        }
        try {
            setResourceAddModal(false);
            setLoading({ isLoading: true, message: "Adding File..." });

            if (!newResource) {
                throw new Error("No new resource provided.");
            }

            // Initialize FormData for the API request
            const formData = new FormData();

            // Add required fields to FormData
            formData.append("lab_id", labID);
            formData.append("resource_type", "Image");
            formData.append("resource_name", newResource.name);
            formData.append("resource_description", newResource.description);
            // Append the new file if it exists
            if (newResource.file) {
                formData.append("resource_file", newResource.file);
            }

            const response = await addResourceToLabAPI(formData);
            console.log("Response is: ", response);

            handleSetFileResources(response.raw_resources);
            showNotification("File Added successfully", "success");
            setNewResource({ name: "", description: "", file: null });
        } catch (error) {
            console.error("Error in replacing resource:", error.message || error);
            showNotification(
                `Failed to replace resource: ${error.message || "Unknown error"}`,
                "danger"
            );
        } finally {
            handleFileUploadDialogClose();
            setLoading({ isLoading: false, message: "" });
        }
    };

    const handleResourceAdditionModal = () => {
        setResourceAddModal(true);
    };

    const handleFileUploadDialogClose = () => {
        setResourceAddModal(false);
        setNewResource({ name: "", description: "", file: null });
    };

    const handleReplaceResource = async () => { };

    return (
        <div>
            {/* Pass props to FileUploadDialog */}
            <FileUploadDialog
                open={resourceAddModal}
                onClose={handleFileUploadDialogClose}
                newFile={newResource}
                setNewFile={setNewResource}
                onSave={replace ? handleReplaceResource : handleAddResource}
                replace={replace}
                file_type="image"
            />
            {loading.isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginLeft: 16 }}>
                        {loading.message}
                    </Typography>
                </Box>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" >
                                Visualization Requirements
                            </Typography>
                            <Typography variant="body1" style={{ marginBottom: "10px" }}>
                                {description}
                            </Typography>
                            <Divider style={{ marginBottom: "10px" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ marginBottom: "10px" }}>
                                Enter the Visualizations Details for the Application
                            </Typography>
                            <Grid item xs={12} sm={12}>
                                <Card>
                                    <div data-color-mode="light">
                                        <MDEditor
                                            value={instructions.visualizationDetails}
                                            onChange={(event) => handleVisualizationDetailsChange(event)}
                                        />
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ marginBottom: "16px" }}>Manage Visualization Files</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <WritingTable
                                files={files}
                                handleDeleteResource={handleDeleteResource}
                                handleReplace={handleReplace}
                                handleResourceAddition={handleResourceAdditionModal}
                                setSelectedResources={setNewFiles}
                                selectedResources={selectedResources}
                                selectColumnRequired={false}
                                viewActionRequired={true}
                                atleastOneFileRequired={false}
                            />
                        </Grid>
                    </Grid>


                    <Grid container style={{ marginTop: "10px" }}>
                        <Grid item xs={12}>
                            <Button color="quCreateColor" style={{ float: 'right', fontWeight: 'bold' }}
                                endIcon={<NavigateNextIcon />} onClick={handleNext}
                            >
                                Next
                            </Button>
                            <Button color="quCreateColor" style={{ float: 'right', fontWeight: 'bold' }}
                                startIcon={<SaveIcon />} onClick={handleSave}
                            >
                                Save
                            </Button>
                            <Button color="quCreateColor" style={{ float: "left", fontWeight: "bold" }}
                                startIcon={<ArrowBackIcon />} onClick={handleBack}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default VisualizationRequirements;