import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

// Material-UI Core Components
import {
  Divider,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
// Custom Components
import Button from "components/CustomButtons/Button.js";
import {
  saveLatestVersionAPI,
  fetchWritingAPI,
  convertToPDFAPI,
  addResourcesToWritingAPI,
  deleteWritingResourceAPI,
  regenerateWritingAPI,
  fetchPDFAPI,
} from "views/APIs/APIUtility";
import WritingTable from "./WritingTable";
import RegenerateAndSaveModal from "./RegenerateAndSaveModal";
import FileUploadDialog from "../FileUploadDialog";
import EditorViewer from "views/Components/EditorViewer";
// Contexts
import NotificationContext from "views/Components/Context.js";
import { format, formatISO } from "date-fns";
import MDEditor from "@uiw/react-md-editor";
// Icons
import { CloudDownload, RotateLeft, ExpandMore } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SyncIcon from "@material-ui/icons/Sync";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  ...styles,
});

const identifiers = {
  research_report: "Research Report",
  white_paper: "White Paper",
  project_plan: "Project Plan",
  blog: "Blog",
  news_letter: "Newsletter",
  case_study: "Case Study",
  key_insights: "Key Insights",
  handout: "Handout",
};

export default function WritingPublisher() {
  // Context and styles
  const { showNotification } = React.useContext(NotificationContext);
  const location = useLocation();
  const classes = useStyles();

  // Determine identifier for document type based on location state
  let identifier = "research_report";

  if (location.pathname) {
    identifier = location.pathname.split("/")[2];
  }
  const identifierPrint = identifiers[identifier];

  // Get the writing ID from URL parameters
  const writingId = useParams()["writingID"];

  // State Hooks for UI components and functionality
  const [alert, setAlert] = React.useState(null);

  // Writing states
  const [writing, setWriting] = useState({});
  const [history, setHistory] = useState([]);
  const [outline, setOutline] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [template, setTemplate] = useState("academic");
  const [version, setVersion] = useState(1);
  const [latestVersion, setLatestVersion] = useState(1);
  const [versionOptions, setVersionOptions] = useState([
    {
      writing_outline: "",
      version: 1,
      timestamp: formatISO(new Date()),
    },
  ]);
  const [resources, setResources] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  //Loading states
  const [loading, setLoading] = useState({ isLoading: false, message: "" });
  const [pdfLoading, setPdfLoading] = useState({
    isLoading: false,
    message: "",
  });
  // Resource Modal. Ex: To Add Resource
  const [resourceAddModal, setResourceAddModal] = useState(false);
  const [newResource, setNewResource] = useState({
    name: "",
    description: "",
    file: null,
  });
  const [replace, setReplace] = useState(false);
  // Regenerate and Save Modal States
  const [rnsModalOpen, setRNSModalOpen] = useState(false);
  const [commitMessage, setCommitMessage] = useState("");
  const [RNSType, setRNSType] = useState("save");
  const [feedback, setFeedback] = useState("");
  // Feedback view States
  const [scratchCheck, setScratchCheck] = useState(true);

  const convertUtcToLocalWithOffset = (utcTimeString, offsetHours = -5) => {
    const date = new Date(utcTimeString); // Convert UTC string to Date object

    // Add the offset hours
    date.setHours(date.getHours() + offsetHours);

    // Format to the user's local timezone
    return date.toLocaleString();
  };
  const [tableExpanded, setTableExpanded] = React.useState(false);
  const [previousExpanded, setPreviousExpanded] = React.useState(false);
  const [regenerateExpanded, setRegenerateExpanded] = React.useState(false);

  // Helper functions
  const _mapResourceToOutputType = (resource) => {
    return {
      resource_id: resource.id,
      resource_name: resource.name,
      resource_description: resource.description,
      resource_link: resource.url,
      resource_type: resource.type,
    };
  };

  const _mapResourceToAppType = (resource) => {
    return {
      id: resource.resource_id,
      name: resource.resource_name,
      description: resource.resource_description,
      url: resource.resource_link,
      type: resource.resource_type,
    };
  };

  const templateOptions = [
    "academic",
    "business",
    "technical",
    "whitepaper",
    "presentation",
  ];



  // Fetch writing data and initialize component state
  useEffect(() => {
    const fetchWritingData = async () => {
      setLoading({
        isLoading: true,
        message: `Loading ${identifierPrint}'s Data`,
      });
      setPdfLoading({ isLoading: true, message: `Loading PDF...` });

      try {
        const data = await fetchWritingAPI(writingId);
        setWriting(data);
        setOutline(data.writing_outline);
        if (!data.history || data.history.length === 0) return; // Exit early if no history

        const latestVersion = data.history[data.history.length - 1];
        const mappedResources = data.all_resources.map(_mapResourceToAppType); // Map once

        setLatestVersion(latestVersion.version);
        setVersion(latestVersion.version);
        setVersionOptions(data.history);
        setHistory(data.history);
        setResources(mappedResources);
        setSelectedResources(mappedResources);

        await getPdf(data.writing_outline);
      } catch (error) {
        showNotification(error, "danger");
      } finally {
        setLoading({ isLoading: false, message: "" });
        setPdfLoading({ isLoading: false, message: "" });
      }
    };

    fetchWritingData();
  }, [writingId]);

  const handleRNSModalClose = () => {
    setRNSModalOpen(false);
    setCommitMessage("");
  };

  // Save Changes: prepares form data and calls API to save the latest version
  const handleSaveChanges = async (prompt = "", use_metaprompt = false) => {
    setLoading({ isLoading: true, message: "Saving Changes..." });
    try {
      const formData = new FormData();
      formData.append("writing_id", writingId);
      formData.append("writing_outline", outline);
      formData.append("message", commitMessage);
      const updatedResources = [];
      selectedResources.forEach((res) => {
        updatedResources.push(_mapResourceToOutputType(res));
      });
      formData.append("resources", JSON.stringify(updatedResources));

      await saveLatestVersionAPI(formData);
      window.location.reload();
      hideAlert();
      // showNotification("Changes saved successfully", "success");
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      handleRNSModalClose();
      setLoading({ isLoading: false, message: "" });
    }
  };

  const handleRegenerate = async (prompt, use_metaprompt) => {
    setLoading({
      isLoading: true,
      message: "Regenerating the content for you...",
    });
    try {
      const formData = new FormData();
      formData.append("writing_id", writingId);
      formData.append("instructions", feedback);
      formData.append("previous_outline", outline);
      const updatedResources = [];
      selectedResources.forEach((res) => {
        updatedResources.push(_mapResourceToOutputType(res));
      });
      formData.append("identifier", identifier);
      formData.append("selected_resources", JSON.stringify(updatedResources));
      formData.append("prompt", prompt);
      formData.append("use_metaprompt", use_metaprompt);
      await regenerateWritingAPI(formData);
      window.location.reload();
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      handleRNSModalClose();
    }
  };

  // Hide the SweetAlert popup
  const hideAlert = () => {
    setAlert(null);
  };

  // Generate PDF from Markdown content, optionally triggering a download
  const getPdf = async (markdown = outline, download = false) => {
    setLoading(true);
    if (download) {
      showNotification("Generating PDF...", "quSandBoxColor");
    }

    const formData = new FormData();
    formData.append("writing_id", writingId);
    formData.append("markdown", markdown);
    formData.append("template_name", template);

    try {
      const data = await convertToPDFAPI(formData);
      const url = data;
      setPdfUrl(url);

      if (download) {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", writing.writing_name + ".pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        showNotification("PDF generated successfully", "success");
      }
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      setLoading(false);
    }
  };

  const handleChangeVersion = async (event) => {

    setLoading({ isLoading: true, message: "Loading Version..." });
    setPdfLoading({ isLoading: true, message: "Loading PDF..." });
    const selectedVersion = event.target.value;
    setVersion(selectedVersion);

    // Update outline and regenerate PDF for the selected version
    const selectedOption = versionOptions.find(
      (option) => option.version === selectedVersion
    );
    if (selectedOption) {
      setOutline(selectedOption.writing_outline);
      await getPdf(selectedOption.writing_outline);
    }
    setLoading({ isLoading: false, message: "" });
    setPdfLoading({ isLoading: false, message: "" });
  };

  const handleTemplateChange = async (event) => {
    setLoading({ isLoading: true, message: "Loading Template..." });
    setPdfLoading({ isLoading: true, message: "Loading PDF..." });
    setTemplate(event.target.value);
    setLoading({ isLoading: false, message: "" });
    await getPdf(outline, false);
    setPdfLoading({ isLoading: false, message: "" });
  };

  // Refresh PDF content based on current outline
  const handleRefreshContent = async (outline, download) => {
    setPdfLoading({ isLoading: true, message: "Loading PDF..." });
    await getPdf(outline, download);
    setPdfLoading({ isLoading: false, message: "" });
  };

  // Navigate back in browser history
  const handleWritingBack = () => {
    window.history.back();
  };

  const handleDeleteResource = async (resource, index) => {
    try {
      const formData = new FormData();
      formData.append("writing_id", writingId);
      formData.append("resource_id", resource.id);
      await deleteWritingResourceAPI(formData);
      const updatedSelectedResources = selectedResources.filter(
        (item, idx) => idx !== index
      );
      setSelectedResources(updatedSelectedResources);
      const updatedResources = resources.filter((item, idx) => idx !== index);
      setResources(updatedResources);
      showNotification("Resource deleted successfully", "success");
    } catch (error) {
      showNotification(error, "danger");
    } finally {
    }
  };

  const handleAddResource = async () => {
    if (!newResource.name || !newResource.description || !newResource.file) {
      showNotification("Please fill all the fields", "danger");
      return;
    }
    try {
      setLoading({ isLoading: true, message: "Adding File..." });
      setReplace(false); // Close any UI related to replacing

      if (!newResource) {
        throw new Error("No new resource provided.");
      }

      // Initialize FormData for the API request
      const formData = new FormData();

      // Add required fields to FormData
      formData.append("writing_id", writingId);
      formData.append("resource_type", "File");
      formData.append("resource_name", newResource.name);
      formData.append("resource_description", newResource.description);
      // Append the new file if it exists
      if (newResource.file) {
        formData.append("resource_file", newResource.file);
      }

      const response = await addResourcesToWritingAPI(formData);

      setResources([...resources, _mapResourceToAppType(response)]);
      showNotification("File Added successfully", "success");
    } catch (error) {
      console.error("Error in replacing resource:", error.message || error);
      showNotification(
        `Failed to replace resource: ${error.message || "Unknown error"}`,
        "danger"
      );
    } finally {
      handleFileUploadDialogClose();
      setLoading({ isLoading: false, message: "" });
    }
  };

  const handleFileUploadDialogClose = () => {
    setResourceAddModal(false);
    setNewResource({ name: "", description: "", file: null });
  };

  const handleResourceAdditionModal = () => {
    setResourceAddModal(true);
  };

  const handleReplace = async (resourceId, file) => { };
  const handleReplaceResource = async () => { };

  // Open the Regenerate and Save modal for saving or regenerating the writing
  const handleRNSModalOpen = (type) => {
    setRNSType(type);
    setRNSModalOpen(true);
  };



  if (loading.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" className={classes.loaderClass} />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loading.message}
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      {alert}
      {/* <div>
        <Divider style={{ marginBottom: "20px" }} />

        <Typography variant="h5">
          Welcome to the {identifierPrint} Publisher!
        </Typography>
        <Typography
          variant="body1"
          style={{ marginTop: "10px", marginBottom: "20px" }}
        >
          Create and publish your {identifierPrint} here.
        </Typography>
      </div>

      <Divider /> */}
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
        }}
      >
        <div>
          <Typography variant="h6">{writing.writing_name}</Typography>
          <Typography variant="body1">{writing.writing_description}</Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px", // Add spacing between dropdowns
            marginLeft: "auto",
          }}
        >
          {/* Dropdown to select the version type */}
          <FormControl style={{ minWidth: "180px" }} variant="filled">
            {" "}
            {/* Ensure consistent width */}
            <InputLabel>Select Version</InputLabel>
            <Select
              value={version}
              renderValue={(selected) => {
                // Display only the version number when the dropdown is not open
                const selectedOption = versionOptions.find(
                  (option) => option.version === selected
                );
                return selectedOption
                  ? `Version ${selectedOption.version}.0`
                  : "";
              }}
              onChange={handleChangeVersion}
              fullWidth
              disableUnderline
            >
              {versionOptions.map((option, index) => (
                <MenuItem key={index} value={option.version}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {" "}
                        {"Version: " + option.version}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption"
                        style={{ fontStyle: "italic" }}
                      >
                        {"Last Updated: " +
                          convertUtcToLocalWithOffset(option.timestamp)}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Dropdown to select the template type */}
          <FormControl style={{ minWidth: "180px" }} variant="filled">
            {" "}
            <InputLabel>Select Template</InputLabel>
            <Select
              value={template}
              onChange={handleTemplateChange}
              fullWidth
              disableUnderline
            >
              {templateOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Divider />

      <EditorViewer
        version={version}
        latestVersion={latestVersion}
        outline={outline}
        setOutline={setOutline}
        pdfUrl={pdfUrl}
        pdfLoading={pdfLoading}
        setPdfLoading={setPdfLoading}
        handleRefreshContent={handleRefreshContent}
      />

      <div
        style={{
          marginTop: "10px",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={6}
            md={12}
            lg={12}
            style={{
              marginTop: "20px",
            }}
          >
            <Button
              onClick={() => getPdf(outline, true)}
              color="quSandBoxColor"
              style={{
                float: "right",
              }}
            >
              <CloudDownload /> &nbsp;Download
            </Button>

            <Button
              // onClick={() => { saveChangesAlert() }}
              onClick={() => {
                handleRNSModalOpen("save");
              }}
              color="quCreateColor"
              disabled={version !== latestVersion}
              style={{
                float: "right",
              }}
            >
              <SaveIcon /> &nbsp;Save Changes
            </Button>

            <Button
              // onClick={() => { saveChangesAlert() }}
              onClick={() => {
                handleRNSModalOpen("regenerate");
              }}
              color="quCreateColor"
              disabled={version !== latestVersion}
              style={{
                float: "right",
              }}
            >
              <SyncIcon /> &nbsp;Regenerate
            </Button>

            <Button
              color="quCreateColor"
              onClick={handleWritingBack}
              style={{
                float: "left",
                fontWeight: "bold",
              }}
            >
              <ArrowBackIcon /> Back
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              marginTop: "20px",
            }}
          >
            <ExpansionPanel
              style={{ borderBottom: "1px solid grey" }}
              expanded={tableExpanded}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                onClick={() => {
                  setTableExpanded(!tableExpanded);
                  setPreviousExpanded(false);
                  setRegenerateExpanded(false);
                }}
              >
                <Typography className="classes.title">
                  <b>References</b>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <WritingTable
                  files={resources}
                  handleDeleteResource={handleDeleteResource}
                  handleReplace={handleReplace}
                  handleResourceAddition={handleResourceAdditionModal}
                  setSelectedResources={setSelectedResources}
                  selectedResources={selectedResources}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <ExpansionPanel
              style={{ borderBottom: "1px solid grey" }}
              expanded={previousExpanded}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                onClick={() => {
                  setPreviousExpanded(!previousExpanded);
                  setTableExpanded(false);
                  setRegenerateExpanded(false);
                }}
              >
                <Typography className="classes.title">
                  <b>Version History</b>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {/*                 
                {history.map((item, index) => (
                  <div key={index}>
                    <div data-color-mode="light">
                      <MDEditor.Markdown source={item.feedback} />
                    </div>
                    <Divider />
                  </div>
                ))} */}
                {history.length > 0 ? (
                  <ul style={{ padding: 0, margin: 0, width: "100%" }}>
                    {history.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          listStyleType: "none",
                          padding: "10px",
                          borderBottom: "1px solid gref(120, 120, 120)",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography variant="body1">
                          <b
                            style={
                              item.version === version
                                ? { color: "green" }
                                : { color: "black" }
                            }
                          >
                            Version {item.version}.0
                          </b>
                          {item.version === version && (
                            <span> (Current Version)</span>
                          )}
                          <span style={{ float: "right" }}>
                            {format(
                              new Date(item.timestamp),
                              "dd MMM yyyy, hh:mm a"
                            )}
                          </span>
                        </Typography>
                        <Typography variant="body1">Feedback:</Typography>
                        <div data-color-mode="light">
                          <MDEditor.Markdown source={item.feedback} />
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography variant="body1">
                    No feedback provided for this document.
                  </Typography>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>

        {/* Pass props to FileUploadDialog */}
        <FileUploadDialog
          open={resourceAddModal}
          onClose={handleFileUploadDialogClose}
          newFile={newResource}
          setNewFile={setNewResource}
          onSave={replace ? handleReplaceResource : handleAddResource}
          replace={replace}
          file_type="file"
        />
        <RegenerateAndSaveModal
          rnsModalOpen={rnsModalOpen}
          RNSType={RNSType}
          handleRNSModalClose={handleRNSModalClose}
          setSelectedResources={setSelectedResources}
          setCommitMessage={
            RNSType === "regenerate" ? setFeedback : setCommitMessage
          }
          commitMessage={commitMessage}
          resources={selectedResources}
          message={feedback}
          onSubmit={
            RNSType === "regenerate" ? handleRegenerate : handleSaveChanges
          }
          scratchCheck={scratchCheck}
          setScratchCheck={setScratchCheck}
          setFeedback={setFeedback}
        />
      </div>
    </div>
  );
}
