import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Tooltip,
} from "@material-ui/core";

// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
// import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import Button from "components/CustomButtons/Button.js";
// Custom styles
import styles from "assets/jss/material-dashboard-pro-react/components/headingStyle.js";

const useStyles = makeStyles(styles);

export default function HeadingWithShortDescription(props) {
    // Destructure props
    const {
        textAlign,
        title,
        shortDescription,
        dialogTitle,
        onChange,
    } = props;

    // Use custom styles
    const classes = useStyles();

    // State to control modal visibility and for editable fields
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [editableTitle, setEditableTitle] = useState(title);
    const [editableShortDescription, setEditableShortDescription] = useState(shortDescription);

    // Combine the classes with dynamic text alignment
    const heading = classes.heading + " " + cx({
        [classes[textAlign + "TextAlign"]]: textAlign !== undefined,
    });

    // Handle modal open
    const handleEditModalOpen = () => {
        setOpenEditModal(true);
    };

    // Handle modal close
    const handleModalClose = () => {
        setOpenEditModal(false);
        setOpenPreviewModal(false);
    };

    // Handle save changes
    const handleSaveChanges = () => {
        // Create a JSON object with all the fields
        const updatedData = {
            title: editableTitle,
            shortDescription: editableShortDescription,
            // longDescription: editableLongDescription,
        };

        // Pass the JSON object to the parent via the onChange prop
        onChange(updatedData);

        // Close the edit modal
        setOpenEditModal(false);
    };

    return (
        <div className={heading}>
            {/* Title and Icons in the same container */}
            <Grid container justifyContent="space-between" alignItems="flex-start">
                {/* Title on the left */}
                <Grid item xs={12} sm={10}>
                    {title !== undefined ? (
                        <Typography variant="h5">
                            {title}
                        </Typography>
                    ) : null}
                    {shortDescription !== undefined ? (
                <Typography variant="body1" >{shortDescription}</Typography>
            ) : null}
                </Grid>

                {/* Icons on the right */}
                <Grid item xs={12} sm={2} style={{ textAlign: "right" }}>
                    <Tooltip title={dialogTitle} placement="top">
                        <IconButton onClick={handleEditModalOpen}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            

            {/* Edit Modal to edit title, short description, and course information */}
            <Dialog
                open={openEditModal}
                onClose={handleModalClose}
                fullWidth
                maxWidth="md"
            >
                <DialogContent>
                    <Typography variant="h6">{dialogTitle}</Typography>
                    <TextField
                        label="Title"
                        value={editableTitle}
                        onChange={(e) => setEditableTitle(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        multiline
                        label="Short Description"
                        value={editableShortDescription}
                        onChange={(e) => setEditableShortDescription(e.target.value)}
                        fullWidth
                        rows={4}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="quCreateColor">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveChanges} color="quCreateColor">
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

HeadingWithShortDescription.propTypes = {
    title: PropTypes.node,
    shortDescription: PropTypes.node,
    longDescription: PropTypes.node,
    dialogTitle: PropTypes.string,
    textAlign: PropTypes.oneOf(["right", "left", "center"]),
    onChange: PropTypes.func.isRequired, // `onChange` is a required prop
};
