// React and Context Imports
import React, { useState } from "react";
import NotificationContext from "views/Components/Context.js";
import EditIcon from "@material-ui/icons/Edit";
// API Imports
import {
  generateWritingOutlineAPI,
  fetchWritingPromptAPI,
} from "views/APIs/APIUtility";

// Custom Components
import Button from "components/CustomButtons/Button.js";
import Alert from "views/Components/Alert";

// Material-UI Core Components
import {
  Grid,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Tooltip,
} from "@material-ui/core";

// Material-UI Styles and Connectors
import { makeStyles } from "@material-ui/core/styles";

// Material-UI Icons
import {
  Adjust,
  Assistant,
  BubbleChart,
  Category,
  CloudUpload,
} from "@material-ui/icons";

// Third-Party Components
import { DropzoneArea } from "material-ui-dropzone";
import MDEditor from "@uiw/react-md-editor";

// Styles and Assets
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import QuStreamlineDialog from "./QuStreamlineDialog";
import useMetaPrompt from "hooks/useMetaPrompt";

import { stepperStyles, QontoConnector } from "views/Styles/stepperStyles";
import { dropzoneStyles } from "views/Styles/dropzoneStyles";

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...stepperStyles(theme),
  ...dropzoneStyles,
  resourceTile: {
    height: "100%", // Ensure all tiles have the same height
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // To ensure the content inside each tile is spaced well
    alignItems: "center",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      boxShadow: theme.shadows[5],
      cursor: "pointer",
    },
  },
  dialogTitle: {
    fontWeight: "600",
    fontSize: "1.25rem",
    textAlign: "left",
    marginBottom: theme.spacing(3),
  },
}));

export default function CreateWritingModal({
  identifier,
  identifierPrint,
  newWritingDetails,
  setNewWritingDetails,
  writingId,
  setWritingId,
  createWritingModal,
  handleCreateWritingModalClose,
  handleCreateWriting,
  handleIconUpload,
  iconImage,
  writingCreationLoader,
  setWritingCreationLoader,
  currentDialog,
  setCurrentDialog,
  handleGenerateTemplate,
}) {
  const {
    metapromptCheckbox,
    setMetapromptCheckbox,
    originalMetaPrompt,
    setOriginalMetaPrompt,
    metaPrompt,
    setMetaPrompt,
    metaPromptDialogOpen,
    setMetaPromptDialogOpen,
    requiredMetaPromptParameters,
    setRequiredMetaPromptParameters,
    displayMetaPromptParameters,
    setDisplayMetaPromptParameters,
    notRequiredParameters,
    isDisabled,
    setIsDisabled,
    handleMetaPromptChange,
    checkForDisabled,
    _extractParameters,
    _handleEnhancePrompt,
  } = useMetaPrompt();

  const category = localStorage.getItem("userCategory");

  const { showNotification } = React.useContext(NotificationContext);
  const classes = useStyles();

  const dialogStages = [
    "Create Writing",
    "Resources",
    "Templates",
    "Update Outline",
    "Submit",
  ];
  // const stepperDisplayStages = ["Basic Details", "Instructions & Files", "Writing Outline"];
  const stepperDisplayStages = [
    "Basic Details",
    "Resources",
    "Templates",
    "Draft",
  ];

  const tones = ["Professional", "Casual", "Assertive", "Friendly"];

  const [activeStep, setActiveStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [backAlert, setBackAlert] = useState(false);

  const icons = [Adjust, Assistant, BubbleChart, Category];

  const _handleCreateWritingModalClose = () => {
    setActiveStep(0);
    setBackAlert(false);
    setSelectedTemplate(null);
    handleCreateWritingModalClose();
  };

  const generateWritingOutlineFunction = async () => {
    setWritingCreationLoader({
      isLoading: true,
      message: _capitalize("Creating " + identifierPrint + " draft..."),
    });
    // setOutlineLoader(true);
    const formData = new FormData();
    newWritingDetails.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("instructions", selectedTemplate.template_content);
    formData.append("identifier", identifier);
    if (metapromptCheckbox) {
      formData.append("use_metaprompt", true);
    }

    try {
      const data = await generateWritingOutlineAPI(formData);
      const writing_id = data["writing_id"];
      const outline = data["writing"];
      setWritingId(writing_id);
      setNewWritingDetails({ ...newWritingDetails, outline: outline });
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      setWritingCreationLoader({ isLoading: false, message: "" });
      // setOutlineLoader(false);
    }
  };
  // handle next actions on dialogs
  const handleStep1 = async () => {
    if (
      newWritingDetails.name.trim() === "" ||
      newWritingDetails.description.trim() === "" ||
      newWritingDetails.target_audience.trim() === "" ||
      newWritingDetails.expected_length.trim() === "" ||
      newWritingDetails.tone.trim() === ""
    ) {
      showNotification(
        "Please make sure all fields are properly filled out.",
        "danger"
      );
      return;
    }
    await getPrompt();
    setActiveStep(activeStep + 1);
    setCurrentDialog(dialogStages[dialogStages.indexOf(currentDialog) + 1]);
  };

  const handleStep2 = async () => {
    if (newWritingDetails.uploadedFiles.length === 0) {
      showNotification(
        _capitalize(
          `Please upload at least one file to generate ${identifierPrint} template.`
        ),
        "danger"
      );
      return;
    }
    prompt = metapromptCheckbox ? metaPrompt : originalMetaPrompt;
    await handleGenerateTemplate(prompt, !metapromptCheckbox);
    _handleTemplateSelect(newWritingDetails.template[0]);
    setActiveStep(activeStep + 1);
    setCurrentDialog(dialogStages[dialogStages.indexOf(currentDialog) + 1]);
  };

  const handleStep3 = async () => {
    if (selectedTemplate.template_content === "") {
      showNotification(
        _capitalize(
          "Please fill in the instructions for generating the " +
          { identifier } +
          " outline."
        ),
        "danger"
      );
      return;
    }
    await generateWritingOutlineFunction();
    setActiveStep(activeStep + 1);
    setCurrentDialog(dialogStages[dialogStages.indexOf(currentDialog) + 1]);
  };

  const handleStep4 = () => {
    if (newWritingDetails.outline === "") {
      showNotification(
        _capitalize("You need to have a " + identifierPrint),
        "danger"
      );
      return;
    }
    // setActiveStep(activeStep + 1);
    setCurrentDialog(dialogStages[dialogStages.indexOf(currentDialog) + 1]);
    handleCreateWriting();
  };

  // const handleStep5 = () => {
  //   handleCreateWriting();
  // };

  const handleBackValidator = () => {
    if (currentDialog === "Templates" || currentDialog === "Update Outline") {
      setBackAlert(true);
      return;
    }
    _handleBack();
  };

  const handleConfirm = () => {
    setBackAlert(false); // Close the alert
    if (currentDialog === "Templates") {
      setSelectedTemplate(null);
    }
    if (currentDialog === "Update Outline") {
      setNewWritingDetails({ ...newWritingDetails, outline: "" });
    }
    _handleBack();
  };

  const handleCancel = () => {
    setBackAlert(false); // Close the alert
  };

  // Helper functions

  const _handleBack = () => {
    setActiveStep(activeStep - 1);
    setCurrentDialog(dialogStages[dialogStages.indexOf(currentDialog) - 1]);
  };

  const _handleFileUpload = (files) => {
    setNewWritingDetails({ ...newWritingDetails, uploadedFiles: files });
  };

  const _handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };

  // Function to update the template content in newWritingDetails
  const _handleTemplateContentChange = (value) => {
    setNewWritingDetails((prevState) => ({
      ...prevState,
      template: prevState.template.map((t) =>
        t.template_name === selectedTemplate.template_name
          ? { ...t, template_content: value }
          : t
      ),
    }));

    // Also update the selectedTemplate state to reflect changes immediately
    setSelectedTemplate((prev) => ({
      ...prev,
      template_content: value,
    }));
  };

  const _capitalize = (text) => {
    const lowerText = text.toLowerCase();
    return lowerText.charAt(0).toUpperCase() + lowerText.slice(1);
  };

  const handleMetaPromptDialog = async (event) => {
    setMetaPromptDialogOpen(true);
  };

  const metaPromptDialogClose = () => {
    setMetaPromptDialogOpen(false);
    setMetapromptCheckbox(false);
  };

  const _handleSubmission = async () => {
    setMetapromptCheckbox(true);
    setMetaPromptDialogOpen(false);
  };

  const getPrompt = async () => {
    try {
      const formData = new FormData();
      formData.append("identifier", identifier);
      const data = await fetchWritingPromptAPI(formData);
      setMetaPrompt(data);
      setOriginalMetaPrompt(data);
      const parameters = _extractParameters(data);
      setRequiredMetaPromptParameters(parameters);
      setDisplayMetaPromptParameters(parameters);
    } catch (error) {
      console.log("error", error);
    }
  };

  const renderDialogContent = () => {
    switch (currentDialog) {
      case "Create Writing":
        return (
          <div>
            <Typography
              variant="h5"
              style={{
                marginBottom: "10px",
              }}
            >
              Enter the {identifierPrint} Details:
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={identifierPrint + " Name"}
              placeholder="Credit Risk Analysis"
              type="text"
              fullWidth
              required
              value={newWritingDetails.name}
              onChange={(event) =>
                setNewWritingDetails({
                  ...newWritingDetails,
                  name: event.target.value,
                })
              }
            />
            <TextField
              margin="dense"
              id="description"
              label="Brief Description"
              placeholder={`A Credit Risk Analysis ${identifierPrint} typically provides a comprehensive study on assessing and managing the risk of financial loss due to a borrower's inability to meet debt obligations.`}
              type="text"
              fullWidth
              multiline
              required
              rows={3}
              value={newWritingDetails.description}
              onChange={(event) =>
                setNewWritingDetails({
                  ...newWritingDetails,
                  description: event.target.value,
                })
              }
            />
            <TextField
              margin="dense"
              id="name"
              label="Target Audience"
              placeholder="Example: Students | Professionals | Industry Experts | General Audience"
              type="text"
              fullWidth
              required
              value={newWritingDetails.target_audience}
              onChange={(event) =>
                setNewWritingDetails({
                  ...newWritingDetails,
                  target_audience: event.target.value,
                })
              }
            />
            <TextField
              margin="dense"
              id="name"
              label={`Length of the ${identifierPrint}`}
              placeholder="Example: 500 words | 1000 characters"
              type="text"
              fullWidth
              required
              value={newWritingDetails.expected_length}
              onChange={(event) =>
                setNewWritingDetails({
                  ...newWritingDetails,
                  expected_length: event.target.value,
                })
              }
            />
            <div>
              <Typography
                variant="body1"
                style={{
                  marginTop: "10px",
                }}
              >
                Expected Tone of the {identifierPrint} *:
              </Typography>
              <RadioGroup
                row
                aria-label="tone"
                name="tone"
                value={newWritingDetails.tone || ""}
                onChange={(event) =>
                  setNewWritingDetails({
                    ...newWritingDetails,
                    tone: event.target.value,
                  })
                }
              >
                {tones.map((tone) => (
                  <FormControlLabel
                    key={tone}
                    value={tone.toLowerCase()}
                    control={<Radio required />}
                    label={tone}
                    labelPlacement="left"
                  />
                ))}
              </RadioGroup>
            </div>
            {/* Icon Image Upload */}
            <Button
              variant="contained"
              component="label"
              color="info"
              style={{ marginTop: "20px" }}
            >
              <CloudUpload /> &nbsp; Upload an Image for Your Project
              <input
                type="file"
                hidden
                accept="image/*"
                required
                onChange={handleIconUpload}
              />
            </Button>
            {iconImage && (
              <div style={{ marginTop: "10px" }}>
                <strong>Uploaded Image:</strong> {iconImage.name}
              </div>
            )}
            <Typography
              variant="caption"
              style={{ display: "block", marginTop: "5px" }}
            >
              A 16:9 aspect ratio image is recommended
            </Typography>
          </div>
        );
      case "Resources":
        return (
          <Grid container spacing={3} alignItems="flex-start">
            {/* Title for File Upload */}
            <Grid item xs={12} style={{ flexGrow: 1 }}>
              <Typography
                variant="h5"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                {_capitalize(
                  `upload supporting files to generate the ${identifierPrint} outline:`
                )}
              </Typography>
            </Grid>
            {/* DropzoneArea for File Upload */}
            <Grid item xs={12}>
              <DropzoneArea
                initialFiles={newWritingDetails.uploadedFiles}
                onChange={_handleFileUpload}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                maxFileSize={50000000}
                acceptedFiles={["application/pdf"]}
                previewText="Uploaded files"
                dropzoneText={
                  <div style={{ textAlign: "center" }}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      style={{ marginBottom: "10px" }}
                    >
                      Acceptable formats: .pdf
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Drag and drop or click here to upload files.
                    </Typography>
                  </div>
                }
                filesLimit={100}
                dropzoneClass={classes.dropzone}
              />
            </Grid>

            {(category === "super_admin" || category === "admin") && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  verticalAlign: "middle",
                }}
              >
                <Checkbox
                  color="quCreateColor"
                  checked={metapromptCheckbox}
                  disabled
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                />
                <Typography variant="body2" color="textSecondary">
                  Enable QuStreamline
                </Typography>
                <Tooltip title="QuStreamline streamlines the generation of the query with best practices and guardrails.">
                  <EditIcon
                    style={{ verticalAlign: "middle", marginLeft: "5px" }}
                    fontSize="small"
                    color="quCreateColor"
                    onClick={handleMetaPromptDialog}
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        );
      case "Templates":
        return (
          <>
            <Typography className={classes.dialogTitle}>
              {_capitalize(
                `Choose from one of the templates below and update it as you see fit:`
              )}
            </Typography>

            {/* Grid for Template Tiles */}
            <Grid container spacing={3} justifyContent="center">
              {newWritingDetails.template.map((template, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box
                    className={classes.resourceTile}
                    onClick={() => _handleTemplateSelect(template)}
                  >
                    {/* Icon and Heading */}
                    <Box mb={2}>
                      <Box mb={2}>
                        {icons[index] &&
                          React.createElement(icons[index], {
                            fontSize: "large",
                          })}
                      </Box>
                    </Box>
                    <Typography variant="body1">
                      {template.template_name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>

            {/* Markdown Editor for Template Editing */}
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={12} style={{ flexGrow: 1 }}>
                <div data-color-mode="light">
                  {selectedTemplate &&
                    selectedTemplate.template_content?.length > 0 && (
                      <MDEditor
                        value={selectedTemplate?.template_content || ""}
                        onChange={_handleTemplateContentChange}
                        height={250}
                      />
                    )}
                </div>
              </Grid>
            </Grid>
          </>
        );
      case "Update Outline":
        return (
          <div>
            <Typography variant="h5" style={{ marginBottom: "10px" }}>
              {_capitalize(identifierPrint)} outline:
            </Typography>
            <div data-color-mode="light">
              <MDEditor
                value={newWritingDetails.outline}
                onChange={(event) =>
                  setNewWritingDetails({ ...newWritingDetails, outline: event })
                }
                height={400}
              />
            </div>
          </div>
        );
      case "Submit":
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <CircularProgress size={20} /> &nbsp;
            <Typography variant="body1">
              Creating {identifierPrint} for you...
            </Typography>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {alert}
      {backAlert && (
        <Alert
          title="Are you sure?"
          warning={true}
          showCancel={true}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        >
          If you proceed, changes made at this step will be lost. Are you sure
          you want to continue?
        </Alert>
      )}
      {/* Dialog Box for Adding new writing */}
      <Dialog
        open={createWritingModal}
        onClose={_handleCreateWritingModalClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        {/* Stepper Component */}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<QontoConnector />}
          className={classes.stepperRoot}
        >
          {stepperDisplayStages.map((label, index) => (
            <Step key={index}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.stepIcon, // Default icon color
                    active: classes.activeStepIcon, // Active step circle
                    completed: classes.completedStepIcon, // Completed step color
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Divider />
        <DialogTitle id="form-dialog-title">
          <div style={{ fontSize: "30px" }}>{newWritingDetails.name}</div>
        </DialogTitle>
        <DialogContent>
          {/* Conditionally display loading state */}
          {writingCreationLoader.isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="60vh"
            >
              <CircularProgress color="quCreateColor" />
              <Typography variant="h6" style={{ marginLeft: 16 }}>
                {writingCreationLoader.message}
              </Typography>
            </Box>
          ) : (
            renderDialogContent() // Render the usual content when not loading
          )}
        </DialogContent>
        <DialogActions>
          {currentDialog !== "Create Writing" && (
            <Button
              onClick={handleBackValidator}
              color="quCreateColor"
              disabled={writingCreationLoader.isLoading}
            >
              Back
            </Button>
          )}

          <Button
            onClick={_handleCreateWritingModalClose}
            color="quCreateColor"
            disabled={writingCreationLoader.isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              switch (currentDialog) {
                case "Create Writing":
                  return handleStep1();
                case "Resources":
                  return handleStep2();
                case "Templates":
                  return handleStep3();
                case "Update Outline":
                  return handleStep4();
                // case "Submit":
                //   return handleStep5();
                default:
                  return null;
              }
            }}
            disabled={writingCreationLoader.isLoading}
            color="quCreateColor"
          >
            {currentDialog === "Update Outline" ? "Submit" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>
      <QuStreamlineDialog
        metaPromptDialogOpen={metaPromptDialogOpen}
        metaPromptDialogClose={metaPromptDialogClose}
        metaPrompt={metaPrompt}
        setMetaPrompt={setMetaPrompt}
        displayMetaPromptParameters={displayMetaPromptParameters}
        notRequiredParameters={notRequiredParameters}
        handleMetaPromptChange={handleMetaPromptChange}
        handleSubmission={_handleSubmission}
        checkForDisabled={checkForDisabled}
        _handleEnhancePrompt={_handleEnhancePrompt}
        _handleSubmission={_handleSubmission}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />
    </div>
  );
}
