import { useState } from "react";
import { enhancePromptAPI } from "views/APIs/APIUtility";

export default function useMetaPrompt() {
    const [metapromptCheckbox, setMetapromptCheckbox] = useState(false);
    const [originalMetaPrompt, setOriginalMetaPrompt] = useState("");
    const [metaPrompt, setMetaPrompt] = useState("");
    const [metaPromptDialogOpen, setMetaPromptDialogOpen] = useState(false);
    const [requiredMetaPromptParameters, setRequiredMetaPromptParameters] = useState([]);
    const [displayMetaPromptParameters, setDisplayMetaPromptParameters] = useState([]);
    const [notRequiredParameters, setNotRequiredParameters] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const _extractParameters = (input) => {
        const regex = /\{([^\s{}]+)\}/g; // Matches {IDENTIFIER_TEXT} but not {{ IDENTIFIER_TEXT }} or JSON-like content
        let match;
        const parametersMap = new Map();

        while ((match = regex.exec(input)) !== null) {
            const paramName = match[1];

            if (parametersMap.has(paramName)) {
                parametersMap.get(paramName).occurrences += 1;
            } else {
                parametersMap.set(paramName, { name: paramName, present: true, occurrences: 1, message: "Correct" });
            }
        }

        parametersMap.forEach((param) => {
            if (param.occurrences > 1) {
                param.message = "Extra parameters";
            } else {
                param.message = "Correct";
            }
        });

        return Array.from(parametersMap.values());
    };

    const handleMetaPromptChange = (value) => {
        setMetaPrompt(value);
        _validateAndSetParameters(value);
    };

    const _validateAndSetParameters = (value) => {
        const valueChangeParameters = _extractParameters(value);
        const valueParamsSet = new Set(valueChangeParameters.map(param => param.name));

        const notRequiredParams = valueChangeParameters
            .filter(param => !requiredMetaPromptParameters.some(rParam => rParam.name === param.name))
            .map(param => ({ ...param, message: "" }));

        setNotRequiredParameters(notRequiredParams);

        const updatedDisplayParameters = requiredMetaPromptParameters.map(param => {
            const matchingParam = valueChangeParameters.find(vParam => vParam.name === param.name);
            let updatedParam = { present: valueParamsSet.has(param.name), name: param.name, occurrences: param.occurrences };

            if (matchingParam) {
                if (matchingParam.occurrences > 1) {
                    updatedParam.message = "Extra parameters";
                } else if (matchingParam.occurrences === 0) {
                    updatedParam.message = "Missing parameters";
                } else {
                    updatedParam.message = "Correct";
                }
            } else {
                updatedParam.message = "Missing parameters";
            }

            return updatedParam;
        });

        const allParametersCorrect = updatedDisplayParameters.every(param => param.message === "Correct");
        setDisplayMetaPromptParameters(updatedDisplayParameters);
        setIsDisabled(!(allParametersCorrect && notRequiredParams.length === 0));
    };

    const checkForDisabled = () => {
        const allParametersCorrect = displayMetaPromptParameters.every(param => param.message === "Correct");
        return !(allParametersCorrect && notRequiredParameters.length === 0);
    };

    const _handleEnhancePrompt = async () => {
        try {
            const formData = new FormData();
            formData.append("prompt", metaPrompt);
            const data = await enhancePromptAPI(formData);
            handleMetaPromptChange(data);
        }
        catch (error) {
            console.log("Error: ", error);
        }
    };

    return {
        metapromptCheckbox,
        setMetapromptCheckbox,
        setMetapromptCheckbox,
        originalMetaPrompt,
        setOriginalMetaPrompt,
        metaPrompt,
        setMetaPrompt,
        metaPromptDialogOpen,
        setMetaPromptDialogOpen,
        requiredMetaPromptParameters,
        setRequiredMetaPromptParameters,
        displayMetaPromptParameters,
        setDisplayMetaPromptParameters,
        notRequiredParameters,
        isDisabled,
        setIsDisabled,
        handleMetaPromptChange,
        checkForDisabled,
        _extractParameters,
        _handleEnhancePrompt,
    };
}
