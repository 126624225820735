// TabsComponent.js
import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { Create, Publish, School, Share, List } from '@material-ui/icons';

const TabsComponent = ({ activeTab, handleTabChange }) => {
    return (
        <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
        >
            <Tab
                label={
                    <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        <Create fontSize="small" /> Create
                    </span>
                }
            />
            <Tab
                label={
                    <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        <Publish fontSize="small" /> Publish
                    </span>
                }
            />
            <Tab
                label={
                    <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        <School fontSize="small" /> Research Ideas
                    </span>
                }
            />
            <Tab
                label={
                    <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        <Share fontSize="small" /> Share
                    </span>
                }
            />
            <Tab
                label={
                    <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        <List fontSize="small" /> All
                    </span>
                }
            />
        </Tabs>
    );
};

export default TabsComponent;
