import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// Amplify components
import { Auth } from "aws-amplify";

import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { fetchUserAPI } from "views/APIs/APIUtility";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function LoginPage() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [emailModal, setEmailModal] = React.useState(false);
  // const [passwordModal, setPasswordModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [captcha, setCaptcha] = React.useState(true);

  // const [confirmationCode, setConfirmationCode] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  // const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validationErrors, setValidationErrors] = React.useState({});

  /**
   * Add the list of required fields in this function.
   */
  const validate = () => {
    const err = {};
    const requiredFields = [email, password];
    requiredFields.forEach((field) => {
      if (!field) {
        err[field] = "Required";
      }
    });
    setValidationErrors(err);
    if (Object.keys(err).length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const setUserDetails = async (username) => {
    try {
      const formData = new FormData();
      formData.append("username", username);
      const response = await fetchUserAPI(formData);
      const user = response[0];
      localStorage.setItem("userCategory", user.category);
    }
    catch (error) {
      console.error("Error in setUserDetails:", error);
    }
  };

  const cognitoLogin = async (e) => {
    if (!validate()) return;

    if (!captcha) {
      setErrorMessage("Complete the captcha to continue");
      setAlert(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={"Complete the captcha to continue"}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + " " + classes.warning}
        />
      );
      return;
    }

    e.preventDefault();

    try {
      const user = await Auth.signIn({ username: email, password });

      // Reset input fields after successful login
      setEmail("");
      setPassword("");
      await setUserDetails(user.username);

      // Redirect to the homepage
      history.push("/admin/project-design");
    } catch (err) {
      if (err.name === "UserNotConfirmedException") {
        // Resend confirmation email if the user is not confirmed
        await Auth.resendSignUp(email);
        history.push("/auth/verification-page", { email });
      } else {
        // Handle other authentication errors
        setErrorMessage(err.message);
        console.error("Login Error:", err);
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={err.message}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + " " + classes.warning}
          />
        );
      }
    }
  };


  function onChange(value) {
    setCaptcha(true);
  }

  // const resetPassword = () => {

  //   Auth.forgotPassword(email)
  //   .then(data => )
  //   .catch(err => console.log(err));

  //   setEmailModal(false);
  //   setPasswordModal(true);

  // }

  // const updatePassword = () => {

  //   Auth.forgotPasswordSubmit(email, confirmationCode, confirmPassword)
  //       .then(data => {

  //       })
  //       .catch(err => console.log(err));

  //   setPasswordModal(false)

  // }

  const basicAlert = async () => {
    let alertMessage;
    await setErrorMessage((errorMessage) => {
      alertMessage = errorMessage;
      return errorMessage;
    });
    
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={alertMessage}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.warning}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {alert}
      <GridContainer justify="center">
        <GridItem sm={12} md={8} lg={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter} `}
                color="quSandBox"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
                {/* <div className={classes.socialLine}>
                  {[
                    "fab fa-facebook-square",
                    "fab fa-twitter",
                    "fab fa-google-plus"
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div> */}
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  helperText={validationErrors[email]}
                  error={!!validationErrors[email] || false}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  helperText={validationErrors[password]}
                  error={!!validationErrors[password] || false}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
                {/* <Captcha onChange={onChange} /> */}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  onClick={cognitoLogin}
                  color="rose"
                  simple
                  size="lg"
                  block
                >
                  Log In
                </Button>

                <Button
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={() => history.push("/auth/reset-password-page")}
                >
                  Forgot Password
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
