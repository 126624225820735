import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

// @material-ui/core components
import { CircularProgress, Typography, Box } from "@material-ui/core";

// core components
import NotificationContext from "views/Components/Context.js";
import { CloudUpload, Create, DoneAll } from "@material-ui/icons";
import CreateCourseCard from "views/Components/CreateCourseCard";

import { fetchAllPodcastsAPI, createPodcastAPI, deletePodcastAPI, updatePodcastTagsAPI, cloneEntryAPI } from "views/APIs/APIUtility";
import CreatePodcastModal from "views/Components/CreatePodastModal";
import AssetDashboard from "views/Components/AssetDashboard";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";

// Custom components
import { fetchImageAsFile } from "views/Dashboard/Course/CourseConstants";
import PodcastDetails from "../Models/PodcastDetails";

const useStyles = makeStyles({
  ...styles,
})

export default function PodcastDesign() {
  const images = require.context("assets/img/create_cards_16_9/", true);
  const path = useRouteMatch().path;
  const classes = useStyles();

  const { showNotification } = React.useContext(NotificationContext);

  // state variables
  const [createPodcastModal, setCreatePodcastModal] = React.useState(false);
  const [newPodcastDetails, setNewPodcastDetails] = React.useState(new PodcastDetails());
  const [iconImage, setIconImage] = React.useState();
  const [allPodcasts, setAllPodcasts] = React.useState([]);
  const [initialPodcasts, setInitialPodcasts] = React.useState([]);
  const [loading, setLoading] = React.useState({ loading: false, message: "" })
  const [podcastCreationLoader, setPodcastCreationLoader] = React.useState(false);
  const [podcastAdded, setPodcastAdded] = React.useState(false);

  useEffect(() => {
    const fetchAllPodcasts = async () => {
      // Fetch all podcasts
      try {
        setLoading({ loading: true, message: "Loading Podcasts..." });
        const data = await fetchAllPodcastsAPI();
        const mappedData = data.map(_mapResourceToAppType);
        setAllPodcasts(mappedData);
        setInitialPodcasts(mappedData);
      } catch (error) {
        showNotification(error, "danger");
      } finally {
        setLoading({ loading: false, message: "" });
      }
    };

    fetchAllPodcasts();
  }, []);

  const handleIconUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIconImage(file);
    }
  };

  const handleDeletePodcast = async (podcastData) => {
    const formData = new FormData();
    formData.append("podcast_id", podcastData._id);

    try {
      const data = await deletePodcastAPI(formData);
      setAllPodcasts(
        allPodcasts.filter((podcast) => podcast._id !== podcastData._id)
      );
    } catch (error) {
      showNotification(error, "danger");
    } finally { }
  };

  const handleCloneCourse = async (courseData) => {
    try {
      setLoading({ loading: true, message: "Cloning Podcast..." });
      const formData = new FormData();
      formData.append("collection", "podcast_design");
      formData.append("id", courseData._id);

      const data = await cloneEntryAPI(formData);
      setAllPodcasts((prevCourses) => [...prevCourses, _mapResourceToAppType(data)]);
      setPodcastAdded(true);
      showNotification("Podcast cloned successfully", "success");
    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally {
      setLoading({ loading: false, message: "" });
    }
  };

  const handleCreatePodcast = async (modulesAtCreation = true) => {
    setPodcastCreationLoader(true);
    const imageToUse =
      iconImage || (await fetchImageAsFile(images("./podcast.png")));
    // const imageToUse = iconImage || images("./qusandbox_logo_v2.5.png");
    const formData = new FormData();
    formData.append("podcast_name", newPodcastDetails.name);
    formData.append("podcast_description", newPodcastDetails.description);
    formData.append("podcast_image", imageToUse);
    formData.append("podcast_transcript", newPodcastDetails.podcastOutline);
    newPodcastDetails.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const data = await createPodcastAPI(formData);
      setAllPodcasts([...allPodcasts, _mapResourceToAppType(data)]);
      setPodcastAdded(true);
      showNotification("Podcast created successfully", "success");
    } catch (error) {
      showNotification(error, "danger");
    } finally {
      setPodcastCreationLoader(false);
      handleCreatePodcastModalClose();
    }
  };

  const handleSaveLabel = async (data) => {
    try {
      const formData = new FormData();
      formData.append("podcast_id", data._id);
      if (data.tags.length === 0) {
        formData.append("tags", []);
      }
      // Append each tag separately
      else {
        data.tags.forEach(tag => formData.append("tags", tag));
      }
      await updatePodcastTagsAPI(formData);
      setAllPodcasts((prevPodcast) =>
        prevPodcast.map((podcast) =>
          podcast._id === data._id ? { ...podcast, tags: data.tags } : podcast
        )
      );
    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally {
    }
  };

  const handleCreatePodcastModalClose = () => {
    setCreatePodcastModal(false);
    setIconImage(null);
    setNewPodcastDetails(new PodcastDetails());
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "In Design Phase":
        return <Create style={{ color: "#435966" }} />;
      case "Ready for Publishing":
        return <CloudUpload style={{ color: "#435966" }} />;
      case "Published":
        return <DoneAll style={{ color: "#435966" }} />;
      default:
        return null;
    }
  };

  const _mapResourceToAppType = (resource) => {
    // Destructure the resource to exclude course_name and course_description
    const { podcast_name, podcast_description, podcast_image, ...rest } = resource;

    return {
      ...rest,               // Spread all remaining properties
      name: podcast_name,     // Add name property
      description: podcast_description, // Add description property
      image: podcast_image,   // Add image property
    };
  };

  if (loading.loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" className={classes.loaderClass} />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          {loading.message}
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <CreatePodcastModal
        newPodcastDetails={newPodcastDetails}
        setNewPodcastDetails={setNewPodcastDetails}
        iconImage={iconImage}
        createPodcastModal={createPodcastModal}
        handleCreatePodcastModalClose={handleCreatePodcastModalClose}
        handleCreatePodcast={handleCreatePodcast}
        handleIconUpload={handleIconUpload}
        podcastCreationLoader={podcastCreationLoader}
      />

      <AssetDashboard
        path={path}
        welcomeTitle="Welcome to the Podcast Design Dashboard!"
        welcomeSubtitle="Start by creating a new podcast or reviewing your existing podcasts."
        allAssets={allPodcasts}
        setAllAssets={setAllPodcasts}
        initialAssets={initialPodcasts}
        noAssetMessage="Create or Review your Podcasts to get started."
        linkPath="/podcast/"
        getStatusIcon={getStatusIcon}
        CreateAssetCard={CreateCourseCard}
        setCreateAssetModal={setCreatePodcastModal}
        createAssetButtonText="Create Podcast"
        handleSaveLabel={handleSaveLabel}
        asset_identifier="podcast"
        handleDeleteAsset={handleDeletePodcast}
        handleCloneAsset={handleCloneCourse}
        assetAdded={podcastAdded}
        setAssetAdded={setPodcastAdded}
      />
    </div>
  );
}
