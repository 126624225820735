import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
  Divider,
  Box,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const ImportProjectsModal = ({
  open,
  onClose,
  projects,
  selectedProject,
  setSelectedProject,
  handleProjectSelection,
}) => {
  // Function to handle selecting/deselecting a project
  const handleCheckboxChange = (templateId) => {
    setSelectedProject(templateId);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Select the Project to import the form into:</DialogTitle>
      <Divider />
      <DialogContent>
        {projects.length === 0 ? (
          <Box textAlign="center" py={3}>
            <Typography variant="body1">
              No projects found.{" "}
              <Link
                to="/admin/templates" // Navigate to the templates page
                style={{ color: "#3f51b5", textDecoration: "none" }} // Optional styling for the link
              >
                Create one now.
              </Link>
            </Typography>
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => (
                <TableRow key={project.id}>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      onChange={() => handleCheckboxChange(project._id)} // Handle the checkbox change
                      checked={selectedProject == project._id} // Check if the project is selected
                    />
                  </TableCell>
                  <TableCell>{project.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleProjectSelection} // Trigger the import process
          color="primary"
          disabled={!selectedProject} // Disable if no project is selected
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportProjectsModal;
