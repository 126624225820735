import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";

// Amplify components
import { Auth } from "aws-amplify";

// @material-ui/icons
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from '@material-ui/core/TextField';
import { InputLabel, FormControl, Select } from "@material-ui/core";

// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import availableProviders from "provider.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles(styles);
const useextendedFormsStyles = makeStyles(extendedFormsStyle);

export default function HeaderLinks(props) {
  const [openNotification, setOpenNotification] = React.useState(null);
  const extendedFormsClasses = useextendedFormsStyles();

  const [user, setUser] = React.useState("");
  const [editModal, setEditModal] = React.useState(false);
  // const [cloneModal, setCloneModal] = React.useState(false);
  // const [deleteModal, setDeleteModal] = React.useState(false);

  const [apiData, setApiData] = React.useState(props);

  // let { path, url } = useRouteMatch();
  // const rootPath = path.split(':')[0].slice(0, -1)

  React.useEffect(() => {
    setApiData(props.apiData);
  }, [props.apiData])

  // const [originalApiName, setOriginalApiName] = React.useState(apiData.APIName);
  // React.useEffect(() => {
  //   setOriginalApiName(props.apiData.APIName);
  // }, [props.apiData.APIName])

  // const [originalApiVersion, setOriginalApiVersion] = React.useState(apiData.APIName);
  // React.useEffect(() => {
  //   setOriginalApiVersion(props.apiData.APIVersion);
  // }, [props.apiData.APIVersion])

  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then(function (user) {
      setUser(user)
    }).catch(err => console.log(err));
  }, []);

  // const handleClickNotification = event => {
  //   if (openNotification && openNotification.contains(event.target)) {
  //     setOpenNotification(null);
  //   } else {
  //     setOpenNotification(event.currentTarget);
  //   }
  // };
  // const handleCloseNotification = () => {
  //   setOpenNotification(null);
  // };
  const [openProfile, setOpenProfile] = React.useState(null);
  // const handleClickProfile = event => {
  //   if (openProfile && openProfile.contains(event.target)) {
  //     setOpenProfile(null);
  //   } else {
  //     setOpenProfile(event.currentTarget);
  //   }
  // };
  // const handleCloseProfile = () => {
  //   setOpenProfile(null);
  // };
  const classes = useStyles();
  const { rtlActive } = props;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });

  const editButton = () => {
    setEditModal(true)
  }
  const handleEditClose = () => {
    setEditModal(false);
  };
  const handleEditProject = () => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(process.env.REACT_APP_BACK_END_URL + "/apiservice", {
      method: "PUT",
      mode: "cors",
      credentials: 'omit',
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      },
      body: JSON.stringify(apiData)
    }).then(function (res) {
      return res.json()
    }).then(function (data) {
      var pathArray = window.location.pathname.split('/')

      pathArray.pop()
      pathArray.pop()
      var basePath = pathArray.join('/')
      window.location.href = basePath + '/' + apiData.APIName + '/' + apiData.APIVersion
    }).catch((error) => {
      console.log(error)
    });
    setEditModal(false);
  }

  // const cloneButton = () => {
  //   setCloneModal(true)
  // }
  // const handleCloneClose = () => {
  //   setCloneModal(false);
  // };
  // const handleCloneProject = () => {

  //   let token = user.signInUserSession.accessToken.jwtToken;
  //   fetch(process.env.REACT_APP_BACK_END_URL + "/apiservice?operation=clone", {
  //     method: "POST",
  //     mode: "cors",
  //     credentials: 'omit',
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": token
  //     },
  //     body: JSON.stringify({ "APIName": apiData.APIName, "APIVersion": apiData.APIVersion })
  //   }).then((res) => {
  //     window.location.href = rootPath
  //   }).catch((error) => {

  //   });
  // }

  // const deleteButton = () => {
  //   setDeleteModal(true)
  // }
  // const handleDeleteClose = () => {
  //   setDeleteModal(false);
  // };
  // const handleDeleteProject = () => {

  //   let token = user.signInUserSession.accessToken.jwtToken;
  //   fetch(process.env.REACT_APP_BACK_END_URL + "/apiservice", {
  //     method: "DELETE",
  //     mode: "cors",
  //     credentials: 'omit',
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": token
  //     },
  //     body: JSON.stringify(apiData)
  //   }).then(function (res) {
  //     return res.json()
  //   }).then(function (data) {
  //     window.location.href = rootPath
  //   }).catch((error) => {

  //   });
  //   setDeleteModal(false);
  // }

  return (
    <div className={wrapper} style={{ float: "right" }}>

      <Dialog open={editModal} onClose={handleEditClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Project</DialogTitle>
        <DialogContent>

          <FormControl
            fullWidth
            className={extendedFormsClasses.selectFormControl}
          >
            <InputLabel
              htmlFor="simple-select"
              className={extendedFormsClasses.selectLabel}
            >
              Choose Type
            </InputLabel>
            <Select
              MenuProps={{
                className: extendedFormsClasses.selectMenu
              }}
              classes={{
                select: extendedFormsClasses.select
              }}
              onChange={event => { setApiData({ ...apiData, APIType: event.target.value }) }}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select",
                value: apiData.APIType
              }}
            >
              {Object.keys(availableProviders).map((provider, index) => (
                <MenuItem
                  key={index}
                  classes={{
                    root: extendedFormsClasses.selectMenuItem,
                    selected: extendedFormsClasses.selectMenuItemSelected
                  }}
                  value={provider}
                >
                  {provider}
                </MenuItem>
              ))
              }
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            className={extendedFormsClasses.selectFormControl}
          >
            <InputLabel
              htmlFor="simple-select"
              className={extendedFormsClasses.selectLabel}
            >
              Choose Permission
            </InputLabel>
            <Select
              MenuProps={{
                className: extendedFormsClasses.selectMenu
              }}
              classes={{
                select: extendedFormsClasses.select
              }}
              value={apiData.APIPermission}
              onChange={event => { setApiData({ ...apiData, APIPermission: event.target.value }) }}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select",
                value: apiData.APIPermission
              }}
            >
              <MenuItem
                key="0"
                classes={{
                  root: extendedFormsClasses.selectMenuItem,
                  selected: extendedFormsClasses.selectMenuItemSelected
                }}
                value="public"
              >
                Public
              </MenuItem>
              <MenuItem
                key="1"
                classes={{
                  root: extendedFormsClasses.selectMenuItem,
                  selected: extendedFormsClasses.selectMenuItemSelected
                }}
                value="private"
              >
                Private
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="head"
            label="Name"
            value={apiData.APIName}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, APIName: event.target.value })
            }}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="version"
            label="Version"
            value={apiData.APIVersion}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, APIVersion: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="tag"
            label="Tag(split by ,)"
            value={apiData.tag}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, tag: event.target.value.split(',') })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="brief"
            label="Brief Description"
            value={apiData.brief}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, brief: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="iframeLink"
            label="Link to iFrame content"
            value={apiData.iframeLink}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, iframeLink: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="iframeHeight"
            label="iFrame Height"
            value={apiData.iframeHeight}
            type="number"
            onChange={event => {
              setApiData({ ...apiData, iframeHeight: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="streamlitLink"
            label="Link for the Run Demo Button"
            value={apiData.streamlitLink}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, streamlitLink: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="gistLink"
            label="Link to git gist for this project"
            type="text"
            value={apiData.gistLink}
            onChange={event => { setApiData({ ...apiData, gistLink: event.target.value }) }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="colab"
            label="Link for the Run on QUSandbox Button"
            value={apiData.colab}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, colab: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="description"
            label="Detailed desciption for this project"
            value={apiData.description}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, description: event.target.value })
            }}
            fullWidth
          />
          <CustomInput
            labelText="Custom key json"
            id="custom_key_json"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              multiline: true,
              rows: 5,
              onChange: event => {
                setApiData({ ...apiData, json: event.target.value })
              },
              value: apiData.json
            }}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="quSandBoxColor">
            Cancel
          </Button>
          <Button onClick={handleEditProject} color="quSandBoxColor">
            Submit
          </Button>
        </DialogActions>
      </Dialog>


      {/* <Dialog open={cloneModal} onClose={handleCloneClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Clone Project</DialogTitle>
        <DialogContent>

          <FormControl
            fullWidth
            className={extendedFormsClasses.selectFormControl}
          >
            <InputLabel
              htmlFor="simple-select"
              className={extendedFormsClasses.selectLabel}
            >
              Choose Type
                        </InputLabel>
            <Select
              MenuProps={{
                className: extendedFormsClasses.selectMenu
              }}
              classes={{
                select: extendedFormsClasses.select
              }}
              onChange={event => { setApiData({ ...apiData, APIType: event.target.value }) }}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select",
                value: apiData.APIType
              }}
            >
              {Object.keys(availableProviders).map((provider, index) => (
                <MenuItem
                  key={index}
                  classes={{
                    root: extendedFormsClasses.selectMenuItem,
                    selected: extendedFormsClasses.selectMenuItemSelected
                  }}
                  value={provider}
                >
                  {provider}
                </MenuItem>
              ))
              }
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="head"
            label="Name"
            value={apiData.APIName}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, APIName: event.target.value })
            }}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="version"
            label="Version"
            value={apiData.APIVersion}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, APIVersion: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="tag"
            label="Tag(split by ,)"
            value={apiData.tag}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, tag: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="brief"
            label="Brief Description"
            value={apiData.brief}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, brief: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="iframeLink"
            label="Link to iFrame content"
            value={apiData.iframeLink}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, iframeLink: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="streamlitLink"
            label="Link to streamlit link where the demo is hosted"
            value={apiData.streamlitLink}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, streamlitLink: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="gistLink"
            label="Link to git gist for this project"
            type="text"
            onChange={event => { setApiData({ ...apiData, gistLink: event.target.value }) }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="colab"
            label="Link to the google colab document for this project"
            value={apiData.colab}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, colab: event.target.value })
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="description"
            label="Detailed desciption for this project"
            value={apiData.description}
            type="text"
            onChange={event => {
              setApiData({ ...apiData, description: event.target.value })
            }}
            fullWidth
          />
          <CustomInput
            labelText="Custom key json"
            id="custom_key_json"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              multiline: true,
              rows: 5,
              onChange: event => {
                setApiData({ ...apiData, json: event.target.value })
              },
              value: apiData.json
            }}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloneClose} color="quSandBoxColor">
            Cancel
          </Button>
          <Button onClick={handleCloneProject} color="quSandBoxColor">
            Submit
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* <Dialog open={deleteModal} onClose={handleDeleteClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete Project</DialogTitle>
        <DialogContent>
          Are you sure you want to delete?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="quSandBoxColor">
            Cancel
          </Button>
          <Button onClick={handleDeleteProject} color="quSandBoxColor">
            Submit
          </Button>
        </DialogActions>
      </Dialog> */}


      <Button
        color="transparent"
        onClick={editButton}
        simple
        aria-label="Dashboard"
        justIcon
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : ""
        }}
      >
        <EditIcon
          className={
            classes.headerLinksSvg +
            " " +
            (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
          }
        />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            {rtlActive ? "لوحة القيادة" : "Edit"}
          </span>
        </Hidden>
      </Button>
      {/* <Button
        color="transparent"
        onClick={cloneButton}
        simple
        aria-label="Dashboard"
        justIcon
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : ""
        }}
      >
        <FileCopyIcon
          className={
            classes.headerLinksSvg +
            " " +
            (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
          }
        />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            {rtlActive ? "لوحة القيادة" : "Clone"}
          </span>
        </Hidden>
      </Button>
      <Button
        color="transparent"
        onClick={deleteButton}
        simple
        aria-label="Dashboard"
        justIcon
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : ""
        }}
      >
        <DeleteIcon
          className={
            classes.headerLinksSvg +
            " " +
            (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
          }
        />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            {rtlActive ? "لوحة القيادة" : "Delete"}
          </span>
        </Hidden>
      </Button> */}
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
