// Custom hook to set the card title
export const useModuleID = () => {
    const setModuleID = (id) => {
        localStorage.setItem("selectedCardID", id);
    };

    const getModuleID = () => {
        return localStorage.getItem("selectedCardID");
    };

    return { setModuleID, getModuleID };
};
