import React from "react";
import { Typography, Tooltip } from "@material-ui/core";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardTitle from "components/Card/CardTitle";
import CardFooter from "components/Card/CardFooter.js";
import NewCardBody from "components/Card/NewCardBody";

import DeleteIcon from "@material-ui/icons/Delete";
import LabelIcon from "@material-ui/icons/Label";
import FileCopyIcon from '@material-ui/icons/FileCopy';

const AssetCard = ({
    asset,
    deleteAlert,
    cloneAlert,
    handleTags,
    getStatusIcon,
}) => {

    const images = require.context("assets/img", true);

    return (
        <>
            <Card style={{ marginTop: "40px" }}>
                <CardHeader icon text>
                    {/* <CardTitle color="quCreateColor">{asset.name + asset.name + asset.name + asset.name + asset.name + asset.name + asset.name + asset.name}</CardTitle> */}
                    <CardTitle color="quCreateColor">{asset.name}</CardTitle>
                </CardHeader>
                <NewCardBody image={asset.image || images("./qucreate_logo.png")}>
                    {/* {asset.description + asset.description + asset.description} */}
                    {asset.description}
                </NewCardBody>

                <CardFooter stats style={{ marginTop: "0px", paddingBottom: "10px" }}>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        {/* Status on the left */}
                        <Tooltip title={asset.status} arrow>
                            <Typography
                                variant="body2"
                                style={{
                                    color: "#435966",
                                    whiteSpace: "nowrap", // Prevent text from wrapping
                                    overflow: "hidden", // Hide overflowing text
                                    textOverflow: "ellipsis", // Add ellipsis if text overflows
                                    maxWidth: "calc(100% - 60px)", // Ensure the text does not go past icons
                                }}
                            >
                                {getStatusIcon(asset.status)} &nbsp; {asset.status}
                            </Typography>
                        </Tooltip>

                        {/* Icons on the right */}
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Tooltip title="Tags" arrow>
                                <LabelIcon
                                    style={{ color: "#435966", marginLeft: "10px" }}
                                    onClick={(e) => e.preventDefault(handleTags(asset))}
                                />
                            </Tooltip>
                            <Tooltip title="Clone" arrow>
                                <FileCopyIcon
                                    style={{ color: "#435966", marginLeft: "10px" }}
                                    onClick={(e) => e.preventDefault(cloneAlert(asset))}
                                />
                            </Tooltip>
                            <Tooltip title="Delete" arrow>
                                <DeleteIcon
                                    style={{ color: "#435966", marginLeft: "10px" }}
                                    onClick={(e) => e.preventDefault(deleteAlert(asset))}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </CardFooter>

            </Card>
        </>

    );
}

export default AssetCard;