import { Box, Grid, Icon, Typography, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import ReviewAssessment from "./ReviewAssessment";
import NotificationContext from "views/Components/Context.js";
import { replaceResourceAPI } from "views/APIs/APIUtility";

export default function Assessment(props) {
    const [activeSlide, setActiveSlide] = useState(0);
    const [quizObject, setQuizObject] = useState(null);
    const { showNotification } = React.useContext(NotificationContext);
    const [resourceMetaData, setResourceMetaData] = useState(null);


    const fetchQuestions = async (fileUrl) => {
        const formData = new FormData();
        formData.append("url", fileUrl);

        try {
            const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/fetch_quizdata`, {
                method: "POST",
                body: formData,
            });
            const data = await response.json();
            return data;
        } catch (err) {
            console.error("Error fetching file:", err);
        }
    };

    useEffect(() => {
        const setQuizzesAndFetchData = async () => {
            const card = props.assessment[0];
            if (card) {
                const quizDataLink = card.resource_link;
                const data = await fetchQuestions(quizDataLink);
                setQuizObject(data);
                setResourceMetaData(card);
            } else {
                console.warn("No assessment data found.");
            }
        };

        setQuizzesAndFetchData();
    }, [props.assessment]);

    const loadQuestion = (index) => {
        setActiveSlide(index);
    };

    const nextSlide = () => {
        if (activeSlide < quizObject.length - 1) {
            loadQuestion(activeSlide + 1);
        }
    };

    const previousSlide = () => {
        if (activeSlide > 0) {
            loadQuestion(activeSlide - 1);
        }
    };

    const handleQuizUpdate = (index, value, key) => {
        const updatedQuizData = [...quizObject];
        updatedQuizData[index] = {
            ...updatedQuizData[index],
            [key]: value,
        };
        setQuizObject(updatedQuizData);
    };

    if (!quizObject) {
        // return <Typography variant="h6" align="center">Loading quiz data...</Typography>;
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
            >
                <CircularProgress color="quCreateColor" />
                <Typography variant="h6" style={{ marginLeft: 16 }}>
                    Loading Quiz Data...
                </Typography>
            </Box>
        );
    }

    const convertDataToJSON = (data) => {
        const jsonString = JSON.stringify(data);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const file = new File([blob], 'data.json', { type: 'application/json' });
        return file;
    };

    const handleSave = async () => {
        const file = convertDataToJSON(quizObject);
        const formData = new FormData();
        formData.append("course_id", props.courseID);
        formData.append("module_id", props.moduleID);
        formData.append("resource_type", resourceMetaData.resource_type);
        formData.append("resource_description", resourceMetaData.resource_description);
        formData.append("resource_link", resourceMetaData.resource_link);
        formData.append("resource_id", resourceMetaData.resource_id);
        formData.append("resource_name", resourceMetaData.resource_name);
        formData.append("resource_file", file);
        formData.append("course_design_step", 11)

        try {
            await replaceResourceAPI(formData);
            showNotification("Assessment Updated Successfully", "success");
        }
        catch (error) {
            console.error("Error in handleUpdateNote:", error);
            showNotification("Error saving changes", "danger");
        }
    };

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: "20px" }}>
                        <Box>
                            <Typography variant="h5">Assessment</Typography>
                            <Typography variant="body1">Review the assessment questions.</Typography>
                        </Box>
                        <Button color="success" onClick={handleSave}>
                            <Icon>save</Icon> Save Changes
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ReviewAssessment
                        question={quizObject[activeSlide].question}
                        options={quizObject[activeSlide].options}
                        answer={quizObject[activeSlide].answer_option}
                        explanation={quizObject[activeSlide].explanation}
                        setQuizData={(value, key) => handleQuizUpdate(activeSlide, value, key)}
                    />
                </Grid>
                <Grid item xs={12} container justify="center">
                    <Grid item xs={4}>
                        <Button
                            style={{ float: "right" }}
                            color="quSandBoxColor"
                            disabled={activeSlide === 0}
                            onClick={previousSlide}
                        >
                            <Icon>arrow_back</Icon> Previous
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" align="center" style={{ marginTop: "10px" }}>
                            Question {activeSlide + 1} of {quizObject.length}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            style={{ float: "left" }}
                            color="quSandBoxColor"
                            disabled={activeSlide === quizObject.length - 1}
                            onClick={nextSlide}
                        >
                            Next <Icon>arrow_forward</Icon>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    );
}