// React and React Router
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Material UI Core Components and Icons
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  makeStyles,
  AppBar,
  Toolbar, Tooltip, Grid,
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { Description, Edit, Note, ViewAgenda, DoneAll } from "@material-ui/icons";

// Custom Components
import Resources from "../Resources";
import LabUseCase from "./LabUseCase";
import ReviewLabProject from "../ReviewLabProject";
import UploadSourceFile from "./UploadSourceFile";
import LabDesignStep from "./LabDesignStep";
import LabIdea from "./LabIdea";

// API Utilities
import { fetchLabAPI, updateLabDetails } from "views/APIs/APIUtility";

// Styles
import { stepperStyles, QontoConnector } from "views/Styles/stepperStyles";
import appbarStyles from "views/Styles/appbarStyles";

// Contexts
import NotificationContext from "views/Components/Context.js";
import { Auth } from "aws-amplify";


const useStyles = makeStyles((theme) => ({
  ...stepperStyles(theme),
  ...appbarStyles(theme),
}));

const labSteps = [
  {
    label: "Upload Resources",
    icon: <Description />,
    lockedMessage: "",
  },
  {
    label: "Lab Design",
    icon: <ViewAgenda />,
    lockedMessage: "Please upload at least one resource file to move on to the next step.",
  },
  {
    label: "Idea Selection",
    icon: <ViewAgenda />,
    lockedMessage: "Please review the lab design to move on to the next step.",
  },
  {
    label: "Technical Specifications",
    icon: <Edit />,
    lockedMessage:
      "Please review the ideas to move on to the next step.",
  },
  {
    label: "Review",
    icon: <Note />,
    lockedMessage:
      "Please review the technical specifications to move on to the next step.",
  },
];

export default function LabsBoard(props) {
  const history = useHistory();
  const classes = useStyles();
  const { showNotification } = React.useContext(NotificationContext);

  // Map lab status to step index
  const statusToStepMapping = {
    "In Design Phase": 0,
    "Lab Design": 1,
    "Idea Selection": 2,
    "Technical Specifications": 3,
    "In Lab Generation Queue": 3,
    "Review": 4,
    default: 0,
  };

  // State variables
  const [activeStep, setActiveStep] = useState(0);
  const [labCompletedTillStep, setLabCompletedTillStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([0]);
  const [boardLoading, setBoardLoading] = useState(false);
  const [labName, setLabName] = useState("");
  const [lab, setLab] = useState({});
  const [labDetails, setLabDetails] = useState({});

  const { labID } = props.match.params;

  // Update query string stage
  const setStage = (newStage) => {
    history.push(`?stage=${newStage}`);
  };

  // Fetch lab details on component mount or labID change
  useEffect(() => {
    async function fetchLabDetails() {
      try {
        setBoardLoading(true);
        setStage(0);
        const data = await fetchLabAPI(labID);
        setLab(data);
        const step = data.status;
        if (step === "Lab Generation Failed") {
          setLabCompletedTillStep(3);
          setActiveStep(3);
        } else {
          setLabCompletedTillStep(statusToStepMapping[step]);
          setActiveStep(statusToStepMapping[step]);
        }
        setLabDetails(data);
        setLabName(data.lab_name);
      } catch (error) {
        console.error("Error in fetchLabDetails:", error);
        showNotification("Error fetching lab details", "danger");
      } finally {
        setBoardLoading(false);
      }
    }
    fetchLabDetails();
  }, [labID]);

  // Handle visited steps
  useEffect(() => {
    setStage(0);
    if (!visitedSteps.includes(activeStep)) {
      const steps = [];
      for (let i = 0; i <= activeStep; i++) {
        steps.push(i);
      }
      setVisitedSteps(steps);
    }
  }, [activeStep, visitedSteps]);

  // Handle step click
  const handleStepClick = (index) => {
    if (visitedSteps.includes(index)) {
      setActiveStep(index);
    } else {
      const message = labSteps[index].lockedMessage || "This step is locked.";
      showNotification(message, "danger");
    }
  };

  // Render content based on active step
  const renderContent = () => {
    if (!labSteps[activeStep]) {
      return <></>;
    }
    switch (labSteps[activeStep].label) {
      case "Upload Resources":
        return (
          <UploadSourceFile
            labID={labID}
            setLabName={setLabName}
            setActiveStep={setActiveStep}
          />
        );
      case "Lab Design":
        return <LabDesignStep labID={labID} setStage={setStage} setActiveStep={setActiveStep} />;
      case "Idea Selection":
        return <LabIdea labID={labID} setActiveStep={setActiveStep} />;
      case "Technical Specifications":
      case "Lab Generation Failed":
        return (
          <LabUseCase
            projectID={labID}
            projectType="lab_designer"
            setActiveStep={setActiveStep}
            useCase="technical"
          />
        );
      case "Review":
        return (
          <ReviewLabProject
            projectID={labID}
            projectType="lab_designer"
            labDetails={labDetails}
            setActiveStep={setActiveStep}
          />
        );
      default:
        return <></>;
    }
  };

  // Update lab details on change
  const handleLabDetailsChange = async (newLabDetails) => {
    try {
      const formData = new FormData();
      formData.append("lab_id", labID);
      formData.append("lab_name", newLabDetails.title);
      formData.append("lab_description", newLabDetails.shortDescription);
      await updateLabDetails(formData);
      setLab((prevLab) => ({
        ...prevLab,
        lab_name: newLabDetails.title,
        lab_description: newLabDetails.shortDescription,
      }));
      showNotification("Lab details updated successfully", "success");
    } catch (error) {
      showNotification("An error occurred while updating course details.", "danger");
    }
  };

  const [inBetweenStagesLoaderMessage, setInBetweenStagesLoaderMessage] = useState("");
  const inBetweenStagesLoaderOptions = {
    "In Outline Generation Queue": "We are generating the outline for you...",
    "In Content Generation Queue": "We are generating the content for you...",
    "In Structure Generation Queue": "We are generating the structure for you...",
    "In Lab Generation Queue": "We are generating the lab for you...",
    "In Publishing Queue": "Lab marked ready for publishing",
  };

  const [user, setUser] = useState("");

  // write a async useeffect to set the user to auth.currentAuthenticatedUser
  useEffect(() => {
    async function fetchUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch (error) {
        console.error("Error in fetchUser:", error);
        showNotification("Error fetching user details", "danger");
      }
    }
    fetchUser();
  }, []);


  useEffect(() => {
    if (inBetweenStagesLoaderOptions.hasOwnProperty(lab.status)) {
      setInBetweenStagesLoaderMessage(inBetweenStagesLoaderOptions[lab.status]);
    }
  }, [lab.status]);

  useEffect(() => {
    if (user === "" || lab._id === undefined || lab.status !== "In Lab Generation Queue") {
      return;
    }
    const wsUrl = `${process.env.REACT_APP_FASTAPI_URL.replace("http", "ws")}/ws/tasks/${user.username}/${lab._id}`;
    console.log("WebSocket URL is: ", wsUrl);
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => console.log("Task WebSocket connected");
    ws.onmessage = (event) => {
      console.log("Task message:", event.data);
      if (event.data === "Done") {
        setInBetweenStagesLoaderMessage("Lab generation complete");
        window.location.reload();
      }
      else {
        setInBetweenStagesLoaderMessage("Failed");
        setLab((prevLab) => ({
          ...prevLab,
          status: "Lab Generation Failed",
        }));
      }
    };
    ws.onclose = () => console.log("Task WebSocket disconnected");

    return () => ws.close();
  }, [user, lab._id, lab.status]);


  if (boardLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          Loading Lab Board...
        </Typography>
      </Box>
    );
  }


  return (
    <Box>
      {inBetweenStagesLoaderOptions.hasOwnProperty(lab.status) && (
        <Grid container alignItems="center" width="100%">
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
              <Tooltip title="Your job has been submitted and you will be notified when the request is ready" arrow placement="top">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {lab.status === "In Publishing Queue" ? (
                    <>
                      <CheckIcon style={{ color: "green", marginRight: "8px" }} />
                      <Typography variant="subtitle2" style={{ marginRight: "8px", cursor: "pointer" }}>
                        {inBetweenStagesLoaderMessage}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="subtitle2" style={{ marginRight: "8px", cursor: "pointer" }}>
                        {inBetweenStagesLoaderMessage}
                      </Typography>
                      <CircularProgress size={20} />
                    </>
                  )}
                </div>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      )}
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            {/* Title */}
            <Typography
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bold",
                flexShrink: 0,
                maxWidth: "45%", // Ensures it does not exceed the container width
                wordBreak: "break-word", // Allows long words to break and wrap to the next line if necessary
                overflow: "hidden", // Hides any overflow if text is too long
                whiteSpace: "normal", // Ensures the text wraps
              }}
            >
              {labName}
            </Typography>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<QontoConnector />}
              size="small"
              className={classes.stepperRoot}
              style={{ flexGrow: 1 }} // Stepper will take up the remaining space
            >
              {labSteps.map((step, index) => {
                const isDisabled = index > labCompletedTillStep; // Disable steps beyond completed step

                return (
                  <Step key={step.label} onClick={() => handleStepClick(index)}>
                    <StepLabel
                      StepIconProps={
                        !isDisabled
                          ? {
                            classes: {
                              root: classes.stepIcon, // Default icon color
                              active: classes.activeStepIcon, // Active step circle
                              completed: classes.completedStepIcon, // Completed step color
                            },
                          }
                          : {} // If disabled, no classes applied
                      }
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Toolbar>
      </AppBar>

      <Box mt={3}>{renderContent()}</Box>
    </Box>
  );
}